import styled from "@emotion/styled";

const MainCard = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    theme.splus3.shadow.mainCard,
    {
        borderRadius: 6,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
        },        
    }
]));

export default MainCard;