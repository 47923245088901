import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogMessage } from '@visonum/network-quality-sdk/dist/utils/logger';
import { logger } from '../../helper/logger';
import { allowShowLog } from '../../helper/utils';
import { AppThunk } from '../../store';

export interface DebugLogState {
    showDebugLog: boolean;
    collapsed: boolean;
    messages: LogMessage[];
}

const initialState: DebugLogState = {
    showDebugLog: false,
    collapsed: false,
    messages: [],
}

export const initDebugLog = (): AppThunk => (dispatch, getState) => {
    dispatch(setShowDebugLog(allowShowLog()));
    logger.getMessages().subscribe({
        next: (value) => {
            dispatch(addMessage(value));
        },
    });
};

export const debugLogSlice = createSlice({
    name: 'debugLog',
    initialState,
    reducers: {
        setShowDebugLog: (state, action: PayloadAction<boolean>) => {
            state.showDebugLog = action.payload;
        },
        addMessage: (state, action: PayloadAction<LogMessage>) => {
            state.messages = [...state.messages, action.payload];
        },
        collapse: (state) => {
            state.collapsed = true;
        },
        expand: (state) => {
            state.collapsed = false;
        },
    }
});

export const { setShowDebugLog, addMessage, collapse, expand } = debugLogSlice.actions;

export default debugLogSlice;