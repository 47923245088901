import * as R from "ramda";
import UAParser from "ua-parser-js";

interface CompatibilityItem {
    supportedVersion: string;
}

export type OsName = "iOS" | "Tizen";

interface OsCompatibilityItem extends CompatibilityItem {
    name: OsName;
}

const osCompatibilityList: OsCompatibilityItem[] = [
    { name: "iOS", supportedVersion: "14" },
    { name: "Tizen", supportedVersion: "6.5" },
];

export type BrowserName = "Chrome" | "Edge" | "Safari" | "Firefox" | "Opera" | "IE" | "IE Mobile" | "Mobile Safari";

interface BrowserCompatibilityItem extends CompatibilityItem {
    name: BrowserName;    
}

const compatibilityList: BrowserCompatibilityItem[] = [
    { name: "Chrome", supportedVersion: "85" },
    { name: "Edge", supportedVersion: "85" },
    { name: "Safari", supportedVersion: "13.1" },
    { name: "Firefox", supportedVersion: "77" },
    { name: "Opera", supportedVersion: "71" },
    { name: "IE", supportedVersion: "" },
    { name: "IE Mobile", supportedVersion: "" },
    { name: "Mobile Safari", supportedVersion: "13.4" },
];

export const getIsSupported = (userAgent: string): boolean => {
    const uaParser = new UAParser(userAgent);

    const currentOs = uaParser.getOS();
    const foundOs = osCompatibilityList.find(x => x.name === currentOs.name);

    if (R.isNil(foundOs) || R.isNil(currentOs.version)) {
        return true;
    }

    const c = compareVersion(currentOs.version, foundOs.supportedVersion);
    return R.isNil(c) ? true : 0 <= c;
}

const compareVersion = (v1: string, v2: string): number | undefined => {
    if (R.isEmpty(v1) || R.isEmpty(v2)) {
        return undefined;
    }

    const v1a = v1.split(".");
    const v2a = v2.split(".");

    for (let i = 0; i < v1a.length; i += 1) {
        const n1 = +v1a[i]!;
        const n2 = i < v2a.length ? +v2a[i]! : 0;
        if (isNaN(n1) || isNaN(n2)) {
            return undefined;
        }

        if (n1 !== n2) {
            return n1 - n2;
        }
    }
}