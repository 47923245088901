import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import { ConsentProps, getConsentProps, setConsentProps } from "../../helper/storage";
import { NODE_ENV, VARIANT } from "../../config";
import { hideDialog, showConsentDialog } from "../dialogManager/dialogManagerSlice";
import { initMatomo } from "../matomo/matomoSlice";
import { initSentryReports } from "../sentryReports/sentryReportsSlice";

const ConsentTimeToLive = 1 * (1000 * 60 * 60 * 24); // in ms

export interface ConsentState {
    accepted: boolean;
    analytics: boolean;
    bugReports: boolean;
}

const initialState: ConsentState = {
    accepted: false,
    analytics: false,
    bugReports: false,
}

const consentIsLive = (consentProps: ConsentProps): boolean => {
    const now = new Date();
    const timeDelta = now.getTime() - consentProps.timestamp.getTime();
    return timeDelta < ConsentTimeToLive;
}

export const initConsent = (): AppThunk => (dispatch, _) => {
    if (NODE_ENV === "test") {
        dispatch(consentSlice.actions.accept());
        return;
    }
    
    if (VARIANT === "SPLUS" || VARIANT === "SPLUS3") {
        const consentProps = getConsentProps();
        if (consentProps !== null && consentIsLive(consentProps)) {
            dispatch(updateConsentAnalytics(consentProps.analytics));
            dispatch(updateConsentBugReports(consentProps.bugReports));
            dispatch(consentSlice.actions.accept());
        } else {
            dispatch(showConsentDialog());            
        }
    } else {
        dispatch(consentSlice.actions.accept());
    }
}

export const closeConsentDialogAndSaveState = (): AppThunk => (dispatch, getState) => {
    const state = getState().consent;
    setConsentProps({ analytics: state.analytics, bugReports: state.bugReports, timestamp: new Date() });
    dispatch(hideDialog());
    dispatch(initMatomo());
    dispatch(initSentryReports());
    dispatch(consentSlice.actions.accept());
}

export const consentSlice = createSlice({
    name: 'consent',
    initialState,
    reducers: {
        accept: (state) => {
            state.accepted = true;
        },
        updateConsentAnalytics: (state, action: PayloadAction<boolean>) => {
            state.analytics = action.payload;
        },
        updateConsentBugReports: (state, action: PayloadAction<boolean>) => {
            state.bugReports = action.payload;
        },
    }
});

export const { updateConsentAnalytics, updateConsentBugReports } = consentSlice.actions;

export default consentSlice;