import { freeze } from "immer";
import { theme as defaultTheme} from "./default/theme";
import { gigaTvTheme } from "./gigatv/giga-tv-theme";
import { v3Theme } from "./splus3/splus3-theme";

/**
 * An app theme. All leaf values in this object are of type SerializedStyles.
 */
export const theme = freeze({
  ...defaultTheme,
  gigatv: gigaTvTheme,
  splus3: v3Theme,
} as const);

export type AppTheme = typeof theme;
export interface AppThemeProps {
  theme: AppTheme;
}

declare module "@emotion/react" {
  export interface Theme extends AppTheme {}
}
