import styled from "@emotion/styled";
import { r } from "../../../theme/gigatv/sizeUtils";
import CommonButton from "../../../components/elements/gigatv/buttons/CommonButton";
import { InfoButton, InfoScreenPart, InfoTab } from "../../../features/focusManager/FocusabeItemKey";
import Impressum from "./Impressum";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import { InfoScreenState } from "../../../features/focusManager/ViewScreenState";
import { getPackageVersion } from "../../../helper/packageJsonHelper";

const RootDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.fog,
    {
        display: "flex",
        justifyContent: "center",
        height: "100vh",
    }
]));

const InnerContainer = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        marginTop: r(48),
        maxWidth: r(1360),
        minWidth: r(800),
    }
]);

const ControlContainer = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        rowGap: r(78),
    }
]);

const HeaderDiv = styled.div([
    {
        display: "flex",
        columnGap: r(20),
        alignItems: "center",
    }
]);

const HeaderTextDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.fontSize40,
    theme.gigatv.typography.lite,
    theme.gigatv.colors.foreground.primary,
    {
        flexGrow: 10,
    }
]));

const TabPanelDiv = styled.div([
    {
        marginTop: r(48),
        display: "flex",
        columnGap: r(20),
        justifyContent: "center",
    }
]);

export const VersionDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.regular,
    theme.gigatv.typography.fontSize14,
    theme.gigatv.colors.foreground.primary,
    {
        position: "absolute",
        right: r(24),
        bottom: r(24),
        lineHeight: "75%",
        opacity: 0.50
    }
]));

interface InfoScreenProps {
    state: InfoScreenState;
}

const InfoScreen: React.FC<InfoScreenProps> = ({ state }) => {
    const getTab = () => {
        switch (state.selectedTab) {
            case InfoTab.Impressum:
                return (<Impressum />);
            case InfoTab.PrivacyPolicy:
                return (<PrivacyPolicy />);
            case InfoTab.TermsAndConditions:
                return (<TermsAndConditions />);
        }
    }

    return (
        <RootDiv>
            <InnerContainer>
                <ControlContainer>
                    <HeaderDiv>
                        <HeaderTextDiv>
                            Info
                        </HeaderTextDiv>

                        <CommonButton dataCy="infoScreenSpeedtestButton" isFocused={state.screenPart === InfoScreenPart.Buttons && state.selectedButton === InfoButton.SpeedtestStart}>
                            Speedtest neu starten
                        </CommonButton>
                        <CommonButton dataCy="infoScreenResultButton" isFocused={state.screenPart === InfoScreenPart.Buttons && state.selectedButton === InfoButton.Result}>
                            Deine Messungen
                        </CommonButton>
                    </HeaderDiv>
                </ControlContainer>

                <TabPanelDiv>
                    <CommonButton dataCy="infoScreenImpressumTab" isFocused={state.screenPart === InfoScreenPart.Tabs && state.selectedTab === InfoTab.Impressum}>
                        Impressum
                    </CommonButton>
                    <CommonButton dataCy="infoScreenPrivacyTab" isFocused={state.screenPart === InfoScreenPart.Tabs && state.selectedTab === InfoTab.PrivacyPolicy}>
                        Datenschutz
                    </CommonButton>
                    <CommonButton dataCy="infoScreenTermsTab" isFocused={state.screenPart === InfoScreenPart.Tabs && state.selectedTab === InfoTab.TermsAndConditions}>
                        AGBs
                    </CommonButton>
                </TabPanelDiv>

                {getTab()}

                <VersionDiv>v{getPackageVersion()}</VersionDiv>
            </InnerContainer>
        </RootDiv>
    );
}

export default InfoScreen;
