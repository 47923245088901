import Hint3Json from "./Hint3Json";
import { LangName } from "../../types";

import no_problems_de from "../../../public/assets/tips3/de/00_no_problems.json";
import browser_de from "../../../public/assets/tips3/de/01_browser.json";
import disturbance_source_de from "../../../public/assets/tips3/de/02_disturbance_source.json";
import modem_position_de from "../../../public/assets/tips3/de/03_modem_position.json";
import connect_cable_de from "../../../public/assets/tips3/de/04_connect_cable.json";
import old_device_de from "../../../public/assets/tips3/de/06_old_device.json";
import semi_old_device_de from "../../../public/assets/tips3/de/07_semi_old_device.json";
import device_not_compatible_400mb_de from "../../../public/assets/tips3/de/08_device_not_compatible_400mb.json";
import device_not_compatible_1000mb_de from "../../../public/assets/tips3/de/09_device_not_compatible_1000mb.json";
import network_improvements2_de from "../../../public/assets/tips3/de/10_network_improvements2.json";
import network_improvements3_de from "../../../public/assets/tips3/de/11_network_improvements3.json";
import parallel_traffic_de from "../../../public/assets/tips3/de/12_parallel_traffic.json";
import lan_detected_de from "../../../public/assets/tips3/de/14_lan_detected.json";
import vpn_detected_de from "../../../public/assets/tips3/de/15_vpn_detected.json";
import network_improvements_upload2_de from "../../../public/assets/tips3/de/16_network_improvements_upload2.json";
import network_improvements_upload3_de from "../../../public/assets/tips3/de/17_network_improvements_upload3.json";
import wlan_standard_de from "../../../public/assets/tips3/de/19_wlan_standard.json";
import superwifi_gen_dormant_web_de from "../../../public/assets/tips3/de/30_superwifi_gen_dormant_web.json";
import superwifi_gen_dormant_sca_de from "../../../public/assets/tips3/de/31_superwifi_gen_dormant_sca.json";
import superwifi_gen_passiveplus_web_de from "../../../public/assets/tips3/de/32_superwifi_gen_passiveplus_web.json";
import superwifi_gen_active_web_de from "../../../public/assets/tips3/de/33_superwifi_gen_active_web.json";
import superwifi_lowspeed_passiveplus_web_de from "../../../public/assets/tips3/de/34_superwifi_lowspeed_passiveplus_web.json";
import superwifi_lowspeed_active_web_de from "../../../public/assets/tips3/de/36_superwifi_lowspeed_active_web.json";
import ps_reboot_de from "../../../public/assets/tips3/de/25_ps_reboot.json";
import ps_callback_de from "../../../public/assets/tips3/de/26_ps_callback.json";
import device_fast_as_it_can_de from "../../../public/assets/tips3/de/41_device_fast_as_it_can.json";

import no_problems_en from "../../../public/assets/tips3/en/00_no_problems.json";
import browser_en from "../../../public/assets/tips3/en/01_browser.json";
import disturbance_source_en from "../../../public/assets/tips3/en/02_disturbance_source.json";
import modem_position_en from "../../../public/assets/tips3/en/03_modem_position.json";
import connect_cable_en from "../../../public/assets/tips3/en/04_connect_cable.json";
import old_device_en from "../../../public/assets/tips3/en/06_old_device.json";
import semi_old_device_en from "../../../public/assets/tips3/en/07_semi_old_device.json";
import device_not_compatible_400mb_en from "../../../public/assets/tips3/en/08_device_not_compatible_400mb.json";
import device_not_compatible_1000mb_en from "../../../public/assets/tips3/en/09_device_not_compatible_1000mb.json";
import network_improvements2_en from "../../../public/assets/tips3/en/10_network_improvements2.json";
import network_improvements3_en from "../../../public/assets/tips3/en/11_network_improvements3.json";
import parallel_traffic_en from "../../../public/assets/tips3/en/12_parallel_traffic.json";
import lan_detected_en from "../../../public/assets/tips3/en/14_lan_detected.json";
import vpn_detected_en from "../../../public/assets/tips3/en/15_vpn_detected.json";
import network_improvements_upload2_en from "../../../public/assets/tips3/en/16_network_improvements_upload2.json";
import network_improvements_upload3_en from "../../../public/assets/tips3/en/17_network_improvements_upload3.json";
import wlan_standard_en from "../../../public/assets/tips3/en/19_wlan_standard.json";
import superwifi_gen_dormant_web_en from "../../../public/assets/tips3/en/30_superwifi_gen_dormant_web.json";
import superwifi_gen_dormant_sca_en from "../../../public/assets/tips3/en/31_superwifi_gen_dormant_sca.json";
import superwifi_gen_passiveplus_web_en from "../../../public/assets/tips3/en/32_superwifi_gen_passiveplus_web.json";
import superwifi_gen_active_web_en from "../../../public/assets/tips3/en/33_superwifi_gen_active_web.json";
import superwifi_lowspeed_passiveplus_web_en from "../../../public/assets/tips3/en/34_superwifi_lowspeed_passiveplus_web.json";
import superwifi_lowspeed_active_web_en from "../../../public/assets/tips3/en/36_superwifi_lowspeed_active_web.json";
import ps_reboot_en from "../../../public/assets/tips3/en/25_ps_reboot.json";
import ps_callback_en from "../../../public/assets/tips3/en/26_ps_callback.json";
import device_fast_as_it_can_en from "../../../public/assets/tips3/en/41_device_fast_as_it_can.json";

const allHintsDe = [
    no_problems_de,
    browser_de,
    disturbance_source_de,
    modem_position_de,
    connect_cable_de,
    wlan_standard_de,
    old_device_de,
    semi_old_device_de,
    device_not_compatible_400mb_de,
    device_not_compatible_1000mb_de,
    network_improvements2_de,
    network_improvements3_de,
    parallel_traffic_de,
    lan_detected_de,
    vpn_detected_de,
    network_improvements_upload2_de,
    network_improvements_upload3_de,
    superwifi_gen_dormant_web_de,
    superwifi_gen_dormant_sca_de,
    superwifi_gen_passiveplus_web_de,
    superwifi_gen_active_web_de,
    superwifi_lowspeed_passiveplus_web_de,
    superwifi_lowspeed_active_web_de,
    ps_reboot_de,
    ps_callback_de,
    device_fast_as_it_can_de,
];

const allHintsEn = [
    no_problems_en,
    browser_en,
    disturbance_source_en,
    modem_position_en,
    connect_cable_en,
    wlan_standard_en,
    old_device_en,
    semi_old_device_en,
    device_not_compatible_400mb_en,
    device_not_compatible_1000mb_en,
    network_improvements2_en,
    network_improvements3_en,
    parallel_traffic_en,
    lan_detected_en,
    vpn_detected_en,
    network_improvements_upload2_en,
    network_improvements_upload3_en,
    superwifi_gen_dormant_web_en,
    superwifi_gen_dormant_sca_en,
    superwifi_gen_passiveplus_web_en,
    superwifi_gen_active_web_en,
    superwifi_lowspeed_passiveplus_web_en,
    superwifi_lowspeed_active_web_en,
    ps_reboot_en,
    ps_callback_en,
    device_fast_as_it_can_en,
];

/* pure */
const getHints3 = (lang: LangName): Hint3Json[] => {
    switch (lang) {
        case "de": return allHintsDe;
        case "en": return allHintsEn;
    }
}

export default getHints3;