import { getCurrentLang } from "../../../helper/utils";

import info_v3_de from "../../../../public/assets/pages/de/info_v3.json";
import info_v3_en from "../../../../public/assets/pages/en/info_v3.json";

export interface InfoJson {
    title: string;
    content: string;
    image: string;
    imageAlt: string;
    items: InfoItemJson[];
}

export interface InfoItemJson {
    title: string;
    items?: InfoSubitemJson[];
}

export interface InfoSubitemJson {
    title: string;
    content: string;
}

export const getInfoData = (): InfoJson => {
    switch (getCurrentLang()) {
        case "de": return info_v3_de;
        case "en": return info_v3_en;
    }
}