import styled from "@emotion/styled";
import { r } from "../../../theme/gigatv/sizeUtils";
import { StaticImageData } from "next/image";

const RootDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.regular,
    theme.gigatv.typography.fontSize28,
    {
        lineHeight: "133%",
        display: "flex",
        flexDirection: "column",
        rowGap: r(48),
    }
]));

const HeaderDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize40,
    theme.gigatv.typography.regular,
]));

const TextDiv = styled.div([
]);

const ContentHeaderDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.fontSize28,
    theme.gigatv.typography.bold,
    {
        lineHeight: "133%",
    }
]));

const QrImageDiv = styled.div([
    {
        width: r(140),
    }
]);

interface CommonControlProps {
    header: string;
    contentHeader: string;
    qrImage: StaticImageData;
}

const CommonControl: React.FC<CommonControlProps> = ({ header, contentHeader, qrImage, children }) => {
    return (
        <RootDiv>
            <HeaderDiv>
                {header}
            </HeaderDiv>
            <TextDiv>
                <ContentHeaderDiv>
                    {contentHeader}
                </ContentHeaderDiv>
                {children}
                <QrImageDiv>
                    <img width="100%" src={qrImage.src} alt="qr-image" />
                </QrImageDiv>
            </TextDiv>
        </RootDiv>
    );
}

export default CommonControl;