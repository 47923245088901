import { NextRouter } from "next/router";
import * as R from "ramda";

export const getUrlParamValue = (url: string | null | undefined, key: string): string | null => {
    if (R.isNil(url)) {
        return null;
    }

    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.get(key);
}

export const getUrlQuery = (url: string | null | undefined): string | null => {
    if (R.isNil(url)) {
        return null;
    }

    const parsedUrl = new URL(url);
    if (R.isEmpty(parsedUrl.search)) {
        return null;
    }
    const result = parsedUrl.search.substring(1);
    return result;
}

export const combineUrlParts = (part1: string, part2: string): string => {
    const p1 = part1.replace(/\/?$/g, "");
    const p2 = part2.replace(/^\/?/g, "");
    if (R.isEmpty(part1)) {
        return p2;
    }
    if (R.isEmpty(part2)) {
        return p1;
    }
    return `${p1}/${p2}`;
}

export const getApiEndpointUrl = (endpointName: string, baseUrl: string) => {
    if (R.isEmpty(baseUrl)) {
        return endpointName;
    }

    const normalBaseUrl = new URL(baseUrl);
    return combineUrlParts(normalBaseUrl.href, endpointName);
}

export const isInternalLink = (href: string): boolean => {
    return href.startsWith("/");
}

export const isSpecialLink = (href: string): boolean => {
    return isInternalLink(href) && (
        href.includes("/privacy_policy_faq") ||
        href.includes("/cookie_policy")
    );
}

export const getTargetByHref = (href: string) => {
    if (href == "https://vodafone.de") {
        return "_self";
    }
    
    return (isInternalLink(href) && !isSpecialLink(href)) ? undefined : "_blank"
}

export const getRelByHref = (href: string) => {
    return isInternalLink(href) ? undefined : "noopener noreferrer"
}

export const getFragment = (router: NextRouter): string | null => {
    if (R.isNil(router)) {
        return null;
    }

    const str = router.asPath.split("#")[1];
    if (R.isNil(str)) {
        return null;
    }

    return str;
}

export const getId = (router: NextRouter): number | null => {
    const idStr = getFragment(router);
    if (R.isNil(idStr)) {
        return null;
    }
    const tippId = parseInt(idStr);
    return Number.isFinite(tippId) ? tippId : null;
}