import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import { createNQRecord } from "./createNQRecord";
import { migrateLocalStorage } from "./migrateLocalStorage";
import { decodeFromLocalStorage } from "./decodeFromLocalStorage";
import { getHistory, getLastModemResetDate, setHistory } from "../../helper/storage";
import { serializeToLocalStorage } from "./serializeToLocalStorage";
import { NQRecord, NQRecordPartialService } from "./types";
import { HistoryState } from "./HistoryState";
import { SDK_KIND, VARIANT } from "../../config";
import { getSyntheticHistory } from "./syntheticHistory";
import { logger } from "../../helper/logger";
import { lastValueFrom } from "rxjs";
import { getSdk } from "../../networkQuality";
import { Models } from "@visonum/network-quality-sdk";
import sentryCaptureError from "../sentryReports/sentryCaptureError";
import { getNqError } from "../errors/errorUtils";
import { generateInputForCustomPSCase, modifyNqRecordForCustomPSCase, PartialServiceCase } from "../partialService/syntheticPartialService";
import { isRunningInBrowser } from "../../helper/ssr";
import { getUrlParamValue } from "../../helper/utils";
import * as R from "ramda";
import { plainDate } from "../../types";

const initialState: HistoryState = {
  records: [],
  selectedRecords: [],
  expandedRecord: null,
  firstItemIndex: 0,
  frameItemCount: 6,
}

export const initHistory = (): AppThunk => (dispatch, _) => {
  if (SDK_KIND === "Synthetic") {
    dispatch(updateRecords(getSyntheticHistory()));
  } else {
    migrateLocalStorage();
    const records = decodeFromLocalStorage(getHistory());
    dispatch(updateRecords(records));
  }
}

export const deleteAllRecords = (): AppThunk => (dispatch, _) => {
  dispatch(updateStore([]));
}

export const deleteSelectedRecords = (): AppThunk => (dispatch, getState) => {
  const history = getState().history;
  const newItems = history.records.filter(v => !history.selectedRecords.includes(v));
  dispatch(updateSelectedRecords([]));
  dispatch(updateStore(newItems));
}

export const deleteRecordBySpeedtestId = (speedtestId: string): AppThunk => (dispatch, getState) => {
  const newItems = getState().history.records.filter(v => v.prepareResult.init.speedtest.id !== speedtestId);
  dispatch(updateStore(newItems));
}

const getNqRecordPartialService = async (): Promise<NQRecordPartialService> => {
  const callbackCounter = await (async () => {
    try {
      logger.info("getNqRecordPartialService: get callbackCounter...");
      const retVal = await lastValueFrom(getSdk().updateValue(Models.ValueKey.CallbacksCounter, Models.ValueCommand.Read));
      logger.info("initPartialService: get callbackCounter: " + retVal);
      return retVal;
    } catch (err) {
      logger.error("getNqRecordPartialService: get callbackCounter", err);
      sentryCaptureError(getNqError(err));
      return 0;
    }
  })();

  return {
    lastModemResetDate: getLastModemResetDate(),
    callbackCounter,
  }
}

const psCaseFromUrl = (): PartialServiceCase | null => {
  const url = isRunningInBrowser() ? window.location.href : null;
  const param = getUrlParamValue(url, "pscase");

  if (!R.isNil(param)) {
    switch (param) {
      case "Default":
      case "FirstStartResultIsNotOkInterfaceHasErrors":
      case "FirstStartResultIsOkInterfaceHasManyErrors":
      case "FirstStartCase3":
      case "AgainResultIsNotOkInterfaceHasManyErrors":
        return param;
    }
  }

  return null;
}

export const appendStoreNQValues = (): AppThunk<Promise<NQRecord | null>> => async (dispatch, getState) => {
  const state = getState();
  const measurement = state.measurement;
  const nqPartialService = (VARIANT === "SPLUS" || VARIANT === "SPLUS3") ?
    await getNqRecordPartialService() :
    null;

  const nqRecord = createNQRecord(plainDate(), measurement, nqPartialService);

  if (nqRecord === null) {
    return null;
  } else {
    // pscase -> Synthetic Partial Service
    const psCase = psCaseFromUrl();
    const newNqRecord = psCase === null ? nqRecord : modifyNqRecordForCustomPSCase(nqRecord, generateInputForCustomPSCase(psCase))
    const newItems = [...state.history.records, newNqRecord];
    dispatch(updateStore(newItems));
    return newNqRecord;
  }
}

export const updateStore = (newItems: NQRecord[]): AppThunk => (dispatch, _) => {
  dispatch(updateRecords(newItems));
  const serialized = serializeToLocalStorage(newItems);
  setHistory(serialized);
}

export const historySlice = createSlice({
  name: 'history',
  initialState: initialState,
  reducers: {
    updateRecords: (state, action: PayloadAction<NQRecord[]>) => {
      state.records = action.payload;
      if (state.expandedRecord !== null && !state.records.includes(state.expandedRecord)) {
        state.expandedRecord = null;
      }
    },
    updateFirstItemIndex: (state, action: PayloadAction<number>) => {
      state.firstItemIndex = action.payload;
    },
    updateSelectedRecords: (state, action: PayloadAction<NQRecord[]>) => {
      state.selectedRecords = action.payload;
    },
    updateExpandedRecord: (state, action: PayloadAction<NQRecord | null>) => {
      state.expandedRecord = action.payload;
    },
  },
});

export const reducer = historySlice.reducer;
export const {
  updateRecords,
  updateFirstItemIndex,
  updateSelectedRecords,
  updateExpandedRecord,
} = historySlice.actions;
