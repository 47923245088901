import { NQError, NQErrorSchemeJsSdk, WarningDE, isNqError } from "@visonum/network-quality-sdk";
import sentryObject from "./sentryObject";
import { SeverityLevel } from "@sentry/nextjs";
import { SentryTagSet, SentryTags } from "./types";

export const sentryCaptureError = (error: NQError) => {
    const tags = getTagsForError(error);

    sentryObject.captureException(error, scope => {
        scope.setTags(tags);
        if (isNqError(error)) {
            scope.setLevel(errorToSeverityLevel(error));
        }
        return scope;
    });
}

export const sentryReportWarning = (warning: WarningDE) => {
    const tags = getTagsForWarning(warning);
    sentryObject.captureMessage(warning.message, scope => {
        scope.setTags(tags);
        scope.setLevel("warning");
        scope.setExtra("URL", warning.url);
        return scope;
    });
}

const getTagsForError = (error: Error | NQError, prefix: string | null = null): SentryTags => {
    const result: SentryTags = {};

    if (isNqError(error)) {
        const nqError = error as NQError;
        result[createKey("ErrorScheme", prefix)] = nqError.scheme;
        result[createKey("ErrorName", prefix)] = "NQError";
    } else {
        const _error = error as Error;
        result[createKey("ErrorNumber", prefix)] = 1000;
        result[createKey("ErrorName", prefix)] = _error.name;
        if (_error.cause instanceof Error) {
            const causeTags = getTagsForError(_error.cause, createKey("ErrorCause", prefix));
            Object.keys(causeTags).forEach(key => {
                result[key] = causeTags[key];
            });
        }
    }

    result[createKey("ErrorMessage", prefix)] = error.message;

    return result;
}

const getTagsForWarning = (warning: WarningDE): SentryTags => {
    const result: SentryTags = {};
    result[createKey("host", null)] = new URL(warning.url).host;
    return result;
}

export const createKey = (keyName: SentryTagSet, prefix: string | null): string => {
    if (prefix === null) {
        return keyName;
    }

    return `${prefix}.${keyName}`;
}

export const errorToSeverityLevel = (error: NQError): SeverityLevel => {
    switch (error.scheme as NQErrorSchemeJsSdk) {
        case "Sm-1":
        case "Rl-1":
        case "Br-1":
            return "info";
        case "Ps-1":
            return "log";
        case "Rp-Cn-1":
        case "Rp-Cn-2":
            return "log";
        default:
            return "error";
    }
}

export default sentryCaptureError;