import { HistoryErrorScheme, createNqError } from "@visonum/network-quality-sdk";
import { isRunningInBrowser } from "../../helper/ssr";
import { devAssert } from "../errors/devAssert";
import { logger } from "../../helper/logger";

export const reportHistoryError = (errorScheme: HistoryErrorScheme, message: string, cause?: unknown) => {
    const error = createNqError(errorScheme, message, cause);

    if (isRunningInBrowser()) {
        devAssert(error, error.message);
    } else {
        logger.error(error.message, error);
        throw error;
    }
}