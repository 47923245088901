import { useAppDispatch } from "src/hooks";
import ModalDialog from "src/components/elements/ModalDialog";
import { CommonButtonDiv, DefaultButtonDiv, RedButtonDiv } from "../elements/Buttons";
import SmartLink from "src/components/elements/SmartLink";
import t from "../../../helper/t";
import {setShowSurvey} from "../../../features/pageManager/pageManagerSlice";
import styled from "@emotion/styled";
import imageNewVfIconRgbRed from "../../../../assets/images/New_VF_Icon_RGB_RED.svg";
import NavigationButton from "../elements/NavigationButton";

interface SurveyModalProps {
}

const RootDiv = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    {
        height: "100%",
        minHeight: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginBottom: "10px",
        marginTop: "10px",
        position: "relative",
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    `
      @media (max-width: 428px) {
        margin-top: 30px;
        padding-top: 80px;
        padding-left: 0;
        padding-right: 0;
      }
    `
]));

const ButtonsDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
    },
    `
      @media (max-width: 428px) {
        flex-direction: column;
      }
    `
]));

const CaptionDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.h3,
    {
        textAlign: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
]));

const SubCaptionDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.font16Bold,
    {
        textAlign: "center",
        paddingBottom: "20px",
    }
]));

const FooterDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.font16,
    {
        alignSelf: "end",
        marginTop: "20px",
    },
]));

const FooterTextDiv = styled.div(({ theme }) => ([
    {
        alignSelf: "end"
    },
]));

const PoweredBySpan = styled.span(({ theme }) => ([
    {
        opacity: 0.7
    },
]));

const SurveyBrandSpan = styled.span(({ theme }) => ([
    {
        fontWeight: "bold"
    },
]));

const VfLogoImg = styled.img(({ theme }) => ([{
    width: "60px",
    position: "absolute",
    top: 0,
    left: 0,
}]));

export const SurveyModal: React.FC<SurveyModalProps> = () => {
    const dispatch = useAppDispatch();

    const surveyLink = `https://nebula-cdn.kampyle.com/direct/form.html?region=prodEuIrland&websiteId=71175&formId=31278` // TODO: Dmitry should this link be wrapped with some specific component?

    return (
        <ModalDialog maxWidth={700}>
            <RootDiv>
                <VfLogoImg
                    src={imageNewVfIconRgbRed.src}
                    alt="vf-logo"
                />
                <CaptionDiv>
                    {t("Sag uns bitte Deine Meinung zum Speedtest Plus.")}
                </CaptionDiv>
                <SubCaptionDiv>
                    {t("Dein Speedtest bleibt weiter geöffnet.")}
                    <br />
                    {t("Es dauert nur 1 Minute.")}
                    <br />
                    {t("Schau Dir vorher in Ruhe Deine Speedtest-Ergebnisse an.")}
                </SubCaptionDiv>
                <ButtonsDiv>
                    <SmartLink href={surveyLink} onClick={() => dispatch(setShowSurvey(false))}>
                        <RedButtonDiv>
                            {t("Umfrage starten")}
                        </RedButtonDiv>
                    </SmartLink>
                    <NavigationButton dataCy="survey-no" isFocused={true} onClick={() => dispatch(setShowSurvey(false))} isDialog={true}>
                        <CommonButtonDiv >
                            {t("Nein, danke")}
                        </CommonButtonDiv>
                    </NavigationButton>
                </ButtonsDiv>
                <FooterDiv>
                    <FooterTextDiv>
                        <PoweredBySpan>
                            {t("Powered by")}
                        </PoweredBySpan>
                        {" "}
                        <SurveyBrandSpan>
                            {t("Medallia")}
                        </SurveyBrandSpan>
                    </FooterTextDiv>
                </FooterDiv>
            </RootDiv>
        </ModalDialog>
    )
}