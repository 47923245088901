import styled from "@emotion/styled";
import React from "react";
import { getPackageVersion } from "../../../helper/packageJsonHelper";

const RootDiv = styled.footer(({ theme }) => ([
  theme.splus3.typography.small,
  theme.splus3.foreground.monochrome4,
  {
    position: "fixed",
    bottom: 15,
    right: 15,
    float: "right",
    fontSize: 10,
    margin: "32px 10px 0 10px",
  }
]));

interface FooterShowVersionsProps {

}

const FooterShowVersions: React.FC<FooterShowVersionsProps> = () => {
  return (
    <RootDiv>
      Frontend Version: {getPackageVersion()}
    </RootDiv>
  );
}

export default FooterShowVersions;