import { PrepareResultDE } from "@visonum/network-quality-sdk"
import { freeze } from "immer"
import { NQRecord, NQRecordPartialService, NQRecordPrepareResult } from "./types"
import { MeasurementState, Phase } from "../measurement/types"
import { reportHistoryError } from "./reportHistoryError"
import { PlainDate } from "../../types"

/* pure */
export const createNQRecord = (date: PlainDate, measurement: MeasurementState, partialService: NQRecordPartialService | null): NQRecord | null => {

    if (measurement.kind !== Phase.CompletingNoModem &&
        measurement.kind !== Phase.FinishedNoModem &&
        measurement.kind !== Phase.CompletingWithModem &&
        measurement.kind !== Phase.FinishedWithModem) {
        reportHistoryError("Hr-En-1", `Wrong measurement phase "${measurement.kind}"`);
        return null;
    } else {
        return freeze(
            {
                date,
                prepareResult: createNQRecordPrepareResult(measurement.prepareResult),
                benchmarkFinal: measurement.benchmark,
                downloadIntermediates: measurement.downloadIntermediates,
                downloadFinal: measurement.download,
                uploadIntermediates: measurement.uploadIntermediates,
                uploadFinal: measurement.upload,
                pingIntermediates: measurement.pingIntermediates,
                pingFinal: measurement.ping,
                modemFinal: measurement.modem,
                hints: measurement.hints === null ? null : measurement.hints.hints.map(x => x.id),
                partialService
            }
        );
    }
}

/* pure */
const createNQRecordPrepareResult = (p: PrepareResultDE): NQRecordPrepareResult => (
    {
        init: {
            speedtest: {
                id: p.init.speedtest.id,
            },
            connection: {
                ip: p.init.connection.ip,
                isp: p.init.connection.isp,
                isCustomer: p.init.connection.isCustomer,
                ispFootprint: p.init.connection.ispFootprint == null ? undefined : p.init.connection.ispFootprint,
                isVpnActive: p.init.connection.isVpnActive,
            },
            modem: {
                bookedDownloadSpeedMax: p.init.modem?.bookedDownloadSpeedMax,
                bookedDownloadSpeedAvg: p.init.modem?.bookedDownloadSpeedAvg,
                bookedDownloadSpeedMin: p.init.modem?.bookedDownloadSpeedMin,
                bookedUploadSpeedMax: p.init.modem?.bookedUploadSpeedMax,
                bookedUploadSpeedAvg: p.init.modem?.bookedUploadSpeedAvg,
                bookedUploadSpeedMin: p.init.modem?.bookedUploadSpeedMin,
                provisionedDownloadSpeed: p.init.modem?.provisionedDownloadSpeed,
                type: p.init.modem?.type,
                name: p.init.modem?.name,
                vendor: p.init.modem?.vendor,
            },
            cmts: p.init.cmts === null ? null : {
                isModemTestAvailable: p.init.cmts.isModemTestAvailable,
                vendor: p.init.cmts.vendor,
            },
            client: {
                device: p.init.client.device,
                browser: {
                    name: p.init.client.browser.name,
                    version: p.init.client.browser.version,
                    isCurrent: p.init.client.browser.isCurrent,
                }
            },
            communication: {
                modemSwapLink: p.init.communication?.modemSwapLink,
                plannedImprovement: p.init.communication?.plannedImprovement,
            },
            partialService: {
                downloadIndex: p.init.partialService?.downloadIndex ?? 0,
                uploadIndex: p.init.partialService?.uploadIndex ?? 0
            }
        },
        isIPv6: p.isIPv6,
    }
);
