import styled from "@emotion/styled";
import Button, { PrimaryButton } from "../../components/elements/Button";
import ModalDialog from "../../components/elements/ModalDialog";
import SmartLink from "../../components/elements/SmartLink";
import { useAppDispatch } from "../../hooks";
import { PartialServiceCallbackTipId } from "../hints/hintsVirtualIds";
import t from "../../helper/t";
import { hideDialog } from "../dialogManager/dialogManagerSlice";

const ButtonsDiv = styled.div([
    {
        display: "flex",
        gap: 24,
        justifyContent: "center",
        marginTop: 32,
        flexWrap: "wrap"
    }
]);

interface PartialServiceCallbackDialogProps {

}

const PartialServiceCallbackDialog: React.FC<PartialServiceCallbackDialogProps> = () => {
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(hideDialog());
    }

    return (
        <ModalDialog>
            <div>
                <h1>{t("Dein Anschluss braucht Hilfe")}</h1>
                <p>{t("Ein:e Expert:in ist schon für Dich reserviert")}</p>
                <ButtonsDiv>
                    <SmartLink href={`/tipps#${PartialServiceCallbackTipId}`} onClick={() => { close(); }}>
                        <PrimaryButton>
                            {t("Zum Tipp")}
                        </PrimaryButton>
                    </SmartLink>
                    <Button onClick={() => { close(); }}>
                        {t("Abbrechen")}
                    </Button>
                </ButtonsDiv>
            </div>
        </ModalDialog>
    )
}

export default PartialServiceCallbackDialog;