import React, { ReactElement } from "react";
import { getCurrentLang } from "../../../helper/utils";
import { getIndexFaqItems } from "./getIndexFaqItems";
import FaqAccordion from "../faq/FaqAccordion";

const IndexAccordion = (): ReactElement => {
  return (
    <FaqAccordion currentFaqId={null} faqs={getIndexFaqItems(getCurrentLang())} />
  );
}

export default IndexAccordion;
