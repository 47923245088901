import { createNqError } from '@visonum/network-quality-sdk';
import * as R from "ramda";
import { SeoEnabledType, ProcessEnv, SdkKind } from './config-types';

export type BuildVariant = "SCA" | "SFA" | "SPLUS" | "SPLUS3" | "TV";

const getValueOrDefault = <T>(envVar: string | undefined, defaultValue: T): T => {
    if (R.isNil(envVar) || R.isEmpty(envVar)) {
        return defaultValue;
    }

    return envVar as T;
}

export const NODE_ENV = getValueOrDefault<ProcessEnv>(process.env.NEXT_PUBLIC_NODE_ENV, "production");

export const VARIANT = getValueOrDefault<BuildVariant>(process.env.NEXT_PUBLIC_VARIANT, "SPLUS");

export const SDK_KIND = getValueOrDefault<SdkKind>(process.env.NEXT_PUBLIC_SDK_KIND, "JS");

export const SEO_ENABLED = getValueOrDefault<SeoEnabledType>(process.env.NEXT_PUBLIC_SEO_ENABLED, "false");

export const CROSSCHART = getValueOrDefault<Boolean>(process.env.NEXT_PUBLIC_CROSS, false);

const baseUrlOpt = process.env.NEXT_PUBLIC_API_URL;
if (R.isNil(baseUrlOpt)) {
    throw createNqError("Cl-4", "NEXT_PUBLIC_API_URL cannot be undefined.");
}
export const baseUrl: string = baseUrlOpt;

const apiKeyOpt = process.env.NEXT_PUBLIC_API_KEY;
if (R.isNil(apiKeyOpt)) {
    throw createNqError("Cl-5", "NEXT_PUBLIC_API_KEY cannot be undefined.");
}
export const API_KEY = apiKeyOpt;

export const appId = process.env.NEXT_PUBLIC_APP_ID;