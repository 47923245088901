import { PrepareResultDE } from "@visonum/network-quality-sdk";
import * as R from "ramda";
import { NQRecordPrepareResult } from "../features/history/types";

export const getDeviceName = (prepareResult: PrepareResultDE | NQRecordPrepareResult | null): string | null => {
  if (prepareResult === null) {
    return null;
  }

  const device = prepareResult.init.client.device;

  if (!R.isNil(device.model) && !R.isEmpty(device.model)) {
    if (R.isNil(device.vendor) || R.isEmpty(device.vendor)) {
      return device.model;
    } else {
      return `${device.vendor} ${device.model}`;
    }
  }

  return null;
}

// Messung an Deinem Gerät: Samsung A33 5G (WLAN)
export const getDeviceText = (prepareResult: PrepareResultDE | NQRecordPrepareResult | null, text: string): JSX.Element => {
  const name = getDeviceName(prepareResult);

  if (name === null) {
    return <>{text}</>;
  } else {
    return <>{text}:&nbsp;<strong>{name}</strong></>;
  }
}