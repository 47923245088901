import styled from "@emotion/styled";
import NavigationButton from "../elements/NavigationButton";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import FaqCard from "./FaqCard";
import InfoCardDesktop from "./InfoCardDesktop";
import InfoCardMobile from "./InfoCardMobile";
import t from "../../../helper/t";
import { startMeasurement3 } from "../measurement/startMeasurement3";
import CommonContainer from "../elements/CommonContainer";

import SpeedtestF1Webp from "../../../../assets/images/splus3/speedtest_f1.webp";
import SpeedtestF1_1280Webp from "../../../../assets/images/splus3/speedtest_f1_1280.webp";
import StartIconSvg from "../../../../assets/icons/splus3/start-icon.svg";

const ContainerDiv = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.background.white,
    ],
  }
]));

const MainPartDiv = styled.div([
  {
    display: "flex",
    flexDirection: "column",
    flexGrow: 10,
  }
]);

/** Factor calcullation */

const minScreenWidth = 680;
const defaultScreenWidth = 1440;
const defaultVerticalShift = -78;
const widthFactor = defaultVerticalShift / (minScreenWidth - defaultScreenWidth);
const shift = minScreenWidth * widthFactor;

/** Factor calcullation */

const MainImageContainerDesktop = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    height: 213,
    width: "100%",

    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.40) 39.91%, rgba(0, 0, 0, 0.00) 78.61%), url("${SpeedtestF1Webp.src}"), lightgray 0px -322.875px / 100% 190.248% no-repeat`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: `center calc(${shift}px - ${100.0 * widthFactor}vw)`,
    backgroundSize: "cover",

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: {
      display: "none"
    },
  }
]));

/** Factor calcullation */

const minScreenWidthM = 320;
const defaultScreenWidthM = 768;
const defaultVerticalShiftM = -120;
const widthFactorM = defaultVerticalShiftM / (minScreenWidthM - defaultScreenWidthM);
const shiftM = minScreenWidthM * widthFactorM;

/** Factor calcullation */

const MainImageContainerMobile = styled.div(({ theme }) => ([
  {
    display: "none",
    flexDirection: "column",
    alignItems: "center",

    height: 200,
    width: "100%",

    background: `url("${SpeedtestF1_1280Webp.src}")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: `center calc(${shiftM}px - ${100.0 * widthFactorM}vw)`,
    backgroundSize: "cover",

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: {
      display: "flex"
    },

    [theme.splus3.breakpoints.minimal]: {
      backgroundPosition: `center top`,
      display: "flex"
    }
  }
]));

const HomeHeaderText = styled.h1([
  props => props.theme.splus3.typography.h1,
  props => props.theme.splus3.foreground.white,
  {
    marginTop: 57,
    maxWidth: 740,
    fontWeight: 400,
  },
]);

const StartButtonDiv = styled.div(({ theme }) => ([
  props => props.theme.splus3.foreground.monochrome500,
  props => props.theme.splus3.background.white,
  props => props.theme.splus3.typography.large,
  props => props.theme.splus3.shadow.startButton,
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: -80,
    width: 160,
    height: 160,
    borderRadius: "50%",
    rowGap: 7,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.typography.font16Bold,
      {
        marginTop: -60,
        width: 120,
        height: 120,
        rowGap: 4,
      }
    ],
  },
]));

const StartButtonImg = styled.img(({ theme }) => ([
  {
    width: 40,
    marginTop: 42,
    marginLeft: 9,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: {
      width: "auto",
      marginTop: 30,
      marginLeft: 9,
    },
  }
]));

const ContentPartDiv = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",
    rowGap: 56,

    marginTop: 56,
    marginLeft: "min(8.33vw, 120px)",
    marginRight: "min(8.33vw, 120px)",

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: {
      rowGap: 32,
      marginTop: 8,
      marginLeft: 16,
      marginRight: 16,
    },
  }
]));

const TextH2Div = styled.div(({ theme }) => ([
  props => props.theme.splus3.foreground.primary,
  props => props.theme.splus3.typography.h3,
  {
    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.foreground.monochrome500,
      theme.splus3.typography.h1m,
    ],
  }
]));

const SubTextH2Div = styled.div(({ theme }) => ([
  props => props.theme.splus3.foreground.primary,
  props => props.theme.splus3.typography.font24,
  {
    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.foreground.monochrome500,
      theme.splus3.typography.h2m,
    ],
  }
]));

const BottomSpacer = styled.div(({ theme }) => ([
  {
    marginBottom: 152,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: {
      marginBottom: 78,
    },
  }
]));

interface HomeScreenProps {
}

const HomePage: React.FC<HomeScreenProps> = () => {
  const dispatch = useAppDispatch();

  const state = useAppSelector(state => state.measurement);

  return (
    <ContainerDiv>
      <MainPartDiv>
        <MainImageContainerDesktop>
          <CommonContainer>
            <HomeHeaderText>
              {t("Starte Deinen Speedtest Plus")}
            </HomeHeaderText>
          </CommonContainer>
        </MainImageContainerDesktop>

        <MainImageContainerMobile>
        </MainImageContainerMobile>

        <CommonContainer style={{ alignSelf: "center" }}>
          <NavigationButton onClick={() => {
            dispatch(startMeasurement3());
          }}>
            <StartButtonDiv data-cy="start-measurement-button">
              <StartButtonImg src={StartIconSvg.src} alt="start" />
              <span>Start</span>
            </StartButtonDiv>
          </NavigationButton>
        </CommonContainer>
      </MainPartDiv>

      <CommonContainer>
        <ContentPartDiv>
          <div>
            <TextH2Div>
              {t("Du willst mit maximaler Geschwindigkeit surfen?")}
            </TextH2Div>
            <SubTextH2Div>
              {t("Wir helfen Dir, optimale Bedingungen zu schaffen.")}
            </SubTextH2Div>
          </div>

          <InfoCardDesktop />
          <InfoCardMobile />

          <TextH2Div>
            {t("Wie läuft der Speedtest Plus genau ab?")}
          </TextH2Div>

          {/* <VideoCard /> */}

          <FaqCard />

          <BottomSpacer />
        </ContentPartDiv>
      </CommonContainer>
    </ContainerDiv>
  );
}

export default HomePage;
