import { css } from "@emotion/react";
import { freeze } from "immer";
import { r } from "./sizeUtils";

const liteFontFamily = "vodafone-lite";
const regularFontFamily = "vodafone-regular";
const boldFontFamily = "vodafone-bold";

export const commonTheme = freeze({
    typography: {
        lite: css({
            fontFamily: liteFontFamily,
        }),
        regular: css({
            fontFamily: regularFontFamily,
            fontWeight: 400,
            fontStyle: "normal",
        }),
        bold: css({
            fontFamily: boldFontFamily,
            fontWeight: 700,
            fontStyle: "normal",
        }),
        fontWeight400: css({
            fontWeight: 400
        }),
        fontSize14: css({
            fontSize: r(14),
            lineHeight: "115%",
        }),
        fontSize14Abs: css({
            fontSize: 14,
            lineHeight: "115%",
        }),
        fontSize16: css({
            fontSize: r(16),
            lineHeight: "100%",
        }),
        fontSize18: css({
            fontSize: r(18),
            lineHeight: "144%",
        }),
        fontSize20: css({
            fontSize: r(20),
            lineHeight: "115%x",
        }),
        fontSize22: css({
            fontSize: r(22),
            lineHeight: "118%",
        }),
        fontSize24: css({
            fontSize: r(24),
            lineHeight: "108%",
        }),
        fontSize24Abs: css({
            fontSize: 24,
            lineHeight: "27px",
        }),
        fontSize28: css({
            fontSize: r(28),
            lineHeight: "100%",
        }),
        fontSize28Abs: css({
            fontSize: 28,
            lineHeight: "32px",
        }),
        fontSize40: css({
            fontSize: r(40),
            // lineHeight: "120%",
        }),
        fontSize48: css({
            fontSize: r(48),
            lineHeight: "67%%",
        }),
        fontSize64: css({
            fontSize: r(64),
            lineHeight: "112%",
        }),
    }
} as const);