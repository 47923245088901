import { NQError, createNqError, isNqError } from "@visonum/network-quality-sdk";
import { NODE_ENV } from "../../config";
import { logger } from "../../helper/logger";
import sentryCaptureError from "../sentryReports/sentryCaptureError";

export const devAssert = (error: unknown, msg: string) => {
    logger.error(msg, error);
    if (isNqError(error)) {
        sentryCaptureError(error as NQError);
    } else {
        sentryCaptureError(createNqError("Cl-0", "Unknown error in devAssert", error));
    }
    if (NODE_ENV == "development") {
        throw error;
    }
}