import styled from "@emotion/styled";
import * as R from "ramda";
import { useEffect, useRef } from "react";
import { collapse, expand } from "../../../features/debugLog/debugLogSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";

interface RootDivProps {
    collapsed: boolean;
}

const RootDiv = styled.div<RootDivProps>([
    props => (
        props.collapsed ? {
            width: "auto",
            height: "auto",
            overflow: "hidden",
            left: 120,
        } : {
            left: 24,
            top: 24,
            width: "calc(100vw - 72px)",
            height: "calc(100vh - 72px)",
            overflow: "auto",
        }
    ),
    {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        backgroundColor: "yellow",
        zIndex: 1100,
        padding: 10,
        border: "1px solid black"
    }
]);

const HeaderDiv = styled.div([
    {
        display: "flex",
        alignItems: "center",
        height: 32,
    }
]);

const LogDiv = styled.div([
    {
        marginTop: 8,
        overflowY: "scroll",
        backgroundColor: "cyan",
    }
]);

interface LoggerViewProps {

}

const LoggerView: React.FC<LoggerViewProps> = ({ }) => {
    const state = useAppSelector(state => state.debugLog);

    const dispatch = useAppDispatch();

    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, [state]);

    const safeStringify = (obj: unknown, indent: number = 2): string => {
        const cache: any[] = [];
        const retVal = JSON.stringify(
            obj,
            (key, value) =>
                typeof value === "object" && value !== null
                    ? cache.includes(value)
                        ? undefined // Duplicate reference found, discard key
                        : cache.push(value) && value // Store value in our collection
                    : value,
            indent
        );
        return retVal;
    }

    return (
        <RootDiv collapsed={state.collapsed}>
            <HeaderDiv>
                <div style={{ flexGrow: 100, marginRight: 12 }}>
                    Debug Log
                </div>
                {state.collapsed && (
                    <button onClick={() => { dispatch(expand()); }}>SHOW</button>
                )}
                {!state.collapsed && (
                    <button onClick={() => { dispatch(collapse()); }}>HIDE</button>
                )}
            </HeaderDiv>
            <LogDiv>
                {state.messages.map((m, i) => (<p key={`message${i}`}>{`${m.message}${R.isNil(m.object) ? "" : " >>> " + safeStringify(m.object)}`}</p>))}
                <div ref={messagesEndRef} />
            </LogDiv>
        </RootDiv>
    )
};

export default LoggerView;