import styled from "@emotion/styled";
import { StaticImageData } from "next/image";
import { formatSpeed } from "../../../helper/formatSpeeds";
import { FadingTextDiv } from "./FadingText";
import { theme } from "../../../theme/theme";
import t from "../../../helper/t";

import downloadIconSvg from "../../../../assets/icons/common/download-icon.svg";
import uploadIconSvg from "../../../../assets/icons/common/upload-icon.svg";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
    }
]);

const TopLineDiv = styled.div([
    props => props.theme.splus3.background.vodafoneGreen,
    {
        display: "flex",
        justifyContent: "center",
    }
]);

const TextContainerDiv = styled.div(({ theme }) => ([
    props => props.theme.splus3.typography.font16Bold,
    props => props.theme.splus3.foreground.white,
    {
        display: "flex",
        columnGap: 16,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch",
        margin: 16,
        lineHeight: "100%",


        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.smallBold,
            {
                columnGap: 12,
            }
        ],
    },
]));

const TriangleDiv = styled.div([
    {
        alignSelf: "center",
    }
]);

interface TariffInfoProps {
    fading: boolean;
    bookedDownloadSpeed: number | null;
    bookedUploadSpeed: number | null;
}

const TariffInfo: React.FC<TariffInfoProps> = ({ fading, bookedDownloadSpeed, bookedUploadSpeed }) => {
    return (
        <RootDiv>
            <TopLineDiv>
                <TextContainerDiv role="heading" aria-level={1}>
                    {t("Dein Tarif")}:
                    <TariffInfoItem speed={bookedDownloadSpeed} icon={downloadIconSvg} alt="download" fading={fading} />
                    <TariffInfoItem speed={bookedUploadSpeed} icon={uploadIconSvg} alt="upload" fading={fading} />
                </TextContainerDiv>
            </TopLineDiv>

            <TriangleDiv>
                <svg width="64" height="32" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 32L64 0H0L32 32Z" fill={theme.splus3.colors.tariffInfo} />
                </svg>
            </TriangleDiv>
        </RootDiv>
    )
}

const ValueContainerDiv = styled.div([
    {
        display: "flex",
        columnGap: 8,
        alignItems: "center",
        letterSpacing: "3%",
    }
]);

const TariffValueContainerDiv = styled.div([
    {
        display: "flex",
        alignItems: "center",
    }
]);

const TariffValueGrid = styled.div([
    {
        display: "flex",
    }
]);

const TariffUnitDiv = styled.div([
    props => props.theme.splus3.typography.small,
    {
        marginTop: 4
    }
]);

const ImageDiv = styled.div([
    {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
    }
]);

interface TariffInfoItemProps {
    speed: number | null;
    icon: StaticImageData;
    alt: string;
    fading: boolean;
}

const TariffInfoItem: React.FC<TariffInfoItemProps> = ({ speed, icon, alt, fading }) => {
    const speedValues = formatSpeed([speed]);

    return (
        <ValueContainerDiv>
            <ImageDiv>
                <img width="92%" height="92%" src={icon.src} alt={alt} />
            </ImageDiv>
            <TariffValueContainerDiv>
                <TariffSpeed speed={speed} fading={fading} />
                &nbsp;
                <TariffUnitDiv>
                    {speedValues.unit}
                </TariffUnitDiv>
            </TariffValueContainerDiv>
        </ValueContainerDiv>
    )
}

interface TariffSpeedProps {
    speed: number | null;
    fading: boolean;
}

const TariffSpeed: React.FC<TariffSpeedProps> = ({ speed, fading }) => {
    const speedValues = formatSpeed([speed]);

    return (
        <TariffValueGrid>
            {speed === null ? (
                <FadingTextDiv stage="Normal">
                    --
                </FadingTextDiv>
            ) : (
                <FadingTextDiv stage={fading ? "FadeIn" : "Normal"}>
                    {speedValues.values[0]}
                </FadingTextDiv>
            )}
        </TariffValueGrid>
    )
}

export default TariffInfo;