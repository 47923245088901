import styled from "@emotion/styled";
import { ResultButton } from "../../../../features/focusManager/FocusabeItemKey";
import { r } from "../../../../theme/gigatv/sizeUtils";
import CommonButton from "../../../../components/elements/gigatv/buttons/CommonButton";
import { HeaderTextDiv } from "../elements/HeaderTextDiv";

const RootDiv = styled.div([
  {
    display: "flex",
    columnGap: r(20),
    alignItems: "center",
  }
]);

interface ResultButtonsPartProps {
  title: string;
  focusedButton: ResultButton | null;
}

const ResultButtonsPart: React.FC<ResultButtonsPartProps> = ({ title, focusedButton }) => {
  return (
    <RootDiv>
      <HeaderTextDiv>
        {title}
      </HeaderTextDiv>

      <CommonButton dataCy="resultScreenSpeedtestStartButton" isFocused={focusedButton === ResultButton.SpeedtestStart}>
        Speedtest erneut starten
      </CommonButton>

      <CommonButton dataCy="resultScreenInfoButton" isFocused={focusedButton === ResultButton.Info}>
        Info
      </CommonButton>
    </RootDiv>
  );
}

export default ResultButtonsPart;
