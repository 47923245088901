import * as R from "ramda";
import { AppThunk } from "../../store";
import { HistoryControlItemKey, HistoryDetailItemKey, HistoryRecordsItemKey, HistoryScreenPart, RemoveEntryItemKey, ResultButton, ResultScreenPart, ResultTab } from "./FocusabeItemKey";
import focusManagerSlice, { showDialog } from "./focusManagerSlice";
import { getAllRecords } from "../history/getAllRecords";
import { deleteRecordBySpeedtestId, updateFirstItemIndex } from "../history/historySlice";
import ViewDialogKey from "./ViewDialogKey";
import { AppAssertionError } from "../errors/AppAssertionError";
import { devAssert } from "../errors/devAssert";
import ViewScreenKey from "./ViewScreenKey";

export const changeResultScreenPart = (screenPart: ResultScreenPart, options?: { selectedButton?: ResultButton, selectedTab?: ResultTab }): AppThunk => (dispatch, _) => {
    dispatch(focusManagerSlice.actions.updateResultScreenPart(screenPart));
    if (!R.isNil(options?.selectedButton)) {
        dispatch(focusManagerSlice.actions.updateResultSelectedButton(options!.selectedButton));
    }
    if (!R.isNil(options?.selectedTab)) {
        dispatch(focusManagerSlice.actions.updateResultSelectedTab(options!.selectedTab));
    }
}

export const changeHistoryPart = (historyPart: HistoryScreenPart, options?: { selectedControl?: HistoryControlItemKey }): AppThunk => (dispatch, getState) => {
    const historyState = getState().history;
    const records = getAllRecords(historyState);

    dispatch(changeResultScreenPart(ResultScreenPart.History));

    if (records.length === 0) {
        dispatch(focusManagerSlice.actions.updateHistoryPart(HistoryScreenPart.Constrol));
    } else {
        dispatch(focusManagerSlice.actions.updateHistoryPart(historyPart));
    }

    if (!R.isNil(options?.selectedControl)) {
        dispatch(focusManagerSlice.actions.updateHistorySelectedControl(options!.selectedControl));
    }
}

export const historyPageDown = (): AppThunk => (dispatch, getState) => {
    const historyState = getState().history;
    const records = getAllRecords(historyState);

    if (historyControlPageDownIsDisabled(records.length, historyState.frameItemCount, historyState.firstItemIndex)) {
        return;
    }

    const newFirstIndex = Math.min(records.length - historyState.frameItemCount, historyState.firstItemIndex + historyState.frameItemCount);

    dispatch(updateFirstItemIndex(newFirstIndex));
    dispatch(focusManagerSlice.actions.updateSelectedRecordIndex(newFirstIndex));
}

export const historyPageUp = (): AppThunk => (dispatch, getState) => {
    const historyState = getState().history;

    if (historyControlPageUpIsDisabled(historyState.firstItemIndex)) {
        return;
    }

    const newFirstIndex = Math.max(0, historyState.firstItemIndex - historyState.frameItemCount);

    dispatch(updateFirstItemIndex(newFirstIndex));
    dispatch(focusManagerSlice.actions.updateSelectedRecordIndex(newFirstIndex));
}

export const showRemoveEntriesDialog = (): AppThunk => (dispatch, getState) => {
    const historyState = getState().history;

    const records = getAllRecords(historyState);

    if (historyControlRemoveEntriesIsDisabled(records.length)) {
        return;
    }

    dispatch(showDialog({ dialogKey: ViewDialogKey.RemoveEntry, state: { selectedItem: RemoveEntryItemKey.CloseButton } }));
}

export const showHistoryDetails = (index: number): AppThunk => (dispatch, getState) => {
    const historyState = getState().history;
    const records = getAllRecords(historyState);
    const record = records[index];

    if (R.isNil(record)) {
        devAssert(new AppAssertionError({ actual: record, expected: "defined record" }), "History records are not defined");
        return;
    }

    dispatch(showDialog({ dialogKey: ViewDialogKey.HistoryDetail, state: { selectedItem: HistoryDetailItemKey.Back, record } }));
}

export const removeHistoryEntry = (): AppThunk => (dispatch, getState) => {
    const focusState = getState().focusManager;
    const historyState = getState().history;

    const records = getAllRecords(historyState);

    const record = records[focusState.resultState.selectedRecord.index];

    if (R.isNil(record)) {
        devAssert(new AppAssertionError({ actual: record, expected: "defined record" }), "History records are not defined");
        return;
    }

    dispatch(deleteRecordBySpeedtestId(record.prepareResult.init.speedtest.id));
    dispatch(focusManagerSlice.actions.updateSelectedRecordKey(HistoryRecordsItemKey.Item));
}

export const historyRecordDown = (): AppThunk => (dispatch, getState) => {
    const focusState = getState().focusManager;
    const historyState = getState().history;

    const records = getAllRecords(historyState);

    const newSelectedIndex = focusState.resultState.selectedRecord.index + 1;
    const lastIndex = Math.min(records.length - 1, historyState.firstItemIndex + historyState.frameItemCount - 1)

    if (newSelectedIndex <= lastIndex) {
        dispatch(focusManagerSlice.actions.updateSelectedRecordIndex(newSelectedIndex));
    }
}

export const historyRecordUp = (): AppThunk => (dispatch, getState) => {
    const focusState = getState().focusManager;
    const historyState = getState().history;

    const newSelectedIndex = focusState.resultState.selectedRecord.index - 1;
    if (newSelectedIndex < historyState.firstItemIndex) {
        dispatch(changeHistoryPart(HistoryScreenPart.Constrol));
    } else {
        dispatch(focusManagerSlice.actions.updateSelectedRecordIndex(newSelectedIndex));
    }
}

export const updateHistoryRecordIndex = (): AppThunk => (dispatch, getState) => {
    const focusState = getState().focusManager;
    const historyState = getState().history;

    const records = getAllRecords(historyState);

    if (records.length === 0) {
        dispatch(changeHistoryPart(HistoryScreenPart.Constrol));
    }

    const firstItemIndex = (() => {
        if (records.length - historyState.firstItemIndex < historyState.frameItemCount) {
            return Math.max(0, records.length - historyState.frameItemCount);
        } else {
            return historyState.firstItemIndex;
        }
    })();

    dispatch(updateFirstItemIndex(firstItemIndex));

    const selectedIndex = (() => {
        const index = focusState.resultState.selectedRecord.index;

        const lastIndex = Math.min(records.length - 1, firstItemIndex + historyState.frameItemCount)

        if (index > lastIndex) {
            return lastIndex;
        } else if (index < firstItemIndex) {
            return firstItemIndex;
        } else {
            return index;
        }
    })();

    dispatch(focusManagerSlice.actions.updateSelectedRecordIndex(selectedIndex));
}

export const historyControlPageDownIsDisabled = (recordsCount: number, frameItemCount: number, firstItemIndex: number): boolean => {
    return recordsCount <= firstItemIndex + frameItemCount;
}

export const historyControlPageUpIsDisabled = (firstItemIndex: number): boolean => {
    return firstItemIndex === 0;
}

export const historyControlRemoveEntriesIsDisabled = (recordsCount: number): boolean => {
    return recordsCount === 0;
}

export const selectButtonOnResultScreen = (selectedButton: ResultButton): AppThunk => (dispatch, getState) => {
    dispatch(focusManagerSlice.actions.changeFocused({ screenKey: ViewScreenKey.Result, state: { ...getState().focusManager.resultState, selectedButton } }))
}

export const selectTabOnResultScreen = (selectedTab: ResultTab): AppThunk => (dispatch, getState) => {
    dispatch(focusManagerSlice.actions.changeFocused({ screenKey: ViewScreenKey.Result, state: { ...getState().focusManager.resultState, selectedTab } }))
}

export const selectHistoryControl = (selectedControl: HistoryControlItemKey): AppThunk => (dispatch, getState) => {
    dispatch(focusManagerSlice.actions.changeFocused({ screenKey: ViewScreenKey.Result, state: { ...getState().focusManager.resultState, selectedControl } }))
}

export const selectHistoryRecordItem = (key: HistoryRecordsItemKey): AppThunk => (dispatch, getState) => {
    const state = getState().focusManager.resultState;
    dispatch(focusManagerSlice.actions.changeFocused({ screenKey: ViewScreenKey.Result, state: { ...state, selectedRecord: { index: state.selectedRecord.index, key } } }))
}