import { getLogger } from "@visonum/network-quality-sdk";
import * as R from "ramda";
import { ProcessEnv } from "../config-types";
import { isRunningInBrowser } from "./ssr";
import { allowShowLog } from "./utils/allowShowLog";
import { getUrlParamValue } from "./utils/url";
import { getPackageNameMaybe } from "./packageJsonHelper";

const getUseLoggerFromUrl = (url: string | null) => !R.isNil(getUrlParamValue(url, "enable-logger"));

export const isProductionMode = () => {
  const NODE_ENV = (process.env.NEXT_PUBLIC_NODE_ENV as ProcessEnv);
  return (R.isNil(NODE_ENV) || NODE_ENV === "production")
}

const getLoggerLevel = () => {
  const url = isRunningInBrowser() ? window.location.href : null;

  if (getUseLoggerFromUrl(url)) {
    return "trace";
  }

  return isProductionMode() ? "error" : "trace";
}

const getLoggerInternal = () => {
  const logger = getLogger({
    name: getPackageNameMaybe() ?? "test",
    level: getLoggerLevel(),
    formatObjects: true,
    storeMessages: allowShowLog(),
  });

  return logger;
}

export const logger = getLoggerInternal();
