import styled from "@emotion/styled";
import { StaticImageData } from "next/image";

interface HeaderImageDivProps {
    image: StaticImageData;
}

export const HeaderImageDiv = styled.div<HeaderImageDivProps>([
    props => ({
        backgroundImage: `url(${props.image.src})`,
    }),
    {        
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        backgroundSize: "auto",
    },
]);

export const HeaderText = styled.h1([
    props => props.theme.typography.bold,
    props => props.theme.colors.white,
    {
        margin: "auto",
        fontSize: 32,
        textAlign: "center",
        alignItems: "center",
        width: "calc(100% - 40px)",
    },
    `
    @media (max-width: 767px) {
        font-size: 22px;
    }
    `
]);