import { useRouter } from "next/router";
import { MouseEventHandler } from "react";
import { getAsPath } from "../../helper/openUrl";
import { getRelByHref, getTargetByHref } from "../../helper/utils";
import React from "react";
import { devCheckHref } from "../../devCheckHref";

interface SmartLinkProps {
    href: string;
    isEnabled?: boolean;
    isNaked?: boolean;
    isLocalized?: boolean;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const SmartLink: React.FC<SmartLinkProps> = ({ href, isEnabled = true, isNaked = true, isLocalized = true, onClick, children }) => {

    if (!isEnabled) {
        return (
            <div>
                {children}
            </div>
        );
    }

    const router = useRouter();

    const localizedRef = isLocalized ? getAsPath(href, router) : href;

    return (
        <a href={devCheckHref(localizedRef)}
            style={isNaked ? { textDecoration: "none" } : {}}
            onClick={onClick}
            target={getTargetByHref(href)}
            rel={getRelByHref(href)}>
            {children}
        </a>
    );
}

export default SmartLink;