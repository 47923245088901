import styled from "@emotion/styled";

export const RowContainer = styled.div([
    {
        display: "flex",
        flexDirection: "column",
    },
]);

export const CellDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.default,
    {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        lineHeight: "100%",

        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,

        span: [
            theme.splus3.typography.small,
            {
                /* for mobile */
                [theme.splus3.breakpoints.mobile]: [
                    theme.splus3.typography.font12,
                ],
            }
        ],

        strong: [
            theme.splus3.typography.bold,
        ],

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.small,
            {
                paddingLeft: 0,
                paddingRight: 0,
                gap: 0,
                paddingTop: 4,
                paddingBottom: 4,        
            }
        ],
    }
]));

export const TableDivider = styled.line(({ theme }) => ([
    theme.splus3.background.tableDevider,
    {
        display: "block",
        height: 1,
    }
]));