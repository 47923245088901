declare global {
    interface Window {
        webkitConvertPointFromNodeToPage: unknown;
    }
}

export const isWebKit = (): boolean => {
    if (typeof window === "undefined") {
        return false;
    } else {
        const webkitConvertPointFromNodeToPage = window.webkitConvertPointFromNodeToPage;
        return typeof webkitConvertPointFromNodeToPage === "function";
    }
}