import React, { ReactNode } from "react"
import t from "../../helper/t"
import { getErrorMessage, getErrorTitleKey } from "./errorUtils"
import ModalDialog from "../../components/elements/gigatv/ModalDialog"
import { useAppSelector } from "../../hooks"
import DialogButton from "../../components/elements/gigatv/buttons/DialogButton"
import { createNqError } from "@visonum/network-quality-sdk"
import ErrorAdditionalInfo from "./ErrorAdditionalInfo"
import { r } from "../../theme/gigatv/sizeUtils"

const GigaTvErrorDialog: React.FC = () => {

  const errorState = useAppSelector(state => state.error);
  const lastError = errorState.lastError ?? createNqError("Cl-13", "GigaTvErrorDialog called, but error state is null.");

  const title = t(getErrorTitleKey(lastError));

  const content: ReactNode = (
    <>
      <div>{t(getErrorMessage(lastError))}</div>
      <div style={{ marginTop: r(16) }}>
        <ErrorAdditionalInfo lastError={lastError} />
      </div>
    </>
  )

  return (
    <ModalDialog header={title} content={content}>
      <DialogButton isDefault={true} isFocused={true}>{t("Ok")}</DialogButton>
    </ModalDialog>
  );
}

export default GigaTvErrorDialog;