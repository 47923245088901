import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import { HistoryControlItemKey } from "../../../../../features/focusManager/FocusabeItemKey";
import CommonButton from "../../../../../components/elements/gigatv/buttons/CommonButton";
import { historyControlPageDownIsDisabled, historyControlPageUpIsDisabled, historyControlRemoveEntriesIsDisabled } from "../../../../../features/focusManager/resultScreenHelper";

const ButtonsContainer = styled.div([
    {
        display: "flex",
        flexGrow: 10,
        justifyContent: "space-between",
    }
]);


const ControlContainer = styled.div([
    {
        display: "flex",
        columnGap: r(24),
    }
]);

const ControlButtonIconDiv = styled.div([
    {
        width: r(28.6),
        height: r(28.6),
    }
]);

interface HistoryControlProps {
    focusedControl: HistoryControlItemKey | null;
    recordsCount: number;
    frameItemCount: number;
    firstItemIndex: number;
}

const HistoryControl: React.FC<HistoryControlProps> = ({ focusedControl, recordsCount, frameItemCount, firstItemIndex }) => {
    return (
        <ButtonsContainer>
            <ControlContainer>
                <CommonButton dataCy="historyControlDownButton"
                    isFocused={focusedControl === HistoryControlItemKey.PageDown}
                    isDisabled={historyControlPageDownIsDisabled(recordsCount, frameItemCount, firstItemIndex)}>

                    <ControlButtonIconDiv>
                        <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.9997 2.3335L13.9997 25.6668M13.9997 25.6668L25.6663 14.0002M13.9997 25.6668L2.33301 14.0002" stroke="white" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </ControlButtonIconDiv>
                </CommonButton>
                <CommonButton dataCy="historyControlUpButton"
                    isFocused={focusedControl === HistoryControlItemKey.PageUp}
                    isDisabled={historyControlPageUpIsDisabled(firstItemIndex)}>

                    <ControlButtonIconDiv>
                        <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.0003 25.6665L14.0003 2.33317M14.0003 2.33317L2.33366 13.9998M14.0003 2.33317L25.667 13.9998" stroke="white" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </ControlButtonIconDiv>
                </CommonButton>
            </ControlContainer>
            <CommonButton dataCy="historyControlRemoveButton"
                isFocused={focusedControl === HistoryControlItemKey.RemoveEntries}
                isDisabled={historyControlRemoveEntriesIsDisabled(recordsCount)}>
                Alle Einträge löschen
            </CommonButton>
        </ButtonsContainer>
    );
}

export default HistoryControl;
