import * as R from "ramda"
import { AppThunk } from "../../store"
import { resetError } from "../errors/errorSlice"
import { AppAssertionError } from "../errors/AppAssertionError"
import { deleteAllRecords } from "../history/historySlice"
import { reset } from "../measurement/measurementSlice"
import { ErrorDialogState, HistoryDetailOverlayState, RemoveEntryDialogState } from "./DialogState"
import { ErrorItemKey, HistoryDetailItemKey, RemoveEntryItemKey } from "./FocusabeItemKey"
import ViewDialogKey from "./ViewDialogKey"
import ViewScreenKey from "./ViewScreenKey"
import focusManagerSlice, { closeDialog, setScreen } from "./focusManagerSlice"
import { ActionForElement, ViewScreen } from "./types"
import { devAssert } from "../errors/devAssert"

const closeErrorDialog = (): AppThunk => (dispatch) => {
    dispatch(resetError());
    dispatch(reset());
    dispatch(setScreen(ViewScreenKey.Home));
}

const errorDialogScreen: ViewScreen<ErrorDialogState> = (state) => ({
    focusedElementKey: state.selectedItem,
    elementActionsMap: new Map<ErrorItemKey, ActionForElement>([[ErrorItemKey.CloseButton, { enter: closeErrorDialog }]]),
    backAction: () => closeErrorDialog(),
});

const removeEntries = (): AppThunk => (dispatch) => {
    dispatch(deleteAllRecords());
    dispatch(closeDialog());
}

const selectButtonOnRemoveEntryDialog = (selectedItem: RemoveEntryItemKey): AppThunk => (dispatch, getState) => {
    const dialog = getState().focusManager.dialog;

    if (R.isNil(dialog) || dialog.dialogKey !== ViewDialogKey.RemoveEntry) {
        devAssert(new AppAssertionError({ actual: dialog, expected: "dialog.dialogKey = ViewDialogKey.RemoveEntry" }), "Get View Dialog");
        return;
    }

    dispatch(focusManagerSlice.actions.changeDialog({ ...dialog, state: { selectedItem } }));
}

const removeEntryDialogScreen: ViewScreen<RemoveEntryDialogState> = (state) => ({
    focusedElementKey: state.selectedItem,
    elementActionsMap: new Map<RemoveEntryItemKey, ActionForElement>([
        [RemoveEntryItemKey.RemoveButton, {
            tab: () => selectButtonOnRemoveEntryDialog(RemoveEntryItemKey.CloseButton),
            down: () => selectButtonOnRemoveEntryDialog(RemoveEntryItemKey.CloseButton),
            enter: removeEntries,
        }],
        [RemoveEntryItemKey.CloseButton, {
            tab: () => selectButtonOnRemoveEntryDialog(RemoveEntryItemKey.RemoveButton),
            up: () => selectButtonOnRemoveEntryDialog(RemoveEntryItemKey.RemoveButton),
            enter: () => closeDialog(),
        }],
    ]),
    backAction: () => closeDialog(),
});

const historyDetailOverlayScreen: ViewScreen<HistoryDetailOverlayState> = (state) => ({
    focusedElementKey: state.selectedItem,
    elementActionsMap: new Map<HistoryDetailItemKey, ActionForElement>([[HistoryDetailItemKey.Back, { enter: () => closeDialog() }]]),
    backAction: () => closeDialog(),
});

type AllViewDialogs =
    ViewScreen<ErrorDialogState> |
    ViewScreen<RemoveEntryDialogState> |
    ViewScreen<HistoryDetailOverlayState>;

export function getViewDialog(dialogKey: ViewDialogKey.Error): ViewScreen<ErrorDialogState>;
export function getViewDialog(dialogKey: ViewDialogKey.RemoveEntry): ViewScreen<RemoveEntryDialogState>;
export function getViewDialog(dialogKey: ViewDialogKey.HistoryDetail): ViewScreen<HistoryDetailOverlayState>;
export function getViewDialog(dialogKey: ViewDialogKey): AllViewDialogs {
    switch (dialogKey) {
        case ViewDialogKey.Error: return errorDialogScreen;
        case ViewDialogKey.RemoveEntry: return removeEntryDialogScreen;
        case ViewDialogKey.HistoryDetail: return historyDetailOverlayScreen;
    }
}