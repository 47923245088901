import * as R from "ramda";
import * as React from "react";

export type KeyboardHandler = (ev: KeyboardEvent) => void;

export const KeyCode_Enter = "Enter";

export const getKeyCode = (ev: KeyboardEvent | React.KeyboardEvent): string => {
    if (R.isNil(ev.key)) {
        return ev.code;
    }

    return ev.key;
}