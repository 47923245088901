import { NQError } from "@visonum/network-quality-sdk";
import t from "../../helper/t";
import { formatDate } from "../../helper/unitsFormatting";
import { selectIpAddress, selectSpeedtestId } from "./utils";
import * as R from "ramda";
import { plainDate, plainDateToLocaleTimeString } from "../../types";

interface ErrorItem {
    key?: string;
    value: string;
}

export const getErrorClientInfo = (lastError?: NQError): ErrorItem[] => {
    const speedtestId = selectSpeedtestId();
    const ipAddress = selectIpAddress();

    const date = plainDate();

    const items = [
        R.isNil(lastError) ? [] : [{ key: t("Fehlernummer"), value: lastError.scheme }],
        speedtestId == null ? [] : [{ key: t("Speedtest-ID"), value: speedtestId }],
        ipAddress == null ? [] : [{ key: t("IP-Adresse"), value: ipAddress }],
        { value: `${formatDate(date)} - ${plainDateToLocaleTimeString(date, [], { timeStyle: "medium" })} ${t("Uhr")}` }
    ].flat();

    return items;
}