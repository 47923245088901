import { name, version } from '../../package.json';

export const getPackageVersion = (): string => {
    return version;
}

export const getPackageName = (): string => {
    return name;
}

export const getPackageNameMaybe = (): string | undefined => {
    return name;
}