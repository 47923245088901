import { ThemeProvider } from "@emotion/react";
import { AppProps } from "next/app";
import { ReactElement } from "react";
import { Provider } from "react-redux";
import { store } from "../../store";
import { theme } from "../../theme/theme";
import ScaDialogSwitcher from "./ScaDialogSwitcher";

const AdminPage = ({ Component, pageProps }: AppProps): ReactElement => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <></>
            </ThemeProvider>
            <main style={{ height: "auto" }}>
                <Component {...pageProps} />
                <ScaDialogSwitcher />
            </main>
        </Provider>
    );
}

export default AdminPage;