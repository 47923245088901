import {
    InfoScreenPart,
    InfoTab,
    ResultScreenPart,
    ResultButton,
    ResultTab,
    HistoryScreenPart,
    HistoryControlItemKey,
    HistoryRecordsItemKey,
    InfoButton,
    HomeItemKey
} from "./FocusabeItemKey";
import { homeViewScreen, infoViewScreen, resultViewScreen } from "./screens";
import { ViewScreen } from "./types";
import ViewScreenKey from "./ViewScreenKey";
import { HomeScreenState, InfoScreenState, MeasurementScreenState, ResultScreenState } from "./ViewScreenState";

type AllViewScreens =
    ViewScreen<HomeScreenState> |
    ViewScreen<ResultScreenState> |
    ViewScreen<InfoScreenState> |
    null;

export function getViewScreen(screenKey: ViewScreenKey.Home): ViewScreen<HomeScreenState>;
export function getViewScreen(screenKey: ViewScreenKey.Measurement): null;
export function getViewScreen(screenKey: ViewScreenKey.Result): ViewScreen<ResultScreenState>;
export function getViewScreen(screenKey: ViewScreenKey.Info): ViewScreen<InfoScreenState>;
export function getViewScreen(screenKey: ViewScreenKey): AllViewScreens {
    switch (screenKey) {
        case ViewScreenKey.Home:
            return homeViewScreen;
        case ViewScreenKey.Measurement:
            return null;
        case ViewScreenKey.Result:
            return resultViewScreen;
        case ViewScreenKey.Info:
            return infoViewScreen;
    }
}

export function getInitialState(screenKey: ViewScreenKey.Home): HomeScreenState
export function getInitialState(screenKey: ViewScreenKey.Measurement): MeasurementScreenState
export function getInitialState(screenKey: ViewScreenKey.Result): ResultScreenState
export function getInitialState(screenKey: ViewScreenKey.Info): InfoScreenState
export function getInitialState(screenKey: ViewScreenKey) {
    switch (screenKey) {
        case ViewScreenKey.Home:
            return { selectedItem: HomeItemKey.ButtonMeasurement }
        case ViewScreenKey.Measurement:
            return {}
        case ViewScreenKey.Result:
            return {
                screenPart: ResultScreenPart.Tabs,
                selectedButton: ResultButton.SpeedtestStart,
                selectedTab: ResultTab.CurrentMeasurement,
                historyPart: HistoryScreenPart.Constrol,
                selectedControl: HistoryControlItemKey.PageDown,
                selectedRecord: {
                    index: 0,
                    key: HistoryRecordsItemKey.Item,
                }
            }
        case ViewScreenKey.Info:
            return { screenPart: InfoScreenPart.Tabs, selectedButton: InfoButton.SpeedtestStart, selectedTab: InfoTab.Impressum }
    }
}