import { useRouter } from "next/router";
import * as React from "react";
import { getAsPath } from "../../../helper/openUrl";
import { getRelByHref, getTargetByHref } from "../../../helper/utils";
import { ClickableDiv } from "./ClickableDiv";
import { useAppSelector } from "../../../hooks";
import { DialogKind } from "../../../features/dialogManager/dialogManagerSlice";
import { devCheckHref } from "../../../devCheckHref";

type NavigationDestination = {
    href: string;
    isLocalized?: boolean;
} | {
    href?: undefined;
    onClick: () => void;
}

type NavigationButtonProps = NavigationDestination & {
    isEnabled?: boolean;
    isDialog?: boolean;
    isFocused?: boolean;
    dataCy?: string;
}

const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
    if (props.isEnabled === false) {
        return (
            <div data-cy={props.dataCy} style={{ display: "inline" }}>
                {props.children}
            </div>
        );
    }

    if (props.href === undefined) {
        return (
            <ClickableDiv dataCy={props.dataCy} onClick={props.onClick} style={{ display: "inline" }} enabled={props.isDialog === true ? true : "auto"} isFocused={props.isFocused}>
                {props.children}
            </ClickableDiv>
        );
    } else {
        const router = useRouter();
        const localizedRef = (props.isLocalized == undefined || props.isLocalized == true) ? getAsPath(props.href, router) : props.href;
        const linkEnabled = props.isDialog === true ? true : useAppSelector(state => state.dialogManager).kind === DialogKind.None;

        return linkEnabled ? (
            <a data-cy={props.dataCy}
                href={devCheckHref(localizedRef)}
                style={{ textDecoration: "none", cursor: "pointer" }}
                target={getTargetByHref(props.href)}
                rel={getRelByHref(props.href)}>
                {props.children}
            </a>
        ) : (
            <div data-cy={props.dataCy} style={{ display: "inline" }}>
                {props.children}
            </div>
        );
    }
}

export default NavigationButton;