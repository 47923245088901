import * as R from "ramda";
import watch from "redux-watch";
import { store } from "../store";
import { Phase } from "../features/measurement/types";
import { logger } from "./logger";
import { VARIANT } from "../config";

declare global {
    var _ddq: {
        push: (args: unknown[]) => void,
    }
}

type FormStatusKind = "start" | "end" | "success";

type ReportEventOptions = {
    formStep: number,
    formStatus: FormStatusKind[],
    context?: string,
}

const getSiteStructure = (structure: string): string[] => {
    return structure.split(":");
}

export const reportEvent = (structure: string, options?: ReportEventOptions): void => {
    //TODO: check  (_anw_speedtest_customer == 2)
    if (typeof _ddq === "undefined" || R.isNil(_ddq) || VARIANT !== "SPLUS") {
        return;
    }

    logger.info(structure, "Report Event");

    const siteStructure = getSiteStructure(structure);

    const commonPushData = {
        "siteStructure": siteStructure,
        "pageType": "service",
        "loginStatus": "not logged in",
        "siteArea": "service",
        "platformType": "responsive",
        "lineOfCustomer": "consumer",
    }

    const pushData = R.isNil(options) ? commonPushData : {
        ...commonPushData, oForm: {
            name: "speedtest",
            type: "application funnel",
            formStep: options.formStep,
            formStepName: R.last(siteStructure),
            formStatus: options.formStatus,
            context: options.context,
        }
    }

    _ddq.push(["pageview", pushData]);
}

export const reportNavigateToViewEvent = (view: "home" | "tips" | "report"): void => {
    reportEvent(`privatkunden:speedtest:${view}`);
}

export const reportStartEvent = (again: boolean): void => {
    const commonOptions: ReportEventOptions = {
        "formStep": 1,
        "formStatus": [
            "start"
        ],
    };

    const options: ReportEventOptions = again ? { ...commonOptions, "context": "speedtest again" } : commonOptions;

    reportEvent("privatkunden:speedtest:start", options);
}

const tippIdToStructureId = (tippId: number): string => {
    switch (tippId) {
        case 0: return "no problem";
        case 1: return "browser";
        case 2: return "disturbance source";
        case 3: return "modem position";
        case 4: return "connect cable";
        case 5: return "wlan standard";
        case 6: return "old device";
        case 7: return "semi old_device";
        case 8: return "device not_compatible_400mb";
        case 9: return "device not_compatible_1000mb";
        case 10: return "network improvements2";
        case 11: return "network improvements3";
        case 12: return "parallel traffic";
        case 13: return "modem upgrade";
        case 14: return "lan detected";
        case 15: return "vpn detected";
        default: return `tipId_${tippId}`;
    }
}

export const reportTippLink = (tippId: number): void => {
    const id = tippIdToStructureId(tippId);
    reportEvent(`privatkunden:speedtest:tips:${id}`);
}

const subscribeMeasurementEvents = () => {
    logger.info("subscribeMeasurementEvents");
    const w = watch(() => store.getState().measurement.kind);
    store.subscribe(w((newVal: Phase) => {
        switch (newVal) {
            case Phase.ModemResolving:
                reportEvent("privatkunden:speedtest:complete", { formStep: 2, formStatus: ["end", "success"] });
                break;
            case Phase.ModemExecuting:
                reportEvent("privatkunden:speedtest:modem start", { formStep: 3, formStatus: ["start"] });
                break;
            case Phase.CompletingWithModem:
                reportEvent("privatkunden:speedtest:modem complete", { formStep: 4, formStatus: ["end", "success"] });
                break;
        }
    }));
}

subscribeMeasurementEvents();