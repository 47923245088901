import { currentLangIs, fixImageUrl } from "../../helper/utils";

import internetSecurityDe from "../../../public/assets/tooltips/de/internet_security.json";
import bundlesDe from "../../../public/assets/tooltips/de/bundles.json";
import desktopDe from "../../../public/assets/tooltips/de/desktop.json";
import cableLanDslDe from "../../../public/assets/tooltips/de/cable_lan_dsl.json";
import differentTimesDe from "../../../public/assets/tooltips/de/different_times.json";

import internetSecurityEn from "../../../public/assets/tooltips/en/internet_security.json";
import bundlesEn from "../../../public/assets/tooltips/en/bundles.json";
import desktopEn from "../../../public/assets/tooltips/en/desktop.json";
import cableLanDslEn from "../../../public/assets/tooltips/en/cable_lan_dsl.json";
import differentTimesEn from "../../../public/assets/tooltips/en/different_times.json";

export interface InfoData {
    image: string;
    imageAlt: string;
    text: string;
    hintHeader: string;
    hintContent: string;
}

export enum HomeInfoCardId {
    InternetSecurity,
    Bundles,
    Desktop,
    CableLanDsl,
    DifferentTimes,
}

const getHomeInfoCardDataInternal = (langIsEn: boolean, id: HomeInfoCardId): InfoData => {
    switch (id) {
        case HomeInfoCardId.InternetSecurity: return langIsEn ? internetSecurityEn : internetSecurityDe;
        case HomeInfoCardId.Bundles: return langIsEn ? bundlesEn : bundlesDe;
        case HomeInfoCardId.Desktop: return langIsEn ? desktopEn : desktopDe;
        case HomeInfoCardId.CableLanDsl: return langIsEn ? cableLanDslEn : cableLanDslDe;
        case HomeInfoCardId.DifferentTimes: return langIsEn ? differentTimesEn : differentTimesDe;
    }

}

export const getHomeInfoCardData = (id: HomeInfoCardId): InfoData => {
    const retVal = getHomeInfoCardDataInternal(currentLangIs("en"), id);
    return {
        ...retVal,
        image: fixImageUrl(retVal.image),
    }

}