import MarkdownIt from "markdown-it";
import * as R from "ramda";
import { getRelByHref, getTargetByHref } from "./utils";
import { isRunningInBrowser } from "./ssr";
import Renderer from "markdown-it/lib/renderer";
import { logger } from "./logger";
import { store } from "../store";
import { matomoPushEvent } from "../features/matomo/matomoEvents";
import { getSdk } from "../networkQuality";
import { Models } from "@visonum/network-quality-sdk";

const md = new MarkdownIt({ html: true, breaks: true });

// Remember old renderer, if overridden, or proxy to default renderer
const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
}

const markdownRender = (content: string): string => {
    return md.render(content);
}

type LinkAttributes = "target" | "rel" | "onclick";

const linkOpener: Renderer.RenderRule = (tokens, idx, options, env, self): string => {

    const token = tokens[idx];
    if (!R.isNil(token)) {
        const setAttr = (attrName: LinkAttributes, href: string, getValue: string | ((href: string) => string | undefined)) => {
            if (token.attrGet(attrName) === null) {
                const attrValue = typeof (getValue) === "string" ? getValue : getValue(href);
                if (attrValue !== undefined) {
                    token.attrPush([attrName, attrValue]);
                }
            }
        }

        const href = token.attrGet("href");
        if (href !== null) {
            setAttr("target", href, getTargetByHref);
            setAttr("rel", href, getRelByHref);

            if (typeof window.LinkInterceptor !== "undefined" &&
                href.includes("vodafone.de/hilfe-support/rueckruf.html#/rueckrufnummer?thema=SpeedtestPlus_Quality_PS")) {
                setAttr("onclick", href, "window.LinkInterceptor.onCallbackLink()")
            }
        }
    }

    // pass token to default renderer.
    return defaultRender(tokens, idx, options, env, self);
}

if (isRunningInBrowser()) {
    window.LinkInterceptor = {
        onCallbackLink() {
            logger.info("onCallbackLink");
            store.dispatch(matomoPushEvent({
                category: "Partial Service",
                action: "Callback Click",
            }));
            getSdk().updateValue(Models.ValueKey.CallbacksCounter, Models.ValueCommand.Decr).subscribe({
                complete: () => logger.info("Callback counter decremented"),
                error: error => logger.error("Callback counter", error),
            });
        },
    }

    md.renderer.rules.link_open = linkOpener;
}

export default markdownRender;