import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import { SpeedKind, getSpeedBackground } from "./resultUtils";
import RoundDiv from "./RoundDiv";
import { CompareTextContainer, SubTextContainer, SubTextDiv } from "./ProgressBarCommon";
import { formatSpeed } from "src/helper/formatSpeeds";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",

        marginTop: r(40 + 32),
        // maxWidth: r(580),
    }
]);

const ProgressBackground = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.indicator,
    {
        position: "relative",
        height: r(6),
        borderRadius: r(6),
    }
]));

interface ProgressVaueProps {
    speedKind: SpeedKind;
    positionFactor: number;
}

const ProgressValue = styled.div<ProgressVaueProps>(({ speedKind, positionFactor }) => ([
    getSpeedBackground(speedKind),
    {
        width: `${100.0 * positionFactor}%`,
        borderRadius: r(6),
        height: "100%",
    }
]));

const ShiftFactor = 0.95;

interface IndicatorDivProps {
    top: number,
    positionFactor: number;
}

const IndicatorDiv = styled.div<IndicatorDivProps>(({ theme, top, positionFactor }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize18,
    theme.gigatv.typography.regular,
    {
        top: r(top),
        left: `${100.0 * positionFactor * ShiftFactor}%`,
        width: 0,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: r(4),
        lineHeight: r(12),
    }
]));

interface IndicatorProps {
    speedKind: SpeedKind;
    currentValue: number;
    maxValue: number;
}

const Indicator: React.FC<IndicatorProps> = ({ speedKind, currentValue, maxValue }) => {
    return (
        <IndicatorDiv top={-31} positionFactor={currentValue / maxValue}>
            {formatSpeed([], currentValue).value}
            <RoundDiv size={6} background={getSpeedBackground(speedKind)} />
            <div style={{ width: 1, height: r(11) }}>
                <svg width="1" height="100%" viewBox="0 0 1 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1" height="4.00087" rx="0.5" fill="white" />
                    <rect y="6.00098" width="1" height="4.00087" rx="0.5" fill="white" />
                </svg>
            </div>
        </IndicatorDiv>
    );
}

interface ProgressBarProps {
    speedKind: SpeedKind;
    min: number;
    avg: number;
    max: number;
    current: number | null;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ speedKind, min, avg, max, current }) => {
    const progressValue = Math.min(1.0, current === null ? 1.0 : current / max * ShiftFactor);
    return (
        <RootDiv>
            <ProgressBackground>
                <ProgressValue speedKind={speedKind} positionFactor={progressValue} />
                <Indicator speedKind={SpeedKind.Minimal} currentValue={min} maxValue={max} />
                <Indicator speedKind={SpeedKind.Average} currentValue={avg} maxValue={max} />
                <Indicator speedKind={SpeedKind.Maximal} currentValue={max} maxValue={max} />
            </ProgressBackground>
            <SubTextContainer>
                <SubTextDiv>
                    <RoundDiv size={8} background={getSpeedBackground(SpeedKind.Minimal)} />
                    Minimal
                </SubTextDiv>
                <SubTextDiv>
                    <RoundDiv size={8} background={getSpeedBackground(SpeedKind.Average)} />
                    Üblich
                </SubTextDiv>
                <SubTextDiv>
                    <RoundDiv size={8} background={getSpeedBackground(SpeedKind.Maximal)} />
                    Maximal
                </SubTextDiv>
            </SubTextContainer>
            <CompareTextContainer>
                <span>schlechter</span>
                <span>besser</span>
            </CompareTextContainer>
        </RootDiv>
    );
}

export default ProgressBar;