import styled from "@emotion/styled";
import { getErrorClientInfo } from "./getErrorClientInfo";
import { NQError } from "@visonum/network-quality-sdk";
import * as R from "ramda";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gap: 8,
        textAlign: "left",
    }
]));

interface ErrorAdditionalInfoProps {
    lastError?: NQError;
}

const ErrorAdditionalInfo: React.FC<ErrorAdditionalInfoProps> = ({ lastError }) => {

    const errorClientInfo = getErrorClientInfo(lastError);

    return (
        <RootDiv>
            {errorClientInfo.map((item, index) => (
                R.isNil(item.key) ? (
                    <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                        {item.value}
                    </div>
                ) : (
                    <>
                        <div key={`${index}-1`} style={{ whiteSpace: "nowrap" }}>{item.key}:</div>
                        <div key={`${index}-2`} style={{ overflowWrap: "anywhere" }}>{item.value}</div>
                    </>
                )
            ))}
        </RootDiv>
    );
}

export default ErrorAdditionalInfo;