import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ModalDialog, { ModalDialogButtons, ModalDialogResult } from "../elements/ModalDialog";
import t from "../../../helper/t";
import { hideDialog } from "../../../features/dialogManager/dialogManagerSlice";
import { updateCurrenIndex } from "../../../features/hints/hintsSlice";
import { PartialServiceCallbackTipId } from "../../../features/hints/hintsVirtualIds";
import { updateResultPageTab, updatePage } from "../../../features/pageManager/pageManagerSlice";
import { ResultTabKey, PageKey } from "../../../features/pageManager/pageState";

const ContentContaner = styled.div([
    {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    }
]);

const PartialServiceCallbackDialog: React.FC<{}> = () => {
    const dispatch = useAppDispatch();
    const hintsState = useAppSelector(state => state.hints);

    const onClose = (result: ModalDialogResult) => {
        dispatch(hideDialog());

        if (result === ModalDialogResult.GoToTip) {
            dispatch(updateResultPageTab(ResultTabKey.SpeedtestGuide));
            dispatch(updatePage(PageKey.Result));
            const hintIndex = hintsState.hintIds.indexOf(PartialServiceCallbackTipId);
            if (hintIndex >= 0) {
                dispatch(updateCurrenIndex(hintIndex));
            }
        }
    }

    return (
        <ModalDialog header={t("Dein Anschluss braucht Hilfe")} buttons={ModalDialogButtons.TipCancel} onClose={onClose}>
            <ContentContaner>
                {t("Ein:e Expert:in ist schon für Dich reserviert")}
            </ContentContaner>
        </ModalDialog>
    );
}

export default PartialServiceCallbackDialog;