import { freeze } from "immer";
import { NQRecord } from "./types";
import { Models, ModemFinalKind, ModemNotRunReason, NQValueKind } from "@visonum/network-quality-sdk";
import * as R from "ramda";
import { PlainDate, plainDate, plainDateAddDays } from "../../types";

const latestRecord: NQRecord = freeze({
    date: plainDate(),
    prepareResult: {
        init: {
            speedtest: {
                id: "t4k22dykkdck_0",
            },
            connection: {
                ip: "127.0.0.1",
                isp: "Vodafone GmbH",
                isCustomer: false,
                isVpnActive: false,
                ispFootprint: undefined,
            },
            modem: {
                type: "6660",
                name: "FRITZ!Box 6660",
                bookedDownloadSpeedMax: 500e6,
                bookedDownloadSpeedAvg: 450e6,
                bookedDownloadSpeedMin: 300e6,
                bookedUploadSpeedMax: 50e6,
                bookedUploadSpeedAvg: 35e6,
                bookedUploadSpeedMin: 15e6,
                provisionedDownloadSpeed: 1140000000,
            },
            cmts: {
                isModemTestAvailable: true,
                vendor: "Arris"
            },
            client: {
                device: {
                    deviceType: Models.DeviceModelDeviceTypeEnum.Desktop,
                },
                browser: {
                    name: "Zoopark",
                    version: "1.1.1",
                    isCurrent: true
                }
            },
            communication: {
                modemSwapLink: "https://vodafon.de",
            },
            partialService: {
                downloadIndex: 13,
                uploadIndex: 1,
            }
        },
        isIPv6: true,
    },
    benchmarkFinal: {
        kind: NQValueKind.BenchmarkFinalDidNotRun,
    },
    downloadIntermediates: [90e6, 97e6, 102e6, 91e6, 103e6, 99e6],
    downloadFinal: {
        kind: NQValueKind.DownloadFinal,
        speed: 85e6,
        jitter: 3000000
    },
    uploadIntermediates: [20e6, 30e6, 25e6, 27e6, 21e6, 24e6],
    uploadFinal: {
        kind: NQValueKind.UploadFinal,
        speed: 49e6,
        jitter: 1000000,
    },
    pingIntermediates: [0.021, 0.022, 0.019, 0.020, 0.018, 0.019],
    pingFinal: {
        kind: NQValueKind.PingFinal,
        pingTime: 0.018,
        jitter: 0.001,
    },
    modemFinal: {
        kind: NQValueKind.ModemFinal,
        parallelTraffic: null,
        connectionType: Models.SnmpResultModelConnectionTypeEnum.Wlan5,
        modemFinal: {
            kind: ModemFinalKind.ModemFinalAvailable,
            speed: 520e6,
        },
        speedtestState: {
            downloadClient: true,
            uploadClient: true,
            downloadUdp: false,
        },
        snmpAvailable: null,
        wifiExtenderMode: null,
        wifiExtenderRegStatus: null,
    },
    hints: [1, 2, 3, 4],
    partialService: null,
});

const randomSpeed = (max: number, canBeNull: boolean = false): number | null => {
    if (canBeNull && Math.random() < 0.2) {
        return null;
    }
    const min = 0.7 * max;
    const delta = max - min;
    return min + Math.random() * delta;
}

const generateRecord = (date: PlainDate, id: string): NQRecord => {
    return freeze({
        date: date,
        prepareResult: {
            init: {
                speedtest: {
                    id,
                },
                connection: {
                    ip: "127.0.0.1",
                    isp: "Vodafone GmbH",
                    isCustomer: true,
                    isVpnActive: false,
                    ispFootprint: undefined,
                },
                modem: {
                    type: "6660",
                    name: "FRITZ!Box 6660",
                    bookedDownloadSpeedMax: 500e6,
                    bookedDownloadSpeedAvg: 450e6,
                    bookedDownloadSpeedMin: 300e6,
                    bookedUploadSpeedMax: 50e6,
                    bookedUploadSpeedAvg: 35e6,
                    bookedUploadSpeedMin: 15e6,
                    provisionedDownloadSpeed: 512e6,
                },
                cmts: {
                    isModemTestAvailable: true,
                    vendor: "Arris"
                },
                client: {
                    device: {
                        deviceType: Models.DeviceModelDeviceTypeEnum.Desktop,
                    },
                    browser: {
                        name: "Zoopark",
                        version: "1.1.1",
                        isCurrent: true
                    }
                },
                communication: {
                    modemSwapLink: "https://vodafon.de",
                },
                partialService: {
                    downloadIndex: 13,
                    uploadIndex: 1,
                }
            },
            isIPv6: true,
        },
        benchmarkFinal: {
            kind: NQValueKind.BenchmarkFinalDidNotRun,
        },
        downloadIntermediates: [90e6, 97e6, 102e6, 91e6, 103e6, 99e6],
        downloadFinal: {
            kind: NQValueKind.DownloadFinal,
            speed: randomSpeed(100e6)!,
            jitter: 3000000
        },
        uploadIntermediates: [20e6, 30e6, 25e6, 27e6, 21e6, 24e6],
        uploadFinal: {
            kind: NQValueKind.UploadFinal,
            speed: randomSpeed(50e6)!,
            jitter: 1000000,
        },
        pingIntermediates: [0.021, 0.022, 0.019, 0.020, 0.018, 0.019],
        pingFinal: {
            kind: NQValueKind.PingFinal,
            pingTime: randomSpeed(0.1)!,
            jitter: 0.001,
        },
        modemFinal: {
            kind: NQValueKind.ModemFinal,
            parallelTraffic: null,
            connectionType: Models.SnmpResultModelConnectionTypeEnum.Wlan5,
            modemFinal: Math.random() < 0.3 ? {
                kind: ModemFinalKind.ModemFinalDidNotRun,
                reason: ModemNotRunReason.NotAvailable,
            } : {
                kind: ModemFinalKind.ModemFinalAvailable,
                speed: randomSpeed(520e6)!,
            },
            speedtestState: {
                downloadClient: true,
                uploadClient: true,
                downloadUdp: false,
            },
            snmpAvailable: null,
            wifiExtenderMode: null,
            wifiExtenderRegStatus: null,    
        },
        hints: [1, 2, 3, 4],
        partialService: null,
    });
}

export const getSyntheticHistory = (count: number = 20): NQRecord[] => freeze([
    latestRecord,
    ...R.range(0, count
    ).map(n => generateRecord(plainDateAddDays(latestRecord.date, -n - 1), `g4k22dykkdck${n}`)),
]);