import styled from "@emotion/styled";
import React from "react";
import SmartLink from "../../components/elements/SmartLink";
import { useAppSelector } from "../../hooks";
import { StaticImageData } from "next/image";
import { getCurrentLang } from "../../helper/utils";
import { LangName } from "../../types";
import { AppAssertionError } from "../errors/AppAssertionError";
import markdownRender from "../../helper/markdownRender";
import { NboCase, NboObject } from "./NextBestOfferUtils";
import { VARIANT } from "../../config";

import nboObject01_de from "../../../public/assets/nbo/de/nbo01.json";
import nboObject02_de from "../../../public/assets/nbo/de/nbo02.json";
import nboObject03_de from "../../../public/assets/nbo/de/nbo03.json";
import nboObject01_en from "../../../public/assets/nbo/en/nbo01.json";
import nboObject02_en from "../../../public/assets/nbo/en/nbo02.json";
import nboObject03_en from "../../../public/assets/nbo/en/nbo03.json";

import nboImage1 from "../../../assets/images/common/nbo/GigaZuhausePre-MPH-2er-1278x496-dt.webp";
import nboImage2 from "../../../assets/images/common/nbo/gigakombi-imageheader-2560x432-dt.webp";

export const getNboObject = (nboCase: NboCase, lang: LangName): NboObject => {
  if (nboCase === NboCase.None) {
    throw new AppAssertionError({ actual: nboCase, expected: "NonCustomer or GigabitCableCustomer or BelowGigabitCableCustomer" });
  }

  const jsonNboObject = (() => {
    switch (nboCase) {
      case NboCase.NonCustomer:
        switch (lang) {
          case "de":
            return nboObject01_de;
          case "en":
            return nboObject01_en;
        }
      case NboCase.GigabitCableCustomer:
        switch (lang) {
          case "de":
            return nboObject02_de;
          case "en":
            return nboObject02_en;
        }
      case NboCase.BelowGigabitCableCustomer:
        switch (lang) {
          case "de":
            return nboObject03_de;
          case "en":
            return nboObject03_en;
        }
    }
  })();

  const nboImage = (() => {
    switch (nboCase) {
      case NboCase.NonCustomer:
        return nboImage1;
      case NboCase.GigabitCableCustomer:
        return nboImage2;
      case NboCase.BelowGigabitCableCustomer:
        return nboImage1;
    }
  })();

  return {
    ...jsonNboObject,
    image: nboImage
  }
}

const RootDiv = styled.div(({ theme }) => ([
  VARIANT === "SPLUS3" ? theme.splus3.background.white : theme.colors.background.nboTeaser,
  {
    /* mobile and tablet */
    display: "flex",
    flexDirection: "column-reverse",

    [theme.breakpoints.tablet]: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridTemplateRows: "1fr",
    },
  }
]));

const ContentTextDiv = styled.div(({ theme }) => ([
  {
    gridRowStart: 2,
    gridRowEnd: 2,
    gridColumnStart: 1,
    gridColumnEnd: 1,
    paddingTop: 20,
    paddingBottom: 20,
    marginLeft: 20,
    marginRight: 20,

    minHeight: 220,
    height: "auto",

    /* for tablet */
    [theme.breakpoints.mobile]: {
      height: 264,
    },

    [theme.breakpoints.tablet]: {
      gridRowStart: 1,
      gridRowEnd: 1,
      gridColumnStart: 2,
      gridColumnEnd: 6,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      height: "100%",
    },
  }
]));

const ContentImageDiv = styled.div(({ theme }) => ([
  {
    gridRowStart: 1,
    gridRowEnd: 1,
    gridColumnStart: 1,
    gridColumnEnd: 1,

    /* for mobile */
    height: 192,

    /* for tablet */
    [theme.breakpoints.mobile]: {
      height: 204,
    },

    /* for desktop */
    [theme.breakpoints.tablet]: {
      height: 304,
      gridColumnStart: 7,
      gridColumnEnd: 13,
    },
  }
]));


interface ImageDivProps {
  image: StaticImageData;
}

const ImageDiv = styled.div<ImageDivProps>([
  props => ({
    backgroundImage: `url("${props.image.src}")`,
  }),
  {
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    backgroundSize: "cover",
  }
]);

const TextContainerDiv = styled.div(({ theme }) => ([
  theme.colors.foreground.nboText,
  {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",

    height: "100%",
    gap: 20,

    [theme.breakpoints.mobile]: {
      height: "100%",
      gap: 20,
      alignItems: "center",
    },

    [theme.breakpoints.tablet]: {
      height: "100%",
      gap: 20,
      alignItems: "flex-start",
    },
  }
]));

const HeadlineTextDiv = styled.div(({ theme }) => ([
  theme.typography.regular20,
  {
    fontWeight: 700,
    [theme.breakpoints.tablet]: [
      theme.typography.lite28,
    ],
  },
]));

const CopyTextDiv = styled.div(({ theme }) => ([
  theme.typography.lite18,
  {
    fontWeight: 400,
    [theme.breakpoints.tablet]: [
      theme.typography.regular18,
    ],
  },
  `strong {
    ${theme.typography.bold.styles};
  }`,
  `p {
    margin-block-start: 0;
    margin-block-end: 0;
  }`
]));

const NboButtonContaner = styled.div(({ theme }) => ([
  {
    alignSelf: "stretch",

    [theme.breakpoints.mobile]: {
      alignSelf: "center",
    },

    [theme.breakpoints.tablet]: {
      alignSelf: "flex-start",
    },
  }
]));

const NboButtonDiv = styled.div(({ theme }) => ([
  theme.colors.foreground.white,
  theme.colors.background.red,
  theme.typography.regular18,
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 9,
    borderRadius: 6,
    minWidth: 0,

    [theme.breakpoints.mobile]: {
      minWidth: 240,
    },

    [theme.breakpoints.tablet]: {
      paddingLeft: 28,
      paddingRight: 28,
      minWidth: 0,
    },
  }
]));

interface NextBestOfferProps {
}

const NextBestOffer: React.FC<NextBestOfferProps> = ({ }) => {

  if (VARIANT === "SFA") return null;

  const state = useAppSelector(state => state.nextBestOffer);
  const nboObject = getNboObject(state.nboCase, getCurrentLang());

  return (
    <RootDiv>
      <ContentTextDiv>
        <TextContainerDiv>
          <HeadlineTextDiv>{nboObject.headlineText}</HeadlineTextDiv>
          <CopyTextDiv dangerouslySetInnerHTML={{ __html: markdownRender(nboObject.copyText) }}></CopyTextDiv>
          <NboButtonContaner>
            <SmartLink href={nboObject.url}>
              <NboButtonDiv data-cy="tariff-link">
                {nboObject.buttonText}
              </NboButtonDiv>
            </SmartLink>
          </NboButtonContaner>
        </TextContainerDiv>
      </ContentTextDiv>
      <ContentImageDiv>
        <ImageDiv image={nboObject.image} />
      </ContentImageDiv>
    </RootDiv>
  );
}

export default NextBestOffer;
