import { NQError, NQErrorScheme, createNqError, isNqError } from "@visonum/network-quality-sdk";
import LanguageKey from "../../../generated/types/LanguageKey";

/* pure */
export const getNqError = (err: unknown): NQError => {
    if (isNqError(err)) {
        return err as NQError;
    } else if (err instanceof Error) {
        return createNqError("Cl-0", err.message, err);
    } else {
        return createNqError("Cl-0", "Unknown error type", err);
    }
}

/* pure */
export const getErrorTitleKey = (lastError: NQError): LanguageKey => {
    if (isNqError(lastError) && lastError.scheme == "Sm-1") {
        return "Wartungsarbeiten";
    } else {
        return "Es ist ein Fehler aufgetreten";
    }
}

type NQErrorScope = "API" | "Download" | "Upload" | "Ping" | "Remote port detection" | "Tcp Stats" | "Engine" | "unknown";

type NQErrorType = "Connectivity" | "Unexpected response" | "Decode" | "Encode" | "unknown";

type NQErrorKind = "Client" | "Rate limit" | "Parallel speedtests" | "System under maintenance" | "Benchmark error" | "Incompatible browser version" | "History" | "unknown";

const getNqErrorScope = (scheme: NQErrorScheme): NQErrorScope => {
    if (scheme.startsWith("Ap")) {
        return "API";
    } else if (scheme.startsWith("Dn")) {
        return "Download";
    } else if (scheme.startsWith("Up")) {
        return "Upload";
    } else if (scheme.startsWith("Pn")) {
        return "Ping";
    } else if (scheme.startsWith("Rp")) {
        return "Remote port detection";
    } else if (scheme.startsWith("St")) {
        return "Tcp Stats";
    } else if (scheme.startsWith("En")) {
        return "Engine";
    } else {
        return "unknown";
    }
}

const getNqErrorType = (scheme: NQErrorScheme): NQErrorType => {
    if (scheme.includes("-Cn-")) {
        return "Connectivity";
    } else if (scheme.includes("-Re-")) {
        return "Unexpected response";
    } else if (scheme.includes("-Dc-")) {
        return "Decode";
    } else if (scheme.includes("-En-")) {
        return "Encode";
    } else {
        return "unknown";
    }
}

export const getNqErrorKind = (scheme: NQErrorScheme): NQErrorKind => {
    if (scheme.startsWith("Cl")) {
        return "Client";
    } else if (scheme.startsWith("Rl")) {
        return "Rate limit";
    } else if (scheme.startsWith("Ps")) {
        return "Parallel speedtests";
    } else if (scheme.startsWith("Sm")) {
        return "System under maintenance";
    } else if (scheme.startsWith("Bm")) {
        return "Benchmark error";
    } else if (scheme.startsWith("Br")) {
        return "Incompatible browser version";
    } else if (scheme.startsWith("Hr")) {
        return "History";
    } else {
        return "unknown";
    }
}

export const isServerError = (scheme: NQErrorScheme): boolean => {
    return getNqErrorScope(scheme) === "Remote port detection" || getNqErrorType(scheme) === "Unexpected response";
}

export const isConnectivityError = (scheme: NQErrorScheme): boolean => {
    return getNqErrorType(scheme) === "Connectivity" && getNqErrorScope(scheme) !== "Remote port detection";
}

export const isClientError = (scheme: NQErrorScheme): boolean => {
    const kind = getNqErrorKind(scheme);
    return kind == "Client" || kind == "Benchmark error" || kind == "History";
}

/* pure */
export const getErrorMessage = (lastError: NQError): LanguageKey => {
    const scheme = lastError.scheme;

    if (isServerError(scheme)) {
        // The speedtest server is currently not available. Please try again later.
        return "Der Speedtest-Server steht derzeit nicht zur Verfügung. Bitte versuchen Sie es später noch einmal.";
    } else if (isConnectivityError(scheme)) {
        // An unexpected error occurred during measurement. Please try again later.
        return "Während der Messung ist ein unerwarteter Fehler aufgetreten. Bitte versuch es später noch einmal.";
    } else if (isClientError(scheme)) {
        // An internal error has occurred.
        return "Ein interner Fehler ist aufgetreten.";
    } else if (getNqErrorKind(scheme) === "System under maintenance") {
        return "Das System wird zurzeit gewartet. Bitte versuchen Sie es später noch einmal.";
    } else if (getNqErrorKind(scheme) === "Rate limit") {
        return "Du hast die maximal zulässige Anzahl von Speedtests für heute erreicht. Ab morgen steht Dir unser System wieder zur Verfügung. Die Begrenzung dient dem Schutz unseres Netzes und anderer Nutzer.";
    } else if (getNqErrorKind(scheme) === "Parallel speedtests") {
        return "Wir haben festgestellt, dass möglicherweise bereits ein Speedtest von diesem Anschluss durchgeführt wird. Bitte wart in ein paar Minuten vor dem Start eines neuen Speedtests.";
    } else {
        return "Ein interner Fehler ist aufgetreten.";
    }
}