import * as R from "ramda";
import { getLocalizedAddress } from "./utils/getLocalizedAddress";
import { isRunningInBrowser } from "./ssr";
import { NextRouter, Router } from "next/router";

export const getAsPath = (href: string, router?: Router | NextRouter) => {
    if (R.isNil(router) || R.isNil(router.query)) {
        return href;
    }

    const { lang } = router.query;
    return getLocalizedAddress(href, typeof lang === "string" ? lang : undefined);
}

export const openUrl = (href: string, router?: Router | NextRouter) => {
    const localizedHref = getAsPath(href, router);
    if (isRunningInBrowser()) {
        window.location.assign(localizedHref);
    }    
}