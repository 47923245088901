import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import ResultCard from "./ResultCard";
import { Percentage, Speed, SpeedContainer, SubtitleDiv } from "./ResultElements";
import ProgressBar from "./ProgressBar";
import { getSpeedKind } from "./resultUtils";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        width: r(580),
    }
]);

interface UploadCardProps {
    currentSpeed: number;
    bookedUploadSpeedMax: number | null;
    bookedUploadSpeedAvg: number | null;
    bookedUploadSpeedMin: number | null;
}

const UploadCard: React.FC<UploadCardProps> = ({ currentSpeed, bookedUploadSpeedMax, bookedUploadSpeedAvg, bookedUploadSpeedMin }) => {

    const progress = () => {
        if (bookedUploadSpeedMax === null ||
            bookedUploadSpeedAvg === null ||
            bookedUploadSpeedMin === null) {
            return null;
        }

        const speedKind = getSpeedKind(currentSpeed, bookedUploadSpeedAvg, bookedUploadSpeedMin);

        return (
            <ProgressBar 
              speedKind={speedKind}
              min={bookedUploadSpeedMin}
              avg={bookedUploadSpeedAvg}
              max={bookedUploadSpeedMax}
              current={currentSpeed} />
        );
    }

    return (
        <ResultCard title={"Upload"}>
            <RootDiv>
                <SpeedContainer>
                    <Speed currentSpeed={currentSpeed} bookedSpeedMax={bookedUploadSpeedMax} />
                    <Percentage
                        currentSpeed={currentSpeed}
                        bookedSpeedMax={bookedUploadSpeedMax}
                        bookedSpeedAvg={bookedUploadSpeedAvg}
                        bookedSpeedMin={bookedUploadSpeedMin}
                    />
                </SpeedContainer>
                <SubtitleDiv>
                    Messung an Deinem Anschluss
                </SubtitleDiv>
                {progress()}
            </RootDiv>
        </ResultCard>
    );
}

export default UploadCard;