import * as R from "ramda";
import { logger } from "../../helper/logger";
import { i18n } from "next-i18next"
import { NQRecord } from "../history/types";
import { createNqError } from "@visonum/network-quality-sdk";
import sentryCaptureError from "../sentryReports/sentryCaptureError";

type ValueFunc = (lastRecord: NQRecord | null) => string | null;

interface HintVariable {
    name: string;
    valueFunc: ValueFunc;
}

const getPlannedImprovementDate = (lastRecord: NQRecord | null): Date | null => {
    const plannedImprovement = lastRecord?.prepareResult.init.communication?.plannedImprovement;

    if (R.isNil(plannedImprovement)) {
        return null;
    }

    return new Date(plannedImprovement);
}

function formatDate(date: Date): string {
    return date.toLocaleDateString(i18n?.language, { month: "long", year: "numeric" });
}

const hintVariables: HintVariable[] = [
    {
        name: "date_nodesplit_from",
        valueFunc: (lastRecord: NQRecord | null) => {
            const date = getPlannedImprovementDate(lastRecord);
            if (date === null) {
                return null;
            }

            return formatDate(date);
        }
    },
    {
        name: "date_nodesplit_to",
        valueFunc: (lastRecord: NQRecord | null) => {
            const date = getPlannedImprovementDate(lastRecord);
            if (date === null) {
                return null;
            }

            const dateTo = new Date(date.setMonth(date.getMonth() + 3));
            return formatDate(dateTo);
        }
    },
];

export const replaceHintVariableByValue = (content: string, lastRecord: NQRecord | null): string | null => {
    if (lastRecord == null) {
        return content;        
    }

    const values = hintVariables.map(val => {
        const name = `%${val.name}%`;
        const found = content.includes(name);
        if (found) {
            const value = val.valueFunc(lastRecord);
            if (value === null) {
                const nqError = createNqError("Cl-18", `Tips: value for variable ${name} not found.`)
                logger.debug(nqError.message, nqError);
                sentryCaptureError(nqError);
                return null;
            } else {
                return {name, value};
            }            
        } else {
            return null;
        }
    }).filter(v => v != null).map(v => v!);

    if (values.length == 0) {
        return content;
    }

    const replacer = (acc: string | null, val: {name: string, value: string}): [string | null, {name: string, value: string}] => {
        if (acc === null) {
            return [null, val];
        } else {
            return [acc.replaceAll(val.name, val.value), val];
        }
    }
    
    return R.mapAccum(replacer, content, values)[0];
}