import HintJson from "./HintJson";
import { LangName } from "../../types";

import no_problems_de from "../../../public/assets/tips/de/00_no_problems.json";
import browser_de from "../../../public/assets/tips/de/01_browser.json";
import disturbance_source_de from "../../../public/assets/tips/de/02_disturbance_source.json";
import modem_position_de from "../../../public/assets/tips/de/03_modem_position.json";
import connect_cable_de from "../../../public/assets/tips/de/04_connect_cable.json";
import old_device_de from "../../../public/assets/tips/de/06_old_device.json";
import semi_old_device_de from "../../../public/assets/tips/de/07_semi_old_device.json";
import device_not_compatible_400mb_de from "../../../public/assets/tips/de/08_device_not_compatible_400mb.json";
import device_not_compatible_1000mb_de from "../../../public/assets/tips/de/09_device_not_compatible_1000mb.json";
import network_improvements2_de from "../../../public/assets/tips/de/10_network_improvements2.json";
import network_improvements3_de from "../../../public/assets/tips/de/11_network_improvements3.json";
import parallel_traffic_de from "../../../public/assets/tips/de/12_parallel_traffic.json";
import modem_upgrade_de from "../../../public/assets/tips/de/13_modem_upgrade.json";
import lan_detected_de from "../../../public/assets/tips/de/14_lan_detected.json";
import vpn_detected_de from "../../../public/assets/tips/de/15_vpn_detected.json";
import network_improvements_up_ns_de from "../../../public/assets/tips/de/16_network_improvements_up_ns.json";
import network_improvements_up_nons_de from "../../../public/assets/tips/de/17_network_improvements_up_nons.json";
import wlan_standard_default_de from "../../../public/assets/tips/de/18_wlan_standard_default.json";
import wlan_standard_connect_box_de from "../../../public/assets/tips/de/19_wlan_standard_connect_box.json";
import wlan_standard_fritz_box_de from "../../../public/assets/tips/de/20_wlan_standard_fritz_box.json";
import wlan_standard_vodafone_station_de from "../../../public/assets/tips/de/21_wlan_standard_vodafone_station.json";
import super_wifi_gen_dormant_web_de from "../../../public/assets/tips/de/30_super_wifi_gen_dormant_web.json";
import super_wifi_gen_dormant_sca_de from "../../../public/assets/tips/de/31_super_wifi_gen_dormant_sca.json";
import super_wifi_gen_passiveplus_web_de from "../../../public/assets/tips/de/32_super_wifi_gen_passiveplus_web.json";
import super_wifi_gen_active_web_de from "../../../public/assets/tips/de/33_super_wifi_gen_active_web.json";
import super_wifi_lowspeed_passiveplus_web_de from "../../../public/assets/tips/de/34_super_wifi_lowspeed_passiveplus_web.json";
import super_wifi_lowspeed_active_web_de from "../../../public/assets/tips/de/36_super_wifi_lowspeed_active_web.json";
import partial_service_reboot_de from "../../../public/assets/tips/de/25_partial_service_reboot.json";
import partial_service_callback_de from "../../../public/assets/tips/de/26_partial_service_callback.json";
import device_fast_as_it_can_de from "../../../public/assets/tips/de/41_device_fast_as_it_can.json";

import no_problems_en from "../../../public/assets/tips/en/00_no_problems.json";
import browser_en from "../../../public/assets/tips/en/01_browser.json";
import disturbance_source_en from "../../../public/assets/tips/en/02_disturbance_source.json";
import modem_position_en from "../../../public/assets/tips/en/03_modem_position.json";
import connect_cable_en from "../../../public/assets/tips/en/04_connect_cable.json";
import old_device_en from "../../../public/assets/tips/en/06_old_device.json";
import semi_old_device_en from "../../../public/assets/tips/en/07_semi_old_device.json";
import device_not_compatible_400mb_en from "../../../public/assets/tips/en/08_device_not_compatible_400mb.json";
import device_not_compatible_1000mb_en from "../../../public/assets/tips/en/09_device_not_compatible_1000mb.json";
import network_improvements2_en from "../../../public/assets/tips/en/10_network_improvements2.json";
import network_improvements3_en from "../../../public/assets/tips/en/11_network_improvements3.json";
import parallel_traffic_en from "../../../public/assets/tips/en/12_parallel_traffic.json";
import modem_upgrade_en from "../../../public/assets/tips/en/13_modem_upgrade.json";
import lan_detected_en from "../../../public/assets/tips/en/14_lan_detected.json";
import vpn_detected_en from "../../../public/assets/tips/en/15_vpn_detected.json";
import network_improvements_up_ns_en from "../../../public/assets/tips/en/16_network_improvements_up_ns.json";
import network_improvements_up_nons_en from "../../../public/assets/tips/en/17_network_improvements_up_nons.json";
import wlan_standard_default_en from "../../../public/assets/tips/en/18_wlan_standard_default.json";
import wlan_standard_connect_box_en from "../../../public/assets/tips/en/19_wlan_standard_connect_box.json";
import wlan_standard_fritz_box_en from "../../../public/assets/tips/en/20_wlan_standard_fritz_box.json";
import wlan_standard_vodafone_station_en from "../../../public/assets/tips/en/21_wlan_standard_vodafone_station.json";
import super_wifi_gen_dormant_web_en from "../../../public/assets/tips/en/30_super_wifi_gen_dormant_web.json";
import super_wifi_gen_dormant_sca_en from "../../../public/assets/tips/en/31_super_wifi_gen_dormant_sca.json";
import super_wifi_gen_passiveplus_web_en from "../../../public/assets/tips/en/32_super_wifi_gen_passiveplus_web.json";
import super_wifi_gen_active_web_en from "../../../public/assets/tips/en/33_super_wifi_gen_active_web.json";
import super_wifi_lowspeed_passiveplus_web_en from "../../../public/assets/tips/en/34_super_wifi_lowspeed_passiveplus_web.json";
import super_wifi_lowspeed_active_web_en from "../../../public/assets/tips/en/36_super_wifi_lowspeed_active_web.json";
import partial_service_reboot_en from "../../../public/assets/tips/en/25_partial_service_reboot.json";
import partial_service_callback_en from "../../../public/assets/tips/en/26_partial_service_callback.json";
import device_fast_as_it_can_en from "../../../public/assets/tips/en/41_device_fast_as_it_can.json";

const allHintsDe = [
    no_problems_de,
    browser_de,
    disturbance_source_de,
    modem_position_de,
    connect_cable_de,
    old_device_de,
    semi_old_device_de,
    device_not_compatible_400mb_de,
    device_not_compatible_1000mb_de,
    network_improvements2_de,
    network_improvements3_de,
    parallel_traffic_de,
    modem_upgrade_de,
    lan_detected_de,
    vpn_detected_de,
    network_improvements_up_ns_de,
    network_improvements_up_nons_de,
    wlan_standard_default_de,
    wlan_standard_connect_box_de,
    wlan_standard_fritz_box_de,
    wlan_standard_vodafone_station_de,
    super_wifi_gen_dormant_web_de,
    super_wifi_gen_dormant_sca_de,
    super_wifi_gen_passiveplus_web_de,
    super_wifi_gen_active_web_de,
    super_wifi_lowspeed_passiveplus_web_de,
    super_wifi_lowspeed_active_web_de,
    partial_service_reboot_de,
    partial_service_callback_de,
    device_fast_as_it_can_de,
];

const allHintsEn = [
    no_problems_en,
    browser_en,
    disturbance_source_en,
    modem_position_en,
    connect_cable_en,
    old_device_en,
    semi_old_device_en,
    device_not_compatible_400mb_en,
    device_not_compatible_1000mb_en,
    network_improvements2_en,
    network_improvements3_en,
    parallel_traffic_en,
    modem_upgrade_en,
    lan_detected_en,
    vpn_detected_en,
    network_improvements_up_ns_en,
    network_improvements_up_nons_en,
    wlan_standard_default_en,
    wlan_standard_connect_box_en,
    wlan_standard_fritz_box_en,
    wlan_standard_vodafone_station_en,
    super_wifi_gen_dormant_web_en,
    super_wifi_gen_dormant_sca_en,
    super_wifi_gen_passiveplus_web_en,
    super_wifi_gen_active_web_en,
    super_wifi_lowspeed_passiveplus_web_en,
    super_wifi_lowspeed_active_web_en,
    partial_service_reboot_en,
    partial_service_callback_en,
    device_fast_as_it_can_en,
];

/* pure */
const getHints = (lang: LangName): HintJson[] => {
    switch (lang) {
        case "de": return allHintsDe;
        case "en": return allHintsEn;
    }
}

export default getHints;