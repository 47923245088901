import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ModalDialog from "../elements/ModalDialog";
import t from "../../../helper/t";
import { closeConsentDialogAndSaveState, updateConsentAnalytics, updateConsentBugReports } from "../../../features/consent/consentSlice";
import markdownRender from "../../../helper/markdownRender";
import CheckBoxBoolean from "../elements/CheckBoxBoolean";
import NavigationButton from "../elements/NavigationButton";
import { CommonButtonDiv, RedButtonDiv } from "../elements/Buttons";

import headerImage from "../../../../assets/images/splus3/speedtest_f1_1280.webp";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        minWidth: 748,
        maxWidth: 900,
        maxHeight: "100vh",
        overflowY: "auto",


        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            minWidth: 0,
        },
    }
]));

const ImageContainerDesktop = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",

        height: 225,

        background: `url("${headerImage.src}")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: `center center`,
        backgroundSize: "cover",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            height: 160,
            display: "none",
        },
    }
]));

const HeaderDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.h2,
    theme.splus3.foreground.white,
    {
        display: "flex",
        flexDirection: "column",
        maxWidth: 280,
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: 32,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            display: "none",
        },
    }
]));

const ContainerDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 24,
        paddingRight: 24,
    }
]));


const TitleDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.largeBold,
    theme.splus3.foreground.primary,
    {
        marginBottom: 20,
    }
]));

const ContentDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.default,
    theme.splus3.foreground.primary,
    {
        overflowY: "auto",
        height: 160,

        p: [
            {
                marginTop: 0,
                marginBottom: 16,
            }
        ],

        strong: [
            theme.splus3.typography.bold,
        ],
    }
]));

const CheckBoxPanel = styled.div(({ theme }) => ([
    theme.splus3.typography.defaultLite,
    theme.splus3.foreground.primary,
    {
        display: "flex",
        flexWrap: "wrap",
        columnGap: 16,
        marginTop: 24,
        marginBottom: 24,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            flexDirection: "column",
            rowGap: 16,
        },
    }
]));

const ButtonsPanel = styled.div(({ theme }) => ([
    {
        display: "flex",
        columnGap: 32,
        justifyContent: "space-between",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            flexDirection: "column",
            rowGap: 16,
        },
    }
]));

const ConsentDialog: React.FC<{}> = () => {
    const state = useAppSelector(state => state.consent);
    const dispatch = useAppDispatch();

    const onCancellAllClick = () => {
        dispatch(updateConsentAnalytics(false));
        dispatch(updateConsentBugReports(false));
        dispatch(closeConsentDialogAndSaveState());
    }

    const onAcceptAllClick = () => {
        dispatch(updateConsentAnalytics(true));
        dispatch(updateConsentBugReports(true));
        dispatch(closeConsentDialogAndSaveState());
    }

    const onSaveClick = () => {
        dispatch(closeConsentDialogAndSaveState());
    }

    const onAnalyticsClick = () => {
        dispatch(updateConsentAnalytics(!state.analytics));
    }

    const onBugReportsClick = () => {
        dispatch(updateConsentBugReports(!state.bugReports));
    }

    return (
        <ModalDialog>
            <RootDiv>
                <ImageContainerDesktop>
                    <HeaderDiv>{t("Unsere Cookies, Deine Vorteile")}</HeaderDiv>
                </ImageContainerDesktop>
                <ContainerDiv>
                    <TitleDiv>{t("Du hast die Kontrolle über Deine Daten.")}</TitleDiv>
                    <ContentDiv dangerouslySetInnerHTML={{ __html: markdownRender(t("Consent_Content")) }}></ContentDiv>
                    <CheckBoxPanel>
                        <CheckBoxBoolean checked={true}>{t("Allgemeine Geschäftsbedingungen")}</CheckBoxBoolean>
                        <CheckBoxBoolean checked={state.analytics} onClick={onAnalyticsClick} isDialog={true}>{t("Analytik senden")}</CheckBoxBoolean>
                        <CheckBoxBoolean checked={state.bugReports} onClick={onBugReportsClick} isDialog={true}>{t("Fehlerberichte senden")}</CheckBoxBoolean>
                    </CheckBoxPanel>
                    <ButtonsPanel>
                        <NavigationButton onClick={onCancellAllClick} isDialog={true} isFocused={true}>
                            <RedButtonDiv style={{ minWidth: 0 }}>{t("Alle ablehnen")}</RedButtonDiv>
                        </NavigationButton>
                        <ButtonsPanel style={{ justifyContent: "normal" }}>
                            <NavigationButton onClick={onSaveClick} isDialog={true}>
                                <CommonButtonDiv style={{ minWidth: 0 }}>{t("Einstellungen speichern")}</CommonButtonDiv>
                            </NavigationButton>
                            <NavigationButton dataCy="button-accept-all" onClick={onAcceptAllClick} isDialog={true}>
                                <RedButtonDiv style={{ minWidth: 0 }}>{t("Alle akzeptieren")}</RedButtonDiv>
                            </NavigationButton>
                        </ButtonsPanel>
                    </ButtonsPanel>
                </ContainerDiv>
            </RootDiv>
        </ModalDialog>
    );
}

export default ConsentDialog;