import LoggerView from "../../../src/components/layout/logger/LoggerView"
import { useAppSelector } from "../../../src/hooks"

const LoggerViewSwitcher: React.FC<{}> = () => {
    const debugLog = useAppSelector(state => state.debugLog)
    if (debugLog.showDebugLog) {
        return <LoggerView />
    } else {
        return null
    }
}

export default LoggerViewSwitcher;