import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { Phase } from "../../../../features/measurement/types";
import { isSpeedtestRunning, selectPrepareResult } from "../../../../features/measurement/utils";
import { getDeviceName } from "../../../../helper/getDeviceText";
import MeasurementItem from "../common/MeasurementItem";
import ProgressAnimation from "../common/ProgressAnimation";
import NavigationButton from "../../elements/NavigationButton";
import { RedButtonDiv } from "../../elements/Buttons";
import { BottomInfoDiv, InfoContainer, InfoKeySpan, InfoSeparatorSpan } from "../../result/currentMeasurement/resultElements";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";
import * as R from "ramda";
import t from "../../../../helper/t";
import { isMobileDevice } from "../common/utils";
import CopyableData from "../../elements/CopyableData";
import { isServerActive, isRouterActive, isDeviceActive, isFinished } from "../../../../features/measurement/utils";
import MeasurementText from "src/features/measurement/MeasurementText";

import internationalSvg from "../../../../../assets/images/splus3/international.svg";
import routerSvg from "../../../../../assets/images/splus3/router.svg";
import mobileSvg from "../../../../../assets/images/splus3/mobile.svg";
import laptopSvg from "../../../../../assets/images/splus3/laptop.svg";

const MeasurementItemWidth = 88;
const MeasurementItemHeight = 88;

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "none",
        flexDirection: "column",
        alignItems: "center",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            display: "flex"
        },
    }
]));

const LineContainer = styled.div([
    {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 4,
        paddingBottom: 4,
        rowGap: 4,
        overflow: "clip",
        width: 4,
    }
]);

const LineDiv = styled.div(({ theme }) => ([
    theme.splus3.background.monochrome600,
    {
        width: 2,
        height: 8,
        borderRadius: 48,
    }
]));

interface AnimationProps {
    duration: number;
    count: number | "infinite";
    direction: "normal" | "reverse" | "alternate";
}

type AnimationType = {
    enabled: true;
    duration: number;
    count: number | "infinite";
    direction: "normal" | "reverse" | "alternate";
} | {
    enabled: false;
}

const RedLineDiv = styled.div<AnimationProps>(({ theme, duration, count, direction }) => ([
    theme.splus3.background.vodafoneRed,
    {
        position: "absolute",
        top: direction === "reverse" ? "100%" : -8,
        left: 0,
        width: "100%",
        height: 8,
        borderRadius: 48,
        opacity: 1,

        animationName: "moveForward",
        animationDuration: `${duration}s`,
        animationIterationCount: count,
        animationDirection: direction,
    },
    `@keyframes moveForward {
        from {
            top: -8px
        }
        to {
            top: 100%
        }
    }
    `
]));

const InfoTextDiv = styled.div([
    props => props.theme.splus3.foreground.primary,
    props => props.theme.splus3.typography.font16Lite,
    {
        marginTop: -8,
    }
]);

export const BottomInfoContainer = styled.div({
    display: "flex",
    justifyContent: "center",
    paddingTop: 24,
    paddingBottom: 24,
});

interface MeasurementMobileProps {
}

const MeasurementMobile: React.FC<MeasurementMobileProps> = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.measurement);
    const prepareResult = selectPrepareResult(state);

    const speedtestId = prepareResult === null ? null : getSpeedtestId(prepareResult);

    const line = (marginTop: number, props: AnimationType) => {
        return (
            <LineContainer style={{ marginTop }}>
                <LineDiv />
                <LineDiv />
                <LineDiv />
                <LineDiv />
                {props.enabled && (
                    <RedLineDiv key={JSON.stringify(props)} duration={props.duration} count={props.count} direction={props.direction} />
                )}
            </LineContainer>
        )
    }

    const animationTime = ((): { serverRouter: AnimationType, routerDevice: AnimationType } => {
        switch (state.kind) {
            case Phase.Downloading:
                const downloadTime = state.prepareResult.config.download.duration / 4;
                return {
                    serverRouter: {
                        enabled: true,
                        duration: downloadTime,
                        count: 4,
                        direction: "normal"
                    },
                    routerDevice: {
                        enabled: true,
                        duration: downloadTime,
                        count: 4,
                        direction: "normal"
                    }
                }
            case Phase.Uploading:
                const uploadTime = state.prepareResult.config.upload.duration / 4;
                return {
                    serverRouter: {
                        enabled: true,
                        duration: uploadTime,
                        count: 4,
                        direction: "reverse"
                    },
                    routerDevice: {
                        enabled: true,
                        duration: uploadTime,
                        count: 4,
                        direction: "reverse"
                    }
                }
            case Phase.Pinging:
            case Phase.Completing:
            case Phase.ModemResolving:
                const pingDuration = state.prepareResult.config.ping.duration / 8;
                return {
                    serverRouter: {
                        enabled: true,
                        duration: pingDuration,
                        count: 8,
                        direction: "alternate"
                    },
                    routerDevice: {
                        enabled: true,
                        duration: pingDuration,
                        count: 8,
                        direction: "alternate"
                    }
                }
            case Phase.ModemExecuting:
                return {
                    serverRouter: {
                        enabled: true,
                        duration: 2.5,
                        count: "infinite",
                        direction: "normal"
                    },
                    routerDevice: {
                        enabled: false,
                    }
                }
            default:
                return { serverRouter: { enabled: false }, routerDevice: { enabled: false } }
        }
    })();

    return (
        <RootDiv data-cy="measurementElementMobile">
            <MeasurementItem caption={t("Server")} value="Vodafone" isActive={isServerActive(state)} width={MeasurementItemWidth} height={MeasurementItemHeight}>
                <img style={{ transform: `translate(0px, 70px)` }} width={34} src={internationalSvg.src} alt="server" />
            </MeasurementItem>
            {line(14, animationTime.serverRouter)}
            <MeasurementItem caption={t("Router")} value={prepareResult?.init.modem?.name} isActive={isRouterActive(state)} width={MeasurementItemWidth} height={MeasurementItemHeight}>
                <img style={{ transform: `translate(0px, 72px)` }} width={40} src={routerSvg.src} alt="router" />
            </MeasurementItem>
            {line(16, animationTime.routerDevice)}
            <MeasurementItem caption={t("Gerät")} value={getDeviceName(prepareResult) ?? ""} isActive={isDeviceActive(state)} width={MeasurementItemWidth} height={MeasurementItemHeight}>
                {
                    isMobileDevice(prepareResult, false) ?
                        <img style={{ transform: `translate(0px, 78px)` }} width={18} src={mobileSvg.src} alt="mobile" /> :
                        <img style={{ transform: `translate(0px, 72px)` }} width={38} src={laptopSvg.src} alt="laptop" />
                }
            </MeasurementItem>

            <div style={{ marginTop: 24 }}>
                {isFinished(state) ? (<div style={{ height: 32 }} />) : (<ProgressAnimation />)}
            </div>

            {isSpeedtestRunning(state) && (
                <InfoTextDiv>
                    <MeasurementText />
                </InfoTextDiv>
            )}

            <BottomInfoContainer>
                <BottomInfoDiv>
                    {speedtestId !== null && (
                        <InfoContainer>
                            <InfoKeySpan>{t("Speedtest-ID")}:&nbsp;</InfoKeySpan>
                            <CopyableData data={speedtestId}>{speedtestId}</CopyableData>
                        </InfoContainer>
                    )}
                    {!R.isNil(prepareResult?.init.connection.isp) && (
                        <>
                            <InfoSeparatorSpan>|</InfoSeparatorSpan>
                            <InfoContainer>
                                <InfoKeySpan>{t("Anbieter")}:&nbsp;</InfoKeySpan>
                                <span>{prepareResult?.init.connection.isp}</span>
                            </InfoContainer>
                        </>
                    )}
                </BottomInfoDiv>
            </BottomInfoContainer>
        </RootDiv>
    );
}

export default MeasurementMobile;