import { PrepareResultDE, Models } from "@visonum/network-quality-sdk";
import * as R from "ramda";

export const isMobileDevice = (pr: PrepareResultDE | null, defaultValue: boolean): boolean => {
    if (pr === null) {
        return defaultValue;
    }

    const deviceType = pr.init.client.device.deviceType;
    if (R.isNil(deviceType)) {
        return defaultValue;
    }

    return deviceType === Models.DeviceModelDeviceTypeEnum.Mobile ||
        deviceType === Models.DeviceModelDeviceTypeEnum.Tablet;
}