import { PrepareResultDE } from "@visonum/network-quality-sdk";
import * as R from "ramda";
import { isCustomer } from "../../helper/utils/customerUtls";
import t from "../../helper/t";
import { MeasurementState, Phase } from "./types";

type MeasurementPercentageInfo = {
    phaseTime: number; // in sec
    weight: number; // [0.5, 1.0)
}

type PhasePercentage = {
    phase: Phase;
    start: number;
    end: number;
}

export const calcPercentage = (prepareResult: PrepareResultDE | null, percentageUpdateInterval: number, phase: Phase, counter: number): number => {
    const info = phaseToPercentageInfo(prepareResult, phase);
    const maxCounter = info.phaseTime / percentageUpdateInterval;

    const phasePercentage = getPhasePercentage(prepareResult, phase);

    if (maxCounter === 0) {
        return phasePercentage.start;
    }

    const k = counter / maxCounter;
    const w = info.weight;

    const factor = k < w ? k : (w + (1.0 - w) * asmpFunc(k * 37));
    return phasePercentage.start + (phasePercentage.end - phasePercentage.start) * factor;
}

const phaseList = Object.keys(Phase).map(key => key as Phase);

/* pure */
const phaseToPercentageInfo = (prepareResult: PrepareResultDE | null, phase: Phase): MeasurementPercentageInfo => {
    switch (phase) {
        case Phase.NotStarted: return { phaseTime: 0.1, weight: 0.0 }
        case Phase.Preparing: return { phaseTime: 0.1, weight: 0.9 }
        case Phase.Benchmarking: return { phaseTime: 0.4, weight: 0.8 }
        case Phase.Downloading: return { phaseTime: prepareResult === null ? 5 : prepareResult.config.download.duration, weight: 0.99 }
        case Phase.Uploading: return { phaseTime: prepareResult === null ? 5 : prepareResult.config.upload.duration, weight: 0.99 }
        case Phase.Pinging: return { phaseTime: prepareResult === null ? 5 : prepareResult.config.ping.duration, weight: 0.8 }
        case Phase.Completing: return { phaseTime: 2, weight: 0.99 }
        case Phase.ModemResolving: return { phaseTime: isModemTestAvailable(prepareResult) ? 2 : 1, weight: 0.8 }
        case Phase.ModemExecuting: return { phaseTime: isModemTestAvailable(prepareResult) ? 25 : 1, weight: 0.8 }
        case Phase.CompletingNoModem: return { phaseTime: 0.5, weight: 0.99 }
        case Phase.CompletingWithModem: return { phaseTime: 0.5, weight: 0.99 }
        case Phase.FinishedNoModem: return { phaseTime: 0, weight: 0.0 }
        case Phase.FinishedWithModem: return { phaseTime: 0, weight: 0.0 }
        case Phase.Aborting: return { phaseTime: 0, weight: 0.0 }
    }
}

/* pure */
const isModemTestAvailable = (pr: PrepareResultDE | null): boolean => pr === null ? false : (pr.init.cmts?.isModemTestAvailable === true);

/* pure */
const getMeasurementPercentageTotalTime = (prepareResult: PrepareResultDE | null): number =>
    R.sum(phaseList.map(phase => phaseToPercentageInfo(prepareResult, phase).phaseTime));

/* pure */
const getPhasePercentageList = (prepareResult: PrepareResultDE | null): PhasePercentage[] => {
    const totalTime = getMeasurementPercentageTotalTime(prepareResult);
    let start = 0;
    return phaseList.map(phase => {
        const percentageInfo = phaseToPercentageInfo(prepareResult, phase);
        const percentage = percentageInfo.phaseTime / totalTime;
        const result: PhasePercentage = { phase, start, end: start + percentage };
        start = result.end;
        return result;
    });
}

/* pure */
const getPhasePercentage = (prepareResult: PrepareResultDE | null, phase: Phase): PhasePercentage => {
    const list = getPhasePercentageList(prepareResult);
    return list.find(x => x.phase === phase)!;
}

/* pure */
const asmpFunc = (k: number): number => Math.atan(k) / (Math.PI / 2);


export const isServerActive = (state: MeasurementState) => {
    switch (state.kind) {
        case Phase.Aborting:
        case Phase.NotStarted:
        case Phase.Preparing:
        case Phase.Benchmarking:
            return false;
        case Phase.Downloading:
        case Phase.Uploading:
        case Phase.Pinging:
        case Phase.Completing:
        case Phase.ModemResolving:
        case Phase.ModemExecuting:
        case Phase.CompletingNoModem:
        case Phase.CompletingWithModem:
            return true;
        case Phase.FinishedNoModem:
        case Phase.FinishedWithModem:
            return false;
    }
}

export const isRouterActive = (state: MeasurementState) => {
    switch (state.kind) {
        case Phase.Aborting:
        case Phase.NotStarted:
        case Phase.Preparing:
        case Phase.Benchmarking:
        case Phase.Downloading:
        case Phase.Uploading:
        case Phase.Pinging:
        case Phase.Completing:
        case Phase.ModemResolving:
            return false;
        case Phase.ModemExecuting:
        case Phase.CompletingNoModem:
        case Phase.CompletingWithModem:
            return isCustomer(state.prepareResult.init.modem);
        case Phase.FinishedNoModem:
        case Phase.FinishedWithModem:
            return false;
    }
}

export const isDeviceActive = (state: MeasurementState) => {
    switch (state.kind) {
        case Phase.Aborting:
        case Phase.NotStarted:
        case Phase.Preparing:
        case Phase.Benchmarking:
            return false;
        case Phase.Downloading:
        case Phase.Uploading:
        case Phase.Pinging:
        case Phase.Completing:
        case Phase.ModemResolving:
            return true;
        case Phase.ModemExecuting:
        case Phase.CompletingNoModem:
        case Phase.CompletingWithModem:
        case Phase.FinishedNoModem:
        case Phase.FinishedWithModem:
            return false;
    }
}

export const isFinished = (state: MeasurementState): boolean =>
    state.kind === Phase.FinishedNoModem || state.kind === Phase.FinishedWithModem;

export const selectPrepareResult = (state: MeasurementState): PrepareResultDE | null => {
    if (state.kind === Phase.NotStarted || state.kind === Phase.Preparing || state.kind === Phase.Aborting) {
        return null
    } else {
        return state.prepareResult
    }
}

export const isSpeedtestRunning = (measurementState: MeasurementState): boolean => {
    switch (measurementState.kind) {
        case Phase.Aborting:
        case Phase.Preparing:
        case Phase.Benchmarking:
        case Phase.Downloading:
        case Phase.Uploading:
        case Phase.Pinging:
        case Phase.Completing:
        case Phase.ModemResolving:
        case Phase.ModemExecuting:
        case Phase.CompletingNoModem:
        case Phase.CompletingWithModem:
            return true;
        case Phase.NotStarted:
        case Phase.FinishedNoModem:
        case Phase.FinishedWithModem:
            return false;
    }
}

export function isDownloadFinished(phase: Phase): boolean {
    switch (phase) {
        case Phase.Aborting:
            return false;
        case Phase.NotStarted:
            return false
        case Phase.Preparing:
            return false
        case Phase.Benchmarking:
            return false
        case Phase.Downloading:
            return false
        case Phase.Uploading:
            return true
        case Phase.Pinging:
            return true
        case Phase.Completing:
            return true
        case Phase.ModemResolving:
            return true
        case Phase.ModemExecuting:
            return true
        case Phase.CompletingNoModem:
            return true
        case Phase.CompletingWithModem:
            return true
        case Phase.FinishedNoModem:
            return true
        case Phase.FinishedWithModem:
            return true
    }
}

export function isUploadFinished(phase: Phase): boolean {
    switch (phase) {
        case Phase.Aborting:
            return false;
        case Phase.NotStarted:
            return false
        case Phase.Preparing:
            return false
        case Phase.Benchmarking:
            return false
        case Phase.Downloading:
            return false
        case Phase.Uploading:
            return false
        case Phase.Pinging:
            return true
        case Phase.Completing:
            return true
        case Phase.ModemResolving:
            return true
        case Phase.ModemExecuting:
            return true
        case Phase.CompletingNoModem:
            return true
        case Phase.CompletingWithModem:
            return true
        case Phase.FinishedNoModem:
            return true
        case Phase.FinishedWithModem:
            return true
    }
}

export function isPingFinished(phase: Phase): boolean {
    switch (phase) {
        case Phase.Aborting:
            return false;
        case Phase.NotStarted:
            return false
        case Phase.Preparing:
            return false
        case Phase.Benchmarking:
            return false
        case Phase.Downloading:
            return false
        case Phase.Uploading:
            return false
        case Phase.Pinging:
            return false
        case Phase.Completing:
            return true
        case Phase.ModemResolving:
            return true
        case Phase.ModemExecuting:
            return true
        case Phase.CompletingNoModem:
            return true
        case Phase.CompletingWithModem:
            return true
        case Phase.FinishedNoModem:
            return true
        case Phase.FinishedWithModem:
            return true
    }
}

export const selectIsCustomer = (state: MeasurementState): boolean | null => {
    const prepareResult = selectPrepareResult(state)

    if (prepareResult === null) {
        return null
    } else {
        return isCustomer(prepareResult);
    }
}