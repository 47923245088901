import { Primitive, CaptureContext, Options } from "@sentry/types";
import * as Sentry from "@sentry/nextjs";

export type SentryTagSet =
    "FrontendVersion" |
    "VARIANT" |
    "SDK_KIND" |
    "NqCode" |
    "NqInternalCode" |
    "ErrorNumber" |
    "SpeedtestID" |
    "ErrorName" |
    "ErrorMessage" |
    "ErrorCause" |
    "ErrorScheme" |
    "host";

export type SentryTags = { [key: string]: Primitive }

export interface SentryInterface {
    /** Inits the Sentry NextJS SDK on node. */
    init(options: Options): void;

    /**
     * Set an object that will be merged sent as tags data with the event.
     * @param tags Tags context object to merge into current context.
     */
    setTags(tags: { [key: string]: Primitive; }): ReturnType<Sentry.Hub['setTags']>;

    /**
     * Set key:value that will be sent as tags data with the event.
     *
     * Can also be used to unset a tag, by passing `undefined`.
     *
     * @param key String key of tag
     * @param value Value of tag
     */
    setTag(key: SentryTagSet, value: Primitive): ReturnType<Sentry.Hub['setTag']>;

    /**
     * Captures a message event and sends it to Sentry.
     *
     * @param message The message to send to Sentry.
     * @param Severity Define the level of the message.
     * @returns The generated eventId.
     */
    captureMessage(message: string, captureContext?: CaptureContext | Sentry.SeverityLevel): ReturnType<Sentry.Hub['captureMessage']>;

    /**
     * Captures an exception event and sends it to Sentry.
     *
     * @param exception An exception-like object.
     * @param captureContext Additional scope data to apply to exception event.
     * @returns The generated eventId.
     */
    captureException(exception: any, captureContext?: CaptureContext): ReturnType<Sentry.Hub['captureException']>;

    /**
     * Records a new breadcrumb which will be attached to future events.
     *
     * Breadcrumbs will be added to subsequent events to provide more context on
     * user's actions prior to an error or crash.
     *
     * @param breadcrumb The breadcrumb to record.
     */
    addBreadcrumb(breadcrumb: Sentry.Breadcrumb): ReturnType<Sentry.Hub['addBreadcrumb']>;

    /**
     * Updates user context information for future events.
     *
     * @param user User context object to be set in the current context. Pass `null` to unset the user.
     */
    setUser(user: Sentry.User | null): ReturnType<Sentry.Hub['setUser']>;
}

export interface TestSentryInterface extends SentryInterface {
    getBreadcrumbs(): Sentry.Breadcrumb[];
    getMessages(): string[];
}

export enum CustomSentryMessage {
    BeforeUnload,
    CancellationDialogOpened,
    CancellationDialogStopMeasurement,
    CancellationDialogContinueMeasurement,
    MeasurementCompleted,
}