import styled from "@emotion/styled";
import NavigationButton from "../../../components/elements/gigatv/buttons/NavigationButton";
import { HomeItemKey } from "../../../features/focusManager/FocusabeItemKey";
import { r } from "../../../theme/gigatv/sizeUtils";

import ErgebnisseSvg from "../../../../assets/images/gigatv/ergebnisse.svg";
import InfoSvg from "../../../../assets/images/gigatv/info.svg";

const RootDiv = styled.div([
    {
        position: "relative",
        height: "100%",
    }
]);

const BackgroundDiv = styled.div([
    {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "linear-gradient(45deg, #0D0D0D 0%, #262626 100%);",
        opacity: 0.89,
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    }
]);

const ButtonPanelDiv = styled.div([
    {
        display: "flex",
        columnGap: r(40),
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    }
])

interface NavbarTvProps {
    selectedItem: HomeItemKey;
}

const NavbarTv: React.FC<NavbarTvProps> = ({ selectedItem }) => {
    return (
        <RootDiv>
            <BackgroundDiv />

            <ButtonPanelDiv>
                <NavigationButton dataCy="navigationButtonResult" icon={ErgebnisseSvg} isFocused={selectedItem === HomeItemKey.NavigationButtonResult}>
                    Deine Messungen
                </NavigationButton>
                <NavigationButton dataCy="navigationButtonInfo" icon={InfoSvg} isFocused={selectedItem === HomeItemKey.NavigationButtonInfo}>
                    Info
                </NavigationButton>
            </ButtonPanelDiv>
        </RootDiv>
    );
}

export default NavbarTv;
