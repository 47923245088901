import styled from "@emotion/styled";
import { BottomInfoDiv, InfoContainer, InfoKeySpan, InfoSeparatorSpan } from "../currentMeasurement/resultElements";
import t from "../../../../helper/t";
import { NQRecordPrepareResult } from "../../../../features/history/types";
import * as R from "ramda";
import { formatDate, formatTime } from "../../../../helper/unitsFormatting";
import CopyableData from "../../elements/CopyableData";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";
import { PlainDate } from "../../../../types";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        justifyContent: "center",
        padding: 24,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                justifyContent: "flex-start",
                padding: 0,
            }
        ],
    }
]));

interface BottomInfoProps {
    date: PlainDate;
    prepareResult: NQRecordPrepareResult;
}

const BottomInfo: React.FC<BottomInfoProps> = ({ date, prepareResult }) => {
    return (
        <RootDiv>
            <BottomInfoDiv>
                <InfoContainer>
                    <InfoKeySpan>{t("Speedtest-ID")}:&nbsp;</InfoKeySpan>
                    <CopyableData data={getSpeedtestId(prepareResult)}>{getSpeedtestId(prepareResult)}</CopyableData>
                </InfoContainer>
                <InfoSeparatorSpan>|</InfoSeparatorSpan>
                <InfoContainer>
                    <InfoKeySpan>{t("IP-Adresse")}:&nbsp;</InfoKeySpan>
                    <span>{prepareResult.init.connection.ip}</span>
                </InfoContainer>
                {!R.isNil(prepareResult.init.connection.isp) && (
                    <>
                        <InfoSeparatorSpan>|</InfoSeparatorSpan>
                        <InfoContainer>
                            <InfoKeySpan>{t("Anbieter")}:&nbsp;</InfoKeySpan>
                            <span>{prepareResult.init.connection.isp}</span>
                        </InfoContainer>
                    </>
                )}
                {!R.isNil(prepareResult.init.cmts?.vendor) && (
                    <>
                        <InfoSeparatorSpan>|</InfoSeparatorSpan>
                        <InfoContainer>
                            <InfoKeySpan>{t("CMTS")}:&nbsp;</InfoKeySpan>
                            <span>{prepareResult.init.cmts!.vendor}</span>
                        </InfoContainer>
                    </>
                )}
                {!R.isNil(prepareResult.init.modem.name) && (
                    <>
                        <InfoSeparatorSpan>|</InfoSeparatorSpan>
                        <InfoContainer>
                            <InfoKeySpan>{t("Router")}:&nbsp;</InfoKeySpan>
                            <span>{prepareResult.init.modem.name}</span>
                        </InfoContainer>
                    </>
                )}
                <InfoSeparatorSpan>|</InfoSeparatorSpan>
                <InfoContainer>
                    <InfoKeySpan>{t("Durchgeführt")}:&nbsp;</InfoKeySpan>
                    <span>{`${formatDate(date)} · ${formatTime(date)} ${t("Uhr")}`}</span>
                </InfoContainer>
            </BottomInfoDiv>
        </RootDiv>

    );
}

export default BottomInfo;