import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";

export const SubTextContainer = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize22,
    theme.gigatv.typography.bold,
    {
        display: "flex",
        marginTop: r(16),
        columnGap: r(12),
        lineHeight: "100%",
    }
]));

export const SubTextDiv = styled.div([
    {
        display: "flex",
        columnGap: r(4),
        alignItems: "center",
    }
]);

export const CompareTextContainer = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.thirthy,
    theme.gigatv.typography.fontSize22,
    {
        display: "flex",
        justifyContent: "space-between",
        marginTop: r(18),
        lineHeight: "100%",
    }
]));