import styled from "@emotion/styled"
import { useAppSelector } from "../../../src/hooks"
import { SDK_KIND } from "../../config"
import { isRunningInBrowser } from "../../helper/ssr"
import * as R from "ramda"
import { getUrlParamValue } from "../../helper/utils"
import ViewScreenKey from "../../features/focusManager/ViewScreenKey"
import { HistoryScreenPart, InfoScreenPart, ResultScreenPart } from "../../features/focusManager/FocusabeItemKey"
import NoSSR from "../../components/elements/NoSSR"

const DebugInfoSwitcher: React.FC<{}> = () => {
    if (isRunningInBrowser()) {
        const url = window.location.href;
        if (R.isNil(getUrlParamValue(url, "debug-info"))) {
            return null;
        }

        return (
            <NoSSR>
                <DebugInfo />
            </NoSSR>
        );
    }

    return null;
}

const DebugInfoRootDiv = styled.div([
    {
        position: "absolute",
        bottom: 0,
        left: 0,
        padding: "12px 24px",
        color: "yellow",
        background: "#222933",

        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "calc(100% - 48px)",
    }
]);

interface DebugInfoProps {

}

const DebugInfo: React.FC<DebugInfoProps> = () => {
    const measurement = useAppSelector((state) => state.measurement);
    const focusManager = useAppSelector((state) => state.focusManager);

    const focusedStr = (): string => {
        switch (focusManager.focusedScreen) {
            case ViewScreenKey.Home:
                return focusManager.homeState.selectedItem;
            case ViewScreenKey.Measurement:
                return "";
            case ViewScreenKey.Result:
                switch (focusManager.resultState.screenPart) {
                    case ResultScreenPart.Buttons:
                        return focusManager.resultState.selectedButton;
                    case ResultScreenPart.Tabs:
                        return focusManager.resultState.selectedTab;
                    case ResultScreenPart.History:
                        switch (focusManager.resultState.historyPart) {
                            case HistoryScreenPart.Constrol:
                                return focusManager.resultState.selectedControl;
                            case HistoryScreenPart.Records:
                                return JSON.stringify(focusManager.resultState.selectedRecord);
                        }
                }
                return "";
            case ViewScreenKey.Info:
                switch (focusManager.infoState.screenPart) {
                    case InfoScreenPart.Buttons:
                        return focusManager.infoState.selectedButton;
                    case InfoScreenPart.Tabs:
                        return focusManager.infoState.selectedTab;
                }
        }
    }

    return (
        <DebugInfoRootDiv>
            <span>Phase: {measurement.kind}</span>
            <span>Screen: {focusManager.focusedScreen}</span>
            <span>Focused: {focusedStr()}</span>
            <span>Last Pressed: {focusManager.lastPressedKey}</span>
            <span>Engine: {SDK_KIND}</span>
        </DebugInfoRootDiv>
    )
}

export default DebugInfoSwitcher;