import { useEffect, useState } from "react";
import LanguageKey from "generated/types/LanguageKey";
import t from "src/helper/t";

const textKeys: LanguageKey[] = [
    "Deine Messung wird durchgeführt.",
    "Es dauert noch ein bisschen.",
    "Fast fertig.",
];

interface MeasurementTextProps { }

const MeasurementText: React.FC<MeasurementTextProps> = ({ }) => {

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const handler = setTimeout(() => {
            setIndex((index + 1) % textKeys.length);
        }, 5000);

        return function teadDown() {
            clearTimeout(handler);
        }
    }, [index]);

    return <>{t(textKeys[index]!)}</>
}

export default MeasurementText;