import { NboCase } from "../../features/nextBestOffer/NextBestOfferUtils";
import { getValue, getVolatileStorage, IStorage, Keys, setValue } from "./common";

const storage: IStorage = isSessionStorageAvailable() ? sessionStorage : getVolatileStorage("session");

export const getNboTeaseCase = (): NboCase | null => {
    const value = getValue(storage, Keys.NboTeaserCase);
    switch (value) {
        case NboCase.None:
        case NboCase.NonCustomer:
        case NboCase.GigabitCableCustomer:
        case NboCase.BelowGigabitCableCustomer:
            return value;
        default:
            return null;
    }
}

export const setNboTeaseCase = (nboCase: NboCase) => setValue(storage, Keys.NboTeaserCase, nboCase);

function isSessionStorageAvailable(): boolean {
    try {
        const storage = window["sessionStorage"];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return false;
    }
}