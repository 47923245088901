import styled from "@emotion/styled";
import { StaticImageData } from "next/image";
import { r } from "../../../../theme/gigatv/sizeUtils";
import { CSSProperties } from "react";

const RootDiv = styled.div([
    {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        rowGap: r(8),
    }
]);

const IconDiv = styled.div([
    {
        position: "relative",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: r(68),
        height: r(68),
        marginLeft: r(24),
        marginRight: r(24),
    }
]);

const commonBackgroundStyle: CSSProperties = {
    position: "absolute",
    borderRadius: "50%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transition: "opacity 0.3s",
}

interface BackgroundDivProps {
    isFocused: boolean;
}

const BackgoundDiv = styled.div<BackgroundDivProps>(({ theme, isFocused }) => ([
    theme.gigatv.colors.background.navigationButton,
    {
        ...commonBackgroundStyle,
        opacity: isFocused ? 0 : 1,
    }
]));

const FocusedBackgoundDiv = styled.div<BackgroundDivProps>(({ theme, isFocused }) => ([
    theme.gigatv.colors.background.navigationButtonFocused,
    theme.gigatv.colors.border.navigationButton,
    {
        ...commonBackgroundStyle,
        opacity: isFocused ? 1 : 0,
        borderStyle: "solid",
        borderWidth: r(2),
    }
]));

const ContentContaner = styled.div(({ theme }) => ([
    theme.gigatv.typography.fontSize24,
    theme.gigatv.colors.foreground.primary,
    {
        position: "relative",
        lineHeight: "67%",
    }
]));

interface ContentWraperProps {
    isBold: boolean;
    isVisible: boolean;
    isAbsolute: boolean;
}

const ContentWraper = styled.div<ContentWraperProps>(({ theme, isBold, isVisible, isAbsolute }) => ([
    isBold ? theme.gigatv.typography.bold : theme.gigatv.typography.regular,
    {
        opacity: isVisible ? 1 : 0,
        position: isAbsolute ? "absolute" : "relative",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        transition: "opacity 0.3s",
    }
]));

interface NavigationButtonProps {
    dataCy?: string;
    icon: StaticImageData;
    isFocused: boolean;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ dataCy, icon, isFocused, children }) => {
    return (
        <RootDiv data-cy={dataCy}>
            <IconDiv>
                <BackgoundDiv isFocused={isFocused} />
                <FocusedBackgoundDiv data-cy="focused-part" isFocused={isFocused} />
                <img width="70%" height="70%" src={icon.src} alt={`${children}`} style={{ zIndex: 10 }} />
            </IconDiv>
            <ContentContaner>
                <ContentWraper isBold={true} isVisible={false} isAbsolute={false}>
                    {children}
                </ContentWraper>
                <ContentWraper isBold={false} isVisible={!isFocused} isAbsolute={true}>
                    {children}
                </ContentWraper>
                <ContentWraper isBold={true} isVisible={isFocused} isAbsolute={true}>
                    {children}
                </ContentWraper>
            </ContentContaner>
        </RootDiv>
    )
}

export default NavigationButton;