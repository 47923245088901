import styled from "@emotion/styled";
import * as R from "ramda";
import CheckBox, { CheckedType } from "../../elements/CheckBox";
import { formatDate, formatPingTime, formatTime } from "../../../../helper/unitsFormatting";
import { CheckBoxContainer, FirstCellDiv, RowDivStyleMobile } from "./elements";
import { NQRecord } from "../../../../features/history/types";
import { historyRecordSpeedUnits, historyRecordSpeedValues } from "./historyTableData";
import { CellDiv, RowContainer, TableDivider } from "./TableRowCommon";
import t from "../../../../helper/t";

interface RowDivProps {
    isSelected: boolean;
}

const RowDiv = styled.div<RowDivProps>(({ theme, isSelected }) => ([
    isSelected ? theme.splus3.background.monochrome3 : { background: "" },
    RowDivStyleMobile
]));

const DateCellDiv = styled(CellDiv)(({ theme }) => ([
    {
        alignSelf: "center",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font12Bold,
        ],
    }
]));

interface HeaderRowProps {
    records: NQRecord[],
    checked: CheckedType;
    onCheckedClick: () => void;
}

export const HeaderRow: React.FC<HeaderRowProps> = ({ records, checked, onCheckedClick }) => {
    const headerValues = [
        { title: t("Datum") },
        { title: t("Download"), sub: `(${t("Router")})` },
        { title: t("Download"), sub: `(${t("Gerät")})` },
        { title: t("Upload"), sub: `(${t("Gerät")})` },
        { title: t("Ping") },
    ];

    return (
        <RowContainer>
            <RowDiv isSelected={false}>
                <FirstCellDiv>
                    <CheckBoxContainer>
                        <CheckBox checked={checked} onClick={onCheckedClick}/>
                    </CheckBoxContainer>
                </FirstCellDiv>
                {headerValues.map((v, index) =>
                    <CellDiv key={index}>
                        <strong>{v.title}</strong>
                        {!R.isNil(v.sub) && (
                            <span>{v.sub}</span>
                        )}
                    </CellDiv>
                )}
            </RowDiv>
            <TableDivider />
        </RowContainer>
    );
}

interface ContentDivProps {
    isExpanded: boolean;
}

const ContentDiv = styled.div<ContentDivProps>(({ theme, isExpanded }) => ([
    {
        overflow: "hidden",
        transition: "max-height 0.2s ease-out",
        maxHeight: isExpanded ? 2000 : 0,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            marginTop: isExpanded ? 12 : 0,
        },
    },
]));

interface TableRowProps {
    records: NQRecord[];
    record: NQRecord;
    isExpanded: boolean;
    isChecked: boolean;
    onCheck: () => void;
    onExpand: () => void;
}

export const TableRow: React.FC<TableRowProps> = ({ records, record, isExpanded, isChecked, onCheck, onExpand, children }) => {
    const values = historyRecordSpeedValues(records, record);
    const units = historyRecordSpeedUnits(records);

    return (
        <RowContainer data-cy="historyRowMobile">
            <RowDiv isSelected={isChecked || isExpanded} onClick={onExpand}>
                <FirstCellDiv>
                    <CheckBoxContainer data-cy="historyCheckBoxMobile">
                        <CheckBox checked={isChecked} onClick={onCheck} />
                    </CheckBoxContainer>
                </FirstCellDiv>

                <DateCellDiv>
                    {formatDate(record.date)}
                </DateCellDiv>
                <CellDiv data-cy="routerPrevMobile">
                    <strong>{values.routerSpeedStr ?? "--"}</strong>
                    <span>{units.routerSpeedUnit}</span>
                </CellDiv>
                <CellDiv data-cy="downloadPrevMobile">
                    <strong>{values.downloadSpeedStr}</strong>
                    <span>{units.downloadSpeedUnit}</span>
                </CellDiv>
                <CellDiv data-cy="uploadPrevMobile">
                    <strong>{values.uploadSpeedStr}</strong>
                    <span>{units.uploadSpeedUnit}</span>
                </CellDiv>
                <CellDiv data-cy="pingPrevMobile">
                    <strong>{formatPingTime(record.pingFinal.pingTime)}</strong>
                    <span>{t("ms")}</span>
                </CellDiv>
            </RowDiv>
            <TableDivider />
            <ContentDiv isExpanded={isExpanded}>
                {children}
            </ContentDiv>
        </RowContainer>
    );
}