import styled from "@emotion/styled";
import CommonButton from "../../../../components/elements/gigatv/buttons/CommonButton";
import { HeaderTextDiv } from "../elements/HeaderTextDiv";

const RootDiv = styled.div([
  {
    display: "flex",
    alignItems: "center",
  }
]);

interface ButtonsPartProps {
  title: string;
}

const ButtonsPart: React.FC<ButtonsPartProps> = ({ title }) => {
  return (
    <RootDiv>
      <HeaderTextDiv>
        {title}
      </HeaderTextDiv>

      <CommonButton dataCy="detailsOverlayBackButton" isFocused={true}>
        Zurück
      </CommonButton>
    </RootDiv>
  );
};

export default ButtonsPart;
