import {
    Models,
    ModemFinalAvailableDE,
    ModemFinalDE,
    ModemFinalDidNotRunDE,
    ModemFinalFailedDE,
    ModemFinalKind,
    ModemNotRunReason,
    NQValueKind
} from "@visonum/network-quality-sdk";
import { freeze } from "immer";
import * as R from "ramda";
import { logger } from "../../helper/logger";
import { getHistory, getHistoryDidMigrate, getHistoryLegacy, setHistory, setHistoryDidMigrate } from "../../helper/storage";
import { decodeFromLocalStorage } from "./decodeFromLocalStorage";
import { serializeToLocalStorage } from "./serializeToLocalStorage";
import { LegacyRecord, NQRecord, NQRecordPrepareResult } from "./types";
import { reportHistoryError } from "./reportHistoryError";
import { plainDateFrom } from "../../types";

export const migrateLocalStorage = () => {
    const legacyData = getHistoryLegacy();
    if (legacyData === null) {
        return;
    }
    const didMigrate = getHistoryDidMigrate();
    if (didMigrate) {
        return;
    }
    const legacyRecords = parseLegacyData(legacyData);
    const modernRecords = decodeFromLocalStorage(getHistory());

    setHistory(serializeToLocalStorage(legacyRecords.concat(modernRecords)));
    setHistoryDidMigrate();
}

/** pure 
 @throws - never
*/
const parseLegacyData = (data: string): NQRecord[] => {
    try {
        const parsed = JSON.parse(data) as unknown;
        if (!Array.isArray(parsed)) {
            // TODO: Generate Sentry warning
            logger.warn(`parseLegacyData: expected array in legacy data but got ${data.substring(0, 20)}`);
            return []
        }
        return parsed
            .map(v => decodeNQRecordFromLegacy(v))
            .filter(v => v !== null) as NQRecord[]

    } catch (err) {
        // TODO: Generate Sentry warning
        logger.warn(`parseLegacyData got exception: ${err}`);
        return []
    }
}

/* pure */
const decodeNQRecordFromLegacy = (value: LegacyRecord): NQRecord | null => {
    function isIPv4(ipAddress: string): boolean {
        const regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/gm;
        return (regex.exec(ipAddress) !== null);
    }

    function parseDate(date_full: string, hourStr: string): Date | null {
        // Example date_full value: "24.09.2021"
        const [day, month, year] = date_full.split(".").map(Number);
        // Example hourStr value: "24.09"
        const [hour, minute] = hourStr.split(":").map(Number);
        if (year === undefined || month === undefined || day === undefined || hour === undefined || minute === undefined) {
            return null;
        }
        return new Date(year, month - 1, day, hour, minute);
    }

    function decodeConnectionType(value: LegacyRecord): Models.SnmpResultModelConnectionTypeEnum | null {
        if (value.wlan_2dot4) {
            return Models.SnmpResultModelConnectionTypeEnum.Wlan24
        }
        if (value.wlan_5) {
            return Models.SnmpResultModelConnectionTypeEnum.Wlan5
        }
        if (value.lan) {
            return Models.SnmpResultModelConnectionTypeEnum.Lan
        }
        return null
    }


    try {
        // Validate object form
        if (!(
            typeof value.downstream_booked === "number" &&
            typeof value.upstream_booked === "number" &&
            typeof value.date_full === "string" &&
            typeof value.hour === "string" &&
            typeof value.speedtest_id === "string" && value.speedtest_id.length > 0 &&
            typeof value.clientIp === "string" &&
            typeof value.isp === "string" &&
            (typeof value.cmtsVendor === "string" || value.cmtsVendor === null || value.cmtsVendor === undefined) &&
            typeof value.downstream === "number" &&
            typeof value.upstream === "number"
        )) {
            // TODO: report to sentry
            return null;
        }

        const isCustomer = (value.downstream_booked > 0) && (value.upstream_booked > 0);

        const modemFinalFunc = (): ModemFinalAvailableDE | ModemFinalDidNotRunDE | ModemFinalFailedDE => {
            if (typeof value.downstream_udp === "number" && value.downstream_udp > 0) {
                return {
                    kind: ModemFinalKind.ModemFinalAvailable,
                    speed: value.downstream_udp * 1e6,
                };
            } else {
                if (!isCustomer) {
                    return {
                        kind: ModemFinalKind.ModemFinalDidNotRun,
                        reason: ModemNotRunReason.NotACustomer,
                    }
                } else {
                    if (value.udp_error) {
                        return {
                            kind: ModemFinalKind.ModemFinalFailed,
                            message: null
                        }
                    } else {
                        if (!value.speedtest_ok_download) {
                            return {
                                kind: ModemFinalKind.ModemFinalDidNotRun,
                                reason: ModemNotRunReason.NotAvailable,
                            }
                        } else {
                            return {
                                kind: ModemFinalKind.ModemFinalDidNotRun,
                                reason: ModemNotRunReason.NotNeeded,
                            }
                        }
                    }
                }
            }
        };

        const modemFinal: ModemFinalDE = {
            kind: NQValueKind.ModemFinal,
            parallelTraffic: Boolean(value.parallel),
            connectionType: decodeConnectionType(value),
            modemFinal: modemFinalFunc(),
            speedtestState: { downloadClient: true, uploadClient: true, downloadUdp: null },
            snmpAvailable: null,
            wifiExtenderMode: null,
            wifiExtenderRegStatus: null,
        }

        const dateNum = parseDate(value.date_full, value.hour)?.getTime();
        if (R.isNil(dateNum) || isNaN(dateNum)) {
            reportHistoryError("Hr-Dc-7", `Could not parse date: ${value.date_full} ${value.hour}`)
            return null;
        };

        const prepareResult: NQRecordPrepareResult = {
            init: {
                speedtest: {
                    id: value.speedtest_id,
                },
                connection: {
                    ip: value.clientIp,
                    isp: value.isp,
                    isCustomer: isCustomer,
                    isVpnActive: value.vpnDetected === undefined ? undefined : Boolean(value.vpnDetected),
                },
                modem: isCustomer ? {
                    bookedDownloadSpeedMax: value.downstream_booked === 0 ? null : value.downstream_booked * 1e6,
                    bookedUploadSpeedMax: value.upstream_booked === 0 ? null : value.upstream_booked * 1e6,
                    name: R.isNil(value.modemType?.name) ? null : String(value.modemType?.name),
                    type: R.isNil(value.modemType?.code) ? null : String(value.modemType?.code),
                } : {},
                cmts: R.isNil(value.secondStepPossible) ? null : {
                    isModemTestAvailable: Boolean(value.secondStepPossible),
                    vendor: value.cmtsVendor,
                },
                client: {
                    device: {},
                    browser: {
                        name: String(value.browser_name),
                        version: String(value.browser_version),
                        isCurrent: Boolean(value.browser_isCurrent),
                    }
                },
                communication: {},
                partialService: { downloadIndex: 0, uploadIndex: 0 },
            },
            isIPv6: !isIPv4(value.clientIp),
        }

        const result: NQRecord = freeze({
            date: plainDateFrom(dateNum),
            prepareResult: prepareResult,
            benchmarkFinal: {
                kind: NQValueKind.BenchmarkFinalAvailable,
                result1: Number(value.browser_benchmark),
                result2: Number(value.browser_benchmark),
            },
            downloadIntermediates: [],
            downloadFinal: {
                kind: NQValueKind.DownloadFinal,
                speed: value.downstream * 1e6,
                jitter: 0
            },
            uploadIntermediates: [],
            uploadFinal: {
                kind: NQValueKind.UploadFinal,
                speed: value.upstream * 1e6,
                jitter: 0,
            },
            pingIntermediates: [],
            pingFinal: {
                kind: NQValueKind.PingFinal,
                pingTime: value.ping * 1e-3,
                jitter: value.jitter * 1e-3,
            },
            modemFinal: modemFinal,
            hints: null,
            partialService: null,
        });
        return result;
    } catch (err) {
        // TODO: report warning to Sentry
        logger.warn(`Error parsing NQRecord: ${err}`);
        return null;
    }
}