import CommonControl from "./CommonControl";

import qrBusinessTermsPng from "../../../../assets/images/gigatv/qr-business-terms.png";

interface TermsAndConditionsProps {
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = () => {
    return (
        <CommonControl
            header="Allgemeine Geschäftsbedingungen"
            contentHeader="Allgemeine und besondere Geschäftsbedingungen, Nutzungsbedingungen, Preislisten und Leistungsbeschreibungen"
            qrImage={qrBusinessTermsPng}>
                <p>Scann einfach den QR-Code ein, um auf die Webseite mit den aktuellsten Informationen zu gelangen.</p>
        </CommonControl>
    );
}

export default TermsAndConditions;