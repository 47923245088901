import styled from "@emotion/styled";

export type FadingTextStage = "Normal" | "FadeIn" | "FadeOut";

interface FadingTextDivProps {
    stage: FadingTextStage;
}

export const FadingTextDiv = styled.div<FadingTextDivProps>([
    props => props.theme.splus3.typography.largeBold,
    {
        gridRow: 1,
        gridColumn: 1,
        lineHeight: "100%",
    },
    props => {
        switch (props.stage) {
            case "Normal":
                return [
                    {
                        opacity: 1,
                    },
                ]
            case "FadeOut":
                return [
                    {
                        opacity: 1,
                    },
                    `
                    @keyframes fadeOut {
                        from {
                            opacity: 1.0
                        }
                        to {
                            opacity: 0.0
                        }
                    }                   

                    animation: 0.4s ease-out forwards fadeOut
                    `
                ]
            case "FadeIn":
                return [
                    {
                        opacity: 0,
                    },
                    `
                        @keyframes fadeIn {
                            from {
                                opacity: 0.0
                            }
                            to {
                                opacity: 1.0
                            }
                        }                   
    
                        animation: 0.4s ease-in 0.2s forwards fadeIn
                        `
                ]
        }
    },
]);