import Head from "next/head";
import { useRouter } from "next/router";
import * as R from "ramda";
import { combineUrlParts, isSeoEnabled } from "../../helper/utils";
import { defaultLanguage, getFullLanguageList } from "../../helper/utils/languageList";

type PageName = "/" | "know_how" | "modemSwap" | "privacy_policy_faq" | "report" | "result" | "speedtest" | "tipps" | "cookie_policy";

interface HeadWithTagsProps {
    name: PageName;
    enable?: boolean;
}

const HeadWithTags: React.FC<HeadWithTagsProps> = ({ name, enable = true }) => {
    if (!isSeoEnabled() || enable !== true) {
        return (
            <Head>
                <meta name="robots" content="noindex, nofollow"></meta>
            </Head>
        )
    }

    const router = useRouter();
    const baseUrl = "https://speedtest.vodafone.de/";

    const addLangToAddress = (address: string, lang: string | undefined): string => {
        if (R.isNil(lang) || lang === defaultLanguage) {
            return address;
        }

        return combineUrlParts(address, lang);
    }

    const combine = (lang: string | undefined): string => {
        const link = name === "/" ? baseUrl : combineUrlParts(baseUrl, name);
        return addLangToAddress(link, lang);
    }

    const canonicalLink = () => {
        const { lang } = router.query;
        return <link rel="canonical" href={combine(typeof (lang) === "string" ? lang : undefined)} />
    }

    const alternateLink = (lang: string) => {
        return <link key={`link-alternate-${lang}`} rel="alternate" href={combine(lang)} hrefLang={lang} />;
    }

    const getIndexValue = (): "index" | "noindex" => {
        switch (name) {
            case "/":
            case "know_how":
            case "modemSwap":
            case "privacy_policy_faq":
            case "report":
            case "tipps":
            case "cookie_policy":
                return "index";
            case "result":
            case "speedtest":
                return "noindex";
        }
    }

    return (
        <Head>
            <meta name="robots" content={`${getIndexValue()}, follow`}></meta>
            {canonicalLink()}
            {getFullLanguageList().map(lang => alternateLink(lang))}
        </Head>
    );
}

export default HeadWithTags;