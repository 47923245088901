import React, { useState } from "react";

import imageAppMobileEntertainment from "../../../../public/img/app-mobile-entertainment.svg";
import imageClockOrTimed from "../../../../public/img/clock-or-timed.svg";
import imageClusterOfEvents from "../../../../public/img/cluster-of-events.svg";
import imageSecurityPC1 from "../../../../public/img/security_pc1.svg";
import t from "../../../helper/t";
import { getPackageVersion } from "../../../helper/packageJsonHelper";

const IndexCardSlider: React.FC<{}> = () => {

  const [numClicks, setNumClicks] = useState(0);
  const isVersionVisible = (numClicks >= 5) && (numClicks % 2 === 1);

  return (
    <div className="index-carousel-container">
      <div className="index-carousel">
        <input
          type="radio"
          name="slides"
          id="index-carousel_item1"
          defaultChecked
        />
        <input type="radio" name="slides" id="index-carousel_item2" />
        <input type="radio" name="slides" id="index-carousel_item3" />
        <input type="radio" name="slides" id="index-carousel_item4" />
        <ul className="slides">
          <li className="index-carousel_item">
            <div className="index-carousel_item_svg">
              <img src={imageAppMobileEntertainment.src} alt="" />
            </div>
            <div className="index-carousel_item_content">
              {t("Schau, dass beim Speedtest so wenig Geräte wie möglich in Deinem Heimnetzwerk aktiv sind.")}
            </div>
          </li>
          <li className="index-carousel_item">
            <div className="index-carousel_item_svg">
              <img src={imageClockOrTimed.src} alt="" />
            </div>
            <div className="index-carousel_item_content">
              {t("Mach bitte den Speedtest Plus zu unterschiedlichen Tageszeiten. So bekommst Du ein genaueres Ergebnis.")}
            </div>
          </li>
          <li className="index-carousel_item">
            <div className="index-carousel_item_svg">
              <img src={imageClusterOfEvents.src} alt="" id="showVersion" />
            </div>
            <div className="index-carousel_item_content">
              {t("Mach während des Speedtests alle anderen Browser-Fenster und Programme zu.")}
            </div>
          </li>
          <li className="index-carousel_item" style={{ display: "flex", flexDirection: "column" }} onClick={() => { setNumClicks(n => n + 1) }}>
            <div className="index-carousel_item_svg" style={{ marginBottom: 0 }}>
              <img src={imageSecurityPC1.src} alt="" />
            </div>
            <div className="index-carousel_item_content">
              {t("Schalt eine VPN-Verbindung für die Dauer des Speedtests aus, falls eine aktiv sein sollte.")}
            </div>
            <div style={{ flexGrow: 2, display: isVersionVisible ? "flex" : "none", flexDirection: "column", justifyContent: "end" }}>
              v{getPackageVersion()}
            </div>
          </li>
        </ul>
        <div className="slidesNavigation">
          <label
            htmlFor="index-carousel_item1"
            id="dotindex-carousel_item1"
            className="index-carousel_item_nav"
          />
          <label
            htmlFor="index-carousel_item2"
            id="dotindex-carousel_item2"
            className="index-carousel_item_nav"
          />
          <label
            htmlFor="index-carousel_item3"
            id="dotindex-carousel_item3"
            className="index-carousel_item_nav"
          />
          <label
            htmlFor="index-carousel_item4"
            id="dotindex-carousel_item4"
            className="index-carousel_item_nav"
          />
        </div>
      </div>
    </div>
  );
};

export default IndexCardSlider;
