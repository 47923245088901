import { BenchmarkFinalDE, NQValueKind } from "@visonum/network-quality-sdk";

export enum BenchmarkLevel {
    Low,
    Medium,
    High,
}

/* pure */
export const getBenchmatkLevel = (benchmark: BenchmarkFinalDE): BenchmarkLevel | null => {
    const benchmarkResult =
        benchmark.kind === NQValueKind.BenchmarkFinalAvailable
            ? benchmark.result2 ?? benchmark.result1
            : null;

    const benchmarkLevel =
        benchmarkResult === null
            ? null
            : benchmarkResult < 40
                ? BenchmarkLevel.Low
                : benchmarkResult < 70
                    ? BenchmarkLevel.Medium
                    : BenchmarkLevel.High;

    return benchmarkLevel;
}