import { PrepareResultDE } from "@visonum/network-quality-sdk";
import { NQRecordPrepareResult } from "../../features/history/types";

/* pure */
const getSpeedtestId = (prepareResult: PrepareResultDE | NQRecordPrepareResult): string => {
    const speedtestId = prepareResult.init.speedtest.id;
    const short = prepareResult.init.connection.ispFootprint == "AR";
    return short ? speedtestId.slice(0, 6) : speedtestId
}

export default getSpeedtestId;