import { i18n } from "next-i18next"
import * as R from "ramda";
import LanguageKey from "../../generated/types/LanguageKey";

const t = (key: LanguageKey): string => {
    if (R.isNil(i18n)) {
        return key;
    }

    return i18n.t(key);
}

export default t;