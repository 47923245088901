import styled from "@emotion/styled";
import { deleteSelectedRecords } from "../../../features/history/historySlice";
import { useAppDispatch } from "../../../hooks";
import ModalDialog, { ModalDialogButtons, ModalDialogResult } from "../elements/ModalDialog";
import t from "../../../helper/t";
import { hideDialog } from "../../../features/dialogManager/dialogManagerSlice";

const ContentContaner = styled.div([
    {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        maxWidth: 480,
    }
]);

const RemoveHistoryDialog: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const onClose = (result: ModalDialogResult) => {
        if (result === ModalDialogResult.Remove) {
            dispatch(deleteSelectedRecords());
        }

        dispatch(hideDialog());
    }

    return (
        <ModalDialog header={t("Eintrag löschen")} buttons={ModalDialogButtons.RemoveYesNo} onClose={onClose}>
            <ContentContaner>
                {t("Bist Du sicher, dass Du alle Einträge löschen willst?")}
            </ContentContaner>            
        </ModalDialog>
    );
}

export default RemoveHistoryDialog;