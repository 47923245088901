import styled from "@emotion/styled";
import { r } from "../../../theme/gigatv/sizeUtils";
import t from "../../../helper/t";

const RootDiv = styled.div(({ theme }) => ([
  theme.gigatv.colors.foreground.primary,
  theme.gigatv.typography.regular,
  theme.gigatv.typography.fontSize20,
  {
    padding: r(10),
    textAlign: "center",
  }
]));

const NoDataStub: React.FC = () => {
  return (
    <RootDiv>
      {t("Es sind keine Messungen vorhanden")}
    </RootDiv>
  );
}

export default NoDataStub;