import styled from "@emotion/styled";
import { r } from "../../../theme/gigatv/sizeUtils";
import { ReactNode } from "react";

const RootDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.dialog,
    {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        zIndex: 1000,
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        opacity: 1,
        transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    }
]));

const Card = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.dialogCard,
    {
        display: "flex",
        flexDirection: "column",
        rowGap: r(32),
        width: "100%",
        maxWidth: r(504),
        margin: r(12),
        padding: r(20),
        border: "none",
        borderRadius: 6,
        flexWrap: "wrap",
        textAlign: "left",
        wordWrap: "break-word"
    },
]));

const ContentContainerDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        rowGap: r(24),
    }
]);

const HeaderDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.bold,
    theme.gigatv.typography.fontSize24,
    {
        lineHeight: "117%",
        paddingBottom: r(4),
    }
]));

const ContentDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.lite,
    theme.gigatv.typography.fontSize22,
    {
        lineHeight: "145%",
    }
]));

interface ModalDialogProps {
    header: string;
    content: string | ReactNode;
}

const ModalDialog: React.FC<ModalDialogProps> = ({ header, content, children }) => (
    <RootDiv>
        <Card>
            <ContentContainerDiv>
                <HeaderDiv>{header}</HeaderDiv>
                <ContentDiv>{content}</ContentDiv>
            </ContentContainerDiv>
            <ContentContainerDiv>
                {children}
            </ContentContainerDiv>            
        </Card>
    </RootDiv>
);

export default ModalDialog;