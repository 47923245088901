import styled from "@emotion/styled";
import { ResultTab } from "../../../../features/focusManager/FocusabeItemKey";
import { r } from "../../../../theme/gigatv/sizeUtils";
import CommonButton from "../../../../components/elements/gigatv/buttons/CommonButton";

const RootDiv = styled.div([
  {
    display: "flex",
    columnGap: r(20),
    justifyContent: "center",
  }
]);

interface ResultTabsPartProps {
  focusedTab: ResultTab | null;
}

const ResultTabsPart: React.FC<ResultTabsPartProps> = ({ focusedTab }) => {
  return (
    <RootDiv>
      <CommonButton dataCy="resultScreenCurentMeasurementTab" isFocused={focusedTab === ResultTab.CurrentMeasurement}>
        Aktuelle Messung
      </CommonButton>
      <CommonButton dataCy="resultScreenAdditionalInfoTab" isFocused={focusedTab === ResultTab.AdditionalInfo}>
        Zusätzliche Infos
      </CommonButton>
      <CommonButton dataCy="resultScreenHistoryTab" isFocused={focusedTab === ResultTab.History}>
        Frühere Ergebnisse
      </CommonButton>
    </RootDiv>
  );
}

export default ResultTabsPart;
