import styled from "@emotion/styled";

const WhiteContainer = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.background.transparent,
        ],
    }
]));

export default WhiteContainer;