import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import { SerializedStyles } from "@emotion/react";

interface RoundDivProps {
    size: number;
    background: SerializedStyles;
}

const RoundDiv = styled.div<RoundDivProps>(({ size, background }) => ([
    background,
    {
        width: r(size),
        height: r(size),
        borderRadius: "50%",
    }
]));

export default RoundDiv;