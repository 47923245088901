import { useState } from "react";
import ModalDialog from "./ModalDialog";
import styled from "@emotion/styled";
import t from "../../../helper/t";

const CopyableDataSpan = styled.div(({ theme }) => ([
    {
        textDecoration: "none",
        borderBottom: "1px dotted #333",
        cursor: "pointer",
    }
]));

interface CopyableDataProps {
    data: string;
}

const CopyableData: React.FC<CopyableDataProps> = ({ data, children }) => {
    const [isMessageVisible, setMessageVisible] = useState(false)

    const copyToClipboard = () => {
        navigator.clipboard.writeText(data)
        setMessageVisible(true)
        setTimeout(() => setMessageVisible(false), 1000)
    }

    return (
        <>
            <CopyableDataSpan onClick={copyToClipboard}>{children}</CopyableDataSpan>
            {isMessageVisible && (
                <ModalDialog>
                    <div style={{ display: "flex", justifyContent: "center", padding: 8 }}>
                        {t("kopiert!")}
                    </div>
                </ModalDialog>
            )}
        </>
    );
}

export default CopyableData;