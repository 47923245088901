import styled from "@emotion/styled";
import InfoCardItem from "./InfoCardItem";
import MainCard from "../elements/MainCard";
import { theme } from "../../../theme/theme";
import { HomeInfoCardId, getHomeInfoCardData } from "../infoData";

const RootDiv = styled(MainCard)([
    {
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",

        paddingLeft: 30,
        paddingRight: 15,
        paddingTop: 24,
        paddingBottom: 24,
        columnGap: 30,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            display: "none"
        },
    }
]);

const SegmentDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        rowGap: 18,
    }
]);

const Separator = styled.div([
    props => props.theme.splus3.background.separator,
    {
        width: 1,
    }
]);

interface InfoCardDesktopProps {

}

const InfoCardDesktop: React.FC<InfoCardDesktopProps> = () => {
    const item = (id: HomeInfoCardId) => {
        const data = getHomeInfoCardData(id);
        return (
            <InfoCardItem imageSrc={data.image}
                imageAlt={data.imageAlt}
                text={data.text}
                hintHeader={data.hintHeader}
                hintContent={data.hintContent} />
        );
    }

    return (
        <RootDiv>
            <SegmentDiv>
                {item(HomeInfoCardId.InternetSecurity)}
                {item(HomeInfoCardId.Bundles)}
            </SegmentDiv>
            <Separator />
            <SegmentDiv>
                {item(HomeInfoCardId.Desktop)}
                {item(HomeInfoCardId.CableLanDsl)}
            </SegmentDiv>
        </RootDiv>
    )
}

export default InfoCardDesktop;