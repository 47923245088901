import { HomeItemKey } from "../FocusabeItemKey";
import ViewScreenKey from "../ViewScreenKey";
import { HomeScreenState } from "../ViewScreenState";
import { setScreen } from "../focusManagerSlice";
import { selectItemOnHomeScreen, startMeasurementTv } from "../homeHelper";
import { ActionForElement, ViewScreen } from "../types";

export const homeViewScreen: ViewScreen<HomeScreenState> = (state) => ({
    focusedElementKey: state.selectedItem,
    elementActionsMap: new Map<HomeItemKey, ActionForElement>([
        [HomeItemKey.ButtonMeasurement,
        {
            tab: () => selectItemOnHomeScreen(HomeItemKey.NavigationButtonResult),
            down: () => selectItemOnHomeScreen(HomeItemKey.NavigationButtonResult),
            enter: startMeasurementTv,
        }
        ],
        [HomeItemKey.NavigationButtonResult,
        {
            tab: () => selectItemOnHomeScreen(HomeItemKey.NavigationButtonInfo),
            right: () => selectItemOnHomeScreen(HomeItemKey.NavigationButtonInfo),
            up: () => selectItemOnHomeScreen(HomeItemKey.ButtonMeasurement),
            enter: () => setScreen(ViewScreenKey.Result),
        }
        ],
        [HomeItemKey.NavigationButtonInfo,
        {
            tab: () => selectItemOnHomeScreen(HomeItemKey.ButtonMeasurement),
            left: () => selectItemOnHomeScreen(HomeItemKey.NavigationButtonResult),
            up: () => selectItemOnHomeScreen(HomeItemKey.ButtonMeasurement),
            enter: () => setScreen(ViewScreenKey.Info),
        }
        ]
    ])
})