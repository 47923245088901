import styled from "@emotion/styled";
import { NQRecord } from "../../../../features/history/types";
import { formatSpeed } from "../../../../helper/formatSpeeds";
import { nullOrValue } from "../../../../helper/utils";
import { r } from "../../../../theme/gigatv/sizeUtils";
import * as R from "ramda";
import { formatDate, formatTime } from "../../../../helper/unitsFormatting";
import t from "../../../../helper/t";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";

const MainTableDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize28,
    {
        display: "flex",
        flexDirection: "column",
        rowGap: r(73),
        lineHeight: "130%",
    }
]));

const InnerTableDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
    }
]);

const TableHeaderDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.lite,
    {
        marginBottom: r(32),
    }
]));

const HeaderRow = styled.div(({ theme }) => ([
    theme.gigatv.typography.bold,
    {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        paddingTop: r(8),
        paddingBottom: r(8),
        paddingLeft: r(16),
        paddingRight: r(16),
        columnGap: r(16),
    }
]));

const CellRow = styled.div(({ theme }) => ([
    theme.gigatv.typography.regular,
    {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        paddingTop: r(8),
        paddingBottom: r(8),
        paddingLeft: r(16),
        paddingRight: r(16),
        columnGap: r(16),
    }
]));

const CellRowNetwork = styled.div(({ theme }) => ([
    theme.gigatv.typography.regular,
    {
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        paddingTop: r(8),
        paddingBottom: r(8),
        paddingLeft: r(16),
        paddingRight: r(16),
        columnGap: r(16),
    }
]));

const TableDivider = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.separator,
    {
        height: 1,
    }
]));

interface AdditionalInfoTabProps {
    lastRecord: NQRecord;
}

const AdditionalInfoTab: React.FC<AdditionalInfoTabProps> = ({ lastRecord }) => {
    const initModem = lastRecord.prepareResult.init.modem;

    const bookedDownloadSpeedMin = nullOrValue(initModem.bookedDownloadSpeedMin);
    const bookedDownloadSpeedAvg = nullOrValue(initModem.bookedDownloadSpeedAvg);
    const bookedDownloadSpeedMax = nullOrValue(initModem.bookedDownloadSpeedMax);
    const bookedUploadSpeedMin = nullOrValue(initModem.bookedUploadSpeedMin);
    const bookedUploadSpeedAvg = nullOrValue(initModem.bookedUploadSpeedAvg);
    const bookedUploadSpeedMax = nullOrValue(initModem.bookedUploadSpeedMax);

    const { values, unit } = formatSpeed([
        bookedDownloadSpeedMin,
        bookedDownloadSpeedAvg,
        bookedDownloadSpeedMax,
        bookedUploadSpeedMin,
        bookedUploadSpeedAvg,
        bookedUploadSpeedMax]);
    const [downMin, downAvg, downMax, upMin, upAvg, upMax] = values;

    const firstTableData = [
        { caption: t("Maximal"), downValue: downMax, upValue: upMax },
        { caption: t("Üblich"), downValue: downAvg, upValue: upAvg },
        { caption: t("Minimal"), downValue: downMin, upValue: upMin },
    ];

    const networkTableData = [
        { caption: t("Speedtest-ID"), value: getSpeedtestId(lastRecord.prepareResult)},
        { caption: t("IP-Adresse"), value: lastRecord.prepareResult.init.connection.ip },
        { caption: t("Anbieter"), value: nullOrValue(lastRecord.prepareResult.init.connection.isp) },
        { caption: t("CMTS"), value: nullOrValue(lastRecord.prepareResult.init.cmts?.vendor) },
        { caption: t("Router"), value: nullOrValue(lastRecord.prepareResult.init.modem.name) },
        { caption: t("Durchgeführt"), value: `${formatDate(lastRecord.date)}, ${formatTime(lastRecord.date)} ${t("Uhr")}` },
    ];

    const formatSpeedValue = (value?: string | null): string => {
        if (R.isNil(value)) {
            return "--";
        } else {
            return value;
        }
    }

    return (
        <MainTableDiv>
            {values.find(x => !R.isNil(x)) && (
                <InnerTableDiv>
                    <TableHeaderDiv>
                        Diese Geschwindigkeit ist mit Deinem Tarif möglich
                    </TableHeaderDiv>
                    <HeaderRow>
                        <span>Geschwindigkeit</span>
                        <span>Im Download</span>
                        <span>Im Upload</span>
                    </HeaderRow>
                    <TableDivider />
                    {firstTableData.map((data, index) =>
                        <div key={`firstCell${index}`}>
                            <CellRow>
                                <span>{data.caption}</span>
                                <span>{formatSpeedValue(data.downValue)}&nbsp;{unit}</span>
                                <span>{formatSpeedValue(data.upValue)}&nbsp;{unit}</span>
                            </CellRow>
                            <TableDivider />
                        </div>
                    )}
                </InnerTableDiv>
            )}

            <InnerTableDiv>
                <TableHeaderDiv>
                    {t("Dein Heimnetzwerk")}
                </TableHeaderDiv>
                {networkTableData.filter(x => x.value !== null).map((data, index) =>
                    <div key={`networkCell${index}`}>
                        <CellRowNetwork>
                            <span>{data.caption}</span>
                            <span>{data.value}</span>
                        </CellRowNetwork>
                        <TableDivider />
                    </div>
                )}
            </InnerTableDiv>
        </MainTableDiv>
    );
}

export default AdditionalInfoTab;