import styled from "@emotion/styled";
import { formatSpeed } from "../../../../helper/formatSpeeds";

export const SpeedContainer = styled.div([
    {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
]);

export const SpeedDiv = styled.div([
    {
        display: "flex",
        alignItems: "end",
    }
]);

export const SpeedValue = styled.span(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.h2Bold,
    {
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font24Bold,
            {
            }
        ],
    }
]));

export const SpeedUnit = styled.span(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.default,
    {
        marginBottom: 2,

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.default,
            {
                marginBottom: -2,
            }
        ],
    }
]));

interface SpeedProps {
    currentSpeed: number;
    bookedSpeedMax: number | null;
}

export const Speed: React.FC<SpeedProps> = ({ currentSpeed, bookedSpeedMax }) => {
    const speedValues = formatSpeed([currentSpeed, bookedSpeedMax]);
    const speedStr = speedValues.values === null || speedValues.values.length === 0 || speedValues.values[0] === null ? "--" : speedValues.values[0];

    return (
        <SpeedDiv>
            <SpeedValue data-cy="speedModem">
                {speedStr}&nbsp;
            </SpeedValue>
            <SpeedUnit data-cy="speedUnitModem">
                {speedValues.unit}
            </SpeedUnit>
        </SpeedDiv>
    );
}

export const SubSpeedValue = styled.span(({ theme }) => ([
    theme.splus3.typography.largeBold,
    theme.splus3.foreground.monochrome600,
    {
        lineHeight: "115%",
    }
]));

export const SubSpeedUnit = styled.span(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.small,
    {
        marginBottom: -1,
    }
]));

export const SubSpeed: React.FC<SpeedProps> = ({ currentSpeed, bookedSpeedMax }) => {
    const speedValues = formatSpeed([currentSpeed, bookedSpeedMax]);
    const speedStr = speedValues.values === null || speedValues.values.length === 0 || speedValues.values[0] === null ? "--" : speedValues.values[0];

    return (
        <SpeedDiv>
            <SubSpeedValue data-cy="speedDownloadToComputer">
                {speedStr}&nbsp;
            </SubSpeedValue>
            <SubSpeedUnit data-cy="speedUnitDownloadToComputer">
                {speedValues.unit}
            </SubSpeedUnit>
        </SpeedDiv>
    );
}

export const SubtitleContainer = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        marginTop: 4,
        minHeight: 64,

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font12Lite,
            {
                minHeight: 0,
                marginBottom: 16,
            }
        ],
    }
]));

export const SubtitleSpan = styled.span(({ theme }) => ([
    theme.splus3.foreground.monochrome500,
    theme.splus3.typography.font16Lite,
    {
        overflow: "clip",
        whiteSpace: "break-spaces",
        lineHeight: "26px",

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font12Lite,
        ],
        strong: [
            theme.splus3.typography.bold,
        ],
    }
]));

export const SubtitleRightSpan = styled(SubtitleSpan)(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
]));

interface BottomInfoDivProps {
    hideIfMobile?: boolean;
}

export const BottomInfoDiv = styled.div<BottomInfoDivProps>(({ hideIfMobile = false, theme }) => ([
    theme.splus3.foreground.primary,
    theme.splus3.typography.small,
    {
        display: "flex",
        justifyContent: "flex-start",
        columnGap: 12,
        lineHeight: "100%",
        flexWrap: "wrap",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            hideIfMobile ? {
                display: "none",
            } : {
                flexDirection: "column",
                rowGap: 4,
                justifyContent: "flex-start",
            }
        ],
    }
]));

export const InfoContainer = styled.div([
    {
        display: "flex",
        flexWrap: "nowrap",
    }
]);

export const InfoKeySpan = styled.div(({ theme }) => ([
    theme.splus3.typography.bold,
    {

    }
]));

export const InfoSeparatorSpan = styled.div(({ theme }) => ([
    {
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "none",
            }
        ],
    }
]));