import styled from "@emotion/styled";
import { r } from "../../../../theme/gigatv/sizeUtils";
import { CSSProperties } from "react";

const RootDiv = styled.div(({ theme }) => ([
    {
        position: "relative",
        borderRadius: 2,
        outline: "none",

        opacity: 0.66,

        padding: r(12),
    },
]));

const backgoundCommonPart: CSSProperties = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 2,
    transition: "opacity 0.3s",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

interface BackgroundDivProps {
    isFocused: boolean;
    isDisabled: boolean;
}

const BackgroundDiv = styled.div<BackgroundDivProps>(({ theme, isFocused, isDisabled }) => ([
    theme.gigatv.colors.background.iconButton,
    {
        ...backgoundCommonPart,
        opacity: isFocused && !isDisabled ? 0 : 1,
    }
]));

interface ChildrenDivProps {
    isDisabled: boolean;
}

const ChildrenDiv = styled.div<ChildrenDivProps>(({ isDisabled }) => ([
    {
        opacity: isDisabled ? 0.4 : 1,
    },
]));
interface FocusedBackgroundDivProps {
    isFocused: boolean;
    isDisabled: boolean;
}

const FocusedBackgroundDiv = styled.div<FocusedBackgroundDivProps>(({ theme, isFocused, isDisabled }) => ([
    theme.gigatv.colors.background.iconButtonFocused,
    theme.gigatv.typography.bold,
    {
        ...backgoundCommonPart,
        opacity: isFocused && !isDisabled ? 1 : 0,
    }
]));

interface FocusedBorderDivProps {
    isFocused: boolean;
}

const FocusedBorderDiv = styled.div<FocusedBorderDivProps>(({ theme, isFocused }) => ([
    theme.gigatv.colors.border.buttonFocused,
    {
        ...backgoundCommonPart,
        opacity: isFocused ? 1 : 0,
        borderWidth: r(2),
        borderStyle: "solid",       
    }
]));

const HiddenBoldTextDiv = styled.div([
    {
        position: "relative",
        opacity: 0
    }
]);

interface IconButtonProps {
    isFocused: boolean;
    isDisabled?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({ isFocused, isDisabled = false, children }) => {
    return (
        <RootDiv>
            <HiddenBoldTextDiv>
                {children}
            </HiddenBoldTextDiv>
            <BackgroundDiv isFocused={isFocused} isDisabled={isDisabled}>
                <ChildrenDiv isDisabled={isDisabled}>
                    {children}
                </ChildrenDiv>
            </BackgroundDiv>
            <FocusedBackgroundDiv isFocused={isFocused} isDisabled={isDisabled}>
                {children}
            </FocusedBackgroundDiv>
            <FocusedBorderDiv isFocused={isFocused}>
            </FocusedBorderDiv>
        </RootDiv>
    );
}

export default IconButton;