import React, { useEffect, useState } from "react";

/**
 * Renders Children only on client. Avoid rendering of the children on server.
 */
const NoSSR: React.FC<{}> = ({ children }) => {
  const [isSsrDone, setSsrDone] = useState(false); 
  useEffect(() => {
    setSsrDone(true)
  },[]);

  if (isSsrDone) {
    return <>{children}</>; // Rendered on client
  } else {
    return null;            // Rendered on server
  }
}

export default NoSSR;