import * as storage from "../../helper/storage";
import { AppThunk } from "../../store";
import { getDays, getInputFromNqRecord, getPartialServiceState, PartialServiceDialogKind } from "./partialServiceUtils";
import { selectPrepareResult } from "../measurement/utils";
import { logger } from "../../helper/logger";
import { matomoPushEvent } from "../matomo/matomoEvents";
import { NQRecord } from "../history/types";
import { showPartialServiceDialog } from "../dialogManager/dialogManagerSlice";

export const updateAfterMeasurement = (record: NQRecord): AppThunk => (dispatch, getState) => {
    const input = getInputFromNqRecord(record);
    logger.debug("PS input", input);

    if (input === null) {
        return;
    }

    const state = getPartialServiceState(input);
    logger.debug("PS state", state);

    if (state.partialServiceDialogKind !== PartialServiceDialogKind.None) {
        dispatch(showPartialServiceDialog(state.partialServiceDialogKind));
    }

    if (state.partialServiceDialogKind === PartialServiceDialogKind.Reset) {
        storage.setModemResetCount((storage.getModemResetCount() ?? 0) + 1);
        storage.setLastModemResetDate(new Date());
    }

    const prepareResult = selectPrepareResult(getState().measurement);
    const days = getDays(input.lastModemResetDate, input.date);

    dispatch(
        matomoPushEvent({
            category: "Partial Service",
            action: "Partial Service Calculated",
            name: {
                ident: prepareResult === null ? "" : prepareResult.init.speedtest.id,
                downIndex: input.partialService.downloadIndex,
                upIndex: input.partialService.uploadIndex,
                days: days === null ? null : (Math.round(days * 10) / 10),
                lastModemResetDate: input.lastModemResetDate,
                dialog: state.partialServiceDialogKind,
                modemResetTip: state.modemResetTipStatus,
                modemResetCount: storage.getModemResetCount(),
                callbackTip: state.callbackTipStatus,
                callbackCounter: input.callbackCounter,
            },
        })
    );
}