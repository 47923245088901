import styled from "@emotion/styled";
import LottiePlayer from "../../../components/elements/spinner/LottiePlayer";

import middleAnimationData from "../../../../assets/spinner/VF_Living_Speechmark_drawn_on_Lottie_Red_1200.json";
import ProgressAnimationWrapper from "../../../features/measurement/ProgressAnimationWrapper";

const SpeechmarkDiv = styled.div(({ theme }) => ([
    {
        position: "relative",
        width: 148,
    }
]));

const ProgressTextDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.fontSize14Abs,
    theme.gigatv.typography.bold,
    theme.gigatv.colors.foreground.primary,
    {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        transform: "translate(0px, 8px)",
    }
]));

interface ProgressAnimationProps {

}

const ProgressAnimation: React.FC<ProgressAnimationProps> = () => {
    const childFunc = (percentage: number) => {
        return (
            <SpeechmarkDiv>
                <LottiePlayer animationData={middleAnimationData} />
                <ProgressTextDiv>{`${percentage}%`}</ProgressTextDiv>
            </SpeechmarkDiv>
        );
    }

    return <ProgressAnimationWrapper childFunc={childFunc} />
}

export default ProgressAnimation;