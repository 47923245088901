import {
  Models,
  CompleteDE,
  DownloadFinalDE,
  ModemDE,
  NetworkQualitySDK,
  NQValueDE,
  PingDE,
  PrepareResultDE,
  UploadFinalDE,
  NQHint,
  NQValueKind,
  HintsDE,
  BenchmarkDE,
} from "@visonum/network-quality-sdk";
import { concat, map, mergeMap, Observable, of } from "rxjs";
import { logger } from "../../helper/logger";
import { SpeedtestOptions, speedtest } from "@fusion/fusion";

export class NetworkQualitySdkFusion extends NetworkQualitySDK {
  constructor(private readonly networkQualitySDK: NetworkQualitySDK) {
    super();
  }

  protected getSpeedtestOptions(): SpeedtestOptions {
    return { downloadMockData: null, uploadMockData: null }
  }


  public speedtest(): Observable<NQValueDE> {
    const speedtestOptions = this.getSpeedtestOptions();

    logger.info("speedtestOptions", speedtestOptions);

    //TODO: Temporary solution
    let pr: PrepareResultDE | undefined = undefined;

    const result = speedtest(this.networkQualitySDK, speedtestOptions).pipe(
      mergeMap(x => {
        switch (x.kind) {
          case NQValueKind.PrepareResult:
            pr = x;
            return of(x);
          case NQValueKind.ModemFinal:
            return concat(
              of(x),
              this.networkQualitySDK.hints(pr?.init.speedtest.id).pipe(
                map(hints => {
                  return { kind: NQValueKind.Hints, hints } as HintsDE;
                })
              ),
            );
          default:
            return of(x)
        }
      }),
    )

    return result;
  }

  public prepare(): Observable<PrepareResultDE> {
    return this.networkQualitySDK.prepare();
  }

  public benchmark(prepareResult: PrepareResultDE): Observable<BenchmarkDE> {
    return this.networkQualitySDK.benchmark(prepareResult);
  }

  public calcDownload(data: Models.CalcDownloadRequestModel): Observable<DownloadFinalDE> {
    return this.networkQualitySDK.calcDownload(data);
  }

  public calcUpload(data: Models.CalcUploadRequestModel): Observable<UploadFinalDE> {
    return this.networkQualitySDK.calcUpload(data);
  }

  public ping(prepareResult: PrepareResultDE): Observable<PingDE> {
    return this.networkQualitySDK.ping(prepareResult);
  }

  public complete(prepareResult: PrepareResultDE): Observable<CompleteDE> {
    return this.networkQualitySDK.complete(prepareResult);
  }

  public abort(prepareResult: PrepareResultDE): Observable<never> {
    return this.networkQualitySDK.abort(prepareResult);
  }

  public modem(prepareResult: PrepareResultDE): Observable<ModemDE> {
    return this.networkQualitySDK.modem(prepareResult);
  }

  public hints(speedtestId?: string): Observable<NQHint[]> {
    return this.networkQualitySDK.hints(speedtestId);
  }

  public print(printRequestModel: Models.PrintRequestModel): Observable<Blob> {
    return this.networkQualitySDK.print(printRequestModel);
  }

  public updateValue(key: Models.ValueKey, command: Models.ValueCommand): Observable<number> {
    return this.networkQualitySDK.updateValue(key, command);
  }
}