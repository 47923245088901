import styled from "@emotion/styled";
import Button, { PrimaryButton } from "../../components/elements/Button";
import ModalDialog from "../../components/elements/ModalDialog";
import SmartLink from "../../components/elements/SmartLink";
import t from "../../helper/t";
import { useAppDispatch } from "../../hooks";
import { PartialServiceRebootTipId } from "../hints/hintsVirtualIds";
import { hideDialog } from "../dialogManager/dialogManagerSlice";

const ButtonsDiv = styled.div([
    {
        display: "flex",
        gap: 24,
        justifyContent: "center",
        marginTop: 32,
        flexWrap: "wrap"
    }
]);

interface PartialServiceResetDialogProps {

}

const PartialServiceResetDialog: React.FC<PartialServiceResetDialogProps> = () => {
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(hideDialog());
    }

    return (
        <ModalDialog>
            <div>
                <h1>{t("Routerneustart empfohlen")}</h1>
                <p>{t("Schau im Tipp, wie Du Deine Leitung verbessern kannst")}</p>
                <ButtonsDiv>
                    <SmartLink href={`/tipps#${PartialServiceRebootTipId}`} onClick={() => { close(); }}>
                        <PrimaryButton>
                            {t("Zum Tipp")}
                        </PrimaryButton>
                    </SmartLink>
                    <Button onClick={close}>
                        {t("Schließen")}
                    </Button>
                </ButtonsDiv>
            </div>
        </ModalDialog>
    )
}

export default PartialServiceResetDialog;