import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initFocusManager, resetFocusManager } from "../../features/focusManager/focusManagerSlice";
import ViewScreenKey from "../../features/focusManager/ViewScreenKey";
import { useAppSelector } from "../../hooks";
import MeasurementScreen from "./measurement/MeasurementScreen";
import ResultScreen from "./result/ResultScreen";
import InfoScreen from "./info/InfoScreen";
import HomeScreen from "./home/HomeScreen";

const IndexComponent: React.FC = () => {
  const focusState = useAppSelector(state => state.focusManager);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initFocusManager());
    return function tearDown() {
      dispatch(resetFocusManager());
    }
  }, []);

  switch (focusState.focusedScreen) {
    case ViewScreenKey.Home:
      return (<HomeScreen selectedItem={focusState.homeState.selectedItem} />);
    case ViewScreenKey.Measurement:
      return (<MeasurementScreen />);
    case ViewScreenKey.Result:
      return (<ResultScreen state={focusState.resultState} />);
    case ViewScreenKey.Info:
      return (<InfoScreen state={focusState.infoState} />);
  }
}

export default IndexComponent;
