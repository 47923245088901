import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import { HistoryControlItemKey, HistoryRecordsItemKey, HistoryScreenPart } from "../../../../../features/focusManager/FocusabeItemKey";
import HistoryTable from "./HistoryTable";
import { NQRecord } from "../../../../../features/history/types";
import HistoryControl from "./HistoryControl";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        rowGap: r(40),
    }
]);

interface HistoryTabProps {
    records: NQRecord[];
    frameItemCount: number;
    firstItemIndex: number;
    historyPart: HistoryScreenPart | null;
    focusedControl: HistoryControlItemKey;
    selectedIndex: number;
    selectedKey: HistoryRecordsItemKey;
}

const HistoryTab: React.FC<HistoryTabProps> = ({ records, frameItemCount, firstItemIndex, historyPart, focusedControl, selectedIndex, selectedKey }) => {
    return (
        <RootDiv>
            <HistoryControl
                focusedControl={historyPart === HistoryScreenPart.Constrol ? focusedControl : null}
                recordsCount={records.length}
                frameItemCount={frameItemCount}
                firstItemIndex={firstItemIndex} />
            <HistoryTable
                records={records}
                frameItemCount={frameItemCount}
                firstItemIndex={firstItemIndex}
                selectedIndex={selectedIndex}
                selectedKey={historyPart === HistoryScreenPart.Records ? selectedKey : null}
            />
        </RootDiv>
    );
}

export default HistoryTab;
