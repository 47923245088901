import * as R from "ramda";
import { Models, PrepareResultDE } from "@visonum/network-quality-sdk";
import { NQRecordPrepareResult } from "../../features/history/types";

export const IspVodafoneSubstring = "Vodafone";
export const IspVodafoneString = "Vodafone GmbH";

/* pure */
export function isCustomer(modem: Models.ModemModel | null): boolean;
export function isCustomer(prepareResult: PrepareResultDE): boolean;
export function isCustomer(modemOrPrepareResult: Models.ModemModel | PrepareResultDE | null): boolean {
    const modem = R.has("kind", modemOrPrepareResult) ? modemOrPrepareResult.init.modem : modemOrPrepareResult;   
    return !R.isNil(modem?.bookedDownloadSpeedMax) && !R.isNil(modem?.bookedUploadSpeedMax)
}

/* pure */
export function isCustomerByIsp(prepareResult: PrepareResultDE): boolean;
export function isCustomerByIsp(isp?: string | null): boolean
export function isCustomerByIsp(ispOrPrepareresult?: PrepareResultDE | string | null): boolean {
    if (R.isNil(ispOrPrepareresult)) {
        return false;
    }

    const isp = R.has("kind", ispOrPrepareresult) ? ispOrPrepareresult.init.connection.isp : ispOrPrepareresult;
    return isp === IspVodafoneString;
}

/* pure */
export const isNonCableCustomer = (prepareResult: PrepareResultDE | NQRecordPrepareResult): boolean => {
    return prepareResult.init.connection.isCustomer === false &&
        includes(prepareResult.init.connection.isp, IspVodafoneSubstring);
}

/* pure */
export const isCableCustomer = (prepareResult: PrepareResultDE | NQRecordPrepareResult): boolean => {
    return prepareResult.init.connection.isCustomer === true &&
        isCustomerByIsp(prepareResult.init.connection.isp) &&
        (prepareResult.init.modem?.bookedDownloadSpeedMax ?? 0) > 0 &&
        (prepareResult.init.modem?.bookedUploadSpeedMax ?? 0) > 0;
}

/* pure */
const includes = (source: string | null | undefined, substring: string) =>
    R.isNil(source) ? false : source.includes(substring);