import t from "../../../helper/t";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Phase } from "../../../features/measurement/types";
import { sentryReportsCancellationDialogContinueMeasurement, sentryReportsCancellationDialogStopMeasurement } from "../../../features/sentryReports/sentryReportsSlice";
import { cancel } from "../../../features/measurement/measurementSlice";
import { currentSubscription } from "../../../networkQuality";
import { hideDialog } from "../../../features/dialogManager/dialogManagerSlice";
import ModalDialog, { ModalDialogButtons, ModalDialogResult } from "../elements/ModalDialog";
import { updatePage } from "../../../features/pageManager/pageManagerSlice";
import { PageKey } from "../../../features/pageManager/pageState";

const CancellationDialog: React.FC<{}> = () => {
    const measurementState = useAppSelector(state => state.measurement);
    const dispatch = useAppDispatch();

    const onClose = async (result: ModalDialogResult) => {
        if (result === ModalDialogResult.StopMeasurement) {
            sentryReportsCancellationDialogStopMeasurement();
            if (measurementState.kind !== Phase.Aborting) {
                await dispatch(cancel(currentSubscription.subscription));
                dispatch(updatePage(PageKey.Home));
            }
        } else {
            sentryReportsCancellationDialogContinueMeasurement();
        }

        dispatch(hideDialog());
    }

    return (
        <ModalDialog header={t("Momentan läuft eine Messung!")} buttons={ModalDialogButtons.MeasurementStopContinue} onClose={onClose}>
        </ModalDialog>
    );
}

export default CancellationDialog;