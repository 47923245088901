import getSpeedtestId from "../../helper/utils/getSpeedtestId";
import { store } from "../../store"
import { selectPrepareResult } from "../measurement/utils";

export const selectSpeedtestId = (): string | null => {
    const state = store.getState();

    const prepareResult = selectPrepareResult(state.measurement);
    if (prepareResult !== null) {
        return getSpeedtestId(prepareResult);
    } else {
        return null;
    }
}

export const selectIpAddress = (): string | null => {
    const state = store.getState();

    const prepareResult = selectPrepareResult(state.measurement);
    if (prepareResult !== null) {
        return prepareResult.init.connection.ip;
    } else {
        return null;
    }
}