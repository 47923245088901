import { freeze } from "immer";
import { JsonNQRecordVersion, JsonNQRecord_v12, NQRecord } from "./types"
import { plainDateToISOString } from "../../../src/types";

/* pure */
export const serializeToLocalStorage = (records: NQRecord[]): string => {
    const encodedItems = records.map(encodeNQRecord)
    return JSON.stringify(encodedItems)
}

/* pure */
export const encodeNQRecord = (nqRecord: NQRecord): JsonNQRecord_v12 => {
    return freeze({
        version: JsonNQRecordVersion.v12,
        date: plainDateToISOString(nqRecord.date),
        prepareResult: {
            ...nqRecord.prepareResult,
            init: {
                ...nqRecord.prepareResult.init,
                connection: {
                    ...nqRecord.prepareResult.init.connection,
                    ispFootprint: nqRecord.prepareResult.init.connection.ispFootprint == null ? undefined : nqRecord.prepareResult.init.connection.ispFootprint,
                }
            }
        },
        benchmarkFinal: nqRecord.benchmarkFinal,
        downloadIntermediates: nqRecord.downloadIntermediates,
        downloadFinal: {
            speed: nqRecord.downloadFinal.speed,
            jitter: nqRecord.downloadFinal.jitter,
        },
        uploadIntermediates: nqRecord.uploadIntermediates,
        uploadFinal: {
            speed: nqRecord.uploadFinal.speed,
            jitter: nqRecord.uploadFinal.jitter,
        },
        pingIntermediates: nqRecord.pingIntermediates,
        pingFinal: {
            pingTime: nqRecord.pingFinal.pingTime,
            jitter: nqRecord.pingFinal.jitter,
        },
        modemFinal: nqRecord.modemFinal,
        hints: nqRecord.hints,
        partialService: nqRecord.partialService === null ?
            null : {
                callbackCounter: nqRecord.partialService.callbackCounter,
                lastModemResetDate: nqRecord.partialService.lastModemResetDate === null ? null : nqRecord.partialService.lastModemResetDate.toISOString(),
            }

    });
}