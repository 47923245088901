import { AssertionError } from "assert";

export class AppAssertionError extends AssertionError {
    constructor(options?: {
      /** If provided, the error message is set to this value. */
      message?: string | undefined;
      /** The `actual` property on the error instance. */
      actual?: any;
      /** The `expected` property on the error instance. */
      expected?: any;
      /** The `operator` property on the error instance. */
      operator?: string | undefined;
      /** If provided, the generated stack trace omits frames before this function. */
      // tslint:disable-next-line:ban-types
      stackStartFn?: Function | undefined;
    }) {
      super(options)
    }
  }