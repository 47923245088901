import { AppThunk } from "../../store";

export enum TvKeyCode {
    VK_ENTER = 13,
    VK_BACK = 461,
    VK_BACK_SPACE = 8,
    VK_TAB = 9,
    VK_LEFT = 37,
    VK_UP = 38,
    VK_RIGHT = 39,
    VK_DOWN = 40,
}

export type TvAction = () => AppThunk;

export interface ActionForElement {
    tab?: TvAction;
    left?: TvAction;
    up?: TvAction;
    right?: TvAction;
    down?: TvAction;
    enter?: TvAction;
}

export interface ConditionalItemMap<TFocusedElementKey> {
    focusedElementKey: TFocusedElementKey;
    elementActionsMap: Map<TFocusedElementKey, ActionForElement>;
    backAction?: TvAction;
}

export type ViewScreen<TState> = (state: TState) => ConditionalItemMap<unknown>;