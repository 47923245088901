import * as R from "ramda";
import { isRunningInBrowser } from "../ssr";
import { getUrlParamValue } from "./url";

const allowSentryReportFlag = "enable-sentry";

export const allowSentryReport = (): boolean | null => {
    const url = isRunningInBrowser() ? window.location.href : null;
    const param = getUrlParamValue(url, allowSentryReportFlag);

    if (R.isNil(param)) {
        return null;
    }

    return param.localeCompare("true", undefined, {sensitivity: "accent" }) === 0;
}