import React from "react";
import { reportTippLink } from "../../helper/analyticsService";
import t from "../../helper/t";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Button, { PrimaryButton } from "../elements/Button";
import ModalDialog from "../elements/ModalDialog";
import SmartLink from "../elements/SmartLink";
import { hideDialog } from "../../features/dialogManager/dialogManagerSlice";

const ModemSwapMessage: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(hideDialog());
  }

  return (
    <ModalDialog>
      <div>
        <h1>{t("Kostenloser Routertausch")}</h1>
        <p>{t("Jetzt informieren und unseren aktuellen Kabel-Router kostenlos bekommen.")}</p>
        <div style={{
          display: "flex",
          gap: "24px",
          justifyContent: "center",
          marginTop: 32,
          flexWrap: "wrap"
        }}>
          <Button onClick={close}>
            {t("Später erinnern")}
          </Button>
          <SmartLink href="/tipps#13" onClick={() => { close(); reportTippLink(13); }}>
            <PrimaryButton>
              {t("Zum Tausch-Tipp")}
            </PrimaryButton>
          </SmartLink>
        </div>
      </div>
    </ModalDialog>
  );
}

export default ModemSwapMessage;