import styled from "@emotion/styled";
import { useState } from "react";
import t from "../../../helper/t";

import closeSvg from "../../../../assets/icons/splus3/close.svg";

const RootDiv = styled.div(({ theme }) => ([
    theme.splus3.background.vodafoneRed,
    {
        display: "flex",
        alignItems: "center",
    }
]));

const TextContainerDiv = styled.div(({ theme }) => ([
    props => props.theme.splus3.typography.font16Bold,
    props => props.theme.splus3.foreground.white,
    {
        columnGap: 16,
        flexGrow: 10,
        textAlign: "center",
        margin: 16,
        lineHeight: "100%",

        // background: "blue",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.smallBold,
            {
                columnGap: 12,
            }
        ],
    },
]));

const CloseDiv = styled.div([
    {
        width: 24,
        height: 24,
        marginRight: 16,
        cursor: "pointer",
    },
]);

interface VpnStickyProps { }

const VpnSticky: React.FC<VpnStickyProps> = () => {
    const [isVisible, setVisible] = useState(true);

    return isVisible ? (
        <RootDiv>
            <TextContainerDiv>
                {t("Mögliche VPN-Verbindung erkannt")}: {t("Schalt diese für die Dauer des Speedtests aus, um valide Ergebnisse zu erhalten.")}
            </TextContainerDiv>
            <CloseDiv onClick={() => setVisible(false)}>
                <img
                    src={closeSvg.src}
                    alt="close"
                    width={24}
                    height={24}
                    onClick={() => { setVisible(false) }}
                />
            </CloseDiv>
        </RootDiv>
    ) : <></>
}

export default VpnSticky;