import { AppThunk } from "../../store";
import { checkPrivacyAndStartMeasurement } from "../measurement/measurementSlice";
import { HomeItemKey } from "./FocusabeItemKey";
import ViewScreenKey from "./ViewScreenKey";
import focusManagerSlice, { setScreen } from "./focusManagerSlice";

export const startMeasurementTv = (): AppThunk => (dispatch, _) => {
    dispatch(setScreen(ViewScreenKey.Measurement));
    dispatch(checkPrivacyAndStartMeasurement());
}

export const selectItemOnHomeScreen = (selectedItem: HomeItemKey): AppThunk => (dispatch, state) => {
    dispatch(focusManagerSlice.actions.changeFocused({ screenKey: ViewScreenKey.Home, state: { selectedItem } }))
}