import styled from "@emotion/styled";
import Navbar from "./elements/Navbar";
import NavigationButton from "./elements/NavigationButton";
import { RedButtonDiv } from "./elements/Buttons";
import t from "../../helper/t";

import maintenanceBgWebp from "../../../assets/images/splus3/maintenance-bg.webp";

const ContainerDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        height: "100vh",

        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${maintenanceBgWebp.src}), lightgray 50% / cover no-repeat`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: `center center`,
        backgroundSize: "cover",
    }
]);

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        paddingTop: 64,
        alignItems: "center",
        rowGap: 16,
        flexGrow: 10,
    }
]));

const TopTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.font24Bold,
    {
        background: "linear-gradient(90deg, #CB0000 1.53%, #E60000 99.79%)",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
        lineHeight: "150%",
        textAlign: "center",
    }
]));

const TitleTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.font36Bold,
    theme.splus3.foreground.white,
    {
        lineHeight: "150%",
    }
]));

const BodyTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.large,
    theme.splus3.foreground.white,
    {
        textAlign: "center",
        lineHeight: "150%",
    }
]));

const MaintenanceComponent: React.FC = () => {

    return (
        <ContainerDiv>
            <Navbar hideMenu={true} />
            <RootDiv>
                <TopTextDiv>{t("Wartungsarbeiten")}</TopTextDiv>
                <TitleTextDiv>{t("Der Speedtest ist kurz offline.")}</TitleTextDiv>
                <BodyTextDiv>
                    {t("Wir arbeiten gerade am Speedtest. Die Seite wird gleich wieder erreichbar sein.")}
                    <br />
                    {t("Vielen Dank für Dein Verständnis.")}
                </BodyTextDiv>
                <BodyTextDiv>{t("Besuch uns doch zwischenzeitlich hier")}</BodyTextDiv>
                <div style={{ marginTop: 8 }} />
                <NavigationButton href="https://vodafone.de">
                    <RedButtonDiv>
                        {t("Zu Vodafone.de")}
                    </RedButtonDiv>
                </NavigationButton>
            </RootDiv>
        </ContainerDiv>
    );
}

export default MaintenanceComponent;
