import styled from "@emotion/styled";
import { currentLangIs, nullOrValue } from "../../../../helper/utils";
import UploadCard from "./UploadCard";
import PingCard from "./PingCard";
import GuideCard from "./GuideCard";
import { BottomInfoDiv, InfoContainer, InfoKeySpan, InfoSeparatorSpan } from "./resultElements";
import * as R from "ramda";
import t from "../../../../helper/t";
import { useAppSelector } from "../../../../hooks";
import { getAllRecords } from "../../../../features/history/getAllRecords";
import NoDataStub from "../../elements/NoDataStub";
import ResultCard from "./ResultCard";
import CommonContainer from "../../elements/CommonContainer";
import CopyableData from "../../elements/CopyableData";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";
import DownloadCardWrapper from "./Download/DownloadCardWrapper";

import uploadTooltipDe from "@assets/tooltips/de/upload.json";
import pingTooltipDe from "@assets/tooltips/de/ping.json";

import uploadTooltipEn from "@assets/tooltips/en/upload.json";
import pingTooltipEn from "@assets/tooltips/en/ping.json";

const RootDiv = styled(CommonContainer)(({ theme }) => ([
    theme.splus3.background.primary,
    {
        display: "flex",
        flexDirection: "column",
        padding: 20,

        [theme.splus3.breakpoints.mobile]: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 2,
            paddingRight: 2,
        },
    }
]));

const ContainerDiv = styled.div(({ theme }) => ([
    {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "repeat(2, 1fr)",
        columnGap: 20,
        rowGap: 20,
        marginTop: 24,
        marginBottom: 24,

        [theme.splus3.breakpoints.smallDesktop]: {
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "1fr 1fr auto",
        },

        [theme.splus3.breakpoints.mobile]: {
            display: "flex",
            flexDirection: "column",
            rowGap: 16,
        },
    }
]));

const DownloadContainerDiv = styled.div(
    {
        gridRow: 1,
        gridColumnStart: 1,
        gridColumnEnd: 3,
    }
);

const UploadContainerDiv = styled.div(
    {
        gridRow: 2,
        gridColumn: 1,
    }
);

const PingContainerDiv = styled.div(
    {
        gridRow: 2,
        gridColumn: 2,
    }
);

const GuideContainerDiv = styled.div(({ theme }) => ([
    {
        gridRowStart: 1,
        gridRowEnd: 3,
        gridColumn: 3,

        [theme.splus3.breakpoints.smallDesktop]: {
            gridRow: 3,
            gridColumnStart: 1,
            gridColumnEnd: 3,
        },
    }
]));

const Spacer = styled.div(({ theme }) => ([
    {
        height: 100,

        [theme.splus3.breakpoints.mobile]: {
            height: 36,
        },
    }
]));

const InnerTableDiv = styled.div(({ theme }) => ([
    {
        display: "none",
        flexDirection: "column",
        // maxWidth: 240,

        [theme.splus3.breakpoints.mobile]: {
            display: "flex",
        },
    }
]));

const CellRowNetwork = styled.div(({ theme }) => ([
    theme.splus3.typography.small,
    {
        display: "flex",
        columnGap: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        justifyContent: "space-between",
    }
]));

interface CurrentMeasurementProps {
    showInfo: boolean;
}

const CurrentMeasurement: React.FC<CurrentMeasurementProps> = ({ showInfo }) => {
    const historyState = useAppSelector(state => state.history);
    const sortedRecords = getAllRecords(historyState);
    const record = sortedRecords[0];

    if (R.isNil(record)) {
        return <NoDataStub message={t("Es sind keine Messungen vorhanden")} />
    }

    const isEn = currentLangIs("en");

    const uploadTooltip = isEn ? uploadTooltipEn : uploadTooltipDe;
    const pingTooltip = isEn ? pingTooltipEn : pingTooltipDe;

    const networkTableData = [
        { caption: t("Speedtest-ID"), value: getSpeedtestId(record.prepareResult), isClipboardEnabled: true },
        { caption: t("Anbieter"), value: nullOrValue(record.prepareResult.init.connection.isp) },
    ];

    return (
        <RootDiv>
            <ContainerDiv>
                <DownloadContainerDiv>
                    <DownloadCardWrapper record={record} />
                </DownloadContainerDiv>
                <UploadContainerDiv>
                    <ResultCard title={uploadTooltip.text} hintHeader={uploadTooltip.hintHeader} hintContent={uploadTooltip.hintContent}>
                        <UploadCard record={record} showAdditionalInfo={true}/>
                    </ResultCard>
                </UploadContainerDiv>
                <PingContainerDiv>
                    <ResultCard title={pingTooltip.text} hintHeader={pingTooltip.hintHeader} hintContent={pingTooltip.hintContent}>
                        <PingCard pingTime={record.pingFinal.pingTime} />
                    </ResultCard>
                </PingContainerDiv>
                <GuideContainerDiv>
                    <ResultCard title={t("Dein Speedtest Guide")}>
                        <GuideCard />
                    </ResultCard>
                </GuideContainerDiv>
            </ContainerDiv>

            {showInfo && (
                <>
                    <BottomInfoDiv hideIfMobile={true} style={{ paddingLeft: 12, paddingRight: 12 }}>
                        <InfoContainer>
                            <InfoKeySpan>{t("Speedtest-ID")}:&nbsp;</InfoKeySpan>
                            <CopyableData data={getSpeedtestId(record.prepareResult)}>{getSpeedtestId(record.prepareResult)}</CopyableData>
                        </InfoContainer>
                        {!R.isNil(record.prepareResult.init.connection.isp) && (
                            <>
                                <InfoSeparatorSpan>|</InfoSeparatorSpan>
                                <InfoContainer>
                                    <InfoKeySpan>{t("Anbieter")}:&nbsp;</InfoKeySpan>
                                    <span>{record.prepareResult.init.connection.isp}</span>
                                </InfoContainer>
                            </>
                        )}
                    </BottomInfoDiv>

                    <InnerTableDiv>
                        {networkTableData.filter(x => x.value !== null).map((data, index) =>
                            <div key={index}>
                                <CellRowNetwork>
                                    <span style={{ display: "flex", alignItems: "center" }}>
                                        {data.caption}
                                    </span>
                                    {data.isClipboardEnabled === true ? (
                                        <div style={{ display: "flex" }}>
                                            <CopyableData data={data.value}>{data.value}</CopyableData>
                                        </div>
                                    ) : (
                                        <span>{data.value}</span>
                                    )}
                                </CellRowNetwork>
                            </div>
                        )}
                    </InnerTableDiv>
                </>
            )}
            <Spacer />
        </RootDiv>
    );
}

export default CurrentMeasurement;