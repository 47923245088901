import { FaqJson, LangName } from "../../../types";

import faq01de from "../../../../public/assets/faqs/de/faq01.json";
import faq02de from "../../../../public/assets/faqs/de/faq02.json";
import faq03de from "../../../../public/assets/faqs/de/faq03.json";
import faq04de from "../../../../public/assets/faqs/de/faq04.json";
import faq05de from "../../../../public/assets/faqs/de/faq05.json";
import faq06de from "../../../../public/assets/faqs/de/faq06.json";
import faq07de from "../../../../public/assets/faqs/de/faq07.json";

import faq01en from "../../../../public/assets/faqs/en/faq01.json";
import faq02en from "../../../../public/assets/faqs/en/faq02.json";
import faq03en from "../../../../public/assets/faqs/en/faq03.json";
import faq04en from "../../../../public/assets/faqs/en/faq04.json";
import faq05en from "../../../../public/assets/faqs/en/faq05.json";
import faq06en from "../../../../public/assets/faqs/en/faq06.json";
import faq07en from "../../../../public/assets/faqs/en/faq07.json";

export const getIndexFaqItems = (lang: LangName): FaqJson[] => {
    switch (lang) {
        case "de":
            return [faq01de, faq02de, faq03de, faq04de, faq05de, faq06de, faq07de];
        case "en":
            return [faq01en, faq02en, faq03en, faq04en, faq05en, faq06en, faq07en];
    }
}