import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NQError, WarningDE } from '@visonum/network-quality-sdk';
import { AppThunk } from '../../store';
import { logger } from '../../helper/logger';
import { sentryReportWarning } from '../sentryReports/sentryCaptureError';

export interface ErrorState {
  lastError: NQError | null
}

const initialState: ErrorState = {
  lastError: null
}

export const reportWarning = (warning: WarningDE): AppThunk => (dispatch, getState) => {
  logger.warn(warning.message, { url: warning.url });
  sentryReportWarning(warning);
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    reportNQError: (state, action: PayloadAction<NQError>) => {
      state.lastError = action.payload
    },
    resetError: (state) => {
      state.lastError = null
    }
  }
});

export const { reportNQError, resetError } = errorSlice.actions;

export default errorSlice.reducer;