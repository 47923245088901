import { getLastModemResetDate } from "../../helper/storage";
import { NQRecord } from "../history/types";
import { PartialServiceInput } from "./partialServiceUtils";
import { dateToPlainDate } from "../../types";

export type PartialServiceCase =
    "Default" |
    "FirstStartResultIsNotOkInterfaceHasErrors" |
    "FirstStartResultIsOkInterfaceHasManyErrors" |
    "FirstStartCase3" |
    "AgainResultIsNotOkInterfaceHasManyErrors";

export const generateInputForCustomPSCase = (psCase: PartialServiceCase): PartialServiceInput => {

    const addDays = (currentDate: Date, days: number): Date => {
        const result = new Date();
        result.setDate(currentDate.getDate() + days);
        return result;
    }

    const date = new Date();
    const ispFootprint = "UM";
    const partialService = { downloadIndex: 0, uploadIndex: 0 };
    const speedtestState = { downloadClient: true, downloadUdp: true, uploadClient: true };
    const lastModemResetDate = getLastModemResetDate();
    const callbackCounter = 3;
    const currentDownloadSpeed = 80e6;
    const bookedDownloadSpeedMax = 100e6;

    const defaultIn = {
        ispFootprint,
        partialService,
        speedtestState,
        date,
        lastModemResetDate,
        callbackCounter,
        currentDownloadSpeed,
        bookedDownloadSpeedMax,
    }

    switch (psCase) {
        case "Default": return defaultIn;
        case "FirstStartResultIsNotOkInterfaceHasErrors":
            return {
                ...defaultIn,
                partialService: { downloadIndex: 3, uploadIndex: 2 },
                speedtestState: { downloadClient: false, downloadUdp: false, uploadClient: false },
                lastModemResetDate: null,
            }
        case "FirstStartResultIsOkInterfaceHasManyErrors":
            return {
                ...defaultIn,
                partialService: { downloadIndex: 12, uploadIndex: 5 },
                speedtestState: { downloadClient: true, downloadUdp: true, uploadClient: true },
                lastModemResetDate: addDays(date, -40),
            }
        case "FirstStartCase3":
            return {
                ...defaultIn,
                partialService: { downloadIndex: 13, uploadIndex: 2 },
                speedtestState: { downloadClient: true, downloadUdp: null, uploadClient: false },
                lastModemResetDate: addDays(date, -8),
            }
        case "AgainResultIsNotOkInterfaceHasManyErrors":
            return {
                ...defaultIn,
                partialService: { downloadIndex: 13, uploadIndex: 2 },
                speedtestState: { downloadClient: false, downloadUdp: null, uploadClient: false },
                lastModemResetDate: addDays(date, -1),
            }
    }
}

export const modifyNqRecordForCustomPSCase = (nqRecord: NQRecord, psInput: PartialServiceInput): NQRecord => (
    {
        ...nqRecord,
        date: dateToPlainDate(psInput.date),
        prepareResult: {
            ...nqRecord.prepareResult,
            init: {
                ...nqRecord.prepareResult.init,
                connection: {
                    ...nqRecord.prepareResult.init.connection,
                    ispFootprint: psInput.ispFootprint,
                }, // connection
                partialService: psInput.partialService,
                modem: {
                    bookedDownloadSpeedMax: psInput.bookedDownloadSpeedMax,
                }, // modem
            }, // init
        }, // prepareResult
        modemFinal: {
            ...nqRecord.modemFinal,
            speedtestState: psInput.speedtestState,
        }, // modemFinal
        partialService: {
            callbackCounter: psInput.callbackCounter,
            lastModemResetDate: psInput.lastModemResetDate,
        }, // partialService
        downloadFinal: {
            ...nqRecord.downloadFinal,
            speed: psInput.currentDownloadSpeed,
        }, // downloadFinal
    }
);