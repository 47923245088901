import styled from "@emotion/styled";

const ButtonBaseDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.default,
    {
        borderRadius: 6,
        lineHeight: "26px",
        display: "flex",
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
        alignItems: "center",
        justifyContent: "center",
        padding: 7,
        minWidth: 260,

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font16,
            {
                minWidth: 0,
                maxHeight: 28,
                paddingLeft: 24,
                paddingRight: 24,
            }
        ],
    }
]));

export const CommonButtonDiv = styled(ButtonBaseDiv)(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.border.solidDark,
    theme.splus3.background.white,
    {
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.background.transparent,
        ],
    }
]));

export const RedButtonDiv = styled(ButtonBaseDiv)(({ theme }) => ([
    theme.splus3.background.vodafoneRed,
    theme.splus3.foreground.white,
    {
        padding: 9,
        paddingLeft: 16,
        paddingRight: 16,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font16,
            {
                minWidth: 0,
                paddingLeft: 9,
                paddingRight: 9,
            }
        ],
    }
]));

export const DefaultButtonDiv = styled(ButtonBaseDiv)(({ theme }) => ([
    theme.splus3.background.inverted,
    theme.splus3.foreground.white,
    theme.splus3.typography.bold,
    {
        padding: 9,

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.bold,
            {
                minWidth: 80,
                paddingLeft: 24,
                paddingRight: 24,
            },
        ],
    }
]));