import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import ResultCard from "./ResultCard";
import { SpeedContainer, SpeedDiv, SpeedValue, SpeedUnit, SubtitleDiv } from "./ResultElements";
import { formatPingTime } from "../../../../../helper/unitsFormatting";
import PingProgressBar from "./PingProgressBar";
import { getPingTimeKind } from "./resultUtils";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        width: r(580),
    }
]);

interface PingCardProps {
    pingTime: number;
    minTime: number;
    avgTime: number;
    maxTime: number;
}

const PingCard: React.FC<PingCardProps> = ({ pingTime, minTime, avgTime, maxTime }) => {
    const timeStr = formatPingTime(pingTime);
    const min = minTime;
    const max = maxTime;
    const avg = avgTime;
    const current = pingTime;
    const timeKind = getPingTimeKind(current, min, avg, max);

    return (
        <ResultCard title={"Reaktionszeit (Ping)"}>
            <RootDiv>
                <SpeedContainer>
                    <SpeedDiv>
                        <SpeedValue>
                            {timeStr}&nbsp;
                        </SpeedValue>
                        <SpeedUnit>
                            ms
                        </SpeedUnit>
                    </SpeedDiv>
                </SpeedContainer>
                <SubtitleDiv>
                    Reaktionszeit Deines Anschlusses
                </SubtitleDiv>
                <PingProgressBar pingTimeKind={timeKind} min={min} avg={avg} max={max} current={current} />
            </RootDiv>
        </ResultCard>
    );
}

export default PingCard;