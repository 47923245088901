import styled from "@emotion/styled";
import React from "react";
import { getPackageVersion } from "../../../helper/packageJsonHelper";

const RootDiv = styled.div([
  props => (
    props.theme.typography.regular,
    props.theme.colors.lightGrey
  ),
  {
    position: "fixed",
    bottom: 15,
    right: 15,
    float: "right",
    fontSize: 10,
    margin: "32px 10px 0 10px",
  }
]);

const FooterShowVersions: React.FC<{ isVisible: boolean }> = ({ isVisible }) => {
  return (
    <RootDiv style={{ display: isVisible ? "inline-block" : "none" }}>
      Frontend Version: {getPackageVersion()}
    </RootDiv>
  );
};

export default FooterShowVersions;