import { createSlice } from '@reduxjs/toolkit';
import { getPrivacyPolicyAcceptedDate, setPrivacyPolicyAcceptedDate } from '../../helper/storage';
import { AppThunk } from '../../store';
import { isRunningInBrowser } from '../../helper/ssr';
import { NODE_ENV, VARIANT } from '../../config';
import { getUrlParamValue } from '../../helper/utils';
import { hideDialog } from '../dialogManager/dialogManagerSlice';

interface PrivacyPolicyState {
  privacyPolicyAccepted: boolean;
}

const checkPrivacyPolicyAccepted = (): boolean => {
  if (!isRunningInBrowser()) {
    return true;
  }

  if (VARIANT !== "SPLUS" && VARIANT !== "SPLUS3") {
    return true;
  }

  if (NODE_ENV === "test" && getUrlParamValue(window.location.href, "allow-privacy-policy") === null) {
    return true;
  }

  const privacyAcceptedDate = getPrivacyPolicyAcceptedDate();
  if (privacyAcceptedDate === null) {
    return false;
  }

  const now = new Date();

  return privacyAcceptedDate.getFullYear() == now.getFullYear() &&
    privacyAcceptedDate.getMonth() == now.getMonth() &&
    privacyAcceptedDate.getDate() == now.getDate();
}

export const closePrivacyPolicyDialog = (acceptPrivacyPolicy: boolean): AppThunk => (dispatch, _) => {
  if (acceptPrivacyPolicy) {
    setPrivacyPolicyAcceptedDate(new Date());
    dispatch(privacyPolicySlice.actions.acceptPrivacyPolicy());
  }

  dispatch(hideDialog());
}

const initialState: PrivacyPolicyState = {
  privacyPolicyAccepted: checkPrivacyPolicyAccepted(),
}

export const privacyPolicySlice = createSlice({
  name: 'privacyPolicy',
  initialState,
  reducers: {
    acceptPrivacyPolicy: (state) => {
      state.privacyPolicyAccepted = true;
    }
  },
});

export default privacyPolicySlice;