import styled from "@emotion/styled";
import { r } from "../../../theme/gigatv/sizeUtils";
import ResultButtonsPart from "./parts/ResultButtonsPart";
import { ResultScreenPart, ResultTab } from "../../../features/focusManager/FocusabeItemKey";
import ResultTabsPart from "./parts/ResultTabsPart";
import { useAppSelector } from "../../../hooks";
import { getAllRecords } from "../../../features/history/getAllRecords";
import MeasurementInfo from "./tabs/currentMeasurement/MeasurementInfo";
import * as R from "ramda";
import AdditionalInfoTab from "./tabs/AdditionalInfoTab";
import NoDataStub from "./NoDataStub";
import HistoryTab from "./tabs/history/HistoryTab";
import { ResultScreenState } from "../../../features/focusManager/ViewScreenState";
import { InnerContainer } from "./elements";
import TariffInfo from "../components/TariffInfo";

const RootDiv = styled.div(({ theme }) => ([
  theme.gigatv.colors.background.fog,
  {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  }
]));

const TariffInfoContainer = styled.div([
  {
    width: "100%",
  }
]);

const ContainerDiv = styled.div([
  {
    marginTop: r(8),
    display: "flex",
    justifyContent: "center"
  }
]);

export const ControlsContainerDiv = styled.div([
  {
    display: "flex",
    flexDirection: "column",
    rowGap: r(40),
  }
]);

interface ResultScreenProps {
  state: ResultScreenState;
}

const ResultScreen: React.FC<ResultScreenProps> = ({ state }) => {
  const historyState = useAppSelector(state => state.history);

  const getTitle = (): string => {
    switch (state.selectedTab) {
      case ResultTab.CurrentMeasurement: return "Dein Ergebnis";
      case ResultTab.AdditionalInfo: return "Zusätzliche Infos";
      case ResultTab.History: return "Frühere Ergebnisse";
    }
  }

  const getTab = (): {} => {
    const sortedRecords = getAllRecords(historyState);
    const lastRecord = sortedRecords[0];

    switch (state.selectedTab) {
      case ResultTab.CurrentMeasurement:
        return R.isNil(lastRecord) ? (<NoDataStub />) : (<MeasurementInfo showInfo={true} record={lastRecord} />);
      case ResultTab.AdditionalInfo:
        return R.isNil(lastRecord) ? (<NoDataStub />) : (<AdditionalInfoTab lastRecord={lastRecord} />);
      case ResultTab.History:
        return (<HistoryTab
          records={sortedRecords}
          frameItemCount={historyState.frameItemCount}
          firstItemIndex={historyState.firstItemIndex}
          historyPart={state.screenPart === ResultScreenPart.History ? state.historyPart : null}
          focusedControl={state.selectedControl}
          selectedIndex={state.selectedRecord.index}
          selectedKey={state.selectedRecord.key}
        />);
    }
  }

  return (
    <RootDiv>
      <TariffInfoContainer>
        <TariffInfo />
      </TariffInfoContainer>
      <ContainerDiv>
        <InnerContainer>
          <ControlsContainerDiv>
            <ResultButtonsPart title={getTitle()} focusedButton={state.screenPart === ResultScreenPart.Buttons ? state.selectedButton : null} />
            <ResultTabsPart focusedTab={state.screenPart === ResultScreenPart.Tabs ? state.selectedTab : null} />
          </ControlsContainerDiv>
          {getTab()}
        </InnerContainer>
      </ContainerDiv>
    </RootDiv>
  );
}

export default ResultScreen;
