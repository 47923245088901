import React, { useEffect, useState } from "react";
import * as R from "ramda"
import styled from "@emotion/styled";
import { fixBodyUrl } from "../../../helper/utils";
import FaqAccordionItem from "./FaqAccordionItem";
import markdownRender from "../../../helper/markdownRender";
import { FaqJson } from "../../../types";

const AnswerBodyDiv = styled.div([
    props => props.theme.typography.regular14,
    props => props.theme.colors.foreground.secondary,
    {
    },
    props =>
        `
    strong {
      ${props.theme.typography.bold.styles};
    }

    img {
      max-width: 600px;
      align-self: center;
      display: block;
      margin: auto;
    }
  `
]);

interface FaqAccordionProps {
    faqs: FaqJson[];
    currentFaqId: number | null;
}

const FaqAccordion: React.FC<FaqAccordionProps> = ({ faqs, currentFaqId }) => {

    const initialState: (boolean | null)[] = Array(faqs.length).fill(null);
    const resetState: boolean[] = Array(faqs.length).fill(false);

    const [isExpanded, setExpanded] = useState(initialState);

    function onClick(itemIndex: number) {
        setExpanded(oldState => R.update(itemIndex, !oldState[itemIndex], resetState));        
    }

    useEffect(() => {
        // This is needed to make the accordion fully open when JavaScript is disabled in the browser
        setExpanded(resetState);
        
        if (currentFaqId !== null) {
            const expandedFaqIndex = faqs.findIndex(x => x.id == currentFaqId);
            if (expandedFaqIndex >= 0) {
                onClick(expandedFaqIndex);
            }
        }
    }, []);

    return (
        <div className="accordion">
            {faqs.map((faq, index) => {
                return (
                    <FaqAccordionItem key={index} title={faq.question} isExpanded={isExpanded[index]!} onClick={() => onClick(index)}>
                        <AnswerBodyDiv dangerouslySetInnerHTML={{ __html: markdownRender(fixBodyUrl(faq.body)) }} />
                    </FaqAccordionItem>
                );
            })}
        </div>
    );
};

export default FaqAccordion;
