import styled from "@emotion/styled";
import ModalDialog, { ModalDialogButtons } from "./ModalDialog";

const GridDiv = styled.div(({ theme }) => ([
    {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr auto auto",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "none",
            }
        ],
    }
]));

const ModalDiv = styled.div(({ theme }) => ([
    {
        display: "none",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "initial",
            }
        ],
    }
]));

const ContentDiv = styled.div([
    props => props.theme.splus3.background.white,
    props => props.theme.splus3.border.solidGray100,
    props => props.theme.splus3.shadow.containerWide,
    {
        gridColumn: 1,
        gridRowStart: 1,
        gridRowEnd: 3,
        borderRadius: 3,
    }
]);

const TooltipContainer = styled.div(({ theme }) => ([
    theme.splus3.foreground.primary,
    {
        display: "flex",
        flexDirection: "column",
        rowGap: 10,

        marginTop: 12,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 12,
    }
]));

const TooltipHeader = styled.div(({ theme }) => ([
    theme.splus3.typography.font16Bold,
]));

const TooltipContent = styled.div(({ theme }) => ([
    theme.splus3.typography.small,
    {
        minWidth: 226,
    },
]));

export type HandlePosition = "BottomRight" | "BottomLeft";

interface HandleProps {
    handlePosition: HandlePosition;
}

const HandleDiv = styled.div<HandleProps>(({ theme, handlePosition }) => ([
    handlePosition === "BottomRight" ? {
        alignItems: "end",
        paddingRight: 8,
    } : {
        alignItems: "start",
        paddingLeft: 8,
    },
    {
        display: "flex",
        flexDirection: "column",
        gridColumn: 1,
        gridRowStart: 2,
        gridRowEnd: 4,

        transform: "translate(0px,-1px)",
    }
]));

interface TooltipProps {
    handlePosition: HandlePosition;
    header: string;
    onClose?: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ handlePosition, onClose, header, children }) => {
    return (
        <>
            <GridDiv>
                <ContentDiv>
                    <TooltipContainer>
                        <TooltipHeader>
                            {header}
                        </TooltipHeader>
                        <TooltipContent>
                            {children}
                        </TooltipContent>
                    </TooltipContainer>
                </ContentDiv>
                <HandleDiv handlePosition={handlePosition}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                        <path d="M1.20705 10.4706H15.7634L8.48523 17.7488L1.20705 10.4706Z" fill="white" stroke="#CCCCCC" />
                        <rect x="8.48523" width="12" height="12" transform="rotate(45 8.48523 0)" fill="white" />
                    </svg>
                </HandleDiv>
            </GridDiv>

            <ModalDiv>
                <ModalDialog header={header} buttons={ModalDialogButtons.None} onClose={onClose}>
                    {children}
                </ModalDialog>
            </ModalDiv>
        </>
    );
}

export default Tooltip;