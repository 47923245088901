import { MeasurementMode, NetworkQualitySDK } from "@visonum/network-quality-sdk";
import { NetworkQualitySdkFusion } from "./NetworkQualitySdkFusion";
import { SyntheticNetworkQualitySdkFusion } from "./SyntheticNetworkQualitySdkFusion";

export const createSdkFusion = (mode: MeasurementMode, networkQualitySDK: NetworkQualitySDK) => {
    if (mode === MeasurementMode.Production) {
        return new NetworkQualitySdkFusion(networkQualitySDK);
    }

    return new SyntheticNetworkQualitySdkFusion(mode, networkQualitySDK);
}