import Head from "next/head";
import { AppProps } from "next/app";
import { ReactElement } from "react";
import { Provider } from "react-redux";
import t from "../../../src/helper/t";
import { store } from "../../../src/store";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../theme/theme";

const SPlus3ViewPage = ({ Component, pageProps }: AppProps): ReactElement => {
    return (
        <>
            <Head>
                <title>{t("Title")}</title>
                <meta name="description" content={t("Description")}></meta>
                <meta name="revisit-after" content="1 days"></meta>
                <meta name="referrer" content="no-referrer"></meta>
            </Head>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <Component {...pageProps} />
                </ThemeProvider>
            </Provider>
        </>
    );
}

export default SPlus3ViewPage;