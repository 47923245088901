import React, { } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sentryReportsCancellationDialogOpened } from "../../features/sentryReports/sentryReportsSlice";
import { isRunningInBrowser } from "../../helper/ssr";
import { isSpeedtestRunning } from "../../features/measurement/utils";
import { showCancellationDialog } from "../../features/dialogManager/dialogManagerSlice";

const CancellationGuard: React.FC<{}> = ({ children }) => {
    if (!isRunningInBrowser()) {
        return null;
    }

    const measurementState = useAppSelector(state => state.measurement);
    const dispatch = useAppDispatch();

    function clickHandler(e: React.MouseEvent<HTMLElement>): void {
        if (isSpeedtestRunning(measurementState)) {
            e.preventDefault();
            e.stopPropagation();
            dispatch(showCancellationDialog());
            sentryReportsCancellationDialogOpened();
        }
    }

    return (
        <div style={{ display: "inline" }} onClick={clickHandler}>
            {children}
        </div>
    )
}

export default CancellationGuard;