import styled from "@emotion/styled";
import t from "../../../helper/t";
import NavbarTv from "./NavbarTv";
import CommonButton from "../../../components/elements/gigatv/buttons/CommonButton";
import { HomeItemKey } from "../../../features/focusManager/FocusabeItemKey";
import { r } from "../../../theme/gigatv/sizeUtils";

const MainPartDiv = styled.div(({ theme }) => ([
  theme.gigatv.colors.background.homeFog,
  {
    display: "flex",
    width: "100%",
    flexGrow: 10,
  }
]));

const InnerImageDiv = styled.div([
  {
    display: "flex",
    paddingTop: r(180),
    paddingBottom: r(80),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 10,
  }
]);

const HomeHeaderText = styled.h1(({ theme }) => ([
  theme.gigatv.typography.lite,
  theme.gigatv.typography.fontWeight400,
  theme.gigatv.typography.fontSize64,
  theme.gigatv.colors.foreground.primary,
  {
    maxWidth: r(740),
    textAlign: "center",
  },
]));

const ContainerDiv = styled.div([
  {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  }
]);

const NavigatorDiv = styled.div([
  {
    height: r(216),
  }
]);

interface HomeScreenProps {
  selectedItem: HomeItemKey;
}

const HomeScreen: React.FC<HomeScreenProps> = ({ selectedItem }) => {
  return (
    <ContainerDiv>
      <MainPartDiv>
        <InnerImageDiv>
          <HomeHeaderText>
            {t("Starte Deinen Speedtest Plus auf Deinem GigaTV")}
          </HomeHeaderText>

          <CommonButton dataCy="homeScreenSpeedtestStartButton" isFocused={selectedItem === HomeItemKey.ButtonMeasurement}>
            Starte Deinen Speedtest Plus
          </CommonButton>
        </InnerImageDiv>
      </MainPartDiv>
      <NavigatorDiv>
        <NavbarTv selectedItem={selectedItem} />
      </NavigatorDiv>
    </ContainerDiv>
  );
};

export default HomeScreen;
