import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import DownloadCard from "./DownloadCard";
import { nullOrValue } from "../../../../../helper/utils";
import UploadCard from "./UploadCard";
import PingCard from "./PingCard";
import { ModemFinalKind } from "@visonum/network-quality-sdk";
import { NQRecord } from "../../../../../features/history/types";
import * as R from "ramda";
import t from "../../../../../helper/t";
import { BottomInfoDiv, InfoContainer, InfoKeySpan } from "../../../components/bottom-info";
import getSpeedtestId from "../../../../../helper/utils/getSpeedtestId";

const RootDiv = styled.div([
    {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr auto",
        columnGap: r(20),
        rowGap: r(20),

        flexGrow: 10,
    }
]);

const ResultCardContainer = styled.div([
    {
        display: "flex",
        flexDirection: "column",
    },
]);

const DownloadCardContainer = styled(ResultCardContainer)([
    {
        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRow: 1,
    }
]);

const UploadCardContainer = styled(ResultCardContainer)([
    {
        gridColumn: 1,
        gridRow: 2,
    }
]);

const PingCardContainer = styled(ResultCardContainer)([
    {
        gridColumn: 2,
        gridRow: 2,
    }
]);

interface MeasurementInfoProps {
    showInfo: boolean;
    record: NQRecord;
}

const MeasurementInfo: React.FC<MeasurementInfoProps> = ({ showInfo, record }) => {
    const modem = record.modemFinal;
    const downloadModemSpeed = modem.modemFinal.kind === ModemFinalKind.ModemFinalAvailable ? modem.modemFinal.speed : null;
    const initModem = record.prepareResult.init.modem;

    return (
        <RootDiv>
            <DownloadCardContainer>
                <DownloadCard
                   
                    deviceSpeed={record.downloadFinal.speed}
                    downloadModemSpeed={downloadModemSpeed}
                    bookedDownloadSpeedMin={nullOrValue(initModem.bookedDownloadSpeedMin)}
                    bookedDownloadSpeedAvg={nullOrValue(initModem.bookedDownloadSpeedAvg)}
                    bookedDownloadSpeedMax={nullOrValue(initModem.bookedDownloadSpeedMax)}
                    deviceName="GigaTV"
                    routerName={nullOrValue(record.prepareResult.init.modem.name)}
                    connectionType={modem.connectionType}
                    downloadIsOk={modem.speedtestState.downloadClient}
                />
            </DownloadCardContainer>
            <UploadCardContainer>
                <UploadCard
                    currentSpeed={record.uploadFinal.speed}
                    bookedUploadSpeedMax={nullOrValue(initModem.bookedUploadSpeedMax)}
                    bookedUploadSpeedAvg={nullOrValue(initModem.bookedUploadSpeedAvg)}
                    bookedUploadSpeedMin={nullOrValue(initModem.bookedUploadSpeedMin)}
                />
            </UploadCardContainer>
            <PingCardContainer>
                <PingCard
                    pingTime={record.pingFinal.pingTime}
                    minTime={0.015}
                    avgTime={0.035}
                    maxTime={0.050}
                />
            </PingCardContainer>

            {showInfo && (
                <div style={{ marginTop: r(14), gridColumnStart: 1, gridColumnEnd: 3, gridRow: 3 }}>
                    <BottomInfoDiv>
                        <InfoContainer>
                            <InfoKeySpan>{t("Speedtest-ID")}:&nbsp;</InfoKeySpan>
                            <span>{getSpeedtestId(record.prepareResult)}</span>
                        </InfoContainer>
                        {!R.isNil(record.prepareResult.init.connection.isp) && (
                            <>
                                <span>|</span>
                                <InfoContainer>
                                    <InfoKeySpan>{t("Anbieter")}:&nbsp;</InfoKeySpan>
                                    <span>{record.prepareResult.init.connection.isp}</span>
                                </InfoContainer>
                            </>
                        )}
                    </BottomInfoDiv>
                </div>
            )}
        </RootDiv>
    );
};

export default MeasurementInfo;