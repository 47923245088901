import styled from "@emotion/styled";
import * as R from "ramda";
import { InnerContainer } from "../elements";
import { formatDate, formatTime } from "../../../../helper/unitsFormatting";
import ButtonsPart from "./ButtonsPart";
import MeasurementInfo from "../tabs/currentMeasurement/MeasurementInfo";
import { r } from "../../../../theme/gigatv/sizeUtils";
import t from "../../../../helper/t";
import { NQRecord } from "../../../../features/history/types";
import { BottomInfoDiv, InfoContainer, InfoKeySpan } from "../../components/bottom-info";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";

const DialogDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.dialog,
    {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        zIndex: 1000,
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        opacity: 1,
        transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    }
]));

const RootDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.fog,
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        paddingTop: r(48),
    }
]));

export const ControlsContainerDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
    }
]);

export const TimeDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.lite,
    theme.gigatv.typography.fontSize24,
    theme.gigatv.colors.foreground.button,
    {
        lineHeight: "120%",
    }
]));

interface HistoryDetailOverlayProps {
    record: NQRecord;
}

const HistoryDetailOverlay: React.FC<HistoryDetailOverlayProps> = ({ record }) => {
    return (
        <DialogDiv>
            <RootDiv>
                <InnerContainer>
                    <ControlsContainerDiv>
                        <ButtonsPart title={R.isNil(record) ? "" : formatDate(record.date)} />
                        <TimeDiv>
                            {R.isNil(record) ? "" : `${formatTime(record.date)} ${t("Uhr")}`}
                        </TimeDiv>
                    </ControlsContainerDiv>
                    <MeasurementInfo showInfo={false} record={record} />
                </InnerContainer>
                <div style={{ marginTop: r(14), marginBottom: r(80) }}>
                    <BottomInfoDiv>
                        <InfoContainer>
                            <InfoKeySpan>{t("Speedtest-ID")}:&nbsp;</InfoKeySpan>
                            <span>{getSpeedtestId(record.prepareResult)}</span>
                        </InfoContainer>
                        <span>|</span>
                        <InfoContainer>
                            <InfoKeySpan>{t("IP-Adresse")}:&nbsp;</InfoKeySpan>
                            <span>{record.prepareResult.init.connection.ip}</span>
                        </InfoContainer>
                        {!R.isNil(record.prepareResult.init.connection.isp) && (
                            <>
                                <span>|</span>
                                <InfoContainer>
                                    <InfoKeySpan>{t("Anbieter")}:&nbsp;</InfoKeySpan>
                                    <span>{record.prepareResult.init.connection.isp}</span>
                                </InfoContainer>
                            </>
                        )}
                        {!R.isNil(record.prepareResult.init.cmts?.vendor) && (
                            <>
                                <span>|</span>
                                <InfoContainer>
                                    <InfoKeySpan>{t("CMTS")}:&nbsp;</InfoKeySpan>
                                    <span>{record.prepareResult.init.cmts!.vendor}</span>
                                </InfoContainer>
                            </>
                        )}
                        {!R.isNil(record.prepareResult.init.modem.name) && (
                            <>
                                <span>|</span>
                                <InfoContainer>
                                    <InfoKeySpan>{t("Router")}:&nbsp;</InfoKeySpan>
                                    <span>{record.prepareResult.init.modem.name}</span>
                                </InfoContainer>
                            </>
                        )}
                        <span>|</span>
                        <InfoContainer>
                            <InfoKeySpan>{t("Durchgeführt")}:&nbsp;</InfoKeySpan>
                            <span>{`${formatDate(record.date)} · ${formatTime(record.date)} ${t("Uhr")}`}</span>
                        </InfoContainer>
                    </BottomInfoDiv>
                </div>
            </RootDiv>
        </DialogDiv>
    );
}

export default HistoryDetailOverlay;