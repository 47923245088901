import { NQRecord } from "../../../../../features/history/types";
import DownloadCard from "./DownloadCard";
import ResultCard from "../ResultCard";
import { currentLangIs } from "src/helper/utils";
import { showRouterSpeed } from "./utils";

import downloadRouterTooltipDe from "@assets/tooltips/de/download_router.json";
import downloadDevice1TooltipDe from "@assets/tooltips/de/download_device_1.json";
import downloadRouterTooltipEn from "@assets/tooltips/en/download_router.json";
import downloadDevice1TooltipEn from "@assets/tooltips/en/download_device_1.json";

interface DownloadCardWrapperProps {
    record: NQRecord;
}

const DownloadCardWrapper: React.FC<DownloadCardWrapperProps> = ({ record }) => {
    const isEn = currentLangIs("en");

    const downloadTooltip = showRouterSpeed(record) ?
        (isEn ? downloadRouterTooltipEn : downloadRouterTooltipDe) :
        (isEn ? downloadDevice1TooltipEn : downloadDevice1TooltipDe);

    return (
        <ResultCard title={downloadTooltip.text} hintHeader={downloadTooltip.hintHeader} hintContent={downloadTooltip.hintContent}>
            <DownloadCard record={record} showAdditionalInfo={true} />
        </ResultCard>
    );
}

export default DownloadCardWrapper;