import { SerializedStyles } from "@emotion/react";
import { SPlus3Theme } from "../../../../theme/splus3/splus3-theme";

export enum SpeedKind {
    Maximal,
    Average,
    Minimal
}

export enum PingTimeKind {
    Optimal,
    Good,
    Enough
}

export const getSpeedKind = (current: number, avg: number, min: number): SpeedKind => {
    if (current <= min) {
        return SpeedKind.Minimal;
    }

    if (current > avg) {
        return SpeedKind.Maximal;
    }

    return SpeedKind.Average;
}

export const getPingTimeKind = (current: number, min: number, avg: number, max: number): PingTimeKind => {
    if (current < avg) {
        return PingTimeKind.Optimal;
    }

    if (current > max) {
        return PingTimeKind.Enough;
    }

    return PingTimeKind.Good;
}

export const getSpeedBackground = (theme: SPlus3Theme, speedKind: SpeedKind): SerializedStyles => {
    switch (speedKind) {
        case SpeedKind.Maximal: return theme.background.indicatorMaximal;
        case SpeedKind.Average: return theme.background.indicatorAverage;
        case SpeedKind.Minimal: return theme.background.indicatorMinimal;
    }
}

export const getSpeedForeground = (theme: SPlus3Theme, speedKind: SpeedKind): SerializedStyles => {
    switch (speedKind) {
        case SpeedKind.Maximal: return theme.foreground.white;
        case SpeedKind.Average: return theme.foreground.white;
        case SpeedKind.Minimal: return theme.foreground.white;
    }
}

export const getPingTimeBackground = (theme: SPlus3Theme, pingTimeKind: PingTimeKind): SerializedStyles => {
    switch (pingTimeKind) {
        case PingTimeKind.Optimal: return theme.background.indicatorOptimal;
        case PingTimeKind.Good: return theme.background.indicatorGood;
        case PingTimeKind.Enough: return theme.background.indicatorEnough;
    }
}