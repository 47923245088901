import { InfoButton, InfoScreenPart, InfoTab } from "../FocusabeItemKey";
import ViewScreenKey from "../ViewScreenKey";
import { InfoScreenState } from "../ViewScreenState";
import { setScreen } from "../focusManagerSlice";
import { startMeasurementTv } from "../homeHelper";
import { changeInfoScreenPart, impressumDown, impressumUp } from "../infoScreenHelper";
import { ActionForElement, ViewScreen } from "../types";

export const infoViewScreen: ViewScreen<InfoScreenState> = (state: InfoScreenState) => {
    switch (state.screenPart) {
        case InfoScreenPart.Buttons:
            return {
                focusedElementKey: state.selectedButton,
                elementActionsMap: new Map<InfoButton, ActionForElement>([
                    [InfoButton.SpeedtestStart, {
                        tab: () => changeInfoScreenPart(InfoScreenPart.Buttons, { selectedButton: InfoButton.Result }),
                        right: () => changeInfoScreenPart(InfoScreenPart.Buttons, { selectedButton: InfoButton.Result }),
                        down: () => changeInfoScreenPart(InfoScreenPart.Tabs),
                        enter: startMeasurementTv,
                    }],
                    [InfoButton.Result, {
                        tab: () => changeInfoScreenPart(InfoScreenPart.Tabs, { selectedTab: InfoTab.Impressum }),
                        left: () => changeInfoScreenPart(InfoScreenPart.Buttons, { selectedButton: InfoButton.SpeedtestStart }),
                        down: () => changeInfoScreenPart(InfoScreenPart.Tabs),
                        enter: () => setScreen(ViewScreenKey.Result),
                    }],
                ]),
                backAction: () => setScreen(ViewScreenKey.Result),
            }
        case InfoScreenPart.Tabs:
            return {
                focusedElementKey: state.selectedTab,
                elementActionsMap: new Map<InfoTab, ActionForElement>([
                    [InfoTab.Impressum, {
                        tab: () => changeInfoScreenPart(InfoScreenPart.Tabs, { selectedTab: InfoTab.PrivacyPolicy }),
                        right: () => changeInfoScreenPart(InfoScreenPart.Tabs, { selectedTab: InfoTab.PrivacyPolicy }),
                        up: impressumUp,
                        down: impressumDown,
                    }],
                    [InfoTab.PrivacyPolicy, {
                        tab: () => changeInfoScreenPart(InfoScreenPart.Tabs, { selectedTab: InfoTab.TermsAndConditions }),
                        left: () => changeInfoScreenPart(InfoScreenPart.Tabs, { selectedTab: InfoTab.Impressum }),
                        right: () => changeInfoScreenPart(InfoScreenPart.Tabs, { selectedTab: InfoTab.TermsAndConditions }),
                        up: () => changeInfoScreenPart(InfoScreenPart.Buttons),
                    }],
                    [InfoTab.TermsAndConditions, {
                        tab: () => changeInfoScreenPart(InfoScreenPart.Buttons),
                        left: () => changeInfoScreenPart(InfoScreenPart.Tabs, { selectedTab: InfoTab.PrivacyPolicy }),
                        up: () => changeInfoScreenPart(InfoScreenPart.Buttons),
                    }],                    
                ]),
                backAction: () => setScreen(ViewScreenKey.Result),
            }
    }
}