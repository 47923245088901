import IndexCardSlider from "../components/layout/index/IndexCardSlider";
import styled from "@emotion/styled";
import { reportNavigateToViewEvent } from "../helper/analyticsService";
import { useEffect } from "react";
import IndexAccordion from "../components/layout/index/IndexAccordion";
import t from "../helper/t";
import { HeaderImageDiv, HeaderText } from "../components/elements/HeaderImageDiv";
import StartButton from "../components/speedtest/StartButton";
import SmartLink from "../components/elements/SmartLink";
import { currentLangIs } from "../helper/utils";
import HeadWithTags from "../components/elements/HeadWithTags";
import { useAppDispatch } from "../hooks";
import { hideNboTeaser } from "../features/nextBestOffer/nextBestOfferSlice";

import imagePlayArrow from "../../public/img/play-arrow.svg";
import imageIndexMeasurementExample from "../../public/img/index/de/index-measurement-example.svg";
import imageIndexMeasurementExampleEn from "../../public/img/index/en/index-measurement-example.svg";
import imageGigahome from "../../public/img/gigahome_2560.webp";

interface IndexProps { }

const IndexMeasuringExample = styled.img([
  {
    display: "none",
    margin: "70px 20px 0 20px",
    maxWidth: 930,
    height: "auto",
    position: "relative",
    width: "calc(100% - 40px)",
  },
  `
    @media (min-width: 768px) {
      display: inline;
    }
  `,
]);

const ImageDiv = styled(HeaderImageDiv)([
  {},
  `
  @media (max-width: 767px) {
        background-size: 1280px;
        height: 413px;          
  }

  @media (min-width: 768px) {
        height: 385px;
        padding-top: 32px
  }
  `,
]);

const IndexHeaderText = styled(HeaderText)([
  {},
  `
  @media (max-width: 767px) {
      padding-top: 30px;
  }
  `,
]);

const IndexComponent: React.FC<IndexProps> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    reportNavigateToViewEvent("home");
    dispatch(hideNboTeaser());
  }, []);

  return (
    <>
      <HeadWithTags name="/" />
      <div>
        <ImageDiv image={imageGigahome}>
          <IndexHeaderText>
            {t("Dein Speedtest Plus startet nach dem Klick.")}
          </IndexHeaderText>
        </ImageDiv>
        <div className="dot-wrapper">
          <StartButton startAgain={false}>
            <div id="startMeasurementBtn" className="dot">
              <img
                className="play-btn"
                src={imagePlayArrow.src}
                alt="play-arrow"
              />
              <p className="text-start">{t("Start")}</p>
            </div>
          </StartButton>
        </div>
        <div className="main-content">
          <div className="btn-wrapper">
            <SmartLink href="/report">
              <div data-cy="button-left" className="vf-button-left">
                <div className="btn-text">{t("Bisherige Ergebnisse")}</div>
              </div>
            </SmartLink>
            <SmartLink href="/know_how">
              <div data-cy="button-right" className="vf-button-right">
                <div className="btn-text">{t("Infos zum Speedtest Plus")}</div>
              </div>
            </SmartLink>
          </div>
        </div>
        <div className="um-content-wrapper">
          <p className="um-title-content start-mobile-hidden">
            {t("Das macht den Speedtest Plus so einzigartig")}
          </p>
          <p className="um-title-text start-mobile-hidden">
            {t(
              "Bei Vodafone-Kunden mit Kabel-Anschluss messen wir in zwei Stufen die Verbindung zu Deinem Gerät und falls nötig auch zu Deinem Router. Dadurch können wir Dir gezielt Tipps geben, um die Geschwindigkeit Deines Heimnetzwerks zu optimieren."
            )}
          </p>
          <IndexMeasuringExample
            src={
              currentLangIs("en")
                ? imageIndexMeasurementExampleEn.src
                : imageIndexMeasurementExample.src
            }
            alt="Messung Diagramm"
            loading="lazy"
          />
          <p className="um-title-content-small">
            {t("Beste Voraussetzungen für exakte Ergebnisse")}
          </p>
          <IndexCardSlider />
          <p className="um-title-content">
            {t("Häufige Fragen zum Speedtest")}
          </p>
          <IndexAccordion />
        </div>
      </div>
    </>
  );
};

export default IndexComponent;
