import { showRouterSpeed } from "./utils";
import DownloadCard1 from "./DownloadCard1";
import DownloadCard2 from "./DownloadCard2";
import { DownloadCardProps } from "./DownloadCardProps";

const DownloadCard: React.FC<DownloadCardProps> = ({ record, showAdditionalInfo: additionalInfo }) => {
    return showRouterSpeed(record) ? 
        <DownloadCard2 record={record} showAdditionalInfo={additionalInfo} /> : 
        <DownloadCard1 record={record} showAdditionalInfo={additionalInfo} />
}

export default DownloadCard;