import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import { SpeedKind, getSpeedBackground, getSpeedForeground, getSpeedKind } from "./resultUtils";
import { formatSpeed } from "../../../../../helper/formatSpeeds";

export const SpeedContainer = styled.div([
    {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
]);

export const SpeedDiv = styled.div([
    {
        display: "flex",
        alignItems: "end",
    }
]);

export const SpeedValue = styled.span(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize48,
    theme.gigatv.typography.bold,
    {
        lineHeight: r(32),
    }
]));

export const SpeedUnit = styled.span(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize20,
    theme.gigatv.typography.regular,
    {
        lineHeight: "75%",
    }
]));

interface PercentageDivProps {
    speedKind: SpeedKind;
}

const PercentageDiv = styled.span<PercentageDivProps>(({ theme, speedKind }) => ([
    getSpeedForeground(speedKind),
    getSpeedBackground(speedKind),
    theme.gigatv.typography.fontSize16,
    theme.gigatv.typography.regular,
    {
        borderRadius: r(128),
        paddingLeft: r(12),
        paddingRight: r(12),
        paddingTop: r(4),
        paddingBottom: r(4),
        letterSpacing: "0.01em",
        fontWeight: 700,
    }
]));

interface SpeedProps {
    currentSpeed: number;
    bookedSpeedMax: number | null;
}

export const Speed: React.FC<SpeedProps> = ({ currentSpeed, bookedSpeedMax }) => {
    const speedValues = formatSpeed([currentSpeed, bookedSpeedMax]);
    const speedStr = speedValues.values === null || speedValues.values.length === 0 || speedValues.values[0] === null ? "--" : speedValues.values[0];

    return (
        <SpeedDiv>
            <SpeedValue>
                {speedStr}&nbsp;
            </SpeedValue>
            <SpeedUnit>
                {speedValues.unit}
            </SpeedUnit>
        </SpeedDiv>
    );
}

export const SubSpeedDiv = styled.span(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize28,
    theme.gigatv.typography.bold,
]));

export const SubSpeed: React.FC<SpeedProps> = ({ currentSpeed, bookedSpeedMax }) => {
    const speedValues = formatSpeed([currentSpeed, bookedSpeedMax]);
    const speedStr = speedValues.values === null || speedValues.values.length === 0 || speedValues.values[0] === null ? "--" : speedValues.values[0];

    return (
        <SubSpeedDiv>
            {speedStr}&nbsp;{speedValues.unit}
        </SubSpeedDiv>
    );
}

interface PercentageProps {
    currentSpeed: number;
    bookedSpeedMax: number | null;
    bookedSpeedAvg: number | null;
    bookedSpeedMin: number | null;
}

export const Percentage: React.FC<PercentageProps> = ({ currentSpeed, bookedSpeedMax, bookedSpeedAvg, bookedSpeedMin }) => {
    if (bookedSpeedMax === null) {
        return null;
    }

    const avg = bookedSpeedAvg === null ? Math.round(bookedSpeedMax * 0.9) : bookedSpeedAvg;
    const min = bookedSpeedMin === null ? Math.round(bookedSpeedMax * 0.6) : bookedSpeedMin;

    const value = Math.round(100.0 * currentSpeed / bookedSpeedMax);
    const kind = getSpeedKind(currentSpeed, avg, min);

    return (
        <PercentageDiv speedKind={kind}>
            {value}%
        </PercentageDiv>
    );
}

export const SubtitleDiv = styled.span(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize28,
    theme.gigatv.typography.lite,
    {
        marginTop: r(20),
        maxWidth: r(760),

        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",

        strong: [
            theme.gigatv.typography.bold,
        ]
    }
]));

export const SubtitleRightDiv = styled(SubtitleDiv)(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize24,
    theme.gigatv.typography.lite,
    {
        marginTop: r(24),
        lineHeight: r(32),
    }
]));