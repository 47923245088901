import { Models } from "@visonum/network-quality-sdk";
import t from "../../../helper/t";

export const connectionToStr = (connectionType: Models.SnmpResultModelConnectionTypeEnum | null): string | null => {
    switch (connectionType) {
        case null: return null;
        case Models.SnmpResultModelConnectionTypeEnum.Lan: return t("LAN");
        case Models.SnmpResultModelConnectionTypeEnum.Wlan24: return t("WLAN 2.4 GHz");
        case Models.SnmpResultModelConnectionTypeEnum.Wlan5: return t("WLAN 5 GHz");
    }
}

export const currentToStr = (isCurrent?: boolean | null): string => {
    if (isCurrent == true) {
        return t("aktuell");
    } else if (isCurrent == false) {
        return t("nicht aktuell");
    } else {
        return "";
    }
}