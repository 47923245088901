import styled from "@emotion/styled";

const CommonContainer = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",

        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "min(100%, 1440px)",
    }
]));

export default CommonContainer;