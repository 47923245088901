import { PrepareResultDE } from "@visonum/network-quality-sdk";
import { StaticImageData } from "next/image";
import * as R from "ramda";
import { AppAssertionError } from "../../features/errors/AppAssertionError";
import { isCableCustomer, isCustomerByIsp } from "../../helper/utils/customerUtls";

export interface NboObject {
  title: string;
  headlineText: string;
  copyText: string;
  buttonText: string;
  url: string;
  image: StaticImageData;
}

export enum NboCase {
  None = "None",
  NonCustomer = "NonCustomer",
  GigabitCableCustomer = "GigabitCableCustomer",
  BelowGigabitCableCustomer = "BelowGigabitCableCustomer",
}

export interface GetNboCaseOptions {
  prepareResult: PrepareResultDE,
  downloadSpeed: number,
  uploadSpeed: number,
  modemSpeed: number | null,
}

/* pure */
export const getNboCase = (options: GetNboCaseOptions): NboCase => {

  if (options.prepareResult.init.connection.isVpnActive === true) {
    return NboCase.None;
  }

  if (!isCustomerByIsp(options.prepareResult)) {
    return NboCase.NonCustomer;
  }

  if (!isCableCustomer(options.prepareResult)) {
    return NboCase.None;
  }

  const bookedDownloadSpeedMax = options.prepareResult.init.modem?.bookedDownloadSpeedMax;
  if (R.isNil(bookedDownloadSpeedMax) || bookedDownloadSpeedMax === 0) {
    throw new AppAssertionError({ message: "Cable customer must have defined bookedDownloadSpeedMax > 0" });
  }
  const bookedDownloadSpeedAvg = options.prepareResult.init.modem?.bookedDownloadSpeedAvg ?? 0;
  const bookedUploadSpeedAvg = options.prepareResult.init.modem?.bookedUploadSpeedAvg ?? 0;

  const Gigabit = 1e9;

  if (bookedDownloadSpeedMax === Gigabit && options.uploadSpeed >= bookedUploadSpeedAvg &&
    (options.downloadSpeed >= bookedDownloadSpeedAvg || (options.modemSpeed !== null && options.modemSpeed >= bookedDownloadSpeedAvg))) {
    return NboCase.GigabitCableCustomer;
  } else if (bookedDownloadSpeedMax < Gigabit &&
    options.downloadSpeed >= bookedDownloadSpeedAvg &&
    options.uploadSpeed >= bookedUploadSpeedAvg) {
    return NboCase.BelowGigabitCableCustomer;
  } else {
    return NboCase.None;
  }
}
