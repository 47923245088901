import * as R from "ramda";
import { AppThunk } from "../../store";
import { InfoButton, InfoScreenPart, InfoTab } from "./FocusabeItemKey";
import focusManagerSlice from "./focusManagerSlice";
import { impressumScrollId } from "../../pages/gigatv/info/Impressum";
import { AppAssertionError } from "../errors/AppAssertionError";
import { devAssert } from "../errors/devAssert";
import { isRunningInBrowser } from "../../helper/ssr";

export const changeInfoScreenPart = (screenPart: InfoScreenPart, options?: { selectedButton?: InfoButton, selectedTab?: InfoTab }): AppThunk => (dispatch, _) => {
    dispatch(focusManagerSlice.actions.updateInfoScreenPart(screenPart));
    if (!R.isNil(options?.selectedButton)) {
        dispatch(focusManagerSlice.actions.updateInfoSelectedButton(options!.selectedButton));
    }    
    if (!R.isNil(options?.selectedTab)) {
        dispatch(focusManagerSlice.actions.updateInfoSelectedTab(options!.selectedTab));
    }
}

export const impressumDown = (): AppThunk => (dispatch, _) => {
    if (!isRunningInBrowser()) {
        return;
    }

    const scroll = window.document.getElementById(impressumScrollId);

    if (R.isNil(scroll)) {
        devAssert(new AppAssertionError({ actual: scroll, expected: "defined impressum scroll" }), "Scroll is null #1");
    } else {
        const scrollStep = scroll.clientHeight * 0.9;
        scroll.scrollTop = Math.min(scroll.scrollTop + scrollStep, scroll.scrollHeight - scroll.clientHeight);
    }
}

export const impressumUp = (): AppThunk => (dispatch, _) => {
    if (!isRunningInBrowser()) {
        return;
    }
    
    const scroll = window.document.getElementById(impressumScrollId);

    if (R.isNil(scroll)) {
        devAssert(new AppAssertionError({ actual: scroll, expected: "defined impressum scroll" }), "Scroll is null #2");
        dispatch(changeInfoScreenPart(InfoScreenPart.Buttons));
    } else {
        if (scroll.scrollTop > 0) {
            const scrollStep = scroll.clientHeight * 0.9;
            scroll.scrollTop = Math.max(0, scroll.scrollTop - scrollStep);
        } else {
            dispatch(changeInfoScreenPart(InfoScreenPart.Buttons));
        }
    }
}