const prefix = "speedtestUI.";

export interface IStorage {
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
    removeItem(key: string): void;
    clear(): void;
}

export const getValue = (storage: IStorage, key: Keys): string | null => {
    return storage.getItem(fullKey(key));
}

export const setValue = (storage: IStorage, key: Keys, value: string): void => {
    storage.setItem(fullKey(key), value);
}

export const removeValue = (storage: IStorage, key: Keys): void => {
    storage.removeItem(fullKey(key));
}

export const getNumber = (storage: IStorage, key: Keys): number | null => {
    const numberStr = getValue(storage, key);
    if (numberStr != null) {
        const num = Number(numberStr);
        if (Number.isFinite(num)) {
            return num;
        }
    }
    return null;
}

export const setNumber = (storage: IStorage, key: Keys, value: number) => {
    setValue(storage, key, String(value));
}

type BooleanType = "False" | "True";

export const getBoolean = (storage: IStorage, key: Keys): boolean | null => {
    const boolStr = getValue(storage, key);
    if (boolStr != null) {
        const b = boolStr as BooleanType;
        return b === "True";
    }
    return null;
}

export const setBoolean = (storage: IStorage, key: Keys, value: boolean) => {
    const convert = (): BooleanType => {
        switch (value) {
            case false: return "False";
            case true: return "True";
        };
    }

    const valueStr = convert();
    setValue(storage, key, valueStr);
}

export type StorageKind = "local" | "session";

const storageDic = new Map<StorageKind, IStorage>();

export const getVolatileStorage = (kind: StorageKind): IStorage => {
    if (!storageDic.has(kind)) {
        storageDic.set(kind, new VolatileStorage());
    }

    return storageDic.get(kind)!;
}

export enum Keys {
    LastModemSwapDisplay = "LastModemSwapDisplay",
    PrivacyPolicyAcceptedDate = "PrivacyPolicyAcceptedDate",
    FcData = "adv_fcData",
    LastModemResetDate = "LastModemResetDate",
    ModemResetCount = "ModemResetCount",
    History = "history",
    HistoryLegacy = "measuringHistory",
    HistoryDidMigrate = "didMigrate",
    NboTeaserCase = "NboTeaserCase",
    Consent = "Consent",
    OfferedSurvery = "OfferedSurvery",
    LastSurveyDate = "LastSurveyDate",
}

export const fullKey = (k: Keys): string => {
    return prefix + k;
}

class VolatileStorage implements IStorage {
    private data = new Map<string, string | null>();

    getItem(key: string) {
        const r = this.data.get(key);
        return r === undefined ? null : r;
    }

    setItem(key: string, value: string) {
        this.data.set(key, value);
    }

    removeItem(key: string): void {
        this.data.delete(key);
    }

    clear(): void {
        this.data.clear();
    }
}
