import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ModalDialog, { ModalDialogButtons, ModalDialogResult } from "../elements/ModalDialog";
import t from "../../../helper/t";
import { hideDialog } from "../../../features/dialogManager/dialogManagerSlice";
import { updatePage, updateResultPageTab } from "../../../features/pageManager/pageManagerSlice";
import { PageKey, ResultTabKey } from "../../../features/pageManager/pageState";
import { PartialServiceRebootTipId } from "../../../features/hints/hintsVirtualIds";
import { updateCurrenIndex } from "../../../features/hints/hintsSlice";

const ContentContaner = styled.div([
    {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    }
]);

const PartialServiceResetDialog: React.FC<{}> = () => {
    const dispatch = useAppDispatch();
    const hintsState = useAppSelector(state => state.hints);

    const onClose = (result: ModalDialogResult) => {
        dispatch(hideDialog());

        if (result === ModalDialogResult.GoToTip) {
            dispatch(updateResultPageTab(ResultTabKey.SpeedtestGuide));
            dispatch(updatePage(PageKey.Result));
            const hintIndex = hintsState.hintIds.indexOf(PartialServiceRebootTipId);
            if (hintIndex >= 0) {
                dispatch(updateCurrenIndex(hintIndex));
            }
        }
    }

    return (
        <ModalDialog header={t("Routerneustart empfohlen")} buttons={ModalDialogButtons.TipClose} onClose={onClose}>
            <ContentContaner>
                {t("Schau im Tipp, wie Du Deine Leitung verbessern kannst")}
            </ContentContaner>
        </ModalDialog>
    );
}

export default PartialServiceResetDialog;