import { vodafoneRed } from "../../../theme/common";

export type AnimationDirection = "Forward" | "Backward";

interface KakerlakeProps {
    rectWidth: number;
    rectHeight: number;
    strokeLength: number;
    strokeWidth: number;
    direction?: AnimationDirection;
    duration: number;
    delay?: number;
    repeatCount?: number | "indefinite";
    startOffset?: number;
}

export const Kakerlake: React.FC<KakerlakeProps> = ({ rectWidth, rectHeight, strokeLength, strokeWidth, direction = "Forward", duration, delay = 0, repeatCount = "indefinite", startOffset = 0 }) => {
    const width = rectWidth;
    const height = rectHeight;
    const length = (width + height) * 2;
    const startPosition = (direction === "Forward" ? 2 * strokeLength : -width) + startOffset;
    const endPosition = (length + strokeLength) * (direction === "Forward" ? 1 : -1) + startPosition;

    return (
        <div style={{ marginLeft: -strokeWidth / 2, marginTop: -strokeWidth, transform: "scale(1, -1)" }}>
            <svg width={rectWidth + strokeWidth} height={rectHeight + strokeWidth} xmlns='http://www.w3.org/2000/svg'>
                <defs>
                    <clipPath id="cut-off-bottom">
                        <rect x="0" y="50%" width="100%" height="50%" />
                    </clipPath>
                </defs>
                <rect
                    x={strokeWidth / 2}
                    y={strokeWidth / 2}
                    clipPath="url(#cut-off-bottom)"
                    width={width}
                    height={height}
                    fill='none'
                    rx='0'
                    ry='0'
                    stroke={vodafoneRed}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${strokeLength} ${length}`}
                    strokeDashoffset={startPosition}
                    strokeLinecap='round'>
                    <animate
                        attributeName="stroke-dashoffset"
                        values={`${startPosition};${endPosition}`}
                        dur={`${duration * 2}ms`}
                        begin={`${delay}ms`}
                        repeatCount={repeatCount} />
                </rect>
            </svg>
        </div>
    )
}

interface TopLineKakerlakeProps {
    rectWidth: number;
    rectHeight: number;
    strokeLength: number;
    strokeWidth: number;
    duration: number;
}

export const TopLineKakerlake: React.FC<TopLineKakerlakeProps> = ({ rectWidth, rectHeight, strokeLength, strokeWidth, duration }) => {
    const width = rectWidth;
    const height = rectHeight;
    const length = (width + height) * 2;
    const startPosition = -width;
    const middlePosition1 = startPosition - height;
    const middlePosition2 = middlePosition1 - width + strokeLength;
    const endPosition = middlePosition2 - height;

    return (
        <div style={{ marginLeft: -strokeWidth / 2, marginTop: -strokeWidth, transform: "scale(1, -1)" }}>
            <svg width={rectWidth + strokeWidth} height={rectHeight + strokeWidth} xmlns='http://www.w3.org/2000/svg'>
                <defs>
                    <clipPath id="cut-off-bottom">
                        <rect x="0" y="50%" width="100%" height="50%" />
                    </clipPath>
                </defs>
                <rect
                    clipPath="url(#cut-off-bottom)"
                    x={strokeWidth / 2}
                    y={strokeWidth / 2}
                    width={width}
                    height={height}
                    fill='none'
                    rx='0'
                    ry='0'
                    stroke={vodafoneRed}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${strokeLength} ${length}`}
                    strokeDashoffset={startPosition}
                    strokeLinecap='round'>
                    <animate
                        attributeName="stroke-dashoffset"
                        calcMode="paced"
                        values={`${startPosition};${middlePosition1};${middlePosition2};${endPosition};${middlePosition2};${middlePosition1};${startPosition}`}
                        dur={`${duration * 2}ms`}
                        repeatCount="indefinite" />
                </rect>
            </svg>
        </div>
    )
}