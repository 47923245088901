import { MeasurementMode } from "@visonum/network-quality-sdk";
import * as R from "ramda";
import { getUrlParamValue } from "../helper/utils";

export const getMeasurementModeFromUrl = (url: string | null) => parseMeasurementMode(getUrlParamValue(url, "mode"));

const parseMeasurementMode = (modeValue: string | null, defaultMode: MeasurementMode = MeasurementMode.Production) => {
    if (!R.isNil(modeValue) && modeValue in MeasurementMode) {
        return modeValue as MeasurementMode;
    }

    return defaultMode;
}