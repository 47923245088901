import { VARIANT } from "./config";
import { isRunningInBrowser } from "./helper/ssr";
import { isSeoEnabled } from "./helper/utils";

export const devCheckHref = (href: string): string => {
    if (isRunningInBrowser() || isSeoEnabled()) {
        return href;
    }

    console.log("devCheckHref", href);

    if (href.startsWith("/")) {
        if (allowedPages.includes(href)) {
            return href;
        } else {
            throw new Error(`Uknown local link "${href}".`);
        }
    } else if (href.startsWith("https://")) {
        try {
            new URL(href);
        } catch (err) {
            console.error(`URL "${href} is invalid.`, err);
            throw new Error(`URL "${href} is invalid.`);
        }

        if (allowedLinks.includes(href)) {
            return href;
        } else {
            throw new Error(`Uknown link "${href}".`);
        }
    } else {
        throw new Error(`Invalid link "${href}. URL scheme is unknown or not supported.`);
    }
}

const getAllowedPages = () => {
    const pageNames = ["", "cookie_policy", "privacy_policy_faq", "404", "know_how", "modemSwap", "report", "result", "speedtest", "tipps", "en"];
    const langPrefixes = ["/", "/en/"];
    return langPrefixes.map(p => pageNames.map(n => p + n)).flat();
}

const allowedPages = getAllowedPages();

const allowedLinks = [
    "https://zuhauseplus.vodafone.de/internet-telefon/?icmp=speedtest:internettelefon",
    "https://www.vodafone.de/",
    "https://www.vodafone.de/agb.html",
    "https://www.vodafone.de/impressum.html",
    "https://www.vodafone.de/unternehmen/soziale-verantwortung/datenschutz-privatsphaere.html",    
];