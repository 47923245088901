import styled from "@emotion/styled";
import { MeasurementState, Phase } from "../../../../features/measurement/types";
import { AnimatedRectangleHeight, DashWidth, MeasurementItemGap, MeasurementItemWidth } from "./consts";
import { Kakerlake, TopLineKakerlake } from "./Kakerlake";
import { theme } from "src/theme/theme";

const KakerlakeLength = 14;
const KakerlakeWidth = 3;

const KakerlakeContainerLeft = styled.div([
  {
    gridColumnStart: 1,
    gridColumnEnd: 4,
    gridRowStart: 1,
    gridRowEnd: 3,

    marginTop: -2,
    marginLeft: MeasurementItemWidth / 2,
    marginRight: MeasurementItemWidth / 2,
  }
]);

const KakerlakeContainerRight = styled.div([
  {
    gridColumnStart: 3,
    gridColumnEnd: 6,
    gridRowStart: 1,
    gridRowEnd: 3,

    marginTop: -2,
    marginLeft: MeasurementItemWidth / 2,
    marginRight: MeasurementItemWidth / 2,
  }
]);

const KakerlakeContainerFull = styled.div([
  {
    gridColumnStart: 1,
    gridColumnEnd: 6,
    gridRowStart: 1,
    gridRowEnd: 3,

    marginTop: -2,
    marginLeft: MeasurementItemWidth / 2,
    marginRight: MeasurementItemWidth / 2,
  }
]);

const downloadAnimation = (duration: number): JSX.Element => {
  return <>
    <KakerlakeContainerLeft>
      <Kakerlake key="downloadAnimationLeft"
        strokeColor={theme.splus3.colors.vodafoneRed}
        rectWidth={MeasurementItemWidth + MeasurementItemGap}
        rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
        strokeLength={KakerlakeLength}
        strokeWidth={KakerlakeWidth}
        direction="Forward"
        duration={duration}
        delay={0}
        repeatCount={2}
      />
    </KakerlakeContainerLeft>

    <KakerlakeContainerRight>
      <Kakerlake key="downloadAnimationRight"
        strokeColor={theme.splus3.colors.vodafoneRed}
        rectWidth={MeasurementItemWidth + MeasurementItemGap}
        rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
        strokeLength={KakerlakeLength}
        strokeWidth={KakerlakeWidth}
        direction="Forward"
        duration={duration}
        delay={duration * 1.1}
        repeatCount={2}
      />
    </KakerlakeContainerRight>
  </>
}

const uploadAnimation = (duration: number): JSX.Element => {
  return <>
    <KakerlakeContainerLeft>
      <Kakerlake key="uploadAnimationLeft"
        strokeColor={theme.splus3.colors.vodafoneRed}
        rectWidth={MeasurementItemWidth + MeasurementItemGap}
        rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
        strokeLength={KakerlakeLength}
        strokeWidth={KakerlakeWidth}
        direction="Backward"
        duration={duration}
        delay={duration * 1.1}
        repeatCount={2}
      />
    </KakerlakeContainerLeft>

    <KakerlakeContainerRight>
      <Kakerlake key="uploadAnimationRight"
        strokeColor={theme.splus3.colors.vodafoneRed}
        rectWidth={MeasurementItemWidth + MeasurementItemGap}
        rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
        strokeLength={KakerlakeLength}
        strokeWidth={KakerlakeWidth}
        direction="Backward"
        duration={duration}
        delay={0}
        repeatCount={2}
      />
    </KakerlakeContainerRight>
  </>
}

const pingAnimation = (duration: number): JSX.Element => {
  return (
    <KakerlakeContainerFull style={{ zIndex: 20 }}>
      <TopLineKakerlake key="pingAnimationTop"
        strokeColor={theme.splus3.colors.vodafoneRed}
        rectWidth={MeasurementItemWidth * 2 + MeasurementItemGap * 2}
        rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
        strokeLength={KakerlakeLength}
        strokeWidth={KakerlakeWidth}
        duration={duration}
      />
    </KakerlakeContainerFull>
  );
}

const modemAnimation = (): JSX.Element => {
  return (
    <KakerlakeContainerLeft>
      <Kakerlake key="modemAnimationLeft"
        strokeColor={theme.splus3.colors.vodafoneRed}
        rectWidth={MeasurementItemWidth + MeasurementItemGap}
        rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
        strokeLength={KakerlakeLength}
        strokeWidth={KakerlakeWidth}
        direction="Forward"
        duration={2500}
        delay={0}
        repeatCount="indefinite"
      />
    </KakerlakeContainerLeft>
  );
}

export const animated = (state: MeasurementState): JSX.Element | null => {
  switch (state.kind) {
    case Phase.Downloading:
      return downloadAnimation(state.prepareResult.config.download.duration * 1000 / 4);

    case Phase.Uploading:
      return uploadAnimation(state.prepareResult.config.upload.duration * 1000 / 4);

    case Phase.Pinging:
    case Phase.Completing:
    case Phase.ModemResolving:
      return pingAnimation(state.prepareResult.config.ping.duration * 1000 / 4);

    case Phase.ModemExecuting:
      return modemAnimation();
    default:
      return null;
  }
}

