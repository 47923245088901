import * as R from "ramda";
import { isRunningInBrowser } from "../../helper/ssr";
import { devAssert } from "../errors//devAssert";
import { AppAssertionError } from "../errors/AppAssertionError";
import { AppThunk } from "../../store";
import { PartialServiceDialogKind, TipStatus } from "../partialService/partialServiceUtils";

interface SpeedtestStartedInfo {
    ident: string;
}

interface PartialServiceInfo {
    ident: string;
    downIndex: number | null;
    upIndex: number | null;
    days: number | null;
    lastModemResetDate: Date | null;
    dialog: PartialServiceDialogKind;
    modemResetTip: TipStatus;
    modemResetCount: number | null;
    callbackTip: TipStatus;
    callbackCounter: number;
}

interface EventCategoryMap {
    "Partial Service": {
        actionType: "Speedtest Started";
        nameType: SpeedtestStartedInfo;
    } | {
        actionType: "Partial Service Calculated";
        nameType: PartialServiceInfo;
    } | {
        actionType: "Callback Click";
        nameType: undefined;
    },
    "Tips": {
        actionType: "Show Details";
        nameType: string;
    }
}

interface PushEventOptions<K extends keyof EventCategoryMap> {
    category: K;
    action: EventCategoryMap[K]["actionType"];
    name?: EventCategoryMap[K]["nameType"];
    value?: number;
}

export const matomoPushEvent = <K extends keyof EventCategoryMap>(options: PushEventOptions<K>): AppThunk => (_, getState) => {
    if (isRunningInBrowser() && getState().matomo.started) {
        if (R.isNil(window._paq)) {
            devAssert(new AppAssertionError({ actual: window._paq, expected: "window._paq is defined" }), "window._paq is not defined");
        }

        const actionName = R.isNil(options.name) ?
            undefined :
            typeof options.name === "string" ? options.name : JSON.stringify(options.name);

        window._paq?.push(["trackEvent", options.category, options.action, actionName, options.value]);
    }
}