import React from "react";
import SmartLink from "../elements/SmartLink";

interface StartButtonProps {
    startAgain: boolean;
}

const StartButton: React.FC<StartButtonProps> = ({ startAgain, children }) => {
    return (
        <SmartLink href="/speedtest">
            {children}
        </SmartLink>
    );
}

export default StartButton;