import styled from "@emotion/styled";
import * as R from "ramda";
import { ModemFinalKind } from "@visonum/network-quality-sdk";
import { useEffect, useRef } from "react";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import { NQRecord } from "../../../../../features/history/types";
import { formatSpeed } from "../../../../../helper/formatSpeeds";
import { logger } from "../../../../../helper/logger";
import { formatDate, formatPingTime, formatTime } from "../../../../../helper/unitsFormatting";
import { HistoryRecordsItemKey } from "../../../../../features/focusManager/FocusabeItemKey";
import IconButton from "../../../../../components/elements/gigatv/buttons/IconButton";
import NoDataStub from "../../NoDataStub";
import getSpeedtestId from "../../../../../helper/utils/getSpeedtestId";

const RowHeight = 80;

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
    }
]);

interface TableContentProps {
    maxItemsCount: number;
}

const TableContent = styled.div<TableContentProps>(({ theme, maxItemsCount }) => ([
    theme.gigatv.scroll.tvScroll,
    {
        paddingRight: r(4),
        height: r(RowHeight * maxItemsCount),
    }
]));

const RemoveEntryIconDiv = styled.div([
    {
        width: r(32),
        height: r(32),
    }
]);

interface HistoryTableProps {
    records: NQRecord[];
    frameItemCount: number;
    firstItemIndex: number;
    selectedIndex: number;
    selectedKey: HistoryRecordsItemKey | null;
}

const HistoryTable: React.FC<HistoryTableProps> = ({ records, frameItemCount, firstItemIndex, selectedIndex, selectedKey }) => {
    const scrollDivRef = useRef<HTMLDivElement>(null);

    const routerSpeedValues = formatSpeed(records.map(r => r.modemFinal.modemFinal.kind === ModemFinalKind.ModemFinalAvailable ? r.modemFinal.modemFinal.speed : null));
    const downloadSpeedValues = formatSpeed(records.map(r => r.downloadFinal.speed));
    const uploadSpeedValues = formatSpeed(records.map(r => r.uploadFinal.speed));

    const headerValues = [
        { title: "Datum", sub: "Speedtest-ID" },
        { title: `Download (${routerSpeedValues.unit})`, sub: "Zum Router" },
        { title: `Download (${downloadSpeedValues.unit})`, sub: "Zum Gerät" },
        { title: `Upload (${uploadSpeedValues.unit})` },
        { title: "Reaktionszeit (ms)", sub: "Ping" },
    ];

    const scrollToFirstIndex = () => {
        const current = scrollDivRef.current;
        if (current === null) {
            logger.warn("HistoryTable: scroll is null");
            return;
        }

        const rowHeight = current.scrollHeight / records.length;
        const newScrollTop = rowHeight * firstItemIndex;

        current.scrollTop = newScrollTop;
    }

    useEffect(() => {
        scrollToFirstIndex();
    }, [firstItemIndex]);

    return (
        <RootDiv>
            <TableRow state={{ isHeader: true }}>
                {headerValues.map(v =>
                    <HeaderCellDiv key={`header-${v.title}-${v.sub}`}>
                        <strong>{v.title}</strong>
                        {!R.isNil(v.sub) && (
                            <span>{v.sub}</span>
                        )}
                    </HeaderCellDiv>
                )}
            </TableRow>
            <TableContent ref={scrollDivRef} maxItemsCount={frameItemCount}>
                {records.length === 0 ?  (<NoDataStub />) : records.map((v, index) =>
                    <TableRow key={`row-${index}`}
                        state={{ isHeader: false, isSelected: selectedIndex === index && selectedKey === HistoryRecordsItemKey.Item }}>
                        <CellDiv>
                            {formatDate(v.date)} - {formatTime(v.date)}
                            <span>{getSpeedtestId(v.prepareResult)}</span>
                        </CellDiv>
                        <CellDiv>
                            {routerSpeedValues.values[index] ?? "--"}
                        </CellDiv>
                        <CellDiv>
                            {downloadSpeedValues.values[index] ?? "--"}
                        </CellDiv>
                        <CellDiv>
                            {uploadSpeedValues.values[index] ?? "--"}
                        </CellDiv>
                        <LastCellContainer>
                            <CellDiv>
                                {formatPingTime(v.pingFinal.pingTime)}
                            </CellDiv>
                            <div style={{ marginTop: r(-8), marginBottom: r(-8), display: "flex", alignItems: "center" }}>
                                <IconButton isFocused={selectedIndex === index && selectedKey === HistoryRecordsItemKey.RemoveEntry}>
                                    <RemoveEntryIconDiv>
                                        <svg width="100%" height="100%" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.5 6C14.7044 6 13.9413 6.31607 13.3787 6.87868C12.8161 7.44129 12.5 8.20435 12.5 9H9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V24C9 24.7957 9.31607 25.5587 9.87868 26.1213C10.4413 26.6839 11.2043 27 12 27H21C21.7957 27 22.5587 26.6839 23.1213 26.1213C23.6839 25.5587 24 24.7957 24 24V11C24.5523 11 25 10.5523 25 10C25 9.44772 24.5523 9 24 9H20.5C20.5 8.20435 20.1839 7.44129 19.6213 6.87868C19.0587 6.31607 18.2956 6 17.5 6H15.5ZM18.5 9C18.5 8.73478 18.3946 8.48043 18.2071 8.29289C18.0196 8.10536 17.7652 8 17.5 8H15.5C15.2348 8 14.9804 8.10536 14.7929 8.29289C14.6054 8.48043 14.5 8.73478 14.5 9H18.5ZM22 11H11V24C11 24.2652 11.1054 24.5196 11.2929 24.7071C11.4804 24.8946 11.7348 25 12 25H21C21.2652 25 21.5196 24.8946 21.7071 24.7071C21.8946 24.5196 22 24.2652 22 24V11ZM15.5013 13.5C15.5013 12.9477 15.0535 12.5 14.5013 12.5C13.949 12.5 13.5013 12.9477 13.5013 13.5V22.5C13.5013 23.0523 13.949 23.5 14.5013 23.5C15.0535 23.5 15.5013 23.0523 15.5013 22.5V13.5ZM19.5013 13.5C19.5013 12.9477 19.0535 12.5 18.5013 12.5C17.949 12.5 17.5013 12.9477 17.5013 13.5V22.5C17.5013 23.0523 17.949 23.5 18.5013 23.5C19.0535 23.5 19.5013 23.0523 19.5013 22.5V13.5Z" fill="white" />
                                        </svg>
                                    </RemoveEntryIconDiv>
                                </IconButton>
                            </div>
                        </LastCellContainer>
                    </TableRow>
                )}
            </TableContent>
        </RootDiv>
    );
}

const RowContainer = styled.div([
    {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: r(RowHeight),

        transition: "background 3s",

        outline: "none",
    },
    `
    &:focus {
        #rowBackgroundDiv {
            opacity: 1;
        }
    }
    `
]);

const RowDiv = styled.div([
    {
        position: "relative",
        display: "grid",
        flexGrow: 10,
        gridTemplateColumns: "repeat(5, 1fr)",
        paddingTop: r(8),
        paddingBottom: r(8),
        paddingLeft: r(16),
        paddingRight: r(3),
    }
]);

const HeaderCellDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.regular,
    theme.gigatv.typography.fontSize18,
    {
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
        rowGap: r(14),
        lineHeight: "100%",

        strong: [
            theme.gigatv.typography.fontSize24,
            theme.gigatv.typography.bold,
            {
                lineHeight: "100%",
                letterSpacing: 0
            }
        ],
    }
]));

const CellDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.regular,
    theme.gigatv.typography.fontSize24,
    {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 10,
        rowGap: r(14),
        lineHeight: "100%",

        span: [
            theme.gigatv.typography.fontSize18,
            {lineHeight: "100%",}
        ],
    }
]));

const LastCellContainer = styled.div([
    {
        display: "flex",
    }
]);

const TableDivider = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.separator,
    {
        position: "relative",
        height: 1,
    },
]));

interface RowBackgroundDivProps {
    isFocused: boolean;
}

const RowBackgroundDiv = styled.div<RowBackgroundDivProps>(({ theme, isFocused }) => ([
    theme.gigatv.colors.background.historyTableFocused,
    {
        opacity: isFocused ? 1 : 0,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transition: "opacity 0.3s"
    }
]));

interface TableRow {
    state: {
        isHeader: true;
    } | {
        isHeader: false;
        isSelected: boolean;
    }
}

const TableRow: React.FC<TableRow> = ({ state, children }) => {
    if (state.isHeader === true) {
        return (
            <RowContainer>
                <RowDiv>
                    {children}
                </RowDiv>
                <TableDivider />
            </RowContainer>
        );
    } else {
        return (
            <RowContainer>
                <RowBackgroundDiv isFocused={state.isSelected} />
                <RowDiv>
                    {children}
                </RowDiv >
                <TableDivider />
            </RowContainer >
        );
    }
}

export default HistoryTable;
