import styled from "@emotion/styled";

interface StrokedRectangleProps {
  strokeColor: string;
  strokeLength: number;
  strokeWidthTop?: number;
  strokeWidthBottom?: number;
  strokeWidthLeft?: number;
  strokeWidthRight?: number;
}

const StrokedRectangle = styled.div<StrokedRectangleProps>(({ strokeColor, strokeLength, strokeWidthTop, strokeWidthBottom, strokeWidthLeft, strokeWidthRight }) => ([
  {
    background: `
      linear-gradient(90deg, ${strokeColor} 50%, transparent 0) repeat-x,
      linear-gradient(90deg, ${strokeColor} 50%, transparent 0) repeat-x,
      linear-gradient(0deg, ${strokeColor} 50%, transparent 0) repeat-y,
      linear-gradient(0deg, ${strokeColor} 50%, transparent 0) repeat-y;  
    `,
    backgroundSize: `${strokeLength}px ${strokeWidthTop ?? 0}px, ${strokeLength}px ${strokeWidthBottom ?? 0}px, ${strokeWidthLeft ?? 0}px ${strokeLength}px, ${strokeWidthRight ?? 0}px ${strokeLength}px`,
    backgroundPosition: "0 0, 0 100%, 0 0, 100% 0",
    width: "100%",
    height: "100%",
  }
]));

export default StrokedRectangle;