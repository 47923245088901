import React from "react"
import t from "../../helper/t"
import { getErrorMessage, getErrorTitleKey } from "./errorUtils";
import { NQError } from "@visonum/network-quality-sdk";
import ModalDialog, { ModalDialogButtons } from "../../pages/splus3/elements/ModalDialog";
import styled from "@emotion/styled";
import { useAppDispatch } from "../../hooks";
import { resetError } from "./errorSlice";
import { reset } from "../measurement/measurementSlice";
import { updatePage } from "../pageManager/pageManagerSlice";
import { PageKey } from "../pageManager/pageState";
import ErrorAdditionalInfo from "./ErrorAdditionalInfo";

const ErrorContentContaner = styled.div([
  {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    maxWidth: 480,
  }
]);

const ErrorAdditionalInfoDiv = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 12,
    marginLeft: "auto",
    marginRight: "auto",
  }
]));

interface SPlus3ErrorDialogProps {
  lastError: NQError;
}

const SPlus3ErrorDialog: React.FC<SPlus3ErrorDialogProps> = ({ lastError }) => {
  const dispatch = useAppDispatch();

  const title = t(getErrorTitleKey(lastError));

  const onClose = () => {
    dispatch(resetError());
    dispatch(reset());
    dispatch(updatePage(PageKey.Home));
  }

  return (
    <ModalDialog header={title} buttons={ModalDialogButtons.Ok} onClose={onClose}>
      <ErrorContentContaner>
        <span>{t(getErrorMessage(lastError))}</span>
      </ErrorContentContaner>
      <ErrorAdditionalInfoDiv>
        <ErrorAdditionalInfo lastError={lastError} />
      </ErrorAdditionalInfoDiv>
    </ModalDialog>
  );
}

export default SPlus3ErrorDialog;