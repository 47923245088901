import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { ClickableDiv } from "./ClickableDiv";

const RootDiv = styled.div([
    {
        position: "relative",
        cursor: "pointer",
        minWidth: 120,
    }
]);

interface ComboButtonDivProps {
    isTransparent: boolean;
}

const ComboButtonDiv = styled.div<ComboButtonDivProps>(({ theme, isTransparent }) => ([
    isTransparent ? { background: "" } : theme.splus3.background.white,
    theme.splus3.border.solidDark1,
    {
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 6,
        paddingLeft: 12,
        paddingTop: 8,
        paddingBottom: 8,
        gap: 10,
    }
]));

const ValueContainerDiv = styled.div(
    {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",

        whiteSpace: "nowrap",
    }
);

const ValueDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.large,
    {
        gridColumn: 1,
        gridRow: 1,
        lineHeight: "140%",
    }
]));

const ArrowDiv = styled.div(
    {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 48,
    }
);

const ListContainer = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    theme.splus3.shadow.mainCard,
    {
        marginTop: 2,
        position: "absolute",
        width: "100%",
        borderRadius: "0px 0px 6px 6px",
    }
]));

interface ComboBoxItemProps {
    isSelected: boolean;
}

const ComboBoxItem = styled.div<ComboBoxItemProps>(({ theme, isSelected }) => ([
    isSelected ? theme.splus3.background.monochrome3 : { background: "" },
]));

interface ComboBoxProps {
    items: string[];
    selectedIndex: number;
    transparent?: boolean;
    onChangeSelectedIndex: (newIndex: number) => void;
}

const ComboBox: React.FC<ComboBoxProps> = ({ items, selectedIndex, transparent = true, onChangeSelectedIndex }) => {
    const [opened, setOpened] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const onClick = () => {
        setIsFocused(false);
        setOpened(!opened);
    }

    const onItemClick = (index: number) => {
        onChangeSelectedIndex(index);
        setOpened(false);
        setIsFocused(true);
    }

    return (
        <RootDiv>
            <ClickableDiv isFocused={isFocused} onClick={onClick} role="combobox" ariaLabel="Drop-down list" ariaIsExpanded={opened} ariaControls="valueContainer">
                <ComboButtonDiv isTransparent={transparent}>
                    <ValueContainerDiv id="valueContainer">
                        {items.map((item, index) =>
                            <ValueDiv key={index} style={{ opacity: 0 }}>
                                {item}
                            </ValueDiv>
                        )}
                        <ValueDiv>
                            {items[selectedIndex]}
                        </ValueDiv>
                    </ValueContainerDiv>
                    <ArrowDiv>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.6663 5.16699L7.99967 10.8337L2.33301 5.16699" stroke="#0D0D0D" strokeMiterlimit="10" strokeLinecap="round" />
                        </svg>
                    </ArrowDiv>
                </ComboButtonDiv>
            </ClickableDiv>
            {opened &&
                <ListContainer>
                    {opened && items.map((item, index) =>
                        <ClickableDiv key={index} onClick={() => onItemClick(index)} role="listitem">
                            <ComboBoxItem isSelected={selectedIndex === index}>
                                <ValueDiv style={{ paddingLeft: 8, paddingRight: 8 }}>{item}</ValueDiv>
                            </ComboBoxItem>
                        </ClickableDiv>
                    )}
                </ListContainer>
            }
        </RootDiv>
    );
}

export default ComboBox;