import CommonControl from "./CommonControl";

import qrDataPrivacyPng from "../../../../assets/images/gigatv/qr-data-privacy.png";

interface PrivacyPolicyProps {
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
    return (
        <CommonControl
            header="Deine Privatsphäre – unsere Mission"
            contentHeader="Deine Privatsphäre ist uns wichtig"
            qrImage={qrDataPrivacyPng}>
                <p>Du willst wissen, was wir für den vertrauensvollen Umgang mit Deinen persönlichen Daten tun? Hier erklären wir es Dir. Hilf uns dabei, Deine Privatsphäre zu schützen.</p>
                <p>Du und der Gesetzgeber entscheiden, wie wir mit Deinen Daten umgehen. Basis dafür ist Deine Einwilligung. Oder eine gesetzliche Erlaubnis. Davon hängt ab, was wir mit Deinen Daten machen.</p>
                <p>Scann einfach den QR-Code ein, um auf die Webseite mit den aktuellsten Informationen zu gelangen.</p>
        </CommonControl>
    );
}

export default PrivacyPolicy;