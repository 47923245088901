import * as R from "ramda";

export type ValuesWithUnit = {
    values: (string | null)[];
    unit: string;
}

export type ValueWithUnit = {
    value: string;
    unit: string;
}


export function formatSpeed(speeds: (number | null)[]): ValuesWithUnit
export function formatSpeed(speeds: (number | null)[], speed: number): ValueWithUnit
export function formatSpeed(speeds: (number | null)[], speed?: number) {

    const numToString = (num: number): string => {
        const n = (0 < num && num <= 0.01e6 ? 1e4 : num) * 1e-6;
        const absNum = Math.abs(num);
        const fractionDigits = absNum === 0 ? 0 : absNum < 10e6 ? 2 : absNum < 20e6 ? 1 : 0;
        return n.toLocaleString(undefined, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
            useGrouping: false
        });
    }

    if (R.isNil(speed)) {
        return {
            values: speeds.map(s => s === null ? null : numToString(s)),
            unit: "Mbit/s"
        }
    } else {
        const retVal = formatSpeed([speed, ...speeds]);
        return { value: retVal.values[0]!, unit: retVal.unit }
    }
}