import { css } from "@emotion/react";
import { freeze } from "immer";
import { r } from "./sizeUtils";
import { vodafoneRed } from "../common";

const primaryForegroundColorValue = "#FFF";

const indicatorMinimalColorValue = "#EB6100";
const indicatorAverageColorValue = "#FECB00";
const indicatorMaximalColorValue = "#008A00";

const indicatorOptimalColorValue = "#008A00";
const indicatorGoodColorValue = "#80C480";
const indicatorEnoughColorValue = "#FECB00";

export const darkTheme = freeze({
    colors: {
        vodafoneRed,
        primaryForegroundColorValue,
        foreground: {
            primary: css({ color: primaryForegroundColorValue }),
            vodafoneRed: css({ color: vodafoneRed }),
            primaryDark: css({ color: "#0D0D0D" }),
            secondary: css({ color: "#333" }),
            thirthy: css({ color: "#7E7E7E" }),
            button: css({ color: "#BEBEBE" }),
            buttonFocused: css({ color: "#FFFFFF" }),
        },
        background: {
            red: css({ backgroundColor: vodafoneRed }),
            measurementItemActive: css({ backgroundColor: "#262626" }),
            measurementItem: css({ backgroundColor: "#262626" }),
            dialog: css({ background: "linear-gradient(359.98deg, rgba(13, 13, 13, 0.91) -24.28%, #0D0D0D 98.97%)" }),
            dialogCard: css({ backgroundColor: "#262626" }),
            defaultButton: css({ background: "linear-gradient(180deg, #59A156 0%, #3A5624 100%)" }),
            button: css({ background: "linear-gradient(180deg, #222222 0%, #111111 100%)" }),
            buttonFocused: css({ background: "linear-gradient(180deg, #454545 0%, #222222 100%)" }),
            navigationButton: css({ background: "linear-gradient(180deg, #222222 0%, #111111 100%)" }),
            navigationButtonFocused: css({ background: "linear-gradient(180deg, #454545 0%, #222222 100%)" }),
            iconButton: css({ background: "linear-gradient(180deg, #262626 0%, #0D0D0D 100%)" }),
            iconButtonFocused: css({ background: "linear-gradient(180deg, #454545 0%, #222222 100%)" }),
            fog: css({ background: "linear-gradient(359.73deg, rgba(13, 13, 13, 0.51) -23.91%, #0D0D0D 86.22%)" }),
            homeFog: css({ background: "linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6))" }),
            resultCard: css({ background: "rgba(38, 38, 38, 0.8)" }),
            resultCardHeader: css({ background: "#fff" }),
            indicator: css({ background: "#BEBEBE" }),
            indicatorMinimal: css({ background: indicatorMinimalColorValue }),
            indicatorAverage: css({ background: indicatorAverageColorValue }),
            indicatorMaximal: css({ background: indicatorMaximalColorValue }),
            indicatorOptimal: css({ background: indicatorOptimalColorValue }),
            indicatorGood: css({ background: indicatorGoodColorValue }),
            indicatorEnough: css({ background: indicatorEnoughColorValue }),
            deviceDownload: css({ background: "rgba(242, 242, 242, 0.1)" }),
            separator: css({ 
                backgroundColor: "#fff",
                opacity: 0.3,
            }),
            historyTableFocused: css({ background: "linear-gradient(180deg, #454545 0%, #222222 100%)" }),
        },
        border: {
            active: css({ borderColor: vodafoneRed }),
            measurementItem: css({ borderColor: "#262626" }),
            resultCard: css({ border: "1px solid #FFFFFF" }),
            button: css({ borderColor: "transparent" }),
            buttonFocused: css({ borderColor: "#fff" }),
            navigationButton: css({ borderColor: "#fff" }),
        },
    },
    focus: {
        outlineUnfocused: css`
            box-shadow: none;
        `,
        outlineFocused: css`
            box-shadow: 0 0 0 4px #00B0CA;
        `,
    },
    scroll: {
        tvScroll: {
            overflowY: "auto",
            scrollBehavior: "smooth",
    
            // Width
            "::-webkit-scrollbar": {
                width: r(12),
            },
    
            // Track
            "::-webkit-scrollbar-track": {
                background: undefined,
            },
    
            /* Handle */
            "::-webkit-scrollbar-thumb": {
                background: "rgba(190,190,190,0.6)",
                borderRadius: r(10),
            },            
        }
    }
} as const);