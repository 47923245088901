import styled from "@emotion/styled";
import VpnSticky from "./VpnSticky";
import TariffInfo from "../elements/TariffInfo";
import { useAppSelector } from "../../../hooks";
import { selectPrepareResult } from "../../../features/measurement/utils";
import { nullOrValue } from "../../../helper/utils";
import MeasurementDesktop from "./desktop/MeasurementDesktop";
import MeasurementMobile from "./mobile/MeasurementMobile";

const RootDiv = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",
    flexGrow: 10,
  }
]));

const TariffInfoContainer = styled.div([
  {
    width: "100%",
  }
]);

const ContainerDiv = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 10,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: {
      marginTop: 16,
    },
  }
]));

interface MeasurementPageProps {
}

const MeasurementPage: React.FC<MeasurementPageProps> = () => {
  const state = useAppSelector((state) => state.measurement);
  const prepareResult = selectPrepareResult(state);
  const bookedDownloadSpeed = nullOrValue(prepareResult?.init.modem?.bookedDownloadSpeedMax);
  const bookedUploadSpeed = nullOrValue(prepareResult?.init.modem?.bookedUploadSpeedMax);

  return (
    <RootDiv data-cy="measurementPage">
      {prepareResult?.init.connection.isVpnActive == true && <VpnSticky />}

      <TariffInfoContainer>
        <TariffInfo fading={true} bookedDownloadSpeed={bookedDownloadSpeed} bookedUploadSpeed={bookedUploadSpeed} />
      </TariffInfoContainer>

      <ContainerDiv>
        <MeasurementDesktop />
        <MeasurementMobile />
      </ContainerDiv>
    </RootDiv>
  );
}

export default MeasurementPage;
