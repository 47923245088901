import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartialServiceDialogKind } from '../partialService/partialServiceUtils';
import { NQError } from '@visonum/network-quality-sdk';

export enum DialogKind {
    None,
    PartialService,
    PrivacyPolicy,
    Consent,
    Error,
    RemoveHistory,
    ModemSwap,
    SpeedtestCancellation,
}

export type DialogManagerState = {
    kind: DialogKind.None,
} | {
    kind: DialogKind.PartialService,
    partialServiceDialog: PartialServiceDialogKind.Reset | PartialServiceDialogKind.Callback,
} | {
    kind: DialogKind.PrivacyPolicy,
} | {
    kind: DialogKind.Consent,
} | {
    kind: DialogKind.Error,
    error: NQError,
} | {
    kind: DialogKind.RemoveHistory,
} | {
    kind: DialogKind.ModemSwap,
} | {
    kind: DialogKind.SpeedtestCancellation,
}

const initialState = {
    kind: DialogKind.None,
} as DialogManagerState;

export const dialogManagerSlice = createSlice({
    name: 'dialogManager',
    initialState,
    reducers: {
        hideDialog: (_) => {
            return { kind: DialogKind.None }
        },
        showPartialServiceDialog: (_, action: PayloadAction<PartialServiceDialogKind.Reset | PartialServiceDialogKind.Callback>) => {
            return {
                kind: DialogKind.PartialService,
                partialServiceDialog: action.payload,
            }
        },
        showPrivacyPolicyDialog: (_) => {
            return {
                kind: DialogKind.PrivacyPolicy,
            }
        },
        showConsentDialog: (_) => {
            return {
                kind: DialogKind.Consent,
            }
        },
        showErrorDialog: (_, action: PayloadAction<NQError>) => {
            return {
                kind: DialogKind.Error,
                error: action.payload,
            }
        },
        showRemoveHistoryDialog: (_) => {
            return {
                kind: DialogKind.RemoveHistory,
            }
        },
        showModemSwapMessage: (_) => {
            return {
                kind: DialogKind.ModemSwap,
            }
        },
        showCancellationDialog: (_) => {
            return {
                kind: DialogKind.SpeedtestCancellation,
            }
        }
    }
});

export const {
    hideDialog,
    showPartialServiceDialog,
    showPrivacyPolicyDialog,
    showConsentDialog,
    showErrorDialog,
    showRemoveHistoryDialog,
    showModemSwapMessage,
    showCancellationDialog,
} = dialogManagerSlice.actions;

export default dialogManagerSlice;