import styled from "@emotion/styled";
import HeaderWithTooltip from "../elements/HeaderWithTooltip";

import InfoCircleSvg from "../../../../assets/icons/splus3/info-circle.svg";

const RootDiv = styled.div([
    {
        display: "flex",
        alignItems: "flex-start",
    }
]);

const TextDiv = styled.div([
    props => props.theme.splus3.typography.large,
    props => props.theme.splus3.foreground.primary,
    {
        display: "inline"
    }
]);

interface InfoCardItemProps {
    imageSrc: string;
    imageAlt: string;
    text: string;
    hintHeader: string;
    hintContent: string;
}

const InfoCardItem: React.FC<InfoCardItemProps> = ({ imageSrc, imageAlt, text, hintHeader, hintContent }) => {
    return (
        <RootDiv>
            <img src={imageSrc} alt={imageAlt} style={{ marginRight: 15, transform: "translate(0px, 2px)", }} />
            <TextDiv>
                {text}&nbsp;
                <HeaderWithTooltip header={hintHeader} icon={InfoCircleSvg} size={16} left={-250} bottom={24} handlePosition="BottomRight">
                    {hintContent}
                </HeaderWithTooltip>
            </TextDiv>
        </RootDiv>
    );
}

export default InfoCardItem;