import styled from "@emotion/styled";
import React, { CSSProperties } from "react";

interface CardProps {
  fullScreenInMobile: boolean;
  roundBorder: boolean;
  maxWidth: number;
}

const Card = styled.div<CardProps>([
  props => props.theme.colors.grey,
  props => props.theme.colors.background.primary,
  props => props.theme.typography.modalDialogText,
  {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: 12,
    padding: "20px 28px 20px 28px",
    border: "none",    
    background: "#fff",
    color: "#333",
    flexWrap: "wrap",
    textAlign: "center",
    wordWrap: "break-word"
  },
  props => ({
    borderRadius: props.roundBorder ? 10 : 0,
    maxWidth: props.maxWidth,
  }),
  props => (props.fullScreenInMobile ?
    `
  @media (max-width: 767px) {
    max-width: none;
    margin: 0;
    border-radius: 0;
    height: 100%;
  }
  ` : `
  @media (max-width: 767px) {
    padding: 8px;
  }  
  `)
]);

interface ModalDialogProps {
  fullScreenInMobile?: boolean;
  roundBorder?: boolean;
  maxWidth?: number;
}

const ModalDialog: React.FC<ModalDialogProps> = ({ fullScreenInMobile, roundBorder, maxWidth = 512, children }) => (
  <div data-cy="modal-dialog" style={{ ...muiBackdropRootStyle, ...transition }} aria-hidden="true">
    <Card fullScreenInMobile={fullScreenInMobile ?? false} roundBorder={roundBorder ?? true} maxWidth={maxWidth ?? 512}>
      {children}
    </Card>
  </div>
)

const muiBackdropRootStyle: CSSProperties = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  zIndex: 1000,
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.5)"
}

const transition: CSSProperties = {
  opacity: 1,
  transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
}

export default ModalDialog;