import styled from "@emotion/styled";

const Button = styled.button([
  props => props.theme.colors?.grey,
  props => props.theme.colors?.background.primary,
  props => props.theme.typography?.button,
  props => ({'&:hover': props.theme.colors?.background.secondary}),
  {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 0,
    boxShadow: "0 1px 3px rgb(50 50 50 / 60%)",
    height: 46,
    border: 0,
    width: 230,
    textAlign: "center",
    cursor: "pointer",
    padding: 0,
  },
  `&:active {
    box-shadow: none;
  }`
]);

export const PrimaryButton = styled(Button)([
  props => props.theme.colors.white,
  props => props.theme.colors.background.tertiary,
  props => ({'&:hover': props.theme.colors.background.quaternary}),
]);


export default Button;