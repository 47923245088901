import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
    }
]);

const HeaderDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.regular,
    theme.gigatv.typography.fontSize28,
    theme.gigatv.colors.foreground.primaryDark,
    theme.gigatv.colors.background.resultCardHeader,
    {
        paddingTop: r(8),
        paddingBottom: r(8),
        paddingLeft: r(20),
        paddingRight: r(20),
        borderRadius: "6px 6px 0px 0px",
        alignSelf: "flex-start",
    }
]));

const ContentDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.resultCard,
    theme.gigatv.colors.border.resultCard,
    {
        display: "flex",
        flexGrow: 10,
        minHeight: 64,
        borderRadius: "0px 6px 6px 6px",
        padding: r(20),
    }
]));

interface ResultCardProps {
    title: string;
}

const ResultCard: React.FC<ResultCardProps> = ({ title, children }) => {
    return (
        <RootDiv>
            <HeaderDiv>
                {title}
            </HeaderDiv>
            <ContentDiv>
                {children}
            </ContentDiv>
        </RootDiv>
    );
}

export default ResultCard;