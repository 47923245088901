import Head from "next/head";
import styled from "@emotion/styled";
import { AppProps } from "next/app";
import { ReactElement } from "react";
import { Provider } from "react-redux";
import t from "../../../src/helper/t";
import { store } from "../../../src/store";
import LoggerViewSwitcher from "./LoggerViewSwitcher";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../theme/theme";
import DebugInfoSwitcher from "../gigatv/DebugInfoSwitcher";
import DialogSwitcher from "../gigatv/DialogSwitcher";

import backgroundFull from "../../../assets/images/gigatv/speedtest_f1.webp";


const Container = styled.div([
    {
        height: "100vh",
        width: "100vw",
        backgroundPosition: "center top",
        backgroundSize: "cover",
        backgroundImage: `url(${backgroundFull.src})`,
    }
]);

const GigaTvPage = ({ Component, pageProps }: AppProps): ReactElement => {
    return (
        <>
            <Head>
                <title>GigaTV Speedtest</title>
                <meta name="description" content={t("Description")}></meta>
                <meta name="revisit-after" content="1 days"></meta>
                <meta name="referrer" content="no-referrer"></meta>
            </Head>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <Container>
                        <main>
                            <Component {...pageProps} />
                            <LoggerViewSwitcher />
                            <DialogSwitcher />
                            <DebugInfoSwitcher />
                        </main>
                    </Container>
                </ThemeProvider>
            </Provider>
        </>
    );
}

export default GigaTvPage;