import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModemFinalKind } from "@visonum/network-quality-sdk";
import * as R from "ramda";
import { isRunningInBrowser } from "../../helper/ssr";
import { getNboTeaseCase, setNboTeaseCase } from "../../helper/storage";
import { getUrlParamValue } from "../../helper/utils";
import { AppThunk } from "../../store";
import { AppAssertionError } from "../errors/AppAssertionError";
import { Phase } from "../measurement/types";
import { getNboCase, NboCase } from "./NextBestOfferUtils";

export interface NextBestOfferState {
    hidden: boolean;
    nboCase: NboCase;
}

export const initialState: NextBestOfferState = {
    hidden: false,
    nboCase: NboCase.None,
}

export const showLastNboTeaser = (): AppThunk => (dispatch, getState) => {
    const lastNboCase = getNboTeaseCase();
    if (lastNboCase !== null) {
        dispatch(nextBestOfferSlice.actions.updateNboCase(lastNboCase));
    }
}

export const hideNboTeaser = (): AppThunk => (dispatch, getState) => {
    setNboTeaseCase(NboCase.None);
    dispatch(nextBestOfferSlice.actions.updateNboCase(NboCase.None));
}

export const showNextBestOfferAfterMeasurement = (): AppThunk => (dispatch, getState) => {
    const state = getState().measurement;

    if (state.kind !== Phase.CompletingNoModem && state.kind !== Phase.CompletingWithModem) {
        throw new AppAssertionError({ actual: state.kind, expected: "CompletingNoModem or CompletingWithModem" });
    }

    const nboCaseFromUrl = (() => {
        const url = isRunningInBrowser() ? window.location.href : null;
        const param = getUrlParamValue(url, "nbo");

        if (!R.isNil(param)) {
            switch (param) {
                case "0": return NboCase.None;
                case "1": return NboCase.NonCustomer;
                case "2": return NboCase.GigabitCableCustomer;
                case "3": return NboCase.BelowGigabitCableCustomer;
            }
        }

        return null;
    })();

    const nboCase = nboCaseFromUrl === null ?
        getNboCase({
            prepareResult: state.prepareResult,
            downloadSpeed: state.download.speed,
            uploadSpeed: state.upload.speed,
            modemSpeed: state.modem.modemFinal.kind === ModemFinalKind.ModemFinalAvailable ? state.modem.modemFinal.speed : null,
        }) : nboCaseFromUrl;

    setNboTeaseCase(nboCase);
    dispatch(nextBestOfferSlice.actions.updateNboCase(nboCase));
}

export const nextBestOfferSlice = createSlice({
    name: 'nextBestOffer',
    initialState,
    reducers: {
        updateNboCase: (state, action: PayloadAction<NboCase>) => {
            state.nboCase = action.payload;
        },
        temporaryHide: (state) => {
            state.hidden = true;
        },
        resetHidden: (state) => {
            state.hidden = false;
        }
    }
});

export const { temporaryHide, resetHidden } = nextBestOfferSlice.actions;

export default nextBestOfferSlice;