import React, { ReactElement, useEffect, useState } from "react";
import Head from "next/head";
import { Provider } from "react-redux";
import Navbar from "../../components/layout/navbar/Navbar";
import Footer from "../../components/layout/footer/Footer";
import { AppProps } from "next/app";
import { store } from "../../store"
import { theme } from "../../theme/theme";
import { ThemeProvider } from "@emotion/react";
import t from "../../helper/t";
import styled from "@emotion/styled";
import LoggerViewSwitcher from "./LoggerViewSwitcher";
import NextBestOffer from "../../features/nextBestOffer/NextBestOffer";
import { useAppSelector } from "../../hooks";
import { NboCase } from "../../features/nextBestOffer/NextBestOfferUtils";
import { currentLangIs, defaultLanguage } from "../../helper/utils";
import SPlusDialogSwitcher from "./SPlusDialogSwitcher";

const Container = styled.div([
    {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        gridTemplateAreas: "'header' 'main'",
        height: "100vh"
    }
]);

const Header = styled.div([
    {
        gridArea: "header"
    }
]);

const Main = styled.div([
    {
        overflow: "auto",
        gridArea: "main"
    }
]);

const SPlusViewPage = ({ Component, pageProps }: AppProps): ReactElement => {
    const [jsEnabled, setJsEnabled] = useState(false);

    useEffect(() => {
        setJsEnabled(true);
    }, []);

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Head>
                    <title>{t("Title")}</title>
                    <meta name="description" content={t("Description")}></meta>
                    <meta name="revisit-after" content="1 days"></meta>
                    <meta name="referrer" content="no-referrer"></meta>
                </Head>
                <Container>
                    <Header>
                        {jsEnabled && (
                            <Navbar />
                        )}
                    </Header>

                    <Main>
                        <main>
                            <Component {...pageProps} />
                            <BottomWidgetSwitcher />
                            <LoggerViewSwitcher />
                            <SPlusDialogSwitcher />
                        </main>
                        <Footer />
                    </Main>
                </Container>
            </ThemeProvider>
        </Provider>
    );
}

const BottomWidgetSwitcher: React.FC<{}> = () => {
    const nboState = useAppSelector(state => state.nextBestOffer);

    if (currentLangIs(defaultLanguage) && nboState.nboCase !== NboCase.None && !nboState.hidden) {
        return (
            <div style={{ marginTop: 112 }}>
                <NextBestOffer />
            </div>
        );
    }

    return (<div style={{ marginTop: 60 }} />);
}

export default SPlusViewPage;