import styled from "@emotion/styled";
import { useAppSelector } from "../../../hooks";
import { Phase } from "../../../features/measurement/types";
import MeasurementItem, { MeasurementItemWidth } from "./MeasurementItem";
import StrokedRectangle from "./StrokedRectangle";
import { Kakerlake, TopLineKakerlake } from "./Kakerlake";
import { r } from "../../../theme/gigatv/sizeUtils";
import * as R from "ramda";
import t from "../../../helper/t";
import { BottomInfoDiv, InfoKeySpan, InfoContainer } from "../components/bottom-info";
import TariffInfo from "../components/TariffInfo";
import ProgressAnimation from "./ProgressAnimation";
import { isServerActive, isDeviceActive, isRouterActive, selectPrepareResult } from "../../../features/measurement/utils";
import getSpeedtestId from "../../../helper/utils/getSpeedtestId";
import { gigaTvTheme } from "../../../theme/gigatv/giga-tv-theme";
import MeasurementText from "src/features/measurement/MeasurementText";

import vodafoneTvSvg from "../../../../assets/images/gigatv/vodafone-tv.svg";
import routerSvg from "../../../../assets/images/gigatv/router.svg";
import internationalSvg from "../../../../assets/images/gigatv/international.svg";

const MeasurementItemGap = 137;
const DashLength = 22;
const DashWidth = 2;
const AnimatedRectangleHeight = 43;
const KakerlakeLength = 24;
const KakerlakeWidth = 6;

const RootDiv = styled.div(({ theme }) => ([
  theme.gigatv.colors.background.fog,
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
  }
]));

const TariffInfoContainer = styled.div([
  {
    width: "100%",
  }
]);

const ContainerDiv = styled.div([
  {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 10,
    rowGap: 86,
  }
]);

const MeasurementContainer = styled.div([
  {
    display: "grid",
    gridTemplateRows: `${AnimatedRectangleHeight}px ${AnimatedRectangleHeight}px auto`,
    gridTemplateColumns: `${MeasurementItemWidth}px ${MeasurementItemGap}px ${MeasurementItemWidth}px ${MeasurementItemGap}px ${MeasurementItemWidth}px`,
  }
]);

const HeadlineTextDiv = styled.div(({ theme }) => ([
  theme.gigatv.colors.foreground.primary,
  theme.gigatv.typography.fontSize28Abs,
  theme.gigatv.typography.regular,
]));


const StrokesContainerLeft = styled.div([
  {
    gridColumnStart: 1,
    gridColumnEnd: 4,
    gridRow: 1,

    marginLeft: MeasurementItemWidth / 2 - DashWidth / 2,
    marginRight: MeasurementItemWidth / 2 - DashWidth / 2,

    rect: [
      {
        strokeDashoffset: 0,
      }
    ],
  }
]);

const StrokesContainerRight = styled.div([
  {
    gridColumnStart: 3,
    gridColumnEnd: 6,
    gridRow: 1,

    marginLeft: MeasurementItemWidth / 2 - DashWidth / 2,
    marginRight: MeasurementItemWidth / 2 - DashWidth / 2,

    rect: [
      {
        strokeDashoffset: 0,
      }
    ],
  }
]);

const KakerlakeContainerLeft = styled.div([
  {
    gridColumnStart: 1,
    gridColumnEnd: 4,
    gridRowStart: 1,
    gridRowEnd: 3,

    marginTop: 0,
    marginLeft: MeasurementItemWidth / 2,
    marginRight: MeasurementItemWidth / 2,
  }
]);

const KakerlakeContainerRight = styled.div([
  {
    gridColumnStart: 3,
    gridColumnEnd: 6,
    gridRowStart: 1,
    gridRowEnd: 3,

    marginTop: 0,
    marginLeft: MeasurementItemWidth / 2,
    marginRight: MeasurementItemWidth / 2,
  }
]);

const KakerlakeContainerFull = styled.div([
  {
    gridColumnStart: 1,
    gridColumnEnd: 6,
    gridRowStart: 1,
    gridRowEnd: 3,

    marginTop: 0,
    marginLeft: MeasurementItemWidth / 2,
    marginRight: MeasurementItemWidth / 2,
  }
]);

const ProgressContainer = styled.div(({}) => ([
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
]));

const InfoTextDiv = styled.div(({ theme }) => ([
  theme.gigatv.colors.foreground.primary,
  theme.gigatv.typography.fontSize28Abs,
]));

interface MeasurementScreenProps {
}

const MeasurementScreen: React.FC<MeasurementScreenProps> = () => {
  const state = useAppSelector((state) => state.measurement);
  const prepareResult = selectPrepareResult(state);

  const animated = () => {
    switch (state.kind) {
      case Phase.Downloading:
        const downloadingDuration = state.prepareResult.config.download.duration * 1000 / 4;
        return (<>
          <KakerlakeContainerLeft>
            <Kakerlake key="downloadAnimationLeft"
              rectWidth={MeasurementItemWidth + MeasurementItemGap}
              rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
              strokeLength={KakerlakeLength}
              strokeWidth={KakerlakeWidth}
              direction="Forward"
              duration={downloadingDuration}
              delay={0}
              repeatCount={2}
            />
          </KakerlakeContainerLeft>

          <KakerlakeContainerRight>
            <Kakerlake key="downloadAnimationRight"
              rectWidth={MeasurementItemWidth + MeasurementItemGap}
              rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
              strokeLength={KakerlakeLength}
              strokeWidth={KakerlakeWidth}
              direction="Forward"
              duration={downloadingDuration}
              delay={downloadingDuration * 1.1}
              repeatCount={2}
            />
          </KakerlakeContainerRight>
        </>);
      case Phase.Uploading:
        const uploadingDuration = state.prepareResult.config.upload.duration * 1000 / 4;
        return (<>
          <KakerlakeContainerLeft>
            <Kakerlake key="uploadAnimationLeft"
              rectWidth={MeasurementItemWidth + MeasurementItemGap}
              rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
              strokeLength={KakerlakeLength}
              strokeWidth={KakerlakeWidth}
              direction="Backward"
              duration={uploadingDuration}
              delay={uploadingDuration * 1.1}
              repeatCount={2}
            />
          </KakerlakeContainerLeft>

          <KakerlakeContainerRight>
            <Kakerlake key="uploadAnimationRight"
              rectWidth={MeasurementItemWidth + MeasurementItemGap}
              rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
              strokeLength={KakerlakeLength}
              strokeWidth={KakerlakeWidth}
              direction="Backward"
              duration={uploadingDuration}
              delay={0}
              repeatCount={2}
            />
          </KakerlakeContainerRight>
        </>);
      case Phase.Pinging:
      case Phase.Completing:
      case Phase.ModemResolving:
        const pingDuration = state.prepareResult.config.ping.duration * 1000 / 4;
        return (
          <KakerlakeContainerFull style={{ zIndex: 20 }}>
            <TopLineKakerlake key="pingAnimationTop"
              rectWidth={MeasurementItemWidth * 2 + MeasurementItemGap * 2}
              rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
              strokeLength={KakerlakeLength}
              strokeWidth={KakerlakeWidth}
              duration={pingDuration}
            />
          </KakerlakeContainerFull>
        );
      case Phase.ModemExecuting:
        return (
          <KakerlakeContainerLeft>
            <Kakerlake key="modemAnimationLeft"
              rectWidth={MeasurementItemWidth + MeasurementItemGap}
              rectHeight={AnimatedRectangleHeight * 2 - DashWidth / 2}
              strokeLength={KakerlakeLength}
              strokeWidth={KakerlakeWidth}
              direction="Forward"
              duration={2500}
              delay={0}
              repeatCount="indefinite"
            />
          </KakerlakeContainerLeft>
        );
      default:
        return null;
    }
  }

  if (typeof screen === "undefined") {
    return null;
  }

  const getPathOpacity = (position: "left" | "right"): number => {
    const activeOpacity = 1;
    const inactiveOpacity = 0.1;

    switch (position) {
      case "left": return isServerActive(state) ? activeOpacity : inactiveOpacity;
      case "right": return isDeviceActive(state) ? activeOpacity : inactiveOpacity;
    }
  }

  return (
    <RootDiv>
      <TariffInfoContainer>
        <TariffInfo />
      </TariffInfoContainer>

      <ContainerDiv style={{ transform: `scale(${screen.height / 1080.0})` }}>
        <HeadlineTextDiv>
          Deine Messung läuft. Bitte hab einen Moment Geduld.
        </HeadlineTextDiv>

        <MeasurementContainer>
          <StrokesContainerLeft style={{ opacity: getPathOpacity("left") }}>
            <StrokedRectangle strokeLength={DashLength} strokeWidthTop={DashWidth} strokeWidthLeft={DashWidth} strokeWidthRight={DashWidth} strokeColor={gigaTvTheme.colors.primaryForegroundColorValue} />
          </StrokesContainerLeft>

          <StrokesContainerRight style={{ opacity: getPathOpacity("right") }}>
            <StrokedRectangle strokeLength={DashLength} strokeWidthTop={DashWidth} strokeWidthLeft={DashWidth} strokeWidthRight={DashWidth} strokeColor={gigaTvTheme.colors.primaryForegroundColorValue} />
          </StrokesContainerRight>

          {animated()}

          <div style={{ gridColumn: 1, gridRowStart: 2, gridRowEnd: 4, zIndex: 10 }}>
            <MeasurementItem caption="Server" value="Vodafone" icon={{ source: internationalSvg, verticalShift: -28 }} isActive={isServerActive(state)} />
          </div>
          <div style={{ gridColumn: 3, gridRowStart: 2, gridRowEnd: 4, zIndex: 10 }}>
            <MeasurementItem caption="Router" value={prepareResult?.init.modem?.name} icon={{ source: routerSvg, verticalShift: -32 }} isActive={isRouterActive(state)} />
          </div>
          <div style={{ gridColumn: 5, gridRowStart: 2, gridRowEnd: 4, zIndex: 10 }}>
            <MeasurementItem caption="Gerät" value="GigaTV" icon={{ source: vodafoneTvSvg, verticalShift: -51 }} isActive={isDeviceActive(state)} />
          </div>
        </MeasurementContainer>

        <ProgressContainer>
          <ProgressAnimation />
          <InfoTextDiv>
            <MeasurementText />
          </InfoTextDiv>
        </ProgressContainer>
      </ContainerDiv>

      <BottomInfoDiv>
        <InfoContainer style={{ opacity: R.isNil(prepareResult) ? 0 : 1 }}>
          <InfoKeySpan>{t("Speedtest-ID")}:&nbsp;</InfoKeySpan>
          <span>{prepareResult && getSpeedtestId(prepareResult)}</span>
        </InfoContainer>
        {!R.isNil(prepareResult?.init.connection.isp) && (
          <>
            <span>|</span>
            <InfoContainer>
              <InfoKeySpan>{t("Anbieter")}:&nbsp;</InfoKeySpan>
              <span>{prepareResult?.init.connection.isp}</span>
            </InfoContainer>
          </>
        )}
      </BottomInfoDiv>

      <div style={{ height: r(24) }} />
    </RootDiv>
  );
}

export default MeasurementScreen;
