import { useAppSelector } from "../../../src/hooks"
import ModalDialog from "../../components/elements/gigatv/ModalDialog";
import DialogButton from "../../components/elements/gigatv/buttons/DialogButton";
import GigaTvErrorDialog from "../../features/errors/GigaTvErrorDialog";
import { RemoveEntryItemKey } from "../../features/focusManager/FocusabeItemKey";
import ViewDialogKey from "../../features/focusManager/ViewDialogKey";
import HistoryDetailOverlay from "./result/dialog/HistoryDetailOverlay";

const DialogSwitcher: React.FC<{}> = () => {
    const dialog = useAppSelector(state => state.focusManager).dialog;

    if (dialog === null) {
        return null;
    }

    switch (dialog.dialogKey) {
        case ViewDialogKey.Error:
            return <GigaTvErrorDialog />;
        case ViewDialogKey.RemoveEntry:
            return (
                <ModalDialog header="Eintrag löschen" content="Bist Du sicher, dass Du alle Einträge löschen willst?">
                    <DialogButton dataCy="removeHistoryRemoveButton" isDefault={true} isFocused={dialog.state.selectedItem === RemoveEntryItemKey.RemoveButton}>Löschen</DialogButton>
                    <DialogButton dataCy="removeHistoryCloseButton" isFocused={dialog.state.selectedItem === RemoveEntryItemKey.CloseButton}>Schließen</DialogButton>
                </ModalDialog>
            );
        case ViewDialogKey.HistoryDetail:            
            return <HistoryDetailOverlay record={dialog.state.record} />;
    }
}

export default DialogSwitcher;