import React, { ReactElement } from "react";
import Head from "next/head";
import { Provider } from "react-redux";
import { store } from "../../../src/store"
import { theme } from "../../../src/theme/theme";
import { ThemeProvider } from "@emotion/react";
import t from "../../../src/helper/t";
import { UAParser } from "ua-parser-js";
import { OsName } from "../../../src/helper/utils/compatibility";
import styled from "@emotion/styled";
import { VARIANT } from "../../config";
import ScaDialogSwitcher from "./ScaDialogSwitcher";

const Container = styled.div([
    {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        gridTemplateAreas: "'header' 'main'",
        height: "100vh"
    }
]);

const Header = styled.div([
    {
        gridArea: "header"
    }
]);

const Main = styled.div([
    {
        overflow: "auto",
        gridArea: "main"
    }
]);

const OldBrowserPage = (): ReactElement => {

    const userAgent = navigator.userAgent;
    const parser = new UAParser(userAgent);
    const result = parser.getResult();
    const iOsName: OsName = "iOS";

    const getFirstLine = () => {
        if (result.os.name === iOsName) {
            return t("OldBrowser_iOS");
        }

        return t(VARIANT === "SCA" ? "OldBrowser_SCA" : "OldBrowser_WEB");
    }

    const getSecondLine = () => {
        if (result.os.name === iOsName) {
            return "";
        }

        return VARIANT === "SCA" ? `${result.os.name} ${result.os.version}` : `${result.browser.name} ${result.browser.version}`;
    }

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Head>
                    <title>{t("Title")}</title>
                    <meta name="description" content={t("Description")}></meta>
                    <meta name="revisit-after" content="1 days"></meta>
                    <meta name="referrer" content="no-referrer"></meta>
                </Head>
                <Container>
                    <Header>
                    </Header>

                    <Main>
                        <main>
                            <div style={{ margin: 12 }}>
                                <p>{getFirstLine()}</p>
                                <p>
                                    {getSecondLine()}
                                </p>
                            </div>
                            <ScaDialogSwitcher />
                        </main>
                    </Main>
                </Container>
            </ThemeProvider>
        </Provider>
    );
}

export default OldBrowserPage;