export type PlainDate = {
    /**
     * Time value in milliseconds since midnight, January 1, 1970 UTC.
     */
    time: number;
}

export const plainDateToDate = (pd: PlainDate) => new Date(pd.time);
export const dateToPlainDate = (date: Date): PlainDate => ({ time: date.getTime() });

export const plainDate = () => dateToPlainDate(new Date());

export const plainDateFrom = (date: string | number | Date) => dateToPlainDate(new Date(date));
export const plainDateToISOString = (pd: PlainDate) => plainDateToDate(pd).toISOString();

export const plainDateAddDays = (pd: PlainDate, days: number) => {
    const result = plainDateToDate(pd);
    result.setDate(plainDateToDate(pd).getDate() + days);
    return dateToPlainDate(result);
}

/** Gets the month, using local time. */
export const plainDateGetMonth = (pd: PlainDate) => plainDateToDate(pd).getMonth();

/** Gets the year, using local time. */
export const plainDateGetFullYear = (pd: PlainDate) => plainDateToDate(pd).getFullYear();

/**
 * Converts a time to a string by using the current or specified locale.
 * @param locales A locale string, array of locale strings, Intl.Locale object, or array of Intl.Locale objects that contain one or more language or locale tags. If you include more than one locale string, list them in descending order of priority so that the first entry is the preferred locale. If you omit this parameter, the default locale of the JavaScript runtime is used.
 * @param options An object that contains one or more properties that specify comparison options.
 */
export const plainDateToLocaleTimeString = (pd: PlainDate, locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions) =>
    plainDateToDate(pd).toLocaleTimeString(locales, options);