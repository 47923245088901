import {
    Models, 
    CompleteDE, 
    DownloadFinalDE, 
    MeasurementMode, 
    ModemDE, 
    NetworkQualitySDK, 
    NQValueDE,
    PingDE,
    PrepareResultDE,
    UploadFinalDE,
    NQHint,
    BenchmarkDE
} from "@visonum/network-quality-sdk";
import { Observable, zipWith, map, Subscriber, shareReplay, EMPTY } from "rxjs";
import { logger } from "../helper/logger";
import { SdkKind } from "../config-types";
import { SDK_KIND } from "../config";

export class NetworkQualitySdkPacer extends NetworkQualitySDK {
  private readonly observable: Observable<void>;
  private trigger?: Subscriber<void>;

  public nqValues?: Observable<NQValueDE>;

  private readonly nqValuesPromise: Promise<Observable<NQValueDE>>;
  private nqValuesPromiseResolve?: (value: Observable<NQValueDE>) => void;

  constructor(readonly mode: MeasurementMode, private readonly networkQualitySDK: NetworkQualitySDK) {
    super();

    logger.info(`NetworkQualitySdkPacer constructor. Mode = ${mode}`);

    this.observable = new Observable((observer) => {
      this.trigger = observer;
    });

    this.nqValuesPromise = new Promise((resolve) => {
      this.nqValuesPromiseResolve = resolve;
    });
  }

  public next(): void {
    this.trigger?.next();
  }

  public unsubscribe(): void {
    logger.info("pacer unsubscribe");
    this.trigger?.unsubscribe();
  }

  public getNqValuesAsync = (): Promise<Observable<NQValueDE>> => {
    return this.nqValuesPromise;
  }

  public getSdkKind(): SdkKind {
    return SDK_KIND;
  }

  public speedtest(): Observable<NQValueDE> {
    logger.info("pacer speedtest invoked!");

    this.nqValues = this.networkQualitySDK.speedtest()
      .pipe(
        zipWith(this.observable),
        map((v: any[]) => v[0]),
        shareReplay()
      );

    const resolve = this.nqValuesPromiseResolve!;
    resolve(this.nqValues);

    return this.nqValues;
  }

  public prepare(): Observable<PrepareResultDE> {
    return this.networkQualitySDK.prepare();
  }

  public benchmark(prepareResult: PrepareResultDE): Observable<BenchmarkDE> {
    return this.networkQualitySDK.benchmark(prepareResult);
  }

  public calcDownload(data: Models.CalcDownloadRequestModel): Observable<DownloadFinalDE> {
    return this.networkQualitySDK.calcDownload(data);
  }

  public calcUpload(data: Models.CalcUploadRequestModel): Observable<UploadFinalDE> {
    return this.networkQualitySDK.calcUpload(data);
  }

  public ping(prepareResult: PrepareResultDE): Observable<PingDE> {
    return this.networkQualitySDK.ping(prepareResult);
  }

  public complete(prepareResult: PrepareResultDE): Observable<CompleteDE> {
    return this.networkQualitySDK.complete(prepareResult);
  }

  public abort(prepareResult: PrepareResultDE): Observable<never> {
    return this.networkQualitySDK.abort(prepareResult);
  }

  public modem(prepareResult: PrepareResultDE): Observable<ModemDE> {
    return this.networkQualitySDK.modem(prepareResult);
  }

  public hints(speedtestId?: string): Observable<NQHint[]> {
    return this.networkQualitySDK.hints(speedtestId);
  }

  public print(printRequestModel: Models.PrintRequestModel): Observable<Blob> {
    return this.networkQualitySDK.print(printRequestModel);
  }

  public updateValue(key: Models.ValueKey, command: Models.ValueCommand): Observable<number> {
    return this.networkQualitySDK.updateValue(key, command);
  }
}