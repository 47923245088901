import styled from "@emotion/styled";
import { SubtitleSpan } from "./resultElements";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
    }
]));

const TitleSpan = styled(SubtitleSpan)(({ theme }) => ([
    theme.splus3.foreground.modemErrorTitle,
    theme.splus3.typography.bold,
    {
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.bold,
        ],
    }
]));

const ContentSpan = styled(SubtitleSpan)(({ theme }) => ([
    theme.splus3.foreground.modemErrorContent,
]));


interface RouterErrorProps {
    title: string;
}

const RouterError: React.FC<RouterErrorProps> = ({ title, children }) => {
    return (
        <RootDiv>
            <TitleSpan>
                {title}
            </TitleSpan>
            <ContentSpan>
                {children}
            </ContentSpan>
        </RootDiv>
    )
}

export default RouterError;