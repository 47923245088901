import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import * as R from "ramda";
import { logger } from "../../../helper/logger";

interface LottiePlayerProps {
    /**
     * The JSON data exported from Adobe After Effects using the Bodymovin plugin
     */
    animationData: unknown,
    /**
     * Defines if the animation should play only once or repeatedly in an endless loop
     * or the number of loops that should be completed before the animation ends
     */
    loop?: boolean | number;
    /**
     * Defines if the animation should immediately play when the component enters the DOM
     */
    autoplay?: boolean;
}

const LottiePlayer: React.FC<LottiePlayerProps> = ({ animationData, loop = true, autoplay = true }) => {

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (R.isNil(divRef.current)) {
            logger.warn("Lottie: div element not found.");
            return;
        }

        const animationItem = lottie.loadAnimation({
            container: divRef.current,
            animationData: animationData,
            loop: loop,
            autoplay: autoplay,
        });

        return function tearDown() {
            animationItem.destroy();
        }
    }, []);

    return (
        <div data-cy="lottie-player" style={{height: "100%"}} ref={divRef} />
    )
}

export default LottiePlayer;