import { SerializedStyles } from "@emotion/react";
import { gigaTvTheme } from "../../../../../theme/gigatv/giga-tv-theme";

export enum SpeedKind {
    Maximal,
    Average,
    Minimal
}

export enum PingTimeKind {
    Optimal,
    Good,
    Enough
}

export const getSpeedKind = (current: number, avg: number, min: number): SpeedKind => {
    if (current <= min) {
        return SpeedKind.Minimal;
    }

    if (current > avg) {
        return SpeedKind.Maximal;
    }

    return SpeedKind.Average;
}

export const getPingTimeKind = (current: number, min: number, avg: number, max: number): PingTimeKind => {
    if (current < avg) {
        return PingTimeKind.Optimal;
    }

    if (current > max) {
        return PingTimeKind.Enough;
    }

    return PingTimeKind.Good;
}

export const getSpeedBackground = (speedKind: SpeedKind): SerializedStyles => {
    switch (speedKind) {
        case SpeedKind.Maximal: return gigaTvTheme.colors.background.indicatorMaximal;
        case SpeedKind.Average: return gigaTvTheme.colors.background.indicatorAverage;
        case SpeedKind.Minimal: return gigaTvTheme.colors.background.indicatorMinimal;
    }
}

export const getSpeedForeground = (speedKind: SpeedKind): SerializedStyles => {
    switch (speedKind) {
        case SpeedKind.Maximal: return gigaTvTheme.colors.foreground.primary;
        case SpeedKind.Average: return gigaTvTheme.colors.foreground.primaryDark;
        case SpeedKind.Minimal: return gigaTvTheme.colors.foreground.primaryDark;
    }
}


export const getPingTimeBackground = (pingTimeKind: PingTimeKind): SerializedStyles => {
    switch (pingTimeKind) {
        case PingTimeKind.Optimal: return gigaTvTheme.colors.background.indicatorOptimal;
        case PingTimeKind.Good: return gigaTvTheme.colors.background.indicatorGood;
        case PingTimeKind.Enough: return gigaTvTheme.colors.background.indicatorEnough;
    }
}