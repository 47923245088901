import React, { ReactElement } from "react";
import Head from "next/head";
import { Provider } from "react-redux";
import { AppProps } from "next/app";
import { store } from "../../../src/store"
import { theme } from "../../../src/theme/theme";
import { ThemeProvider } from "@emotion/react";
import t from "../../../src/helper/t";
import styled from "@emotion/styled";
import LoggerViewSwitcher from "./LoggerViewSwitcher";
import ScaDialogSwitcher from "./ScaDialogSwitcher";

const Container = styled.div([
    {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        gridTemplateAreas: "'header' 'main'",
        height: "100vh"
    }
]);

const Main = styled.div([
    {
        overflow: "auto",
        gridArea: "main"
    }
]);

const ScaViewPage = ({ Component, pageProps }: AppProps): ReactElement => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Head>
                    <title>{t("Title")}</title>
                    <meta name="description" content={t("Description")}></meta>
                    <meta name="revisit-after" content="1 days"></meta>
                    <meta name="referrer" content="no-referrer"></meta>
                </Head>
                <Container>
                    <Main>
                        <main>
                            <Component {...pageProps} />
                            <BottomWidgetSwitcher />
                            <LoggerViewSwitcher />
                            <ScaDialogSwitcher />
                        </main>
                    </Main>
                </Container>
            </ThemeProvider>
        </Provider>
    );
}

const BottomWidgetSwitcher: React.FC<{}> = () => {
    return (<div style={{ marginTop: 16 }} />);    
}

export default ScaViewPage;