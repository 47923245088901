import { useEffect, useState } from "react";
import { calcPercentage, selectPrepareResult } from "./utils";
import { useAppSelector } from "../../hooks";

interface ProgressAnimationWrapperProps {
    childFunc: (percentage: number) => JSX.Element;
}

const percentageUpdateInterval = 0.1;

const ProgressAnimationWrapper: React.FC<ProgressAnimationWrapperProps> = ({ childFunc }) => {
    const state = useAppSelector(state => state.measurement);
    const [percentage, setPercentage] = useState(0);

    const prepareResult = selectPrepareResult(state);

    useEffect(() => {
        let counter = 0;

        const handler = window.setInterval(() => {
            const p = calcPercentage(prepareResult, percentageUpdateInterval, state.kind, counter);
            setPercentage(Math.round(p * 100));
            counter += 1;
        }, percentageUpdateInterval * 1000);

        return function teadDown() {
            window.clearInterval(handler);
        }
    }, [state.kind]);

    return childFunc(percentage);
}

export default ProgressAnimationWrapper;