import styled from "@emotion/styled";
import { r } from "../../../../theme/gigatv/sizeUtils";
import { CSSProperties } from "react";

const RootDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.regular,
    theme.gigatv.typography.fontSize22,
    {
        position: "relative",
        borderRadius: 2,
        outline: "none",

        paddingLeft: r(20),
        paddingRight: r(20),
        paddingTop: r(18),
        paddingBottom: r(18),
    },
]));

const backgoundCommonPart: CSSProperties = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 2,
    transition: "opacity 0.3s",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

interface BackgroundDivProps {
    isDefault: boolean;
    isFocused: boolean;
    isDisabled: boolean;
}

const BackgroundDiv = styled.div<BackgroundDivProps>(({ theme, isDefault, isFocused, isDisabled }) => ([
    isDefault ? [
        theme.gigatv.colors.background.defaultButton,
        theme.gigatv.colors.foreground.primary,
        theme.gigatv.typography.bold,
    ] : [
        theme.gigatv.colors.background.button,
        theme.gigatv.colors.foreground.button,
        theme.gigatv.typography.regular,
    ],
    {
        ...backgoundCommonPart,
        opacity: isFocused && !isDisabled ? 0 : 1,
    }
]));

interface ChildrenDivProps {
    isDisabled: boolean;
}

const ChildrenDiv = styled.div<ChildrenDivProps>(({ isDisabled }) => ([
    {
        opacity: isDisabled ? 0.4 : 1,
    }
]));

interface FocusedBackgroundDivProps {
    isDefault: boolean;
    isFocused: boolean;
    isDisabled: boolean;
}

const FocusedBackgroundDiv = styled.div<FocusedBackgroundDivProps>(({ theme, isDefault, isFocused, isDisabled }) => ([
    theme.gigatv.colors.foreground.buttonFocused,
    isDefault ? [
        theme.gigatv.colors.background.defaultButton,
        theme.gigatv.typography.bold,        
    ] : [
        theme.gigatv.colors.background.buttonFocused,        
        theme.gigatv.typography.regular,        
    ],
    {
        ...backgoundCommonPart,
        opacity: isFocused && !isDisabled ? 1 : 0,
    }
]));

interface FocusedBorderDivProps {
    isFocused: boolean;
}

const FocusedBorderDiv = styled.div<FocusedBorderDivProps>(({ theme, isFocused }) => ([
    theme.gigatv.colors.border.buttonFocused,
    {
        ...backgoundCommonPart,
        opacity: isFocused ? 1 : 0,
        borderWidth: r(2),
        borderStyle: "solid",
    }
]));

interface DialogButtonProps {
    dataCy?: string;
    isDefault?: boolean;
    isFocused: boolean;
    isDisabled?: boolean;
}

const DialogButton: React.FC<DialogButtonProps> = ({ dataCy, isDefault = false, isFocused, isDisabled = false, children }) => {
    return (
        <RootDiv data-cy={dataCy}>
            {children}
            <BackgroundDiv isDefault={isDefault} isFocused={isFocused} isDisabled={isDisabled}>
                <ChildrenDiv isDisabled={isDisabled}>
                    {children}
                </ChildrenDiv>
            </BackgroundDiv>
            <FocusedBackgroundDiv isDefault={isDefault} isFocused={isFocused} isDisabled={isDisabled}>
                {children}
            </FocusedBackgroundDiv>
            <FocusedBorderDiv data-cy="focused-part" isFocused={isFocused}>
            </FocusedBorderDiv>
        </RootDiv>
    );
}

export default DialogButton;