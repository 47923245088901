import { useAppDispatch } from "../../hooks";
import ModalDialog from "./ModalDialog";
import styled from "@emotion/styled";
import t from "../../helper/t";
import SmartLink from "./SmartLink";
import { closePrivacyPolicyDialog } from "../../features/privacyPolicy/privacyPolicySlice";
import { checkPrivacyAndStartMeasurement } from "../../features/measurement/measurementSlice";

import imageCloseSvg from "../../../public/img/icons/close_black_round.svg";
import imageInfoSvg from "../../../public/img/icons/info-circle-red.svg";
import markdownRender from "../../helper/markdownRender";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",

        p: [
            {
                marginTop: 0,
                marginBottom: 12,
            }

        ],
    }
]);

const CloseButtonDiv = styled.div([
    {
        alignSelf: "end",
    }
]);

const TitleDiv = styled.div([
    props => props.theme.typography.bold32,
    props => props.theme.colors.foreground.primary,
    {
        marginTop: 18,
        marginBottom: 18,
    }
]);

const BodyDiv = styled.div(({ theme }) => ([
    theme.typography.regular14,
    theme.colors.foreground.primary,
    {
        /* for mobile */
        [theme.breakpoints.tablet]: [
            theme.typography.regular18,
            {
                textAlign: "center",
            }
        ],
    }
]));

const Image = styled.img([
    {
        marginTop: -8,
    },
    `@media (max-width: 767px) {
        display: none;
     }
    `
]);

const PrivacyPolicyDialog: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(closePrivacyPolicyDialog(false));
    }

    const onAccept = () => {
        dispatch(closePrivacyPolicyDialog(true));
        dispatch(checkPrivacyAndStartMeasurement());
    }

    return (
        <ModalDialog roundBorder={false} maxWidth={1024}>
            <RootDiv>
                <CloseButtonDiv>
                    <SmartLink href="/" onClick={onCancel}>
                        <img src={imageCloseSvg.src} alt="close" />
                    </SmartLink>
                </CloseButtonDiv>

                <Image src={imageInfoSvg.src} alt="info" />

                <TitleDiv>
                    {t("Hinweis")}
                </TitleDiv>

                <BodyDiv>
                    <div dangerouslySetInnerHTML={{ __html: markdownRender(t("Privacy Policy Content")) }} />
                </BodyDiv>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 40,
                    marginBottom: 40
                }}>
                    <div className="button-rectangle-left rectangle-grey" onClick={onAccept}>
                        <div data-cy="button-tips" className="button-rectangle-text white-label">
                            {t("Speedtest Plus starten")}
                        </div>
                    </div>
                </div>

                <BodyDiv>
                    <div dangerouslySetInnerHTML={{ __html: markdownRender(t("Privacy Policy Footer")) }} />
                </BodyDiv>
            </RootDiv>
        </ModalDialog>
    );
}

export default PrivacyPolicyDialog;