import styled from "@emotion/styled";
import MainCard from "../elements/MainCard";
import Accordion from "../elements/Accordion";
import markdownRender from "../../../helper/markdownRender";
import { fixBodyUrl } from "../../../helper/utils";
import t from "../../../helper/t";
import { currentLangIs } from "../../../helper/utils";
import { useEffect, useState } from "react";

import faq01de from "../../../../public/assets/faqs3/de/faq01.json";
import faq01de_m from "../../../../public/assets/faqs3/de/faq01m.json";
import faq02de from "../../../../public/assets/faqs3/de/faq02.json";
import faq02de_m from "../../../../public/assets/faqs3/de/faq02m.json";
import faq03de from "../../../../public/assets/faqs3/de/faq03.json";
import faq04de from "../../../../public/assets/faqs3/de/faq04.json";
import faq05de from "../../../../public/assets/faqs3/de/faq05.json";
import faq06de from "../../../../public/assets/faqs3/de/faq06.json";
import faq07de from "../../../../public/assets/faqs3/de/faq07.json";

import faq01en from "../../../../public/assets/faqs3/en/faq01.json";
import faq01en_m from "../../../../public/assets/faqs3/en/faq01m.json";
import faq02en from "../../../../public/assets/faqs3/en/faq02.json";
import faq02en_m from "../../../../public/assets/faqs3/en/faq02m.json";
import faq03en from "../../../../public/assets/faqs3/en/faq03.json";
import faq04en from "../../../../public/assets/faqs3/en/faq04.json";
import faq05en from "../../../../public/assets/faqs3/en/faq05.json";
import faq06en from "../../../../public/assets/faqs3/en/faq06.json";
import faq07en from "../../../../public/assets/faqs3/en/faq07.json";

const faqListDe = [faq01de, faq02de, faq03de, faq04de, faq05de, faq06de, faq07de];
const faqListMobileDe = [faq01de_m, faq02de_m, faq03de, faq04de, faq05de, faq06de, faq07de];
const faqListEn = [faq01en, faq02en, faq03en, faq04en, faq05en, faq06en, faq07en];
const faqListMobileEn = [faq01en_m, faq02en_m, faq03en, faq04en, faq05en, faq06en, faq07en];

interface FaqJson {
    question: string;
    body: string;
}

const RootDiv = styled(MainCard)(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",

        paddingLeft: 32,
        paddingRight: 16,
        paddingTop: 40,
        paddingBottom: 48,

        rowGap: 32,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            padding: 0,
            rowGap: 12,
        },
    }
]));

const DesktopContainer = styled.div(({ theme }) => ([
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "none",
            }
        ],
    }
]));

const MobileContainer = styled.div(({ theme }) => ([
    {
        display: "none",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "initial",
            }
        ],
    }
]));


const TextH2Div = styled.div(({ theme }) => ([
    theme.splus3.foreground.primary,
    theme.splus3.typography.h3,
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.foreground.monochrome600,
            theme.splus3.typography.defaultBold,
            {
                lineHeight: "155.6%",
            }
        ],
    }
]));

interface FaqHeaderDivProps {
    isExpanded: boolean;
}

const FaqHeaderDiv = styled.div<FaqHeaderDivProps>(({ theme, isExpanded }) => ([
    theme.splus3.foreground.primary,
    isExpanded ? theme.splus3.typography.largeBold : theme.splus3.typography.large,
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            isExpanded ? theme.splus3.typography.font16Bold : theme.splus3.typography.font16Lite,
            {
                lineHeight: "137.5%",
            }
        ],
    }
]));

const FaqContentDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.large,
    theme.splus3.foreground.primary,
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font12Lite,
            theme.splus3.foreground.monochrome600,
        ],

        strong: [
            theme.splus3.typography.bold,
        ],

        img: [
            {
                maxWidth: "min(100%, 600px)",
                display: "block",
                margin: "auto",
            }
        ],

        p: [
            {
                marginTop: 0,
                marginBottom: 8,
            }

        ],

        ul: [
            {
                paddingLeft: 20,
                margin: 0,
            }
        ],

        li: [
            {
                marginLeft: 0,
                marginBottom: 8,
            }
        ],

        a: [
            theme.splus3.foreground.monochrome500,
            {
            }
        ]
    }
]));

interface FaqCardProps {

}

const FaqCard: React.FC<FaqCardProps> = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null | undefined>(undefined);

    const getItemHeader = (item: FaqJson, isExpanded: boolean) => {
        return (
            <FaqHeaderDiv isExpanded={isExpanded}>
                {item.question}
            </FaqHeaderDiv>
        )
    }

    const getItemContent = (item: FaqJson) => {
        return <FaqContentDiv dangerouslySetInnerHTML={{ __html: markdownRender(fixBodyUrl(item.body)) }} />
    }

    const onItemClick = (index: number) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    }

    useEffect(() => {
        // This is needed to make the accordion fully open when JavaScript is disabled in the browser
        setExpandedIndex(null);
    }, []);

    return (
        <RootDiv>
            <TextH2Div>
                {t("Häufige Fragen zum Speedtest")}
            </TextH2Div>
            <DesktopContainer>
                <Accordion<FaqJson> items={currentLangIs("en") ? faqListEn : faqListDe}
                    expandedIndex={expandedIndex}
                    getItemHeader={getItemHeader}
                    getItemContent={getItemContent}
                    onItemClick={onItemClick}
                />
            </DesktopContainer>
            <MobileContainer>
                <Accordion<FaqJson> items={currentLangIs("en") ? faqListMobileEn : faqListMobileDe}
                    expandedIndex={expandedIndex}
                    getItemHeader={getItemHeader}
                    getItemContent={getItemContent}
                    onItemClick={onItemClick}
                />
            </MobileContainer>
        </RootDiv>
    )
}

export default FaqCard;