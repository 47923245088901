import { createNqError } from "@visonum/network-quality-sdk";
import { format } from "date-fns";
import FileSaver from "file-saver";
import { AppThunk } from "../../store";
import { reportNQError } from "../errors/errorSlice";
import { logger } from '../../helper/logger';
import { NQRecord } from '../history/types';
import getSpeedtestId from "../../helper/utils/getSpeedtestId";
import { plainDateToDate } from "../../types";
import { runPrintRequest } from "src/pdf-report";


export const printReport = (record: NQRecord): AppThunk<Promise<void>> => async (dispatch) => {
    try {
        const blob = await runPrintRequest(record);
        const fileName = `speedtestplus-${format(plainDateToDate(record.date), "yyyy-MM-dd")}-${getSpeedtestId(record.prepareResult)}.pdf`;
        logger.info(`Saving file: ${fileName}`);
        FileSaver.saveAs(blob, fileName);
    } catch (err) {
        logger.error("Print Report", err);
        dispatch(reportNQError(createNqError("Cl-12", "Print Report Saving Error", err)));
    }
}

