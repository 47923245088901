import styled from "@emotion/styled";
import { StaticImageData } from "next/image";
import { formatSpeed } from "../../../helper/formatSpeeds";
import { useAppSelector } from "../../../hooks";
import { selectPrepareResult } from "../../../features/measurement/utils";
import { nullOrValue } from "../../../helper/utils";
import { FadingTextDiv } from "./FadingText";
import { r } from "../../../theme/gigatv/sizeUtils";
import { vodafoneRed } from "../../../theme/common";

import downloadIconSvg from "../../../../assets/icons/common/download-icon.svg";
import uploadIconSvg from "../../../../assets/icons/common/upload-icon.svg";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
    }
]);

const TopLineDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.red,
    {
        display: "flex",
        justifyContent: "center",
    }
]));

const TextContainerDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize24,
    theme.gigatv.typography.bold,
    {
        display: "flex",
        columnGap: r(16),
        alignItems: "center",
        justifyContent: "center",
        height: r(56),
        alignSelf: "stretch",
    },
]));

const TriangleDiv = styled.div([
    {
        alignSelf: "center",
    }
]);

interface TariffInfoProps {
}

const TariffInfo: React.FC<TariffInfoProps> = () => {
    const state = useAppSelector((state) => state.measurement);
    const prepareResult = selectPrepareResult(state);
    const bookedDownloadSpeed = nullOrValue(prepareResult?.init.modem?.bookedDownloadSpeedMax);
    const bookedUploadSpeed = nullOrValue(prepareResult?.init.modem?.bookedUploadSpeedMax);

    return (
        <RootDiv>
            <TopLineDiv>
                <TextContainerDiv>
                    Dein Tarif:
                    <TariffInfoItem speed={bookedDownloadSpeed} icon={downloadIconSvg} alt="download" />
                    <TariffInfoItem speed={bookedUploadSpeed} icon={uploadIconSvg} alt="upload" />
                </TextContainerDiv>
            </TopLineDiv>

            <TriangleDiv>
                <svg width="64" height="32" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 32L64 0H0L32 32Z" fill={vodafoneRed} />
                </svg>
            </TriangleDiv>
        </RootDiv>
    )
}

const ValueContainerDiv = styled.div([
    {
        display: "flex",
        columnGap: r(8),
        alignItems: "center",
        letterSpacing: "3%",
    }
]);

const TariffValueContainerDiv = styled.div([
    {
        display: "flex",
        alignItems: "center",
    }
]);

const TariffValueGrid = styled.div([
    {
        display: "flex",
    }
]);

const TariffUnitDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.regular,
]));

const ImageDiv = styled.div([
    {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: r(24),
        height: r(24),
    }
]);

interface TariffInfoItemProps {
    speed: number | null;
    icon: StaticImageData;
    alt: string;
}

const TariffInfoItem: React.FC<TariffInfoItemProps> = ({ speed, icon, alt }) => {
    const speedValues = formatSpeed([speed]);

    return (
        <ValueContainerDiv>
            <ImageDiv>
                <img width="88%" height="88%" src={icon.src} alt={alt} />
            </ImageDiv>
            <TariffValueContainerDiv>
                <TariffSpeed speed={speed} />
                &nbsp;
                <TariffUnitDiv>
                    {speedValues.unit}
                </TariffUnitDiv>
            </TariffValueContainerDiv>
        </ValueContainerDiv>
    )
}

interface TariffSpeedProps {
    speed: number | null;
}

const TariffSpeed: React.FC<TariffSpeedProps> = ({ speed }) => {
    const speedValues = formatSpeed([speed]);

    return (
        <TariffValueGrid>
            {speed === null ? (
                <FadingTextDiv stage="Normal">
                    --
                </FadingTextDiv>
            ) : (
                <FadingTextDiv stage="FadeIn">
                    {speedValues.values[0]}
                </FadingTextDiv>
            )}
        </TariffValueGrid>
    )
}

export default TariffInfo;