import React, { CSSProperties, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { isSpeedtestRunning } from "../../../features/measurement/utils";
import CancellationGuard from "../../cancellation/CancellationGuard";
import styled from "@emotion/styled";
import t from "../../../helper/t";
import SmartLink from "../../elements/SmartLink";

import imageNewVfIconRgbRed from "../../../../assets/images/New_VF_Icon_RGB_RED.svg";
import imagePlayArrow from "../../../../public/img/play-arrow.svg";
import imageIdeaOrInnovation from "../../../../public/img/idea-or-innovation.svg";
import imageChartBar from "../../../../public/img/chart-bar.svg";
import imageInfoCircle from "../../../../public/img/info-circle.svg";
import imageTvMultiscreen from "../../../../public/img/TV_multiscreen.svg";

interface NavbarButtonProps {
  href: string;
  imageSrc: string;
  alt: string;
  caption: string;
}

const NavbarButton: React.FC<NavbarButtonProps> = ({ href, imageSrc, alt, caption }) => {
  return (
    <li className="navbar-link">
      <SmartLink href={href}>
        <div style={{ height: "100%" }}>
          <img src={imageSrc} alt={alt} />
          <label>{caption}</label>
        </div>
      </SmartLink>
    </li>
  );
}

const NavDiv = styled.div([
  props => props.theme.colors.background.primary,
  {
    padding: "10px 0px",
    borderBottom: "1px solid #eaeaea",
  }
]);

const NavContentDiv = styled.div(({ theme }) => ([
  theme.colors.background.primary,
  {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    height: 50,

    [theme.breakpoints.tablet]: {
      height: 70,
    },
  }
]));

interface ContentDivProps {
  column: number;
  justifyContent?: "start" | "end",
}

const ContentDiv = styled.div<ContentDivProps>([
  props => ({
    gridColumnStart: props.column,
    justifyContent: props.justifyContent,
  }),
  {
    display: "flex",
    alignItems: "center",
  }
])

const NavLogoImg = styled.img([
  {
    gridColumnStart: 2,
    paddingTop: 6,
    width: 48,
    cursor: "pointer",
  }
]);

interface HamburgerMenuProps {
  isOpened: boolean;
  isDisabled: boolean;
}

const LineStyle: CSSProperties = {
  fill: "none",
  transition: "stroke-dasharray 400ms, stroke-dashoffset 400ms",
  stroke: "#000",
  strokeWidth: 4.5,
  strokeLinecap: "round"
};

const DisabledStyle: CSSProperties = {
  stroke: "lightgray"
}

const TopStyle: CSSProperties = {
  strokeDasharray: "40 160"
}

const TopActiveStyle: CSSProperties = {
  strokeDashoffset: -64
}

const MiddleStyle: CSSProperties = {
  strokeDasharray: "40 142",
  transformOrigin: "50%",
  transition: "transform 400ms",
}

const MiddleActiveStyle: CSSProperties = {
  transform: "rotate(90deg)",
}

const BottomStyle: CSSProperties = {
  strokeDasharray: "40 85",
  transformOrigin: "50%",
  transition: "transform 400ms, stroke-dashoffset 400ms",
}

const BottomActiveStyle: CSSProperties = {
  strokeDashoffset: -64
}

const HamburgerRootStyle: CSSProperties = {
  transition: "transform 400ms",
  cursor: "pointer",
}

const RotateStyle: CSSProperties = {
  transform: "rotate(45deg)"
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ isOpened, isDisabled }) => {
  return (
    <svg preserveAspectRatio={isOpened ? "xMidYMid" : "none"} style={{ ...HamburgerRootStyle, ...isOpened ? RotateStyle : {} }} viewBox="0 0 100 100" width={56} height={isOpened ? 56 : 44}>
      <path style={{ ...LineStyle, ...TopStyle, ...isOpened ? TopActiveStyle : {}, ...isDisabled ? DisabledStyle : {} }}
        d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
      <path style={{ ...LineStyle, ...MiddleStyle, ...isOpened ? MiddleActiveStyle : {}, ...isDisabled ? DisabledStyle : {} }}
        d="m 30,50 h 40" />
      <path style={{ ...LineStyle, ...BottomStyle, ...isOpened ? BottomActiveStyle : {}, ...isDisabled ? DisabledStyle : {} }}
        d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
    </svg>
  )
}

const Navbar: React.FC<{}> = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const measurementState = useAppSelector(state => state.measurement);

  function toggleMenu() {
    if (isSpeedtestRunning(measurementState)) {
      setMenuOpen(false)
    } else {
      setMenuOpen(x => !x)
    }
  }

  return (
    <header>
      <NavDiv>
        <NavContentDiv>
          <ContentDiv column={2} justifyContent="start">
            <CancellationGuard>
              <SmartLink href="/" isEnabled={!isSpeedtestRunning(measurementState)}>
                <NavLogoImg
                  src={imageNewVfIconRgbRed.src}
                  alt="vf-logo"
                />
              </SmartLink>
            </CancellationGuard>
          </ContentDiv>
          <ContentDiv column={12} justifyContent="end">
            <CancellationGuard>
              <div style={{ marginTop: 6 }} onClick={toggleMenu}>
                <HamburgerMenu isOpened={isMenuOpen} isDisabled={isSpeedtestRunning(measurementState)} />
              </div>
            </CancellationGuard>
          </ContentDiv>
        </NavContentDiv>
      </NavDiv>
      <ul className={`navbar-list ${isMenuOpen ? "navbar-list-open" : ""}`} onClick={() => setMenuOpen(false)}>
        <NavbarButton href="/" imageSrc={imagePlayArrow.src} alt="startseite" caption={t("Startseite")} />
        <NavbarButton href="/tipps" imageSrc={imageIdeaOrInnovation.src} alt="tipps" caption={t("Tipps zur Optimierung")} />
        <NavbarButton href="/report" imageSrc={imageChartBar.src} alt="report" caption={t("Testergebnisse")} />
        <NavbarButton href="/know_how" imageSrc={imageInfoCircle.src} alt="know_how" caption={t("Was macht der Speedtest Plus?")} />
        <NavbarButton
          href="https://zuhauseplus.vodafone.de/internet-telefon/?icmp=speedtest:internettelefon"
          imageSrc={imageTvMultiscreen.src}
          alt="weiter_produkte"
          caption={t("Weitere Produkte")}
        />
      </ul>
    </header>
  )
};

export default Navbar;

