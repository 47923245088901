import { NQError, NQHint, createNqError, isNqError } from "@visonum/network-quality-sdk";
import { PartialServiceCallbackTipId, PartialServiceRebootTipId } from "./hintsVirtualIds";
import { NetworkImprovementTipsStatus, PartialServiceState, TipStatus } from "../partialService/partialServiceUtils";
import { NQRecord } from "../history/types";
import { LangName } from "../../types";
import HintJson from "./HintJson";
import getHints from "./getHints";
import * as R from "ramda";
import { logger } from "../../helper/logger";
import { replaceHintVariableByValue } from "./hintsVariables";
import { fixBodyUrl, fixImageUrl, getCurrentLang, nullOrValue } from "../../helper/utils";
import { lastValueFrom } from "rxjs";
import { getSdk } from "../../networkQuality";
import sentryCaptureError from "../sentryReports/sentryCaptureError";
import { RootState } from "../../store";
import { getAllRecords } from "../history/getAllRecords";
import Hint3Json from "./Hint3Json";
import getHints3 from "./getHints3";

export const getServerHints = async (speedtestId?: string): Promise<NQHint[]> => {
    try {
        return await lastValueFrom(getSdk().hints(speedtestId));
    } catch (err) {
        logger.debug("getServerHints failed", err);

        if (isNqError(err)) {
            sentryCaptureError(err as NQError);
        } else {
            const nqError = createNqError("Cl-17", "Tips request has failed.", err);
            sentryCaptureError(nqError);
        }
        return [];
    }
}

/* pure */
export const serverHintsToClientHintIds = (nqHints: NQHint[], partialServiceState: PartialServiceState): number[] => {
    const networkImprovementsIds = [10, 11, 16, 17];

    const initialHintIds = [
        (partialServiceState.callbackTipStatus === TipStatus.Visible) ? [PartialServiceCallbackTipId] : [],
        (partialServiceState.modemResetTipStatus === TipStatus.Visible) ? [PartialServiceRebootTipId] : [],
        // (VARIANT === "SPLUS3") ? [DeviceFastAsItCanTipId] : []
    ].flat();

    return [
        ...initialHintIds,
        ...nqHints
            .map((h, index) => ({ id: h.id, index }))
            .filter(x => {
                if (partialServiceState.networkImprovementTipsStatus === NetworkImprovementTipsStatus.Hidden &&
                    networkImprovementsIds.includes(x.id)) {
                    return false;
                }
                return true;
            })
            .sort((a, b) => {
                if (partialServiceState.networkImprovementTipsStatus === NetworkImprovementTipsStatus.OnFirstPlace) {
                    if (networkImprovementsIds.includes(a.id) && networkImprovementsIds.includes(b.id)) {
                        return a.index - b.index;
                    }
                    if (networkImprovementsIds.includes(a.id)) {
                        return -1;
                    }
                    if (networkImprovementsIds.includes(b.id)) {
                        return 1;
                    }
                }
                return a.index - b.index;
            })
            .map(x => x.id)
    ];
}

const hintIdToHintObject = (id: number, lastRecord: NQRecord | null, lang: LangName): HintJson | null => {
    const allHints = getHints(lang);

    const h = allHints.find(h => h.id === id);

    if (R.isNil(h)) {
        const nqError = createNqError("Cl-16", `Tip is not found by Id = ${id}`);
        logger.warn(`Tip #${id} not found.`, nqError);
        sentryCaptureError(nqError);
        return null;
    }

    const teaser = replaceHintVariableByValue(h.teaser, lastRecord);
    if (R.isNil(teaser)) {
        return null;
    }
    const content = R.isNil(h.content) ? h.content : replaceHintVariableByValue(h.content, lastRecord);

    return {
        ...h,
        titleImageUrl: fixImageUrl(h.titleImageUrl)!,
        teaser: fixBodyUrl(teaser),
        teaserImageUrl: fixImageUrl(h.teaserImageUrl),
        content: R.isNil(content) ? undefined : fixBodyUrl(content),
    }
}

const hintIdToHint3Object = (id: number, lastRecord: NQRecord | null, lang: LangName): Hint3Json | null => {
    const allHints = getHints3(lang);

    const h = allHints.find(h => h.id === id);

    if (R.isNil(h)) {
        const nqError = createNqError("Cl-16", `Tip is not found by Id = ${id}`);
        logger.warn(`Tip #${id} not found.`, nqError);
        sentryCaptureError(nqError);
        return null;
    }

    const quickTipBoxBody = replaceHintVariableByValue(h.quickTipBoxBody, lastRecord);
    const expandedTipBody = replaceHintVariableByValue(h.expandedTipBody, lastRecord);
    const expandedTipSublineBody = replaceHintVariableByValue(h.expandedTipSublineBody, lastRecord);
    if (quickTipBoxBody === null || expandedTipBody === null || expandedTipSublineBody === null) {
        return null;
    }

    return {
        ...h,
        quickTipBoxBody: fixBodyUrl(quickTipBoxBody),
        expandedTipBody: fixBodyUrl(expandedTipBody),
        expandedTipSublineBody: fixBodyUrl(expandedTipSublineBody),
    }
}

export const hintIdsToHintObjects = (hintIds: number[], lastRecord: NQRecord | null, lang: LangName): HintJson[] =>
    hintIds
        .map(id => hintIdToHintObject(id, lastRecord, lang))
        .filter(h => h !== null)
        .map(h => h!);

export const hintIdsToHint3Objects = (hintIds: number[], lastRecord: NQRecord | null, lang: LangName): Hint3Json[] =>
    hintIds
        .map(id => hintIdToHint3Object(id, lastRecord, lang))
        .filter(h => h !== null)
        .map(h => h!);

export const getActualHints = (state: RootState): HintJson[] => {
    const result = hintIdsToHintObjects(
        state.hints.hintIds,
        nullOrValue(getAllRecords(state.history)[0]),
        getCurrentLang()
    );

    return result;
}

export const getCurrentHint = (state: RootState): Hint3Json | null => {
    if (state.hints.currentIndex === null) {
        return null;
    }

    const hintId = state.hints.hintIds[state.hints.currentIndex];
    if (R.isNil(hintId)) {
        logger.warn("Tip Id is not found by index.", { currentIndex: state.hints.currentIndex, hintIds: state.hints.hintIds });
        sentryCaptureError(createNqError("Cl-15", `Tip Id is not found by index (${state.hints.currentIndex})`));
        return null;
    }

    const hint = hintIdToHint3Object(hintId, nullOrValue(getAllRecords(state.history)[0]), getCurrentLang());

    if (R.isNil(hint)) {
        logger.warn("Tip is not found by Id", { hintId });
        sentryCaptureError(createNqError("Cl-16", `Tip is not found by Id (${hintId})`));
        return null;
    }

    return hint;
}