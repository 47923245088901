import { useAppSelector } from "../../../src/hooks";
import CancellationDialog from "../../components/cancellation/CancellationDialog";
import { DialogKind } from "../../features/dialogManager/dialogManagerSlice";
import ErrorDialog from "../../features/errors/ErrorDialog";
import { AppAssertionError } from "../../features/errors/AppAssertionError";
import { devAssert } from "../../features/errors/devAssert";

const ScaDialogSwitcher: React.FC<{}> = () => {
    const state = useAppSelector(state => state.dialogManager);

    switch (state.kind) {
        case DialogKind.None:
            return null;
        case DialogKind.Error:
            return <ErrorDialog lastError={state.error} />;
        case DialogKind.SpeedtestCancellation:
            return <CancellationDialog />
        case DialogKind.PartialService:
        case DialogKind.PrivacyPolicy:
        case DialogKind.Consent:
        case DialogKind.RemoveHistory:
        case DialogKind.ModemSwap:
            devAssert(new AppAssertionError({ message: "Wrong dialog kind.", actual: state.kind }), `Wrong dialog kind: ${state.kind}`);
            return null;
    }
}

export default ScaDialogSwitcher;