import React, { HTMLAttributeAnchorTarget, ReactElement, useState } from "react";
import styled from "@emotion/styled";
import t from "../../../helper/t";
import LanguageKey from "../../../../generated/types/LanguageKey";
import LanguageSwitch from "./LanguageSwitch";
import SmartLink from "../../elements/SmartLink";
import { useAppDispatch } from "../../../hooks";
import FooterShowVersions from "./FooterShowVersions";
import { showConsentDialog } from "../../../features/dialogManager/dialogManagerSlice";

const FooterNavItem = styled.div([
  props => (
    props.theme.typography.regular16,
    props.theme.colors.lightGrey
  )
]);

interface FooterLinkProps {
  href: string;
  t_key: LanguageKey;
  target?: HTMLAttributeAnchorTarget;
}

const FooterLink: React.FC<FooterLinkProps> = ({ href, t_key }) => {
  return (
    <SmartLink href={href}>
      <FooterNavItem>
        {t(t_key)}
      </FooterNavItem>
    </SmartLink>
  )
}

const FooterRootDiv = styled.div([
  {
    width: "100%",
    minHeight: 60,
    backgroundColor: "#2e2e2e",
    display: "flex",
    flexWrap: "wrap-reverse",
    alignItems: "center",
  },
]);

const FooterLinksDiv = styled.div([
  {
    flexGrow: 10,
    display: "flex",
    flexWrap: "wrap",
    columnGap: 20,
    justifyContent: "center",
    marginLeft: 12,
    marginRight: 12,
  },
  `
  @media (max-width: 428px) {
    font-size: 12px;
    column-gap: 10px;
  }
  `
]);

const Footer = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [numClicks, setNumClicks] = useState(0);
  const isVersionVisible = (numClicks >= 5) && (numClicks % 2 === 1);

  return (
    <footer onClick={() => { setNumClicks(n => n + 1) }}>
      <FooterRootDiv>
        <div style={{ justifySelf: "start", marginLeft: 12, marginTop: 8, marginBottom: 8 }}>
          <LanguageSwitch />
        </div>
        <FooterLinksDiv>
          <FooterLink href="https://www.vodafone.de/" t_key="Vodafone GmbH" />
          <FooterLink href="https://www.vodafone.de/agb.html" t_key="AGB" />
          <FooterLink href="https://www.vodafone.de/impressum.html" t_key="Impressum" />
          <FooterLink href="https://www.vodafone.de/unternehmen/soziale-verantwortung/datenschutz-privatsphaere.html" t_key="Datenschutz" />
          <FooterLink href="/privacy_policy_faq" t_key="Speedtest Datenschutz" />
          <FooterNavItem style={{ cursor: "pointer" }} onClick={() => dispatch(showConsentDialog())}>
            {t("Speedtest Cookies")}
          </FooterNavItem>
          <FooterLink href="/cookie_policy" t_key="Cookie Policy" />
        </FooterLinksDiv>
      </FooterRootDiv>
      <FooterShowVersions isVisible={isVersionVisible} />
    </footer>
  );
}

export default Footer;
