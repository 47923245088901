import styled from "@emotion/styled";
import { BenchmarkLevel, currentLangIs, getBenchmatkLevel, nullOrValue, fixBodyUrl } from "../../../../helper/utils";
import { formatSpeed } from "../../../../helper/formatSpeeds";
import { formatDate, formatTime } from "../../../../helper/unitsFormatting";
import t from "../../../../helper/t";
import * as R from "ramda";
import HeaderWithTooltip from "../../elements/HeaderWithTooltip";
import { Models } from "@visonum/network-quality-sdk";
import { getAllRecords } from "../../../../features/history/getAllRecords";
import { useAppSelector } from "../../../../hooks";
import NoDataStub from "../../elements/NoDataStub";
import markdownRender from "../../../../helper/markdownRender";
import { connectionToStr, currentToStr } from "../utils";
import CommonContainer from "../../elements/CommonContainer";
import CopyableData from "../../elements/CopyableData";
import MainCard from "../../elements/MainCard";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";
import { getDeviceText } from "../../../../helper/getDeviceText";

import InfoCircleSvg from "../../../../../assets/icons/splus3/info-circle.svg";
import benchmarkJsonDe from "../../../../../public/assets/tooltips/de/benchmark.json";
import benchmarkJsonEn from "../../../../../public/assets/tooltips/en/benchmark.json";
import cmtsJsonDe from "../../../../../public/assets/tooltips/de/cmts.json";
import cmtsJsonEn from "../../../../../public/assets/tooltips/en/cmts.json";
import {VARIANT} from "../../../../config";

const MainTableDiv = styled(CommonContainer)(({ theme }) => ([
    theme.splus3.background.primary,
    theme.splus3.foreground.monochrome600,
    {
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 20,
        paddingRight: 20,
        display: "flex",
        flexDirection: "column",
        rowGap: 24,
        lineHeight: "130%",
        // background: "blue",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                paddingTop: 24,
                paddingBottom: 78,
                paddingLeft: 16,
                paddingRight: 16,
            }
        ],
    }
]));

const InnerTableDiv = styled.div(({}) => ([
    {
        display: "flex",
        flexDirection: "column",
    }
]));

const TableHeaderDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.default,
    {
        marginBottom: 16,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font16,
            {
                marginBottom: 12,
            }
        ],
    }
]));

const CellRow = styled.div(({ theme }) => ([
    theme.splus3.typography.default,
    {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        columnGap: 16,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.small,
            {
                paddingLeft: 8,
                paddingRight: 8,
                columnGap: 8,
            }
        ],
    }
]));

const HeaderRow = styled(CellRow)(({ theme }) => ([
    theme.splus3.typography.bold,
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.smallBold,
        ],
    }
]));

const CellRowNetwork = styled(CellRow)(({ theme }) => ([
    {
        gridTemplateColumns: "1fr 1.8fr",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "flex",
                columnGap: 8,
                justifyContent: "space-between",
            }
        ],
    }
]));

const TableDivider = styled.line(({ theme }) => ([
    theme.splus3.background.monochrome600,
    {
        display: "block",
        height: 1,
    }
]));

const TooltipContentDiv = styled.div(({ theme }) => ([
    {
        width: 320,

        p: [
            {
                margin: 0,
            }
        ],

        strong: [
            theme.splus3.typography.bold,
        ],

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                width: "auto",
            }
        ],
    },
]));

const Card = styled(MainCard)(({ theme }) => ([
    {
        paddingTop: 20,
        paddingBottom: 28,
        paddingLeft: 20,
        paddingRight: 20,


        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                padding: 0,
            }
        ],
    }
]));

interface AdditionalInfoProps {
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({ }) => {
    const historyState = useAppSelector(state => state.history);
    const sortedRecords = getAllRecords(historyState);
    const record = sortedRecords[0];

    if (R.isNil(record)) {
        return <NoDataStub message={t("Es sind keine Messungen vorhanden")} />
    } else {
        const initModem = record.prepareResult.init.modem;

        const bookedDownloadSpeedMin = nullOrValue(initModem.bookedDownloadSpeedMin);
        const bookedDownloadSpeedAvg = nullOrValue(initModem.bookedDownloadSpeedAvg);
        const bookedDownloadSpeedMax = nullOrValue(initModem.bookedDownloadSpeedMax);
        const bookedUploadSpeedMin = nullOrValue(initModem.bookedUploadSpeedMin);
        const bookedUploadSpeedAvg = nullOrValue(initModem.bookedUploadSpeedAvg);
        const bookedUploadSpeedMax = nullOrValue(initModem.bookedUploadSpeedMax);

        const { values, unit } = formatSpeed([
            bookedDownloadSpeedMin,
            bookedDownloadSpeedAvg,
            bookedDownloadSpeedMax,
            bookedUploadSpeedMin,
            bookedUploadSpeedAvg,
            bookedUploadSpeedMax]);
        const [downMin, downAvg, downMax, upMin, upAvg, upMax] = values;

        const firstTableData = [
            { caption: t("Maximal"), downValue: downMax, upValue: upMax },
            { caption: t("Üblich"), downValue: downAvg, upValue: upAvg },
            { caption: t("Minimal"), downValue: downMin, upValue: upMin },
        ];

        const browser = record.prepareResult.init.client.browser;

        const getBenchmarkStr = (): string | null => {
            const benchmarkLevel = getBenchmatkLevel(record.benchmarkFinal);
            if (benchmarkLevel === null) {
                return null;
            }

            switch (benchmarkLevel) {
                case BenchmarkLevel.Low: return t("niedrig");
                case BenchmarkLevel.Medium: return t("mittel");
                case BenchmarkLevel.High: return t("hoch");
            }
        }

        const getConnectionTypeStr = () => connectionToStr(record.modemFinal.connectionType);

        const getConnectionTypeValue = (): string | null => {
            const connectionType = record.modemFinal.connectionType;
            if (connectionType === null) {
                return null;
            }

            switch (connectionType) {
                case Models.SnmpResultModelConnectionTypeEnum.Lan:
                case Models.SnmpResultModelConnectionTypeEnum.Wlan5:
                    return t("optimal");
                case Models.SnmpResultModelConnectionTypeEnum.Wlan24:
                    return t("beste Reichweite, geringere Geschwindigkeit");
            }
        }

        const isEn = currentLangIs("en");
        const benchmarkData = isEn ? benchmarkJsonEn : benchmarkJsonDe;
        const cmtsData = isEn ? cmtsJsonEn : cmtsJsonDe;

        const networkTableData = [
            {
                caption: getDeviceText(record.prepareResult, benchmarkData.text),
                value: getBenchmarkStr(),
                hintHeader: benchmarkData.hintHeader,
                hintContent: benchmarkData.hintContent,
            },
            ...(VARIANT !== "SFA" ? [{ caption: `${t("Dein Internet-Browser")}: ${browser.name} ${browser.version}`, value: currentToStr(browser.isCurrent) }] : []),
            { caption: t("Dein Router"), value: nullOrValue(record.prepareResult.init.modem.name) },
            { caption: `${t("Netzwerkverbindung")}: ${getConnectionTypeStr()}`, value: getConnectionTypeValue() },
            { caption: t("Speedtest-ID"), value: getSpeedtestId(record.prepareResult), isClipboardEnabled: true },
            { caption: t("IP-Adresse"), value: record.prepareResult.init.connection.ip },
            { caption: t("Anbieter"), value: nullOrValue(record.prepareResult.init.connection.isp) },
            {
                caption: cmtsData.text,
                value: nullOrValue(record.prepareResult.init.cmts?.vendor),
                hintHeader: cmtsData.hintHeader,
                hintContent: cmtsData.hintContent
            },
            { caption: t("Durchgeführt"), value: `${formatDate(record.date)}, ${formatTime(record.date)} ${t("Uhr")}` },
        ];

        const formatSpeedValue = (value?: string | null): string => {
            if (R.isNil(value)) {
                return "--";
            } else {
                return value;
            }
        }

        return (
            <MainTableDiv>
                {values.find(x => !R.isNil(x)) && (
                    <Card>
                        <InnerTableDiv>
                            <TableHeaderDiv>
                                {t("Diese Geschwindigkeit ist mit Deinem Tarif möglich")}
                            </TableHeaderDiv>
                            <HeaderRow>
                                <span>{t("Geschwindigkeit")}</span>
                                <span>{t("Im Download")}</span>
                                <span>{t("Im Upload")}</span>
                            </HeaderRow>
                            <TableDivider />
                            {firstTableData.map((data, index) =>
                                <div key={`firstCell${index}`}>
                                    <CellRow data-cy="addInfoRow">
                                        <span>{data.caption}</span>
                                        <span>{formatSpeedValue(data.downValue)}&nbsp;{unit}</span>
                                        <span>{formatSpeedValue(data.upValue)}&nbsp;{unit}</span>
                                    </CellRow>
                                    <TableDivider />
                                </div>
                            )}
                        </InnerTableDiv>
                    </Card>
                )}

                <Card>
                    <InnerTableDiv>
                        <TableHeaderDiv>
                            {t("Dein Heimnetzwerk")}
                        </TableHeaderDiv>
                        {networkTableData.filter(x => x.value !== null).map((data, index) =>
                            <div key={index}>
                                <CellRowNetwork data-cy="addInfoRow">
                                    <span style={{ display: "flex", alignItems: "center" }}>
                                        {data.caption}
                                        {!R.isNil(data.hintHeader) && (
                                            <>
                                                &nbsp;
                                                <HeaderWithTooltip header={data.hintHeader} icon={InfoCircleSvg} size={20} left={-26} bottom={28} handlePosition="BottomLeft">
                                                    <TooltipContentDiv dangerouslySetInnerHTML={{ __html: markdownRender(fixBodyUrl(data.hintContent ?? "")) }}>
                                                    </TooltipContentDiv>
                                                </HeaderWithTooltip>
                                            </>
                                        )}
                                    </span>
                                    {data.isClipboardEnabled === true ? (
                                        <div style={{ display: "flex" }}>
                                            <CopyableData data={data.value}>{data.value}</CopyableData>
                                        </div>
                                    ) : (
                                        <span>{data.value}</span>
                                    )}
                                </CellRowNetwork>
                                <TableDivider />
                            </div>
                        )}
                    </InnerTableDiv>
                </Card>
            </MainTableDiv>
        );
    }
}

export default AdditionalInfo;
