import {
    BenchmarkFinalDE,
    BenchmarkIntermediateDE,
    DownloadFinalDE,
    HintsDE,
    ModemFinalDE,
    ModemIntermediateExecutingDE,
    ModemIntermediateResolvingDE,
    PingFinalDE,
    PrepareResultDE,
    UploadFinalDE
} from "@visonum/network-quality-sdk";
import * as standalone from "@fusion/fusion/dist/output/Fusion.Entrypoint.VelocityUI";

export enum Phase {
    NotStarted = "NotStarted",
    Preparing = "Preparing",
    Benchmarking = "Benchmarking",
    Downloading = "Downloading",
    Uploading = "Uploading",
    Pinging = "Pinging",
    Completing = "Completing",
    ModemResolving = "ModemResolving",
    ModemExecuting = "ModemExecuting",
    CompletingNoModem = "CompletingNoModem",
    CompletingWithModem = "CompletingWithModem",
    FinishedNoModem = "FinishedNoModem",
    FinishedWithModem = "FinishedWithModem",
    Aborting = "Aborting",
}

export type MeasurementState = {
    kind: Phase.Aborting,
} | {
    kind: Phase.NotStarted,
} | {
    kind: Phase.Preparing,
} | {
    kind: Phase.Benchmarking,
    prepareResult: PrepareResultDE,
    benchmark: BenchmarkIntermediateDE | undefined,
} | {
    kind: Phase.Downloading,
    prepareResult: PrepareResultDE,
    benchmark: BenchmarkFinalDE,
    downloadIntermediates: number[],
    loadResult: standalone.LoadResult
} | {
    kind: Phase.Uploading,
    prepareResult: PrepareResultDE,
    benchmark: BenchmarkFinalDE,
    downloadIntermediates: number[],
    download: DownloadFinalDE,
    uploadIntermediates: number[],
} | {
    kind: Phase.Pinging,
    prepareResult: PrepareResultDE,
    benchmark: BenchmarkFinalDE,
    downloadIntermediates: number[],
    download: DownloadFinalDE,
    uploadIntermediates: number[],
    upload: UploadFinalDE,
    pingIntermediates: number[],
} | {
    kind: Phase.Completing,
    prepareResult: PrepareResultDE,
    benchmark: BenchmarkFinalDE,
    downloadIntermediates: number[],
    download: DownloadFinalDE,
    uploadIntermediates: number[],
    upload: UploadFinalDE,
    pingIntermediates: number[],
    ping: PingFinalDE,
} | {
    kind: Phase.ModemResolving,
    prepareResult: PrepareResultDE,
    benchmark: BenchmarkFinalDE,
    downloadIntermediates: number[],
    download: DownloadFinalDE,
    uploadIntermediates: number[],
    upload: UploadFinalDE,
    pingIntermediates: number[],
    ping: PingFinalDE,
    modem: ModemIntermediateResolvingDE | undefined,
} | {
    kind: Phase.ModemExecuting,
    prepareResult: PrepareResultDE,
    benchmark: BenchmarkFinalDE,
    downloadIntermediates: number[],
    download: DownloadFinalDE,
    uploadIntermediates: number[],
    upload: UploadFinalDE,
    pingIntermediates: number[],
    ping: PingFinalDE,
    modem: ModemIntermediateExecutingDE,
} | {
    kind: Phase.CompletingNoModem | Phase.FinishedNoModem | Phase.CompletingWithModem | Phase.FinishedWithModem,
    prepareResult: PrepareResultDE,
    benchmark: BenchmarkFinalDE,
    downloadIntermediates: number[],
    download: DownloadFinalDE,
    uploadIntermediates: number[],
    upload: UploadFinalDE,
    pingIntermediates: number[],
    ping: PingFinalDE,
    modem: ModemFinalDE,
    hints: HintsDE | null,
}