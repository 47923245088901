import { LangName } from "../../types";

export const defaultLanguage: LangName = "de";

export const languageList: LangName[] = ["en"];

export const getFullLanguageList = (): LangName[] => {
    if (languageList.includes(defaultLanguage)) {
        return languageList;
    }

    return [defaultLanguage, ...languageList];
}

export const getPathByLang = (lang: LangName): string | undefined => {
    if (lang === defaultLanguage) {
        return undefined;
    }

    return lang;
}