import React from "react"
import ModalDialog from "../../components/elements/ModalDialog"
import Button from "../../components/elements/Button"
import { useAppDispatch } from "../../hooks"
import { reset } from "../measurement/measurementSlice"
import { resetError } from "./errorSlice"
import t from "../../helper/t"
import SmartLink from "../../components/elements/SmartLink"
import { getErrorMessage, getErrorTitleKey } from "./errorUtils"
import { NQError } from "@visonum/network-quality-sdk"
import ErrorAdditionalInfo from "./ErrorAdditionalInfo"

interface ErrorDialogProps {
  lastError: NQError
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ lastError }) => {

  const dispatch = useAppDispatch()
  function clickHandler() {
    dispatch(resetError());
    dispatch(reset());
  }

  return (
    <ModalDialog>
      <h2>{t(getErrorTitleKey(lastError))}</h2>
      <p style={{ marginBottom: 0 }}>{t(getErrorMessage(lastError))}</p>
      <div style={{marginTop: 12}}>
        <ErrorAdditionalInfo lastError={lastError} />
      </div>      
      <div style={{ marginTop: 36 }}>
        <SmartLink href="/" onClick={clickHandler}>
          <Button>
            {t("Ok")}
          </Button>
        </SmartLink>
      </div>
    </ModalDialog>
  );
}

export default ErrorDialog;