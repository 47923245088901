import { Subscription } from "rxjs"

export const currentSubscription = new class CurrentSubscription {
    private _subscription: Subscription | null = null
    public get subscription(): Subscription | null {
        return this._subscription
    }
    public set subscription(newValue: Subscription | null) {
        this._subscription?.unsubscribe()
        this._subscription = newValue
    }
}