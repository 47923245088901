import styled from "@emotion/styled";
import { r } from "../../../../theme/gigatv/sizeUtils";

export const InnerContainer = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
        rowGap: r(40),
        marginBottom: r(24),
        marginLeft: r(48),
        marginRight: r(48),
        maxWidth: r(1248),
        minWidth: r(800),
    }
]);