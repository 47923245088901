import styled from "@emotion/styled";
import { ButtonDiv } from "./elements";
import { NQRecord } from "../../../../features/history/types";
import { useAppDispatch } from "../../../../hooks";
import { printReport } from "../../../../features/print/printSlice";
import BottomInfo from "./BottomInfo";
import NavigationButton from "../../elements/NavigationButton";
import { TableDivider } from "./TableRowCommon";
import DownloadCard from "../currentMeasurement/Download/DownloadCard";
import UploadCard from "../currentMeasurement/UploadCard";
import PingCard from "../currentMeasurement/PingCard";
import t from "../../../../helper/t";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        rowGap: 24,
    },
]);

const ContainerDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 16,
        paddingRight: 16,
        rowGap: 16,
    },
]);

const CardDiv = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    theme.splus3.shadow.mainCard,
    {
        borderRadius: 6,
    }
]));

const ButtonsContainer = styled.div(
    {
        display: "flex",
        justifyContent: "center",
    }
);

interface HistoryRecordDetailsProps {
    records: NQRecord[];
    record: NQRecord;
}

export const HistoryRecordDetailsMobile: React.FC<HistoryRecordDetailsProps> = ({ records, record }) => {
    const dispatch = useAppDispatch();
    return (
        <RootDiv data-cy="recordDetailsMobile">
            <ContainerDiv>
                <CardDiv>
                    <DownloadCard record={record} showAdditionalInfo={false} />
                </CardDiv>
                <CardDiv>
                    <UploadCard record={record} showAdditionalInfo={false} />
                </CardDiv>
                <CardDiv>
                    <PingCard pingTime={record.pingFinal.pingTime} showCompareText={false} />
                </CardDiv>
                <BottomInfo date={record.date} prepareResult={record.prepareResult} />
            </ContainerDiv>
            <ButtonsContainer>
                <NavigationButton onClick={() => dispatch(printReport(record))} isEnabled={true}>
                    <ButtonDiv isEnabled={true}>
                        {t("Exportieren")}
                    </ButtonDiv>
                </NavigationButton>
            </ButtonsContainer>
            <TableDivider />
        </RootDiv>
    );
}

export default HistoryRecordDetailsMobile;