import styled from "@emotion/styled";
import * as R from "ramda";
import HeaderWithTooltip from "../../elements/HeaderWithTooltip";

import InfoCircleSvg from "@assets/icons/splus3/result-info-circle.svg";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    }
]));

const HeaderDiv = styled.div(({ theme }) => ([
    theme.splus3.shadow.primary,
    theme.splus3.foreground.white,
    theme.splus3.background.vodafoneGreen,
    theme.splus3.typography.defaultBold,
    {
        display: "flex",
        lineHeight: "26px",
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: "6px 6px 0px 0px",
        alignSelf: "flex-start",
        columnGap: 8,
        alignItems: "center",

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font16Bold,
            {
            }
        ],
    }
]));

const ContentDiv = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    theme.splus3.shadow.primary,
    theme.splus3.border.solidGreen,
    {
        display: "flex",
        minHeight: 64,
        borderRadius: "0px 6px 6px 6px",
        flexGrow: 10,
    }
]));

interface ResultCardProps {
    title: string;
    hintHeader?: string;
    hintContent?: string;
}

const ResultCard: React.FC<ResultCardProps> = ({ title, hintHeader, hintContent, children }) => {
    return (
        <RootDiv>
            <HeaderDiv>
                {title}
                {!R.isNil(hintContent) && !R.isNil(hintHeader) && (
                    <HeaderWithTooltip header={hintHeader} icon={InfoCircleSvg} size={20} left={-26} bottom={32} handlePosition="BottomLeft">
                        {hintContent}
                    </HeaderWithTooltip>
                )}
            </HeaderDiv>
            <ContentDiv>
                {children}
            </ContentDiv>
        </RootDiv>
    );
}

export default ResultCard;