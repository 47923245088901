import styled from "@emotion/styled";
import NavigationButton from "../../elements/NavigationButton";
import { HeaderRow, TableRow } from "./TableRowMobile";
import { CheckedType } from "../../elements/CheckBox";
import HistoryRecordDetailsMobile from "./HistoryRecordDetailsMobile";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { updateExpandedRecord, updateSelectedRecords } from "../../../../features/history/historySlice";
import { getAllRecords } from "../../../../features/history/getAllRecords";
import { NQRecord } from "../../../../features/history/types";
import { ButtonDiv } from "./elements";
import t from "../../../../helper/t";
import { showRemoveHistoryDialog } from "../../../../features/dialogManager/dialogManagerSlice";

const RootDiv = styled.div(({ theme }) => ([
    theme.splus3.background.primary,
    {
        display: "none",
        flexDirection: "column",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "flex",
            }
        ],
    }
]));

const ButtonsContainer = styled.div(
    {
        marginTop: 16,
        display: "flex",
        justifyContent: "flex-end",
        columnGap: 8,
        paddingRight: 16,
    }
);

const TableContainer = styled.div(
    {
        display: "flex",
        flexDirection: "column",

        maxHeight: 320,
        overflowY: "hidden",
        ":hover": {
            overflowY: "auto",
        },
    }
);

interface HistoryTableProps {
}

const HistoryTableMobile: React.FC<HistoryTableProps> = ({ }) => {
    const dispatch = useAppDispatch();

    const historyState = useAppSelector(state => state.history);
    const sortedRecords = getAllRecords(historyState);

    const allChecked = ((): CheckedType => {
        if (historyState.selectedRecords.length === 0) {
            return false;
        } else if (historyState.selectedRecords.length === sortedRecords.length) {
            return true;
        } else {
            return "indeterminate";
        }
    })();

    const headerCheckedClick = () => {
        switch (allChecked) {
            case false:
            case "indeterminate":
                dispatch(updateSelectedRecords(sortedRecords));
                break;
            case true:
                dispatch(updateSelectedRecords([]));
                break;
        }
    }

    const onCheck = (record: NQRecord) => {
        const newSelectedRecords = historyState.selectedRecords.includes(record) ?
            historyState.selectedRecords.filter(r => r != record) :
            [...historyState.selectedRecords, record];
        dispatch(updateSelectedRecords(newSelectedRecords));
    }

    const onExpand = (record: NQRecord) => {
        dispatch(updateExpandedRecord(historyState.expandedRecord === record ? null : record));
    }

    const deleteAllEnabled = historyState.selectedRecords.length > 0;
    const deleteAll = () => {
        dispatch(showRemoveHistoryDialog())
    }

    return (
        <RootDiv>
            <HeaderRow records={sortedRecords} checked={allChecked} onCheckedClick={headerCheckedClick} />
            <TableContainer>
                {sortedRecords.map((record, index) => {
                    return <TableRow key={index}
                        records={sortedRecords}
                        record={record}
                        isChecked={historyState.selectedRecords.includes(record)}
                        isExpanded={historyState.expandedRecord === record}
                        onCheck={() => onCheck(record)}
                        onExpand={() => onExpand(record)}>
                        <HistoryRecordDetailsMobile records={sortedRecords} record={record} />
                    </TableRow>
                })}
            </TableContainer>
            {deleteAllEnabled && (
                <ButtonsContainer>
                    <NavigationButton onClick={deleteAll} isEnabled={deleteAllEnabled}>
                        <ButtonDiv isEnabled={deleteAllEnabled}>
                            {t("Alle löschen")}
                        </ButtonDiv>
                    </NavigationButton>
                </ButtonsContainer>
            )}
        </RootDiv>
    );
}

export default HistoryTableMobile;
