import {
    createNqError,
    DownloadDE,
    MeasurementMode,
    NetworkQualitySDK,
    NQValueDE,
    NQValueKind,
    UploadDE,
} from "@visonum/network-quality-sdk";
import { NetworkQualitySdkFusion } from "./NetworkQualitySdkFusion";
import { logger } from "../../helper/logger";
import { AppAssertionError } from "../../features/errors/AppAssertionError";
import { delay, from, map, Observable } from "rxjs";
import { freeze } from "immer";
import * as R from "ramda";
import { SpeedtestOptions } from "@fusion/fusion";

export class SyntheticNetworkQualitySdkFusion extends NetworkQualitySdkFusion {
    constructor(private readonly mode: MeasurementMode, networkQualitySDK: NetworkQualitySDK) {
        super(networkQualitySDK);

        logger.info(`NetworkQualityFusion constructor. Mode = ${mode}`);

        if (mode === MeasurementMode.Production) {
            throw new AppAssertionError({ actual: mode, expected: "Not Production" });
        }
    }

    protected getSpeedtestOptions(): SpeedtestOptions {
        return { downloadMockData: this.getDownloadValues(), uploadMockData: this.getUploadValues() }
    }

    public speedtest(): Observable<NQValueDE> {
        switch (this.mode) {
            case MeasurementMode.Production:
                throw new AppAssertionError({ actual: this.mode, expected: "Not Production" });
            case MeasurementMode.Disabled_Benchmarks:
            case MeasurementMode.Customer_NonSat_ModemAvailable:
            case MeasurementMode.Customer_NonSat_ModemAvailable_SpeedLimit:
            case MeasurementMode.Customer_NonSat_ModemAvailable_SwapLink:
            case MeasurementMode.Customer_NonSat_ModemNotAvailableDCA:
            case MeasurementMode.Customer_NonSat_ModemFailed:
            case MeasurementMode.Customer_Sat:
            case MeasurementMode.Customer_Sat_SwapLink:
            case MeasurementMode.NonCustomer_NoVpn:
            case MeasurementMode.NonCustomer_Vpn:
            case MeasurementMode.Wait_For_Prepare:
            case MeasurementMode.Error_Too_High_Results:
            case MeasurementMode.Error_Ping_No_Intermediate_Values:
                return super.speedtest();
            case MeasurementMode.Error_Unconnected:
                return from([1]).pipe(
                    delay(100),
                    map(_ => { throw createNqError("Ap-Cn-1", "Connectivity Error"); }),
                );
            case MeasurementMode.Error_During_Upload_NonCustomer_NoVpn:
                return super.speedtest().pipe(
                    map(v => {
                        if (v.kind === NQValueKind.UploadFinal) {
                            throw createNqError("Up-Cn-2", "Server Error");
                        } else {
                            return v
                        }
                    }),
                );
            case MeasurementMode.Error_On_Modem_Customer_NonSat_ModemAvailable:
                return super.speedtest().pipe(
                    map(v => {
                        if (v.kind === NQValueKind.ModemFinal) {
                            throw createNqError("Ap-Re-5", "Server Error");
                        } else {
                            return v
                        }
                    }),
                );
            case MeasurementMode.Error_Parallel_Speedtests:
                return super.speedtest().pipe(
                    map(v => {
                        if (v.kind === NQValueKind.PrepareResult) {
                            throw createNqError("Ps-1", "Parallel Speedtest");
                        } else {
                            return v
                        }
                    }),
                );
        }
    }

    createDownloadIntermediate = (speed: number) => {
        return freeze({ kind: NQValueKind.DownloadIntermediate, speed });
    }

    createDownloadFinal = (speed: number, jitter: number) => {
        return freeze({ kind: NQValueKind.DownloadFinal, speed, jitter });
    }

    createUploadIntermediate = (speed: number) => {
        return freeze({ kind: NQValueKind.UploadIntermediate, speed });
    }

    createUploadFinal = (speed: number, jitter: number) => {
        return freeze({ kind: NQValueKind.UploadFinal, speed, jitter });
    }

    getDownloadValues = (): DownloadDE[] => {
        return freeze(
            R.range(0, 10)
                .map(i => this.createDownloadIntermediate((90 + i) * 1e6) as DownloadDE)
                .concat([this.createDownloadFinal(100e6, 3e6) as DownloadDE])
        );
    }

    getUploadValues = (): UploadDE[] => {
        return freeze(
            R.range(0, 10)
                .map(i => this.createUploadIntermediate((15 + i) * 1e6) as UploadDE)
                .concat([this.createUploadFinal(25e6, 1e6) as UploadDE])
        );
    }
}