import styled from "@emotion/styled";
import TipCard from "./TipCard";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { nextIsEnabled, prevIsEnabled, updateCurrenIndex, updateHintIsExpanded } from "../../../../features/hints/hintsSlice";
import NoDataStub from "../../elements/NoDataStub";
import { CommonButtonDiv, RedButtonDiv } from "../../elements/Buttons";
import NavigationButton from "../../elements/NavigationButton";
import t from "../../../../helper/t";
import CommonContainer from "../../elements/CommonContainer";
import { devAssert } from "../../../../features/errors/devAssert";
import { AppAssertionError } from "../../../../features/errors/AppAssertionError";
import { getCurrentHint } from "../../../../features/hints/hintFunctions";

const RootDiv = styled(CommonContainer)(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 20,
        paddingRight: 20,

        [theme.splus3.breakpoints.mobile]: {
            paddingTop: 28,
            paddingBottom: 40,
            paddingLeft: 16,
            paddingRight: 16,
        },
    }
]));

const ContainerDiv = styled.div(({ theme }) => ([
    {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "1fr auto",
        columnGap: 20,
        rowGap: 20,

        [theme.splus3.breakpoints.smallDesktop]: {
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr auto auto",
        },

        [theme.splus3.breakpoints.mobile]: {
            gridTemplateColumns: "1fr",
            gridTemplateRows: "1fr auto auto",
            rowGap: 24,
        },
    }
]));

const TipContainerDiv = styled.div(({ theme }) => ([
    {
        gridRow: 1,
        gridColumnStart: 1,
        gridColumnEnd: 3,

        [theme.splus3.breakpoints.mobile]: {
            gridRow: 1,
            gridColumn: 1,
        },
    }
]));

const CompareContainerDiv = styled.div(({ theme }) => ([
    {
        gridRowStart: 1,
        gridRowEnd: 2,
        gridColumn: 3,

        [theme.splus3.breakpoints.smallDesktop]: {
            gridRow: 2,
            gridColumnStart: 1,
            gridColumnEnd: 3,
        },

        [theme.splus3.breakpoints.mobile]: {
            gridRow: 3,
            gridColumn: 1,
        },
    }
]));

const ButtonsContainerDiv = styled.div(({ theme }) => ([
    {
        gridRow: 2,
        gridColumnStart: 1,
        gridColumnEnd: 3,

        display: "flex",
        justifyContent: "flex-end",

        columnGap: 12,

        [theme.splus3.breakpoints.smallDesktop]: {
            gridRow: 3,
        },

        [theme.splus3.breakpoints.mobile]: {
            gridRow: 2,
            gridColumn: 1,
            flexDirection: "column-reverse",
            alignItems: "stretch",
            rowGap: 16,
            marginLeft: "auto",
            marginRight: "auto",
        },
    }
]));

const NextButtonDiv = styled(RedButtonDiv)(({ theme }) => ([
    {
        maxWidth: 286,

        [theme.splus3.breakpoints.mobile]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    }
]));

const PrevButtonDiv = styled(CommonButtonDiv)(({ theme }) => ([
    {
        maxWidth: 286,

        [theme.splus3.breakpoints.mobile]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    }
]));

interface SpeedtestGuideProps {
}

const SpeedtestGuide: React.FC<SpeedtestGuideProps> = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state);

    const hint = getCurrentHint(state);

    if (hint === null) {
        return <NoDataStub message={t("Es sind keine Tipps vorhanden")} />
    }

    const index = state.hints.currentIndex;

    const nextTip = () => {
        if (nextIsEnabled(state.hints)) {
            if (index === null) {
                devAssert(new AppAssertionError({ actual: index, expected: "index != null" }), "Current Hint Index should not be null");
            } else {
                dispatch(updateCurrenIndex(index + 1));
            }
        } else {
            devAssert(new AppAssertionError({ message: "nextIsEnabled() is false when nextTip() is called" }), "nextIsEnabled() is false when nextTip() is called");
        }
    }

    const prevTip = () => {
        if (prevIsEnabled(state.hints)) {
            if (index === null) {
                devAssert(new AppAssertionError({ actual: index, expected: "index != null" }), "Current Hint Index should not be null");
            } else {
                dispatch(updateCurrenIndex(index - 1));
            }
        } else {
            devAssert(new AppAssertionError({ message: "prevIsEnabled() is false when prevTip() is called" }), "prevIsEnabled() is false when prevTip() is called");
        }
    }

    const expandTip = () => {
        dispatch(updateHintIsExpanded(!state.hints.isExpanded));
    }

    return (
        <RootDiv>
            <ContainerDiv>
                <TipContainerDiv>
                    <TipCard hint={hint} onPrevClick={prevTip} onNextClick={nextTip} onExpandClick={expandTip} />
                </TipContainerDiv>

                <CompareContainerDiv>
                    {/* <CompareCard /> */}
                </CompareContainerDiv>

                <ButtonsContainerDiv>
                    {prevIsEnabled(state.hints) &&
                        <NavigationButton onClick={prevTip}>
                            <PrevButtonDiv>
                                {t("Zum vorherigen Tipp")}
                            </PrevButtonDiv>
                        </NavigationButton>
                    }
                    {nextIsEnabled(state.hints) &&
                        <NavigationButton dataCy="next-tip-button" onClick={nextTip}>
                            <NextButtonDiv>
                                {t("Zum nächsten Tipp")}
                            </NextButtonDiv>
                        </NavigationButton>
                    }
                </ButtonsContainerDiv>
            </ContainerDiv>
        </RootDiv>
    );
}

export default SpeedtestGuide;
