import styled from "@emotion/styled";
import { r } from "../../../theme/gigatv/sizeUtils";

export const BottomInfoDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.regular,
    theme.gigatv.typography.fontSize22,
    {
        display: "flex",
        columnGap: r(16),
    }
]));

export const InfoContainer = styled.div([
    {
        display: "flex",
    }
]);

export const InfoKeySpan = styled.div(({ theme }) => ([
    theme.gigatv.typography.bold,
]));