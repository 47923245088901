import { HistoryControlItemKey, HistoryRecordsItemKey, HistoryScreenPart, ResultButton, ResultScreenPart, ResultTab } from "../FocusabeItemKey";
import ViewScreenKey from "../ViewScreenKey";
import { ResultScreenState } from "../ViewScreenState";
import { setScreen } from "../focusManagerSlice";
import { startMeasurementTv } from "../homeHelper";
import {
    changeHistoryPart,
    changeResultScreenPart,
    historyPageDown,
    historyPageUp,
    historyRecordDown,
    historyRecordUp,
    removeHistoryEntry,
    selectButtonOnResultScreen,
    selectHistoryControl,
    selectHistoryRecordItem,
    selectTabOnResultScreen,
    showHistoryDetails,
    showRemoveEntriesDialog
} from "../resultScreenHelper";
import { ActionForElement, ViewScreen } from "../types";

export const resultViewScreen: ViewScreen<ResultScreenState> = (state: ResultScreenState) => {
    switch (state.screenPart) {
        case ResultScreenPart.Buttons:
            return {
                focusedElementKey: state.selectedButton,
                elementActionsMap: new Map<ResultButton, ActionForElement>([
                    [ResultButton.SpeedtestStart, {
                        tab: () => selectButtonOnResultScreen(ResultButton.Info),
                        right: () => selectButtonOnResultScreen(ResultButton.Info),
                        enter: startMeasurementTv,
                        down: () => changeResultScreenPart(ResultScreenPart.Tabs)
                    }],
                    [ResultButton.Info, {
                        tab: () => changeResultScreenPart(ResultScreenPart.Tabs, { selectedTab: ResultTab.CurrentMeasurement }),
                        left: () => selectButtonOnResultScreen(ResultButton.SpeedtestStart),
                        enter: () => setScreen(ViewScreenKey.Info),
                        down: () => changeResultScreenPart(ResultScreenPart.Tabs)
                    }],
                ]),
            }
        case ResultScreenPart.Tabs:
            return {
                focusedElementKey: state.selectedTab,
                elementActionsMap: new Map<ResultTab, ActionForElement>([
                    [ResultTab.CurrentMeasurement, {
                        tab: () => selectTabOnResultScreen(ResultTab.AdditionalInfo),
                        right: () => selectTabOnResultScreen(ResultTab.AdditionalInfo),
                        up: () => changeResultScreenPart(ResultScreenPart.Buttons),
                    }],
                    [ResultTab.AdditionalInfo, {
                        left: () => selectTabOnResultScreen(ResultTab.CurrentMeasurement),
                        tab: () => selectTabOnResultScreen(ResultTab.History),
                        right: () => selectTabOnResultScreen(ResultTab.History),
                        up: () => changeResultScreenPart(ResultScreenPart.Buttons),
                    }],
                    [ResultTab.History, {
                        left: () => selectTabOnResultScreen(ResultTab.AdditionalInfo),
                        tab: () => () => changeHistoryPart(HistoryScreenPart.Constrol, { selectedControl: HistoryControlItemKey.PageDown }),
                        up: () => changeResultScreenPart(ResultScreenPart.Buttons),
                        down: () => changeHistoryPart(HistoryScreenPart.Constrol),
                    }],
                ]),
            }
        case ResultScreenPart.History:
            switch (state.historyPart) {
                case HistoryScreenPart.Constrol:
                    return {
                        focusedElementKey: state.selectedControl,
                        elementActionsMap: new Map<HistoryControlItemKey, ActionForElement>([
                            [HistoryControlItemKey.PageDown, {
                                tab: () => selectHistoryControl(HistoryControlItemKey.PageUp),
                                right: () => selectHistoryControl(HistoryControlItemKey.PageUp),
                                up: () => changeResultScreenPart(ResultScreenPart.Tabs),
                                down: () => changeHistoryPart(HistoryScreenPart.Records),
                                enter: historyPageDown
                            }],
                            [HistoryControlItemKey.PageUp, {
                                tab: () => selectHistoryControl(HistoryControlItemKey.RemoveEntries),
                                left: () => selectHistoryControl(HistoryControlItemKey.PageDown),
                                right: () => selectHistoryControl(HistoryControlItemKey.RemoveEntries),
                                up: () => changeResultScreenPart(ResultScreenPart.Tabs),
                                down: () => changeHistoryPart(HistoryScreenPart.Records),
                                enter: historyPageUp
                            }],
                            [HistoryControlItemKey.RemoveEntries, {
                                tab: () => changeResultScreenPart(ResultScreenPart.Buttons, { selectedButton: ResultButton.SpeedtestStart }),
                                left: () => selectHistoryControl(HistoryControlItemKey.PageUp),
                                up: () => changeResultScreenPart(ResultScreenPart.Tabs),
                                down: () => changeHistoryPart(HistoryScreenPart.Records),
                                enter: showRemoveEntriesDialog
                            }],
                        ]),
                    }
                case HistoryScreenPart.Records:
                    return {
                        focusedElementKey: state.selectedRecord.key,
                        elementActionsMap: new Map<HistoryRecordsItemKey, ActionForElement>([
                            [HistoryRecordsItemKey.Item, {
                                tab: () => selectHistoryRecordItem(HistoryRecordsItemKey.RemoveEntry),
                                right: () => selectHistoryRecordItem(HistoryRecordsItemKey.RemoveEntry),
                                up: historyRecordUp,
                                down: historyRecordDown,
                                enter: () => showHistoryDetails(state.selectedRecord.index)
                            }],
                            [HistoryRecordsItemKey.RemoveEntry, {
                                tab: () => selectHistoryRecordItem(HistoryRecordsItemKey.Item),
                                left: () => selectHistoryRecordItem(HistoryRecordsItemKey.Item),
                                enter: removeHistoryEntry
                            }],
                        ]),
                        backAction: () => changeHistoryPart(HistoryScreenPart.Constrol),
                    }
            }
    }
}