export enum HomeItemKey {
    ButtonMeasurement = "ButtonMeasurement",
    NavigationButtonResult = "NavigationButtonResult",
    NavigationButtonInfo = "NavigationButtonInfo",
}

export enum ResultScreenPart {
    Buttons,
    Tabs,
    History
}

export enum ResultButton {
    SpeedtestStart = "SpeedtestStart",
    Info = "Info",
}

export enum ResultTab {
    CurrentMeasurement = "CurrentMeasurement",
    AdditionalInfo = "AdditionalInfo",
    History = "History",
}

export enum HistoryControlItemKey {
    PageUp = "PageUp",
    PageDown = "PageDown",
    RemoveEntries = "RemoveEntries"
}

export enum HistoryRecordsItemKey {
    Item = "Item",
    RemoveEntry = "RemoveEntry",
}

export enum HistoryScreenPart {
    Constrol,
    Records,
}

export enum InfoScreenPart {
    Buttons,
    Tabs,    
}

export enum InfoButton {
    SpeedtestStart = "SpeedtestStart",
    Result = "Result",
}

export enum InfoTab {
    Impressum = "Impressum",
    PrivacyPolicy = "PrivacyPolicy",
    TermsAndConditions = "TermsAndConditions"
}

export enum ErrorItemKey {
    CloseButton = "CloseButton",
}

export enum RemoveEntryItemKey {
    RemoveButton = "RemoveButton",
    CloseButton = "CloseButton",
}

export enum HistoryDetailItemKey {
    Back = "Back",
}