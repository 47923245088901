import * as Sentry from "@sentry/nextjs";
import { Primitive, CaptureContext, Options } from "@sentry/types";
import { ProcessEnv } from "../../config-types";
import { isRunningInBrowser } from "../../helper/ssr";
import { SentryInterface, SentryTagSet, TestSentryInterface } from "./types";

class RealSentryObject implements SentryInterface {
    public init(options: Options): void {
        Sentry.init(options);
    }
    public setTags(tags: { [key: string]: Primitive }): void {
        Sentry.setTags(tags);
        Sentry.setUser
    }
    public setTag(key: string, value: Primitive): void {
        return Sentry.setTag(key, value);
    }
    public captureMessage(message: string, captureContext?: Sentry.SeverityLevel | CaptureContext | undefined): string {
        return Sentry.captureMessage(message, captureContext);
    }
    public captureException(exception: any, captureContext?: CaptureContext | undefined): string {
        return Sentry.captureException(exception, captureContext);
    }
    public addBreadcrumb(breadcrumb: Sentry.Breadcrumb): void {
        Sentry.addBreadcrumb(breadcrumb);
    }
    public setUser(user: Sentry.User | null): void {
        Sentry.setUser(user);
    }
}

class TestSentryObject implements TestSentryInterface {
    private readonly messages: string[] = [];
    private readonly breadcrumbs: Sentry.Breadcrumb[] = [];

    public init(options: Options): void {

    }

    public setTags(tags: { [key: string]: Primitive }): void {

    }

    public setTag(key: SentryTagSet, value: Primitive): void {

    }

    public captureMessage(message: string, captureContext?: Sentry.SeverityLevel | CaptureContext | undefined): string {
        this.messages.push(message);
        return "";
    }

    public captureException(exception: any, captureContext?: CaptureContext | undefined): string {
        return "";
    }

    public addBreadcrumb(breadcrumb: Sentry.Breadcrumb): void {
        this.breadcrumbs.push(breadcrumb);
    }

    public getBreadcrumbs(): Sentry.Breadcrumb[] {
        return this.breadcrumbs;
    }

    public getMessages(): string[] {
        return this.messages;
    }

    public setUser(user: Sentry.User | null): void {

    }
}

declare global {
    interface Window {
        sentryObject: SentryInterface;
    }
}

const getSentryObjectInternal = (): SentryInterface => {
    const NODE_ENV = (process.env.NEXT_PUBLIC_NODE_ENV as ProcessEnv);
    if (NODE_ENV === "test") {
        const retVal = new TestSentryObject();
        if (isRunningInBrowser()) {
            window.sentryObject = retVal;
        }
        return retVal;
    }

    return new RealSentryObject();
}

export const sentryObject = getSentryObjectInternal();

export default sentryObject;