export enum PageKey {
    Home,
    Measurement,
    Result,
    Info,
}

export interface HomePageState {

}

export interface MeasurementPageState {

}

export enum ResultTabKey {
    CurrentMesasurement,
    SpeedtestGuide,
    AdditionalInfo,
    History,
}

export interface ResultPageState {
    currentTab: ResultTabKey;
    showSurveyModal: boolean;
    lastSurveyDate: Date | null;
}

export type PageState = {
    key: PageKey.Home,
    state: HomePageState,
} | {
    key: PageKey.Measurement,
    state: MeasurementPageState,
} | {
    key: PageKey.Result,
    state: ResultPageState,
}