import styled from "@emotion/styled";

const RootDiv = styled.div(({ theme }) => ([
  theme.splus3.foreground.primary,
  theme.splus3.typography.default,
  {
    padding: 10,
    textAlign: "center",
  }
]));

interface NoDataStubProps {
  message: string;
}

const NoDataStub: React.FC<NoDataStubProps> = ({ message }) => {
  return (
    <RootDiv>
      {message}
    </RootDiv>
  );
}

export default NoDataStub;