import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as R from "ramda";
import { AppDispatch, AppThunk } from "../../store";
import { getInitialState, getViewScreen } from "./getViewScreen";
import { TvAction, TvKeyCode, ViewScreen } from "./types";
import ViewScreenKey from "./ViewScreenKey";
import ViewDialogKey from "./ViewDialogKey";
import { getViewDialog } from "./getViewDialog";
import DialogState from "./DialogState";
import ViewScreenState, { HomeScreenState, InfoScreenState, MeasurementScreenState, ResultScreenState } from "./ViewScreenState";
import { HistoryControlItemKey, HistoryRecordsItemKey, HistoryScreenPart, InfoButton, InfoScreenPart, InfoTab, ResultButton, ResultScreenPart, ResultTab } from "./FocusabeItemKey";
import { isRunningInBrowser } from "../../helper/ssr";
import { KeyboardHandler, nullOrValue } from "../../helper/utils";
import { AppAssertionError } from "../errors/AppAssertionError";
import { devAssert } from "../errors/devAssert";

export interface FocusManagementState {
    focusedScreen: ViewScreenKey;
    homeState: HomeScreenState;
    measurementState: MeasurementScreenState;
    resultState: ResultScreenState;
    infoState: InfoScreenState;

    dialog: DialogState | null;

    lastPressedKey?: number; // for debug purposes only
}

const initialState: FocusManagementState = {
    focusedScreen: ViewScreenKey.Home,
    homeState: getInitialState(ViewScreenKey.Home),
    measurementState: getInitialState(ViewScreenKey.Measurement),
    resultState: getInitialState(ViewScreenKey.Result),
    infoState: getInitialState(ViewScreenKey.Info),

    dialog: null,
}

const executeAction = <TState>(dispatch: AppDispatch, viewScreen: ViewScreen<TState> | null, state: TState, keyCode: TvKeyCode): boolean => {
    if (R.isNil(viewScreen)) {
        return false;
    }

    const condMap = viewScreen(state);
    const itemKey = condMap.focusedElementKey;
    const actionsForElement = condMap.elementActionsMap.get(itemKey);
    if (R.isNil(actionsForElement)) {
        devAssert(new AppAssertionError({ actual: actionsForElement, expected: "actionsForElement is defined" }), "Focus Manager");
        return false;
    }

    const getAction = (): TvAction | null => {
        switch (keyCode) {
            case TvKeyCode.VK_ENTER: return nullOrValue(actionsForElement.enter);
            case TvKeyCode.VK_TAB: return nullOrValue(actionsForElement.tab);
            case TvKeyCode.VK_LEFT: return nullOrValue(actionsForElement.left);
            case TvKeyCode.VK_UP: return nullOrValue(actionsForElement.up);
            case TvKeyCode.VK_RIGHT: return nullOrValue(actionsForElement.right);
            case TvKeyCode.VK_DOWN: return nullOrValue(actionsForElement.down);
            case TvKeyCode.VK_BACK:
            case TvKeyCode.VK_BACK_SPACE:
                return nullOrValue(condMap.backAction);
        }
    }

    const action = getAction();
    if (R.isNil(action)) {
        return false;
    }

    const thunk = action();
    dispatch(thunk);

    return true;
}

const tryToExecuteAction = (keyCode: TvKeyCode): AppThunk<boolean> => (dispatch, getState) => {
    const state = getState().focusManager;

    if (state.dialog === null) {
        switch (state.focusedScreen) {
            case ViewScreenKey.Home:
                return executeAction(dispatch, getViewScreen(state.focusedScreen), state.homeState, keyCode);
            case ViewScreenKey.Measurement:
                return executeAction(dispatch, getViewScreen(state.focusedScreen), state.measurementState, keyCode);
            case ViewScreenKey.Result:
                return executeAction(dispatch, getViewScreen(state.focusedScreen), state.resultState, keyCode);
            case ViewScreenKey.Info:
                return executeAction(dispatch, getViewScreen(state.focusedScreen), state.infoState, keyCode);
        }
    } else {
        switch (state.dialog.dialogKey) {
            case ViewDialogKey.Error:
                return executeAction(dispatch, getViewDialog(state.dialog.dialogKey), state.dialog.state, keyCode);
            case ViewDialogKey.RemoveEntry:
                return executeAction(dispatch, getViewDialog(state.dialog.dialogKey), state.dialog.state, keyCode);
            case ViewDialogKey.HistoryDetail:
                return executeAction(dispatch, getViewDialog(state.dialog.dialogKey), state.dialog.state, keyCode);
        }
    }
}

let onKeyDown: KeyboardHandler | undefined = undefined;

export const initFocusManager = (): AppThunk => (dispatch, _) => {
    dispatch(focusManagerSlice.actions.reset());

    onKeyDown = (ev: KeyboardEvent) => {
        const keyCode = ev.keyCode;

        dispatch(focusManagerSlice.actions.updateLastPressedKey(keyCode));

        switch (ev.keyCode) {
            case TvKeyCode.VK_TAB:
            case TvKeyCode.VK_BACK:
            case TvKeyCode.VK_BACK_SPACE:
            case TvKeyCode.VK_ENTER:
            case TvKeyCode.VK_LEFT:
            case TvKeyCode.VK_UP:
            case TvKeyCode.VK_RIGHT:
            case TvKeyCode.VK_DOWN:
                dispatch(tryToExecuteAction(keyCode));
                ev.preventDefault();
                ev.stopPropagation();
                break;
        }
    }

    if (isRunningInBrowser()) {
        window.addEventListener("keydown", onKeyDown);
    }
}

export const resetFocusManager = (): AppThunk => () => {
    if (isRunningInBrowser()) {
        window.removeEventListener("keydown", onKeyDown!);
    }
}

export const setScreen = (key: ViewScreenKey): AppThunk => (dispatch) => {
    dispatch(focusManagerSlice.actions.setScreen(key));
}

export const showDialog = (dialog: DialogState): AppThunk => (dispatch) => {
    dispatch(focusManagerSlice.actions.showDialog(dialog));
}

export const closeDialog = (): AppThunk => (dispatch) => {
    dispatch(focusManagerSlice.actions.closeDialog());
}

export const focusManagerSlice = createSlice({
    name: 'focusManager',
    initialState,
    reducers: {
        update: (_, action: PayloadAction<FocusManagementState>) => {
            return action.payload;
        },
        reset: () => initialState,
        setScreen: (state, action: PayloadAction<ViewScreenKey>) => {
            state.focusedScreen = action.payload;
            switch (action.payload) {
                case ViewScreenKey.Home:
                    state.homeState = getInitialState(ViewScreenKey.Home);
                    break;
                case ViewScreenKey.Measurement:
                    state.measurementState = getInitialState(ViewScreenKey.Measurement);
                    break;
                case ViewScreenKey.Result:
                    const oldSelectedRecordIndex = state.resultState.selectedRecord.index;
                    state.resultState = getInitialState(ViewScreenKey.Result);
                    state.resultState.selectedRecord.index = oldSelectedRecordIndex;
                    break;
                case ViewScreenKey.Info:
                    state.infoState = getInitialState(ViewScreenKey.Info);
                    break;
            }
        },
        showDialog: (state, action: PayloadAction<DialogState>) => {
            state.dialog = action.payload;
        },
        closeDialog: (state) => {
            state.dialog = null;
        },

        // Result Actions
        updateResultSelectedButton: (state, action: PayloadAction<ResultButton>) => {
            state.resultState.selectedButton = action.payload;
        },
        updateResultSelectedTab: (state, action: PayloadAction<ResultTab>) => {
            state.resultState.selectedTab = action.payload;
        },
        updateResultScreenPart: (state, action: PayloadAction<ResultScreenPart>) => {
            state.resultState.screenPart = action.payload;
        },
        updateHistorySelectedControl: (state, action: PayloadAction<HistoryControlItemKey>) => {
            state.resultState.selectedControl = action.payload;
        },
        updateHistoryPart: (state, action: PayloadAction<HistoryScreenPart>) => {
            state.resultState.historyPart = action.payload;
        },
        updateSelectedRecordIndex: (state, action: PayloadAction<number>) => {
            state.resultState.selectedRecord.index = action.payload;
        },
        updateSelectedRecordKey: (state, action: PayloadAction<HistoryRecordsItemKey>) => {
            state.resultState.selectedRecord.key = action.payload;
        },

        // Info Actions
        updateInfoScreenPart: (state, action: PayloadAction<InfoScreenPart>) => {
            state.infoState.screenPart = action.payload;
        },
        updateInfoSelectedTab: (state, action: PayloadAction<InfoTab>) => {
            state.infoState.selectedTab = action.payload;
        },
        updateInfoSelectedButton: (state, action: PayloadAction<InfoButton>) => {
            state.infoState.selectedButton = action.payload;
        },

        changeFocused: (state, action: PayloadAction<ViewScreenState>) => {
            switch (action.payload.screenKey) {
                case ViewScreenKey.Home:
                    state.homeState = action.payload.state;
                    break;
                case ViewScreenKey.Measurement:
                    state.measurementState = action.payload.state;
                    break;
                case ViewScreenKey.Result:
                    state.resultState = action.payload.state;
                    break;
                case ViewScreenKey.Info:
                    state.infoState = action.payload.state;
                    break;
            }
        },

        changeDialog: (state, action: PayloadAction<DialogState>) => {
            state.dialog = action.payload;
        },

        updateLastPressedKey: (state, action: PayloadAction<number>) => {
            state.lastPressedKey = action.payload;
        }
    }
});

export default focusManagerSlice;