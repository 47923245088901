import * as R from "ramda";
import { combineUrlParts, isInternalLink } from "./url";

export const getLocalizedAddress = (href: string, langPath: string | undefined): string => {
    if (R.isNil(langPath) || !isInternalLink(href)) {
      return href;
    }
  
    if (href === "/") {
      return combineUrlParts(`/${langPath}`, "");
    }
  
    return combineUrlParts(`/${langPath}`, href);
  }