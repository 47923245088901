import {
    BenchmarkFinalDE,
    Models,
    ModemFinalAvailableDE,
    ModemFinalDE,
    ModemFinalDidNotRunDE,
    ModemFinalFailedDE,
    ModemFinalKind,
    ModemNotRunReason,
    NQValueKind,
    PartialServiceModel,
    SpeedtestState,
    isNqError
} from "@visonum/network-quality-sdk";
import { freeze } from "immer";
import * as R from "ramda";
import { logger } from "../../helper/logger";
import {
    JsonNQRecordVersion,
    JsonNQRecord_v1,
    JsonNQRecord_v2,
    JsonNQRecord_v3,
    JsonNQRecord_v4,
    JsonNQRecord_v5,
    JsonNQRecord_v6,
    JsonNQRecord_v7,
    JsonNQRecord_v8,
    JsonNQRecord_v9,
    JsonNQRecord_v10,
    JsonNQRecord_v11,
    JsonNQRecord_v12,
    NQRecord,
    NQRecordPrepareResult,
    VersionedJsonNQRecord,
    NQRecordPartialService
} from "./types";
import { reportHistoryError } from "./reportHistoryError";
import { dateToPlainDate } from "../../../src/types";

/* pure */
export const decodeFromLocalStorage = (data: string | null): NQRecord[] => {
    if (data === null) {
        return [];
    }
    try {
        const deserializedItems = JSON.parse(data) as unknown;
        if (Array.isArray(deserializedItems)) {
            return deserializedItems.map(decodeValue).filter(v => v != null) as NQRecord[];
        } else {
            reportHistoryError("Hr-Dc-1", "Decoded value is not array.", { data, deserializedItems });
            return [];
        }
    } catch (err) {
        if (!isNqError(err)) {
            reportHistoryError("Hr-Dc-0", "Unknown decode history error.", err);
        }
        return [];
    }
}

/* pure */
const decodeValue = (value: unknown, index: number): NQRecord | null => {
    const valueType = typeof value;
    if (valueType === 'object' && value !== null && !Array.isArray(value)) {
        const versionType = typeof (value as any)["version"];
        if (versionType === typeof JsonNQRecordVersion.v1) {
            const jsonNQRecord = value as VersionedJsonNQRecord;
            try {
                return decodeNQRecord(jsonNQRecord, index);
            } catch (err) {
                reportHistoryError("Hr-Dc-0", `Unknown decode history item error. Item index: ${index}`, err);
                return null;
            }
        } else {
            reportHistoryError("Hr-Dc-3", `History item[${index}] has wrong version type "${versionType}"`);
            return null;
        }
    } else {
        reportHistoryError("Hr-Dc-2", `History item[${index}] has wrong type "${valueType}"`);
        return null;
    }
}

/* pure */
export const decodeNQRecord = (record: VersionedJsonNQRecord, index: number): NQRecord | null => {
    const version = record.version;

    switch (version) {
        case JsonNQRecordVersion.v1:
        case JsonNQRecordVersion.v2:
            return decodeNQRecordFromV1_V2(record);
        case JsonNQRecordVersion.v3:
        case JsonNQRecordVersion.v4:
        case JsonNQRecordVersion.v5:
        case JsonNQRecordVersion.v6:
        case JsonNQRecordVersion.v7:
        case JsonNQRecordVersion.v8:
            return decodeNQRecordFromV3_V8(record);
        case JsonNQRecordVersion.v9:
        case JsonNQRecordVersion.v10:
        case JsonNQRecordVersion.v11:
        case JsonNQRecordVersion.v12:
            return decodeNQRecordFromV9_V12(record);
    }

    reportHistoryError("Hr-Dc-4", `History item[${index}] has wrong version "${version}"`);
    return null;
}

/* pure */
const decodeNQRecordFromV1_V2 = (value: JsonNQRecord_v1 | JsonNQRecord_v2): NQRecord | null => {
    const modemFinal = decodeNQRecordModemFinalV1_V5(value);
    const date = parseDate(value.date);
    const benchmarkFinal = decodeBenchmarkFinal(value);

    if (R.isNil(modemFinal) || R.isNil(date) || R.isNil(benchmarkFinal)) {
        return null;
    } else {
        const prepareResult: NQRecordPrepareResult = {
            ...value.prepareResult,
            init: {
                ...value.prepareResult.init,
                modem: value.prepareResult.init.modem === null ? {} : value.prepareResult.init.modem,
                client: { ...value.prepareResult.init.client, device: {} },
                partialService: { downloadIndex: 0, uploadIndex: 0 },
            }
        }

        return freeze({
            date: dateToPlainDate(date),
            prepareResult: prepareResult,
            benchmarkFinal: benchmarkFinal,
            downloadIntermediates: [],
            downloadFinal: { ...value.downloadFinal, kind: NQValueKind.DownloadFinal },
            uploadIntermediates: [],
            uploadFinal: { ...value.uploadFinal, kind: NQValueKind.UploadFinal },
            pingIntermediates: [],
            pingFinal: { ...value.pingFinal, kind: NQValueKind.PingFinal },
            modemFinal: modemFinal,
            hints: null,
            partialService: null,
        });
    }
}

export const getDeviceType = (deviceType: string | undefined | null): Models.DeviceModelDeviceTypeEnum | undefined => {
    switch (deviceType) {
        case "mobile": return Models.DeviceModelDeviceTypeEnum.Mobile;
        case "tablet": return Models.DeviceModelDeviceTypeEnum.Tablet;
        case "desktop": return Models.DeviceModelDeviceTypeEnum.Desktop;
        case "settop": return Models.DeviceModelDeviceTypeEnum.Settop;
        case "crawler": return Models.DeviceModelDeviceTypeEnum.Crawler;
        case undefined:
        case null:
            return undefined;
    }

    reportHistoryError("Hr-Dc-6", `Unknown value for deviceType: ${deviceType}`);
    return undefined;
}

/* pure */
const decodeNQRecordFromV3_V8 = (value: JsonNQRecord_v3 | JsonNQRecord_v4 | JsonNQRecord_v5 | JsonNQRecord_v6 | JsonNQRecord_v7 | JsonNQRecord_v8): NQRecord | null => {
    const getModemFinal = (): ModemFinalDE | null => {
        switch (value.version) {
            case JsonNQRecordVersion.v3:
            case JsonNQRecordVersion.v4:
            case JsonNQRecordVersion.v5:
                return decodeNQRecordModemFinalV1_V5(value);
            case JsonNQRecordVersion.v6:
            case JsonNQRecordVersion.v7:
            case JsonNQRecordVersion.v8:
                return decodeNQRecordModemFinalV6_V12(value);
        }
    }

    const modemFinal = getModemFinal();
    const date = parseDate(value.date);
    const benchmarkFinal = decodeBenchmarkFinal(value);

    if (R.isNil(modemFinal) || R.isNil(date) || R.isNil(benchmarkFinal)) {
        return null;
    } else {
        const getPartialService = (): PartialServiceModel => {
            switch (value.version) {
                case JsonNQRecordVersion.v3:
                case JsonNQRecordVersion.v4:
                case JsonNQRecordVersion.v5:
                case JsonNQRecordVersion.v6:
                case JsonNQRecordVersion.v7:
                    return { downloadIndex: 0, uploadIndex: 0 }
                case JsonNQRecordVersion.v8:
                    return {
                        downloadIndex: value.prepareResult.init.partialService.downloadIndex ?? 0,
                        uploadIndex: value.prepareResult.init.partialService.uploadIndex ?? 0
                    }
            }
        }

        const prepareResult: NQRecordPrepareResult = {
            ...value.prepareResult,
            init: {
                ...value.prepareResult.init,
                modem: value.prepareResult.init.modem === null ? {} : value.prepareResult.init.modem,
                connection: { ...value.prepareResult.init.connection, ispFootprint: value.prepareResult.init.connection.ispFootprint },
                client: { ...value.prepareResult.init.client, device: { deviceType: getDeviceType(value.prepareResult.init.client.device.deviceType) } },
                partialService: getPartialService(),
            }
        };

        return freeze({
            date: dateToPlainDate(date),
            prepareResult: prepareResult,
            benchmarkFinal: benchmarkFinal,
            downloadIntermediates: [],
            downloadFinal: { ...value.downloadFinal, kind: NQValueKind.DownloadFinal },
            uploadIntermediates: [],
            uploadFinal: { ...value.uploadFinal, kind: NQValueKind.UploadFinal },
            pingIntermediates: [],
            pingFinal: { ...value.pingFinal, kind: NQValueKind.PingFinal },
            modemFinal: modemFinal,
            hints: value.hints,
            partialService: null,
        });
    }
}

/* pure */
const decodeDeviceFromV9_V12 = (value: JsonNQRecord_v9 | JsonNQRecord_v10 | JsonNQRecord_v11 | JsonNQRecord_v12): Models.DeviceModel => {
    switch (value.version) {
        case JsonNQRecordVersion.v9:
        case JsonNQRecordVersion.v10:
            return { deviceType: getDeviceType(value.prepareResult.init.client.device.deviceType) }
        case JsonNQRecordVersion.v11:
        case JsonNQRecordVersion.v12:
            return {
                id: value.prepareResult.init.client.device.id,
                vendor: value.prepareResult.init.client.device.vendor,
                model: value.prepareResult.init.client.device.model,
                inches: value.prepareResult.init.client.device.inches,
                deviceType: getDeviceType(value.prepareResult.init.client.device.deviceType),
            }
    }
}

/* pure */
const decodeNQRecordFromV9_V12 = (value: JsonNQRecord_v9 | JsonNQRecord_v10 | JsonNQRecord_v11 | JsonNQRecord_v12): NQRecord | null => {
    const getPartialService = (): PartialServiceModel => {
        switch (value.version) {
            case JsonNQRecordVersion.v9:
            case JsonNQRecordVersion.v10:
            case JsonNQRecordVersion.v11:
            case JsonNQRecordVersion.v12:
                return {
                    downloadIndex: value.prepareResult.init.partialService.downloadIndex ?? 0,
                    uploadIndex: value.prepareResult.init.partialService.uploadIndex ?? 0
                }
        }
    }

    const getNqPartialService = (): NQRecordPartialService | null => {
        switch (value.version) {
            case JsonNQRecordVersion.v9:
            case JsonNQRecordVersion.v10:
            case JsonNQRecordVersion.v11:
                return null;
            case JsonNQRecordVersion.v12:
                return value.partialService === null ? null : {
                    callbackCounter: value.partialService.callbackCounter,
                    lastModemResetDate: value.partialService.lastModemResetDate === null ? null : parseDate(value.partialService.lastModemResetDate),
                }
        }
    }

    const modemFinal = decodeNQRecordModemFinalV6_V12(value)
    const date = parseDate(value.date);
    const benchmarkFinal = decodeBenchmarkFinal(value);
    if (R.isNil(modemFinal) || R.isNil(date) || R.isNil(benchmarkFinal)) {
        return null;
    } else {
        const prepareResult: NQRecordPrepareResult = {
            ...value.prepareResult,
            init: {
                ...value.prepareResult.init,
                modem: value.prepareResult.init.modem === null ? {} : value.prepareResult.init.modem,
                connection: { ...value.prepareResult.init.connection, ispFootprint: value.prepareResult.init.connection.ispFootprint },
                client: { ...value.prepareResult.init.client, device: decodeDeviceFromV9_V12(value) },
                partialService: getPartialService(),
            }
        };

        return freeze({
            date: dateToPlainDate(date),
            prepareResult: prepareResult,
            benchmarkFinal: benchmarkFinal,
            downloadIntermediates: value.downloadIntermediates,
            downloadFinal: { ...value.downloadFinal, kind: NQValueKind.DownloadFinal },
            uploadIntermediates: value.uploadIntermediates,
            uploadFinal: { ...value.uploadFinal, kind: NQValueKind.UploadFinal },
            pingIntermediates: value.pingIntermediates,
            pingFinal: { ...value.pingFinal, kind: NQValueKind.PingFinal },
            modemFinal: modemFinal,
            hints: value.hints,
            partialService: getNqPartialService(),
        });
    }
}


/* pure */
export const decodeNQRecordModemFinalV1_V5 = (value: JsonNQRecord_v1 | JsonNQRecord_v2 | JsonNQRecord_v3 | JsonNQRecord_v4 | JsonNQRecord_v5): ModemFinalDE => {
    const speedtestState = { downloadClient: true, uploadClient: true, downloadUdp: null }

    switch (value.modemFinal.kind) {
        case "ModemFinalAvailable":
            return {
                kind: NQValueKind.ModemFinal,
                parallelTraffic: value.modemFinal.parallelTraffic,
                connectionType: decodeConnectionType(value.modemFinal.connectionType),
                modemFinal: {
                    kind: ModemFinalKind.ModemFinalAvailable,
                    speed: value.modemFinal.speed,
                },
                speedtestState,
                snmpAvailable: null,
                wifiExtenderMode: null,
                wifiExtenderRegStatus: null,
            }
        case "ModemFinalDidNotRun":
            return {
                kind: NQValueKind.ModemFinal,
                parallelTraffic: null,
                connectionType: null,
                modemFinal: {
                    kind: ModemFinalKind.ModemFinalDidNotRun,
                    reason: decodeModemNotRunReason(value.modemFinal.reason)
                },
                speedtestState,
                snmpAvailable: null,
                wifiExtenderMode: null,
                wifiExtenderRegStatus: null,
            }
        case "ModemFinalFailed":
            return {
                kind: NQValueKind.ModemFinal,
                parallelTraffic: null,
                connectionType: null,
                modemFinal: {
                    kind: ModemFinalKind.ModemFinalFailed,
                    message: value.modemFinal.message,
                },
                speedtestState,
                snmpAvailable: null,
                wifiExtenderMode: null,
                wifiExtenderRegStatus: null,
            }
    }

    logger.error("Unknown kind for ModemFinal", value.modemFinal);
}

/* pure */
const decodeNQRecordModemFinalV6_V12 = (value: JsonNQRecord_v6 | JsonNQRecord_v7 | JsonNQRecord_v8 | JsonNQRecord_v9 | JsonNQRecord_v10 | JsonNQRecord_v11 | JsonNQRecord_v12): ModemFinalDE | null => {
    const modemFinal: ModemFinalAvailableDE | ModemFinalDidNotRunDE | ModemFinalFailedDE = (() => {
        switch (value.modemFinal.modemFinal.kind) {
            case "ModemFinalAvailable":
                return {
                    kind: ModemFinalKind.ModemFinalAvailable,
                    speed: value.modemFinal.modemFinal.speed
                }
            case "ModemFinalDidNotRun":
                return {
                    kind: ModemFinalKind.ModemFinalDidNotRun,
                    reason: decodeModemNotRunReason(value.modemFinal.modemFinal.reason)
                }
            case "ModemFinalFailed":
                return {
                    kind: ModemFinalKind.ModemFinalFailed,
                    message: value.modemFinal.modemFinal.message
                }
        }
    })();

    const speedtestState: SpeedtestState = (() => {
        switch (value.version) {
            case JsonNQRecordVersion.v6:
            case JsonNQRecordVersion.v7:
                return { downloadClient: true, uploadClient: true, downloadUdp: null }
            case JsonNQRecordVersion.v8:
            case JsonNQRecordVersion.v9:
                return {
                    downloadClient: value.modemFinal.speedtestState.downloadIsOk ?? true,
                    uploadClient: value.modemFinal.speedtestState.uploadIsOk ?? true,
                    downloadUdp: null
                }
            case JsonNQRecordVersion.v10:
            case JsonNQRecordVersion.v11:
            case JsonNQRecordVersion.v12:
                return value.modemFinal.speedtestState;
        }
    })();

    return {
        kind: NQValueKind.ModemFinal,
        parallelTraffic: value.modemFinal.parallelTraffic,
        connectionType: decodeConnectionType(value.modemFinal.connectionType),
        modemFinal,
        speedtestState,
        snmpAvailable: null,
        wifiExtenderMode: null,
        wifiExtenderRegStatus: null,
    }
}

/* pure */
export const decodeModemNotRunReason = (reason: "NotNeeded" | "NotAvailable" | "NotACustomer"): ModemNotRunReason => {
    switch (reason) {
        case "NotACustomer":
            return ModemNotRunReason.NotACustomer;
        case "NotAvailable":
            return ModemNotRunReason.NotAvailable;
        case "NotNeeded":
            return ModemNotRunReason.NotNeeded;
    }

    logger.error("Decode History: invalid modem did not run reason value", reason);
}

/* pure */
export const decodeConnectionType = (connectionType: "wlan24" | "wlan5" | "lan" | null): Models.SnmpResultModelConnectionTypeEnum | null => {
    switch (connectionType) {
        case "wlan24":
            return Models.SnmpResultModelConnectionTypeEnum.Wlan24
        case "wlan5":
            return Models.SnmpResultModelConnectionTypeEnum.Wlan5
        case "lan":
            return Models.SnmpResultModelConnectionTypeEnum.Lan
        case null:
            return null;
    }

    logger.error("Decode History: invalid connection type value", connectionType);
}

/* pure */
export const parseDate = (dateStr: string): Date | null => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
        reportHistoryError("Hr-Dc-5", `During NQRecord parsing, could not parse this string as date: "${dateStr}"`);
        return null;
    }
    return date;
}

/* pure */
export const decodeBenchmarkFinal = (value: VersionedJsonNQRecord): BenchmarkFinalDE => {
    switch (value.version) {
        case JsonNQRecordVersion.v1:
            return { ...value.benchmarkFinal, kind: NQValueKind.BenchmarkFinalAvailable };
        case JsonNQRecordVersion.v2:
        case JsonNQRecordVersion.v3:
        case JsonNQRecordVersion.v4:
        case JsonNQRecordVersion.v5:
        case JsonNQRecordVersion.v6:
        case JsonNQRecordVersion.v7:
        case JsonNQRecordVersion.v8:
        case JsonNQRecordVersion.v9:
        case JsonNQRecordVersion.v10:
        case JsonNQRecordVersion.v11:
        case JsonNQRecordVersion.v12:
            switch (value.benchmarkFinal.kind) {
                case "BenchmarkFinalAvailable":
                    return { ...value.benchmarkFinal, kind: NQValueKind.BenchmarkFinalAvailable };
                case "BenchmarkFinalDidNotRun":
                    return { kind: NQValueKind.BenchmarkFinalDidNotRun };
            }
    }
}