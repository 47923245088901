import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { NODE_ENV, SDK_KIND, SEO_ENABLED, VARIANT } from '../../config';
import { logger } from '../../helper/logger';
import { allowSentryReport } from '../../helper/utils/allowSentryReport';
import { AppThunk } from '../../store';
import sentryObject from './sentryObject';
import * as Sentry from "@sentry/nextjs";
import { complete as measurementComplete, updatePrepareResult } from '../measurement/measurementSlice';
import { reportNQError } from '../errors/errorSlice';
import sentryCaptureError from './sentryCaptureError';
import { createNqError, LogCallback, LoggerLevel } from '@visonum/network-quality-sdk';
import { getPackageVersion } from '../../helper/packageJsonHelper';
import { CustomSentryMessage, SentryTags } from './types';
import { getSdk } from '../../networkQuality';
import { ConsentState } from '../consent/consentSlice';
import { isRunningInBrowser } from 'src/helper/ssr';

interface SentryReportsState {
    beforeUnloadCaused: boolean;
    started: boolean;
}

const initialState: SentryReportsState = {
    beforeUnloadCaused: false,
    started: false
}

const allowSentry = (consentState: ConsentState): boolean => {
    const allowFromUrl = allowSentryReport();
    if (allowFromUrl !== null) {
        return allowFromUrl;
    }

    const allowedForVariant = VARIANT === "SPLUS" || VARIANT === "SCA" || VARIANT === "SPLUS3" || (VARIANT === "TV" && SEO_ENABLED === "false");

    switch (NODE_ENV) {
        case "development":
            return allowedForVariant && consentState.bugReports;
        case "test":
            return false;
        default:
            return allowedForVariant && consentState.bugReports;
    }
}

export const initSentryReports = (): AppThunk => (dispatch, getState) => {
    logger.setCallback(loggerCallback);

    const consentState = getState().consent;

    const enabled = allowSentry(consentState);
    logger.info(`Init Sentry. Sentry is enabled = ${enabled}`);
    if (enabled) {
        dispatch(startSentryReports());
    } else {
        dispatch(stopSentryReports());
    }
}

const getDsn = (isProd: boolean): string => {
    return isProd ?
        "https://f27ae16775c044f69d7954ba2b4a3721@sentry.aws.anw.net/7" :
        "https://5e404c14540644a8b0f76a1425fa2384@sentry-test.aws.anw.net/7";
}

export const startSentryReports = (): AppThunk => (dispatch, getState) => {
    if (getState().sentryReports.started) {
        return;
    }

    logger.info("start sentry");

    const dsn = getDsn(SEO_ENABLED === "true");

    sentryObject.init({
        enabled: true,
        dsn,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
        environment: NODE_ENV,
    });

    dispatch(updateStarted(true));

    sentryObject.setUser({ id: getSdk().clientId(), ip_address: "{{auto}}" });

    sentryObject.setTag("FrontendVersion", getPackageVersion());
    sentryObject.setTag("VARIANT", VARIANT);
    sentryObject.setTag("SDK_KIND", SDK_KIND);
}

export const stopSentryReports = (): AppThunk => (dispatch, getState) => {
    if (!getState().sentryReports.started) {
        return;
    }

    logger.info("stop sentry");

    sentryObject.init({
        enabled: false,
        attachStacktrace: false,
        autoSessionTracking: false,
        sendClientReports: false,


    });

    dispatch(updateStarted(false));
}

export const sentryReportsBeforeUnload = () => {
    sendSentryMessage(CustomSentryMessage.BeforeUnload);
}

export const sentryReportsCancellationDialogOpened = () => {
    sendSentryMessage(CustomSentryMessage.CancellationDialogOpened);
}

export const sentryReportsCancellationDialogStopMeasurement = () => {
    sendSentryMessage(CustomSentryMessage.CancellationDialogStopMeasurement);
}

export const sentryReportsCancellationDialogContinueMeasurement = () => {
    sendSentryMessage(CustomSentryMessage.CancellationDialogStopMeasurement);
}

const loggerLevelToSeverity = (level: LoggerLevel): Sentry.SeverityLevel => {
    switch (level) {
        case "silent":
            throw createNqError("Cl-14", `LoggerLevel is out of order: ${level}`);
        case "trace": return "debug";
        case "debug": return "debug";
        case "info": return "info"
        case "warn": return "warning";
        case "error": return "error";
        case "fatal": return "fatal";
    }
}

const loggerCallback: LogCallback = ({ level, message, object }) => {
    if (level === "silent") {
        return;
    }

    sentryObject.addBreadcrumb({
        category: "Logger",
        level: loggerLevelToSeverity(level),
        message: message,
        data: {
            object: object,
        }
    });
}

const sendSentryMessage = (message: CustomSentryMessage, tags?: SentryTags) => {
    const captureContext = R.isNil(tags) ? undefined : { tags }

    switch (message) {
        case CustomSentryMessage.BeforeUnload:
            sentryObject.captureMessage("beforeunload", captureContext);
            break;
        case CustomSentryMessage.CancellationDialogOpened:
            sentryObject.captureMessage("cancellation dialog - opened", captureContext);
            break;
        case CustomSentryMessage.CancellationDialogStopMeasurement:
            sentryObject.captureMessage("cancellation dialog - stop measurement", captureContext);
            break;
        case CustomSentryMessage.CancellationDialogContinueMeasurement:
            sentryObject.captureMessage("cancellation dialog - continue measurement", captureContext);
            break;
        case CustomSentryMessage.MeasurementCompleted:
            sentryObject.captureMessage("measurement completed", captureContext);
            break;
    }
}

const sentryReportsSlice = createSlice({
    name: 'sentryReports',
    initialState,
    reducers: {
        updateStarted: (state, action: PayloadAction<boolean>) => {
            state.started = action.payload;
        },
        updateBeforeUnloadCaused: (state, action: PayloadAction<boolean>) => {
            state.beforeUnloadCaused = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(measurementComplete, (state, _) => {
                if (state.beforeUnloadCaused) {
                    state.beforeUnloadCaused = false;
                    sendSentryMessage(CustomSentryMessage.MeasurementCompleted);
                }
            })
            .addCase(updatePrepareResult, (_, action) => {
                sentryObject.setTag("SpeedtestID", action.payload.init.speedtest.id);
            })
            .addCase(reportNQError, (_, action) => {
                sentryCaptureError(action.payload);
            })
    }
});

export const { updateBeforeUnloadCaused, updateStarted } = sentryReportsSlice.actions

export default sentryReportsSlice;