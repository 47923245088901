import PartialServiceCallbackDialog from "../../../src/features/partialService/PartialServiceCallbackDialog";
import PartialServiceResetDialog from "../../../src/features/partialService/PartialServiceResetDialog";
import { useAppSelector } from "../../../src/hooks";
import CancellationDialog from "../../components/cancellation/CancellationDialog";
import ModemSwapMessage from "../../components/customerCommunication/ModemSwap";
import PrivacyPolicyDialog from "../../components/elements/PrivacyPolicyDialog";
import { DialogKind } from "../../features/dialogManager/dialogManagerSlice";
import ErrorDialog from "../../features/errors/ErrorDialog";
import { AppAssertionError } from "../../features/errors/AppAssertionError";
import { devAssert } from "../../features/errors/devAssert";
import { PartialServiceDialogKind } from "../../features/partialService/partialServiceUtils";
import ConsentDialog from "../splus3/dialogs/ConsentDialog";

const SPlusDialogSwitcher: React.FC<{}> = () => {
    const state = useAppSelector(state => state.dialogManager);

    switch (state.kind) {
        case DialogKind.None:
            return null;
        case DialogKind.PartialService:
            switch (state.partialServiceDialog) {
                case PartialServiceDialogKind.Reset:
                    return <PartialServiceResetDialog />
                case PartialServiceDialogKind.Callback:
                    return <PartialServiceCallbackDialog />
            }
        case DialogKind.PrivacyPolicy:
            return <PrivacyPolicyDialog />;
        case DialogKind.Consent:
            return <ConsentDialog />;
        case DialogKind.Error:
            return <ErrorDialog lastError={state.error} />;
        case DialogKind.ModemSwap:
            return <ModemSwapMessage />;
        case DialogKind.SpeedtestCancellation:
            return <CancellationDialog />
        case DialogKind.RemoveHistory:
            devAssert(new AppAssertionError({ message: "Wrong dialog kind.", actual: state.kind }), `Wrong dialog kind: ${state.kind}`);
            return null;
    }
}

export default SPlusDialogSwitcher;