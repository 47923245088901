import { Link, Page, Text, View, Font, Document, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import VodafoneLt from "../styles/fonts/VodafoneLt.ttf"
import VodafoneRg from "../styles/fonts/VodafoneRg.ttf"
import VodafoneBold from "../styles/fonts/vodafonergbd-webfont.ttf"
import React, { ReactElement } from 'react';
import { NQRecord } from './features/history/types';
import { getCurrentLang } from './helper/utils';
import { getInputFromNqRecord, getPartialServiceState, PartialServiceState } from './features/partialService/partialServiceUtils';
import { Models, NQHint } from '@visonum/network-quality-sdk';
import { LangName, plainDateFrom } from './types';
import { hintIdsToHint3Objects, serverHintsToClientHintIds } from './features/hints/hintFunctions';
import vfLogo from '../public/img/logo-vf-new.png';
import t from './helper/t';
import { formatSpeed } from './helper/formatSpeeds';
import { formatDate, formatPercentage, formatPingTime, formatTime } from './helper/unitsFormatting';
import { marked, MarkedToken } from 'marked';


Font.register({
    family: 'VodafoneLt',
    src: VodafoneLt,
});

Font.register({
    family: 'VodafoneBold',
    src: VodafoneBold,
});

Font.register({
    family: 'VodafoneRg',
    src: VodafoneRg,
});

const colors = {
    darkGrey: "#9D9D9D",
    grey: "#C4C4C4",
}

const Footer = ({ left, center, right }: { left?: string, center: string, right: string }) => {
    const styles = StyleSheet.create({
        footer: {
            position: 'absolute',
            fontSize: 9,
            bottom: 30,
            left: 40,
            right: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        },
        itemLeft: {
            flex: 1,
            textAlign: 'left',
        },
        itemCenter: {
            flex: 1,
            textAlign: 'center',
        },
        itemRight: {
            flex: 1,
            textAlign: 'right',
        },
    });

    const date = plainDateFrom(new Date());

    return (
        <View style={styles.footer} fixed>
            {/* See: [Cannot show copyright symbol in PDF files](https://github.com/diegomura/react-pdf/issues/2277) */}
            <Text style={styles.itemLeft}>Ω  {left}</Text>

            <Text style={styles.itemCenter} render={({ pageNumber, totalPages }) => center
                .replace(/%n/g, `${pageNumber}`)
                .replace(/%p/g, `${totalPages}`)}></Text>

            <Text style={styles.itemRight} >{right
                .replace(/%d/g, formatDate(date))
                .replace(/%t/g, formatTime(date))}</Text>
        </View>
    )
}

const Header = ({ title }: { title?: string }) => {
    const styles = StyleSheet.create({
        root: {
            position: 'absolute',
            top: 30,
            left: 40,
            right: 40,
            height: 60,
            display: 'flex',
            flexDirection: 'row',
            alignItems: "center"
        },
        logo: {
            position: 'absolute',
            width: 45,
            marginRight: 40,
            padding: 3

        },
        title: {
            fontFamily: 'VodafoneBold',
            color: "black",
            fontSize: 28,
            flex: 1,
            textAlign: "center",
        },
    })

    return (
        <View style={styles.root} fixed>
            <Image src={vfLogo.src} style={styles.logo} />
            <Text style={styles.title} render={({ pageNumber }) =>
                pageNumber === 1 ? title : ""} />
        </View>
    )
}

const Box = ({ headline, mainNumber, info }: { headline: string, mainNumber?: ReactElement, info?: ReactElement }) => {
    const styles = StyleSheet.create({
        root: {
            height: 85,
            padding: 10,
            marginTop: 10,
            border: `1px solid ${colors.darkGrey}`,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 5
        },
        headline: {
            fontSize: 10,
        },
        mainNumber: {
        },
        info: {
            fontSize: 10,
        }
    });

    return (
        <View style={styles.root}>
            <Text style={styles.headline}>{headline}</Text>
            <Text style={styles.mainNumber}>{mainNumber}</Text>
            <Text style={styles.info}>{info}</Text>
        </View>
    )
}

const Boxes = ({ boxesTopRow, boxesBottomRow }: {
    boxesTopRow: (ReactElement | null)[],
    boxesBottomRow: (ReactElement | null)[],
}) => {
    const styles = StyleSheet.create({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginTop: 0
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            rowGap: 20,
            columnGap: 30,
        },
    });

    return (
        <View style={styles.root}>
            <View style={styles.row}>
                {boxesTopRow}
            </View>
            <View style={styles.row}>
                {boxesBottomRow}
            </View>
        </View>
    )
}

const Tipp = ({ tipp }: { tipp: Models.ReportModelTip }) => {
    const styles = StyleSheet.create({
        root: {
            marginTop: 10,
            marginBottom: 20,
        },
        title: {
            fontSize: 13,
            fontFamily: 'VodafoneBold',
        },
        teaser: {
            marginTop: 5,
            fontSize: 10,
        },
        contentTitle: {
            fontSize: 11,
            fontFamily: 'VodafoneBold',
            marginTop: 5,
        },
        content: {
            marginTop: 5,
            fontSize: 10,
        }
    });

    return (
        <View style={styles.root} wrap={false}>
            <Text style={styles.title}>{tipp.title}</Text>

            <View style={styles.teaser}>
                <Md md={tipp.teaser} />
            </View>

            <Text style={styles.contentTitle}>{tipp.contentTitle}</Text>

            <View style={styles.content}>
                <Md md={tipp.content || ""} />
            </View>
        </View>
    )
}

const InfoTable = ({ data, headline }: { headline: string, data: { key: string, value: string }[] }) => {
    const styles = StyleSheet.create({
        root: {
            marginTop: 30,
        },
        headline: {
            fontSize: 14,
            marginBottom: 10,
        },
        table: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 5,
            fontSize: 10,
            paddingBottom: 5,
        },
        cellKey: {
            width: 100,
        },
        cellValue: {
            flex: 1,
        },
    });
    return (
        <View style={styles.root}>
            <Text style={styles.headline}>{headline}</Text>
            <View style={styles.table}>
                {data.map(({ key, value }) =>
                    <View style={styles.row}>
                        <Text style={styles.cellKey}>{key}</Text>
                        <Text style={styles.cellValue}>{value}</Text>
                    </View>
                )}
            </View>
        </View>
    )
}

const Md = ({ md }: { md: string }): React.ReactElement => {
    const tokens = marked.lexer(md) as MarkedToken[];
    return (
        <View>
            {tokens.map((t) => fromMarked(t))}
        </View>
    )
}

const fromMarked = (token: MarkedToken): React.ReactNode => {
    switch (token.type) {
        case 'paragraph':
            return (
                <MdParagraph>
                    {token.tokens.map((t) => fromMarked(t as MarkedToken))}
                </MdParagraph>
            )

        case 'text':
            return <Text>{decodeHTMLEntities(token.text)}</Text>

        case 'heading':
            return (
                <MdHeading level={token.depth}>
                    {token.text}
                </MdHeading>
            )

        case "link":
            return (
                <MdLink href={token.href}>
                    {token.text}
                </MdLink>
            )

        case "space":
            return <Text />

        case "image":
            if (token.href.endsWith(".png") || token.href.endsWith(".jpg")) {
                return <Image style={{ width: 40, height: 40 }} src={token.href} />
            } else {
                console.error(`Unknown image format: ${token.href}`);
                return null;
            }

        case "list":
            return (
                <View>
                    {token.items.map((t) => fromMarked(t))}
                </View>
            )

        case "list_item":
            return (
                <MdListItem>
                    {token.tokens.map((t) => fromMarked(t as MarkedToken))}
                </MdListItem>
            )

        default:
            console.error(`Unknown token type: ${token.type}`);
            return null;
    }
}

const BigNumberDisplay = ({ realValue, bookedValue }: { realValue: string, bookedValue?: string | null }) => {
    const styles = StyleSheet.create({
        root: {
            fontSize: 28,
            fontFamily: 'VodafoneBold',
        },
        pipe: {
            color: colors.grey,
        },
        realValue: {
        },
        bookedValue: {
            color: colors.grey,
        }
    });

    return (
        <View style={styles.root}>
            <Text style={styles.realValue}>{realValue}</Text>
            {bookedValue && <Text>
                <Text style={styles.pipe}> | </Text>
                <Text style={styles.bookedValue}>{bookedValue}</Text>
            </Text>}
        </View>
    )
}

const TarifInfo = () => {
    const styles = StyleSheet.create({
        root: {
        }
    });

    return (
        <Text style={styles.root}></Text>
    )
}

const MdListItem = ({ children }: { children: React.ReactNode }) => {
    const styles = StyleSheet.create({
        root: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 5,
        },
        bullet: {
            width: 10,
        },
        text: {
            flex: 1,
        }
    });

    return (
        <View style={styles.root}>
            <Text style={styles.bullet}>•</Text>
            <Text style={styles.text}>{children}</Text>
        </View>
    )
}

const MdParagraph = ({ children }: { children: React.ReactNode }) => {
    const styles = StyleSheet.create({
        paragraph: {
            marginTop: 0,
            marginBottom: 3,
            fontSize: 10,
        }
    });
    return (
        <Text style={styles.paragraph}>
            {children}
        </Text>
    )
}

const MdHeading = ({ children, level }: { children: React.ReactNode, level: number }) => {
    const styles = StyleSheet.create({
        heading: {
            marginTop: 5,
            marginBottom: 3,
            fontSize: {
                1: 11.5,
                2: 11,
                3: 10.5,
                4: 10
            }[level],
            fontFamily: 'VodafoneBold',
        }
    });
    return (
        <Text style={styles.heading}>
            {children}
        </Text>
    )
}

const MdLink = ({ href, children }: { href: string, children: React.ReactNode }) => {
    if (href.startsWith("javascript:")) {
        return <></>
    }

    const styles = StyleSheet.create({
        link: {
            color: "#2e4489",
            textDecoration: "underline"
        }
    });
    return (
        <Text>
            <Link src={href} style={styles.link}>
                {children}
            </Link>
            <Text> </Text>
            ({shortenLink(href)})
        </Text>
    )
}

const shortenLink = (link: string): string => {
    return link
        .replaceAll(/^https?:\/\//g, "")
        .replaceAll(/\/$/g, "")
}

const Tipps = ({ headline, children }: { headline: string, children: React.ReactNode }) => {
    const styles = StyleSheet.create({
        headline: {
            fontSize: 15,
            marginTop: 30,
            fontFamily: 'VodafoneBold',
            marginBottom: 15,
        }
    });

    return (
        <View>
            <Text style={styles.headline}>{headline}</Text>
            {children}
        </View>
    )
}

const BookedPercentageInfo = ({ percentage }: { percentage: string | null }) => {
    const styles = StyleSheet.create({
        root: {
            display: 'flex'
        },
        percentage: {
            fontFamily: 'VodafoneBold',
            fontSize: 14
        },
        text: {
            fontSize: 10,
            fontFamily: 'VodafoneRg',
        }
    });
    return (
        <View style={styles.root}>
            <Text style={styles.percentage}>{percentage}% </Text>
            <Text style={styles.text}>{t("Deines gebuchten Tarifs [pdf]")}</Text>
        </View>
    )
}

const DownloadBox = ({ record }: { record: NQRecord }) => {
    const bookedValue = record.prepareResult.init.modem.bookedDownloadSpeedMax;
    const realValue = record.downloadFinal.speed;

    return (
        <Box
            headline={t("Download zum Computer in Mbit/s [pdf]")}
            mainNumber={
                <BigNumberDisplay
                    realValue={valueOrEmpty(formatSpeed([realValue]).values[0])}
                    bookedValue={bookedValue ? formatSpeed([bookedValue]).values[0] : null} />}
            info={
                <BookedPercentageInfo
                    percentage={formatPercentage(realValue, bookedValue)} />
            }
        />
    )
}

const ModemBox = ({ record }: { record: NQRecord }) => {
    const bookedValue = record.prepareResult.init.modem.bookedDownloadSpeedMax

    switch (record.modemFinal.modemFinal.kind) {
        case 'ModemFinalAvailable':
            const realValue = record.modemFinal.modemFinal.speed;

            return (
                <Box
                    headline={t("Download zum Router in Mbit/s [pdf]")}
                    mainNumber={
                        <BigNumberDisplay
                            realValue={valueOrEmpty(formatSpeed([realValue]).values[0])}
                            bookedValue={bookedValue ? formatSpeed([bookedValue]).values[0] : null} />}
                    info={
                        <BookedPercentageInfo
                            percentage={formatPercentage(realValue, bookedValue)} />}
                />)

        case 'ModemFinalFailed':
            return (
                <Box
                    headline={t("Download zum Router in Mbit/s [pdf]")}
                    mainNumber={
                        <BigNumberDisplay
                            realValue={valueOrEmpty(null)}
                            bookedValue={bookedValue ? formatSpeed([bookedValue]).values[0] : null} />}
                    info={<Text>{t("Die Messung hat leider nicht geklappt. [pdf]")}</Text>}
                />)

        case 'ModemFinalDidNotRun':
            return null

        default:
            return null;
    }
}

const PingBox = ({ record }: { record: NQRecord }) =>
    <Box
        headline={t("Ping in ms [pdf]")}
        mainNumber={<BigNumberDisplay
            realValue={valueOrEmpty(formatPingTime(record.pingFinal.pingTime))}
        />}
    />

const UploadBox = ({ record }: { record: NQRecord }) => {
    const realValue = record.uploadFinal.speed;
    const bookedValue = record.prepareResult.init.modem.bookedUploadSpeedMax;

    return (
        <Box
            headline={t("Upload in Mbit/s [pdf]")}
            mainNumber={
                <BigNumberDisplay
                    realValue={valueOrEmpty(formatSpeed([realValue]).values[0])}
                    bookedValue={bookedValue ? formatSpeed([bookedValue]).values[0] : null} />}
            info={
                <BookedPercentageInfo
                    percentage={formatPercentage(realValue, bookedValue)} />}
        />
    )
}

export const PdfReport = ({ record }: { record: NQRecord }) => {
    const styles = StyleSheet.create({
        document: {
            fontFamily: 'VodafoneRg',
        },
        page: {
            paddingTop: 100,
            paddingBottom: 60,
            paddingLeft: 40,
            paddingRight: 40,
        }
    })

    const lang = getCurrentLang();
    const tips = getTips(lang, getPartialServiceState(getInputFromNqRecord(record)), record);

    return (
        <Document style={styles.document}>
            <Page size="A4" style={styles.page}>
                <Header title={t("Internet Speedtest Plus [pdf]")} />

                <Boxes
                    boxesTopRow={[
                        <DownloadBox record={record} />,
                        <ModemBox record={record} />]}
                    boxesBottomRow={[
                        <UploadBox record={record} />,
                        <PingBox record={record} />]}
                />

                <InfoTable
                    headline={t("Details zu Deinem Speedtest: [pdf]")}
                    data={[
                        {
                            key: t("Speedtest-ID [pdf]"),
                            value: record.prepareResult.init.speedtest.id
                        },
                        {
                            key: t("Durchgeführt am [pdf]"),
                            value: `${formatDate(record.date)} - ${formatTime(record.date)}`
                        },
                        {
                            key: t("Anbieter [pdf]"),
                            value: valueOrEmpty(record.prepareResult.init.connection.isp)
                        },
                        {
                            key: t("Deine IP-Adresse [pdf]"),
                            value: valueOrEmpty(record.prepareResult.init.connection.ip)
                        },
                        {
                            key: t("Dein Router [pdf]"),
                            value: valueOrEmpty(record.prepareResult.init.modem.name)
                        },
                        {
                            key: t("CMTS Hersteller [pdf]"),
                            value: valueOrEmpty(record.prepareResult.init.cmts?.vendor)
                        },
                    ]}
                />

                <Tipps
                    headline={t("Unsere Tipps für Dein optimales Heimnetzwerk")}>
                    {[...tips || []].map(t => <Tipp tipp={t} />)}
                </Tipps>

                <Footer
                    left={t("© 2024 Vodafone GmbH [pdf]")}
                    center={t("Seite %n von %p [pdf]")}
                    right={t("Erstellt am %d um %t Uhr [pdf]")} />
            </Page>
        </Document>
    )
};


const valueOrEmpty = (value: string | null | undefined): string => value ? value : "--";

export async function runPrintRequest(record: NQRecord): Promise<Blob> {
    try {
        const asPdf = pdf();
        asPdf.updateContainer(React.createElement(PdfReport, { record }));
        const pdfBlob = await asPdf.toBlob();
        return pdfBlob
    } catch (error) {
        console.error(`Error generating PDF: ${error}`);
        throw error;
    }
}

const getTips = (lang: LangName, partialServiceState: PartialServiceState, record: NQRecord): Models.ReportModelTip[] | null => {
    if (record.hints === null) {
        return null;
    }

    const nqHints = serverHintsToClientHintIds(record.hints.map(id => ({ id } as NQHint)), partialServiceState);
    return hintIdsToHint3Objects(nqHints, record, lang)
        .map(h => ({
            version: Models.ReportModelTipVersionEnum.V3,
            id: h.id,
            title: h.quickTipTitle,
            teaserTitle: "",
            teaser: h.expandedTipBody,
            contentTitle: h.expandedTipSubline,
            content: h.expandedTipSublineBody,
        }));
}


const decodeHTMLEntities = (encodedString: string): string => {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
}