import { css } from "@emotion/react";
import { freeze } from "immer";

const liteFontFamily = "vodafone-lite";
const regularFontFamily = "vodafone-regular";
const boldFontFamily = "vodafone-bold";

const breakpoints = {
    smallDesktop: "@media (max-width: 1024px)",
    mobile: "@media (max-width: 768px)",
    minimal: "@media (max-width: 320px)",
}

export const commonTheme = freeze({
    typography: {
        regularFontFamily,
        lite: css({
            fontFamily: liteFontFamily,
        }),
        regular: css({
            fontFamily: regularFontFamily,
        }),
        bold: css({
            fontFamily: boldFontFamily,
        }),
        h1: css({
            fontFamily: liteFontFamily,
            fontSize: 56,
            lineHeight: "125%",
        }),
        h1m: css({
            fontFamily: boldFontFamily,
            fontSize: 18,
            lineHeight: "156%",
        }),
        h2: css({
            fontFamily: liteFontFamily,
            fontSize: 40,
            lineHeight: "120%",
        }),
        h2Bold: css({
            fontFamily: boldFontFamily,
            fontSize: 40,
        }),
        h2m: css({
            fontFamily: liteFontFamily,
            fontSize: 16,
            lineHeight: "125%",
        }),
        h3: css({
            fontFamily: boldFontFamily,
            fontSize: 34,
            lineHeight: "125%",
        }),
        font36Bold: css({
            fontFamily: boldFontFamily,
            fontSize: 36,
        }),
        font32Bold: css({
            fontFamily: boldFontFamily,
            fontSize: 32,
        }),
        font28Lite: css({
            fontFamily: liteFontFamily,
            fontSize: 28,
        }),
        font24: css({
            fontFamily: regularFontFamily,
            fontSize: 24,
            lineHeight: "125%",
        }),
        font24Bold: css({
            fontFamily: boldFontFamily,
            fontSize: 24,
        }),
        font16Lite: css({
            fontFamily: liteFontFamily,
            fontSize: 16,
            lineHeight: "125%",
        }),
        font16: css({
            fontFamily: regularFontFamily,
            fontSize: 16,
        }),
        font16Bold: css({
            fontFamily: boldFontFamily,
            fontSize: 16,
            lineHeight: "150%",
        }),
        font15: css({
            fontFamily: regularFontFamily,
            fontSize: 15,
        }),
        large: css({
            fontFamily: regularFontFamily,
            fontSize: 20,
            lineHeight: "150%",
        }),
        largeBold: css({
            fontFamily: boldFontFamily,
            fontSize: 20,
            lineHeight: "150%",
        }),
        defaultLite: css({
            fontFamily: liteFontFamily,
            fontSize: 18,
            lineHeight: "150%",
        }),
        default: css({
            fontFamily: regularFontFamily,
            fontSize: 18,
            lineHeight: "150%",
        }),
        defaultBold: css({
            fontFamily: boldFontFamily,
            fontSize: 18,
        }),
        smallLite: css({
            fontFamily: liteFontFamily,
            fontSize: 14,
        }),
        small: css({
            fontFamily: regularFontFamily,
            fontSize: 14,
            lineHeight: "150%",
        }),
        smallBold: css({
            fontFamily: boldFontFamily,
            fontSize: 14,
        }),
        font12Lite: css({
            fontFamily: liteFontFamily,
            fontSize: 12,
            lineHeight: "133.33%",
        }),
        font12: css({
            fontFamily: liteFontFamily,
            fontSize: 12,
        }),
        font12Bold: css({
            fontFamily: boldFontFamily,
            fontSize: 12,
        }),
        font10: css({
            fontFamily: liteFontFamily,
            fontSize: 10,
        }),
    },
    breakpoints,
} as const);