import styled from "@emotion/styled";
import { CommonButtonDiv } from "../../elements/Buttons";

export const RowDivStyleDesktop = {
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "auto min(14vw, 200px) repeat(4, 1fr)",
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 3,
}

export const RowDivStyleMobile = {
    display: "grid",
    flexGrow: 10,
    gridTemplateRows: "1fr",
    gridTemplateColumns: "auto repeat(5, 1fr)",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 0,
    columnGap: 8,
}

export const ExpanderContainer = styled.div([
    {
        gridColumn: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 40,
    }
]);

export const CheckBoxContainer = styled.div(({ theme }) => ([
    {
        gridColumn: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 40,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                width: "auto",
            }
        ],
    }
]));

export const FirstCellDiv = styled.div(({ theme }) => ([
    {
        paddingTop: 8,
        paddingBottom: 8,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "flex",
                paddingTop: 4,
                paddingBottom: 4,
            }
        ],
    }
]));

export const RemoveButtonContainer = styled.div([
    {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 56,

        cursor: "pointer",
    }
]);

interface ButtonDivProps {
    isEnabled?: boolean;
}

export const ButtonDiv = styled(CommonButtonDiv)<ButtonDivProps>(({ theme, isEnabled = true }) => ([
    {
        display: "flex",
        paddingTop: 6,
        paddingBottom: 6,
        columnGap: 8,
        paddingLeft: 22,
        paddingRight: 22,
        minWidth: 0,
        lineHeight: "20px",

        opacity: isEnabled ? 1 : 0.38,
    }
]));