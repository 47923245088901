import { ModemFinalKind } from "@visonum/network-quality-sdk";
import { NQRecord } from "../../../../features/history/types";
import { formatSpeed } from "../../../../helper/formatSpeeds";
import { getRouterSpeed } from "../../../../helper/utils";

export const RecordsPerPageValueList = [4, 10, 20];

export const PageBlockSize = 5;

export const historyRecordSpeedUnits = (records: NQRecord[]) => {
    const routerSpeedValues = formatSpeed(records.map(r => r.modemFinal.modemFinal.kind === ModemFinalKind.ModemFinalAvailable ? r.modemFinal.modemFinal.speed : null));
    const downloadSpeedValues = formatSpeed(records.map(r => r.downloadFinal.speed));
    const uploadSpeedValues = formatSpeed(records.map(r => r.uploadFinal.speed));

    return {
        routerSpeedUnit: routerSpeedValues.unit,
        downloadSpeedUnit: downloadSpeedValues.unit,
        uploadSpeedUnit: uploadSpeedValues.unit,
    }
}

export const historyRecordSpeedValues = (records: NQRecord[], record: NQRecord) => {
    const routerSpeed = getRouterSpeed(record.modemFinal);
    const routerSpeedValue = routerSpeed === null ? null : formatSpeed(records.map(r => getRouterSpeed(r.modemFinal)), routerSpeed);
    const downloadSpeedValue = formatSpeed(records.map(r => r.downloadFinal.speed), record.downloadFinal.speed);
    const uploadSpeedValue = formatSpeed(records.map(r => r.uploadFinal.speed), record.uploadFinal.speed);

    return {
        routerSpeedStr: routerSpeedValue === null ? null : routerSpeedValue.value,
        downloadSpeedStr: downloadSpeedValue.value,
        uploadSpeedStr: uploadSpeedValue.value,
    }
}