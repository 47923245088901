import styled from "@emotion/styled";
import { r } from "../../../theme/gigatv/sizeUtils";
import markdownRender from "../../../helper/markdownRender";

import impressumJson from "../../../../public/assets/gigatv/de/impressum.json";

const RootDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.regular,
    theme.gigatv.typography.fontSize28,
    theme.gigatv.scroll.tvScroll,
    {
        lineHeight: "133%",
        letterSpacing: r(0.28),
    },
]));

interface ImpressumProps {
}

export const impressumScrollId = "gigatv-impressum-scroll";

const Impressum: React.FC<ImpressumProps> = () => {
    return (
        <RootDiv id={impressumScrollId} dangerouslySetInnerHTML={{ __html: markdownRender(impressumJson.content) }}>
        </RootDiv>
    );
}

export default Impressum;