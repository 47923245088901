import styled from "@emotion/styled";
import { StaticImageData } from "next/image";

export const MeasurementItemWidth = 200;
export const MeasurementItemHeight = 188;
export const MeasurementItemBorderWidth = 3;

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    }
]);

interface MainAreaDivProps {
    isActive: boolean;
}

const MainAreaDiv = styled.div<MainAreaDivProps>(({ theme, isActive }) => ([
    theme.gigatv.colors.foreground.primary,
    isActive ? [
        theme.gigatv.colors.background.measurementItemActive,
        theme.gigatv.colors.border.active,
    ] : [
        theme.gigatv.colors.background.measurementItem,
        theme.gigatv.colors.border.measurementItem,
    ],
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        width: MeasurementItemWidth - 2 * MeasurementItemBorderWidth,
        height: MeasurementItemHeight - 2 * MeasurementItemBorderWidth,

        borderRadius: 6,
        borderStyle: "solid",
        borderWidth: MeasurementItemBorderWidth,
    }
]));

const CaptionTextDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.fontSize28Abs,
    theme.gigatv.typography.bold,
    {
        marginTop: 22,
    }
]));

const ValueTextDiv = styled.div(({ theme }) => ([
    theme.gigatv.typography.fontSize24Abs,
    theme.gigatv.typography.regular,
    {
        marginTop: 6,
        maxWidth: MeasurementItemWidth - 40,
        maxHeight: 54,

        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "center",
    }
]));

const ImageAreaDiv = styled.div([
    {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
    }
]);

interface MeasurementItemProps {
    caption: string;
    value?: string | null;
    icon?: {
        source: StaticImageData,
        verticalShift: number,
    },
    isActive: boolean;
}

const MeasurementItem: React.FC<MeasurementItemProps> = ({ caption, value, icon, isActive }) => {
    return (
        <RootDiv>
            <MainAreaDiv isActive={isActive}>
                <CaptionTextDiv>
                    {caption}
                </CaptionTextDiv>
                <ValueTextDiv>
                    {value}
                </ValueTextDiv>
            </MainAreaDiv>
            <ImageAreaDiv>
                {icon && (
                    <img style={{ marginBottom: icon.verticalShift }} src={icon.source.src} alt={caption} />
                )}
            </ImageAreaDiv>
        </RootDiv>
    );
}

export default MeasurementItem;
