import styled from "@emotion/styled";

interface UnderlineProps {
    isSelected: boolean;
}

const Underline = styled.div<UnderlineProps>(({ theme, isSelected }) => ([
    isSelected ? theme.splus3.background.vodafoneRed : { backgroundColor: "transparent" },
    {
        height: 2,
    }
]));

export default Underline;