import styled from "@emotion/styled";
import { SerializedStyles } from "@emotion/react";

interface RoundDivProps {
    size: number;
    background: SerializedStyles;
}

const RoundDiv = styled.div<RoundDivProps>(({ size, background }) => ([
    background,
    {
        borderRadius: "50%",
        width: size,
        height: size,
    }
]));

export default RoundDiv;