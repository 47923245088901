import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NODE_ENV, SEO_ENABLED, VARIANT } from '../../config';
import { logger } from '../../helper/logger';
import { Keys } from '../../helper/storage/common';
import { AppThunk } from '../../store';
import { isRunningInBrowser } from '../../helper/ssr';
import { getSdk } from '../../networkQuality';
import { ConsentState } from '../consent/consentSlice';

const MatomoServerLink = "https://speedtest.vodafone.de/mtm";

declare global {
    interface Window {
        _paq: unknown[] | undefined;
    }
}

interface MatomoState {
    started: boolean;
}

const initialState = {
    started: false
} as MatomoState;

const allowMatomo = (consentState: ConsentState): boolean => {
    if (VARIANT !== "SPLUS" && VARIANT !== "SPLUS3") {
        return false;
    }

    switch (NODE_ENV) {
        case "development": return consentState.analytics;
        case "test": return false;
        default: return consentState.analytics;
    }
}

export const initMatomo = (): AppThunk => (dispatch, getState) => {
    const matomoSiteId = SEO_ENABLED === "true" ? "1" : "2";

    const _paq = window._paq = window._paq || [];
    _paq.push(['requireConsent']);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    _paq.push(['setUserId', getSdk().clientId()]);

    _paq.push(["setTrackerUrl", `${MatomoServerLink}/matomo.php`]);
    _paq.push(["setSiteId", matomoSiteId]);
    addMatomoScript();

    const consentState = getState().consent;

    const enabled = allowMatomo(consentState);
    logger.info(`Init Matomo. Matomo is enabled = ${enabled}, data = ${sessionStorage.getItem(Keys.FcData)}`);
    if (enabled) {
        dispatch(startMatomo());
    } else {
        dispatch(stopMatomo());
    }
}

const addMatomoScript = () => {
    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    g.async = true;
    g.src = `${MatomoServerLink}/matomo.js`;
    s?.parentNode?.insertBefore(g, s);
}

export const startMatomo = (): AppThunk => (dispatch, getState) => {
    if (!isRunningInBrowser()) {
        return;
    }

    if (getState().matomo.started) {
        return;
    }

    const _paq = window._paq = window._paq || [];
    _paq.push(['setConsentGiven']);
    logger.info("start matomo");


    dispatch(matomoSlice.actions.updateStarted(true));
}

export const stopMatomo = (): AppThunk => (dispatch, getState) => {
    if (!isRunningInBrowser()) {
        return;
    }

    if (!getState().matomo.started) {
        return;
    }

    logger.info("stop matomo");

    const _paq = window._paq = window._paq || [];
    _paq.push(['forgetConsentGiven']);

    dispatch(matomoSlice.actions.updateStarted(false));
}


const matomoSlice = createSlice({
    name: 'matomo',
    initialState,
    reducers: {
        updateStarted: (state, action: PayloadAction<boolean>) => {
            state.started = action.payload;
        },
    }
});

export const { } = matomoSlice.actions;

export default matomoSlice;