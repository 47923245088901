import * as R from "ramda";
import { getLastModemSwapDisplay, setLastModemSwapDisplay } from "../../helper/storage";
import { AppThunk } from "../../store";
import { showModemSwapMessage } from "../dialogManager/dialogManagerSlice";
import { selectPrepareResult } from "../measurement/utils";

export const showCustomerCommunicationAfterMeasurement = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const waitPromise = new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1500);
  });

  await waitPromise;
  const prepareResult = selectPrepareResult(getState().measurement);
  const lastModemSwapDisplay = getLastModemSwapDisplay(); // TODO: The UI should render itself from store, not for local storage. Move the read of the local storage values to initialisation and copy them to the store.
  const show = prepareResult !== null && !R.isNil(prepareResult.init.communication?.modemSwapLink) &&
    (lastModemSwapDisplay == null || Date.now() - lastModemSwapDisplay.getTime() > 2 * 24 * 3600 * 1000);

  if (show) {
    dispatch(showModemSwapMessage());
    setLastModemSwapDisplay(new Date());
  }
}