import styled from "@emotion/styled";
import ModalDialog from "../elements/ModalDialog";
import t from "../../helper/t";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Phase } from "../../features/measurement/types";
import Button from "../elements/Button";
import { sentryReportsCancellationDialogContinueMeasurement, sentryReportsCancellationDialogStopMeasurement } from "../../features/sentryReports/sentryReportsSlice";
import { cancel } from "../../features/measurement/measurementSlice";
import { currentSubscription } from "../../networkQuality";
import { hideDialog } from "../../features/dialogManager/dialogManagerSlice";
import { openUrl } from "../../helper/openUrl";
import { useRouter } from "next/router";

const ModelDialogTitle = styled.p([
    props => props.theme.typography.title,
    props => props.theme.colors.grey,
    `width: 100%; text-align: center`
]);

const CancellationDialog: React.FC<{}> = () => {
    const measurementState = useAppSelector(state => state.measurement);
    const dispatch = useAppDispatch();
    const router = useRouter();
    
    return (
        <ModalDialog>
            <ModelDialogTitle>{t("Momentan läuft eine Messung!")}</ModelDialogTitle>
            <div style={{ display: measurementState.kind === Phase.Aborting ? "none" : "flex", gap: 24, flexWrap: "wrap", justifyContent: "center" }}>
                <Button onClick={async () => {
                    sentryReportsCancellationDialogStopMeasurement();
                    if (measurementState.kind !== Phase.Aborting) {
                        await dispatch(cancel(currentSubscription.subscription));
                        dispatch(hideDialog());
                        openUrl("/", router);
                    }
                }}>
                    {t("Messung beenden")}
                </Button>
                <Button onClick={() => {
                    sentryReportsCancellationDialogContinueMeasurement();
                    dispatch(hideDialog());
                }}>
                    {t("Messung fortführen")}
                </Button>
            </div>
        </ModalDialog>
    )
}

export default CancellationDialog;