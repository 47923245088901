/**
 * Formats the time in milliseconds
 * @param time_sec - Time in seconds
 * @returns string representing the time in ms
 */

import { PlainDate, plainDateToDate } from "../types";

export function formatPingTime(time_sec: number): string {
  const time_ms = time_sec * 1e3;
  return time_ms.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

export function formatPercentage(numerator: number, denominator: number | null | undefined): string | null {
  if (typeof denominator === 'number' && denominator > 0) {
    return (100 * numerator / denominator).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  } else {
    return null
  }
}

export const formatDate = (pd: PlainDate): string =>
  plainDateToDate(pd).toLocaleDateString([], { dateStyle: "short" });

export const formatTime = (pd: PlainDate): string =>
  plainDateToDate(pd).toLocaleTimeString([], { timeStyle: "short" });