import React, { Component, RefObject } from "react";
import * as standalone from "@fusion/fusion/dist/output/Fusion.Entrypoint.VelocityUI";
import { logger } from "src/helper/logger";


class CrossChart extends Component<standalone.ChartProps> {
    private chartHandle: standalone.ChartHandle = {
        canceller: () => { },
        updateProps: () => () => { }
    };

    private halogenRef: RefObject<HTMLDivElement> = React.createRef();
    constructor(props: standalone.ChartProps) {
        super(props);
    }

    render() {
        return (
            <div ref={this.halogenRef}>
            </div>
        );
    }
    componentDidMount() {
        const chartHandle = standalone.standaloneChartHandle({
            element: this.halogenRef.current
        });
        this.chartHandle = chartHandle;
        logger.info("crossChart component did mount");
    }

    componentDidUpdate(props: standalone.ChartProps) {
        this.chartHandle.updateProps(props);
    }

    componentWillUnmount() {
        this.chartHandle.canceller();
        logger.debug("crossChart component will unmount.");
    }
}

export default CrossChart;